import React from 'react';

import DetailSection from '@presenters/web/components/DetailSection';

import DetailSectionField from './DetailSectionField';

import { PRESIDENTIAL_REPRESENTATIVE } from '@domain/districts';

import { getConferenceHeading } from '@use-cases/districts/conferences';

import { getAddress } from '@utils/getAddress';

import { useTranslation } from '@external/react-i18next';

import { BaseAddress, Maybe } from '@typings/graphql';

interface Props {
  isPresRepRequested: boolean;
  phoneNumber?: string;
  prAccommodation?: Maybe<string>;
  prAddress?: Maybe<BaseAddress>;
}

const PresRepSection: React.FC<Props> = ({
  isPresRepRequested,
  phoneNumber,
  prAccommodation,
  prAddress,
}) => {
  const { t } = useTranslation();
  const address = getAddress(prAccommodation, prAddress);
  const isSectionShown = !!(
    isPresRepRequested &&
    (address.length || phoneNumber)
  );

  return isSectionShown ? (
    <DetailSection title={getConferenceHeading(t, PRESIDENTIAL_REPRESENTATIVE)}>
      <h4 className="mb-5 text-md font-normal">
        {t('conference-details.request-president-rep', 'Request President Rep')}
      </h4>

      {!!address.length && (
        <DetailSectionField
          title={t(
            'conference-details.accomodation-address',
            'Accommodation Address'
          )}
          description={address}
          descriptionClassname="m-0"
        />
      )}

      {phoneNumber && (
        <DetailSectionField
          title={t(
            'conference-details.accomodation-phone',
            'Accommodation Phone'
          )}
          description={phoneNumber}
        />
      )}
    </DetailSection>
  ) : null;
};

export default PresRepSection;
