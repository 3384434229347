import { isIE } from '@utils/browser-detection';

import tailwind from '@styles/tailwind.js';

export const controlCustomStyles = {
  border: 'none',
  boxShadow: 'none',
  fontWeight: 700,
  width: isIE ? '6.1rem' : 'auto',
  padding: '2px 0 0 0 ',
  background: 'transparent',
  '& [class*="singleValue"]': {
    display: 'contents',
    color: tailwind.theme.colors['dark-blue']['400'],
  },
};

export const indicatorsCustomStyles = {
  '& svg': {
    color: tailwind.theme.colors['dark-blue']['400'],
  },
  '& [class*="indicatorContainer"]': {
    padding: '8px 0 8px 0 !important',
  },
};

export const valueCustomStyles = {
  padding: '0 0 0 0.25rem',
};

export const menuCustomStyles = {
  width: '5.875rem',
  'z-index': '10',
};
