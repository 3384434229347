import React, { ReactNode } from 'react';

import Link from '@components/Link';

import { useMobileLayout } from '@hooks/useMobileLayout';

type Props = {
  to: string;
  children?: ReactNode[];
  passThrough?: {};
};

const ConferenceAction: React.FC<Props> = ({ to, children, passThrough }) => {
  const { isMobileLayout } = useMobileLayout();

  const mobileClasses =
    'w-full h-10 mt-3 rounded-full leading-10 text-xs \font-bold text-white bg-bright-blue-600 text-center uppercase link-styles-off';

  return (
    <Link
      className={isMobileLayout ? mobileClasses : ''}
      to={to}
      state={passThrough}
    >
      {children}
    </Link>
  );
};

export default ConferenceAction;
