import React from 'react';

import classNames from 'classnames';
import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import Checkbox from '@components/Formik/Checkbox';
import RadioField from '@components/Formik/RadioField';
import TextArea from '@components/Formik/TextArea';
import PrivacyPolicy from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/AddCandidate/AddressInformation/PrivacyPolicy';
import Profession from '@presenters/web/pages/Leads/MMLPage/IdentifyDistrictCandidate/Profession';
import ContactInformationAddress from '@presenters/web/pages/Profile/View/ContactInformation/ContactInformationAddress';
import ContactInformationEmail from '@presenters/web/pages/Profile/View/ContactInformation/ContactInformationEmail';
import ContactInformationPhone from '@presenters/web/pages/Profile/View/ContactInformation/ContactInformationPhone';

import { getClubTypeOptions } from '@domain/leads/referral/optionsList';

import { useTranslation } from '@external/react-i18next';

import { MemberDetailsQuery } from '@typings/operations';

export interface MemberDetailsProps {
  data: MemberDetailsQuery['addMemberGetIndividual']['individual'] | undefined;
  handleBackClick: () => void;
}

const AddDistrictContinue: React.FC<MemberDetailsProps> = ({
  children,
  data,
  handleBackClick,
}) => {
  const { t } = useTranslation();

  const initialValues = {
    ...data,
    club_type: 'rotary',
  };

  return (
    <>
      <p className="mt-6 mb-0">
        <button
          type="button"
          onClick={handleBackClick}
          className={classNames(
            'text-small font-bold text-bright-blue-600 link-styles'
          )}
        >
          &#8249; {t('page-steps.back-button', 'Back')}
        </button>
      </p>
      <div className="mt-5">
        <h2 className="mb-6">
          {t('mml.add-member-form.title', 'Add new candidate')}
        </h2>
      </div>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {({ values }) => {
          return (
            <Form>
              <div>
                <RadioField
                  name="club_type"
                  label="Club type"
                  required
                  options={getClubTypeOptions(t)}
                />
              </div>
              <h3 className="h5">
                {t('mml.add-member-form.member-id', 'Member ID')}
              </h3>
              {values.riIndividualId}

              {values.firstName && (
                <div className="mt-10">
                  <h3 className="h5">
                    {t('mml.add-member-form.first-name', 'First name')}
                  </h3>
                  {values.firstName}
                </div>
              )}

              {values.lastName && (
                <div className="mt-10">
                  <h3 className="h5">
                    {t('mml.add-member-form.last-name', 'Last name')}
                  </h3>
                  {values.lastName}
                </div>
              )}

              {values.primaryEmail && (
                <div className="mt-10">
                  <h3 className="h5">
                    {t('mml.add-member-form.email-primary', 'Primary email')}
                  </h3>
                  <ContactInformationEmail email={values.primaryEmail} />
                </div>
              )}

              {values.primaryPhone && (
                <div className="mt-10">
                  <h3 className="h5">
                    {t('mml.add-member-form.phone-primary', 'Primary phone')}
                  </h3>
                  <ContactInformationPhone phone={values.primaryPhone} />
                </div>
              )}

              {values.primaryAddress && (
                <div className="mt-10">
                  <ContactInformationAddress
                    address={values.primaryAddress}
                    label={t(
                      'mml.add-member-form.address-primary',
                      'Primary address'
                    )}
                  />
                </div>
              )}

              <div className="max-w-lg mt-10 mb-10">
                <Checkbox
                  name="intrested_info"
                  label={t(
                    'They’re interested in an online meeting meeting, if possible.'
                  )}
                />
              </div>
              <hr className="mb-12 border-t-0 border-b border-gray-300 w-full mt-6 desktop:mt-2 mb-6" />
              <p>
                {t(
                  'add-district-continue.form.more-info',
                  'Please tell us a little bit more about the candidate.'
                )}
              </p>
              <div className="max-w-lg mt-10">
                <Profession />
              </div>

              <div className="max-w-lg mt-10">
                <TextArea
                  name="comments"
                  label={t(
                    'mml.add-member-form.additional-comments',
                    'Additional comments'
                  )}
                  characters={250}
                  areaclasses="h-40"
                />
              </div>

              <div className="max-w-lg mt-10">
                <PrivacyPolicy />
              </div>

              <div className="max-w-lg">
                <Button full className="mt-16 mb-6" clickHandler={() => {}}>
                  {t('mml.add-member-form.submit', 'Submit')}
                </Button>
                {children}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddDistrictContinue;
