// Libs
import React from 'react';

// Utils
import tailwind from '@styles/tailwind.js';
import { getYearLabel } from '@use-cases/districts';

const textProps = (x: number = 30, y: number = 20) => ({
  x,
  y,
  textAnchor: 'end',
  fill: tailwind.theme.colors['gray']['600'],
});

const CustomizedXAxisTick = (props: any) => {
  const { x, y, payload } = props;
  const labelClassName = 'text-4xs desktop:text-2xs';

  return (
    <svg>
      <g transform={`translate(${x},${y})`}>
        {/* One line label for desktop */}
        <text
          {...textProps()}
          className={`${labelClassName} hidden desktop:block`}
        >
          {getYearLabel(payload.value)}
        </text>
        {/* Two lines label for mobile */}
        <text
          {...textProps(20, 15)}
          className={`${labelClassName} desktop:hidden`}
        >
          {payload.value - 1}-
        </text>
        <text
          {...textProps(16, 33)}
          className={`${labelClassName} desktop:hidden`}
        >
          {payload.value}
        </text>
      </g>
    </svg>
  );
};

export default CustomizedXAxisTick;
