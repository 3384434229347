// Libs
import React, { useState, ReactElement } from 'react';
import classNames from 'classnames';

// Components
import { Icon } from '@components/Icon';
import { InputCheckbox } from '@components/Forms/InputCheckbox';

// Hooks
import { useTranslation } from '@external/react-i18next';
import { UNASSIGNED } from '@components/DragAndDropContainers/constants/groupnames';
import ContextMenu from '../ContextMenu';

// Types
import { ContextMenuOptions, MenuItem } from '../ContextMenu/types';
import { AccordionClasses } from '../types';

interface Props {
  name: string;
  children?: ReactElement;
  itemCount?: number;
  itemText?: string;
  startOpened?: boolean;
  isIconReversed?: boolean;
  textBeforeName?: string;
  classesForTitle?: string;
  groupId: string;
  dataTestId?: string;
  contextMenuOptions?: ContextMenuOptions;
  classes?: AccordionClasses;
  checkBoxChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isCheckedPriorYearGroup?: boolean;
}

const AccordionItem: React.FC<Props> = props => {
  const {
    name,
    groupId,
    children = null,
    itemCount = 0,
    startOpened = false,
    isIconReversed = false,
    itemText = '',
    textBeforeName = '',
    contextMenuOptions,
    dataTestId = '',
    classes = { container: '', header: '', title: '' },
    checkBoxChangeHandler,
    isCheckedPriorYearGroup,
  } = props;
  const {
    title: contextMenuTitle,
    menuItems,
    currentItemId,
    handleMenuItemClick,
  } = contextMenuOptions || {};
  const {
    container: containerClassName,
    header: headerClassName,
    title: titleClassName,
  } = classes;

  const { t } = useTranslation();
  const isUnassigned = name === UNASSIGNED;
  const [sectionOpened, setSectionOpened] = useState<boolean>(startOpened);

  const handleClickOnAccordion = (): void => {
    setSectionOpened(!sectionOpened);
  };

  const getIconTypeName = (sectionOpened: boolean): string =>
    sectionOpened ? 'collapse' : 'expand';

  const getIconReversedName = (isIconReversed: boolean): string =>
    isIconReversed ? 'reversed' : '';

  const constructIconName = (): string => {
    return [
      'squares/icon',
      getIconTypeName(sectionOpened),
      getIconReversedName(isIconReversed),
    ]
      .filter(name => name !== '')
      .join('-');
  };

  const moveGroupItems = (menuItem: MenuItem) => {
    handleMenuItemClick?.(menuItem, groupId);
  };

  return (
    <div className={classNames(`${containerClassName} flex flex-wrap`)}>
      <div
        data-testid={dataTestId}
        className={classNames(
          `${headerClassName} accordion-header flex items-center cursor-pointer`,
          {
            'w-full tablet:w-1/2': isUnassigned,
          }
        )}
        onClick={handleClickOnAccordion}
      >
        <Icon size="16" name={constructIconName()} />
        <p
          className={classNames(
            `text-base m-0 ml-3 font-bold ${titleClassName}`
          )}
        >
          <span className="font-normal">{textBeforeName}</span> {name} (
          {itemCount} {itemText})
        </p>
        {contextMenuTitle && currentItemId && menuItems && (
          <ContextMenu
            dataTestId={`moved-group-${dataTestId}`}
            handleMenuItemClick={moveGroupItems}
            title={contextMenuTitle}
            className="m-0"
            currentItemId={currentItemId}
            menuItems={menuItems}
          />
        )}
      </div>
      {isUnassigned && (
        <InputCheckbox
          dataTestId="priorYearGroups"
          className="input-checkbox"
          id="priorYearGroups"
          name="priorYearGroups"
          label=""
          description={t(
            'club-assignments.prior-year-groups',
            'Prior year groups'
          )}
          checked={isCheckedPriorYearGroup}
          changeHandler={checkBoxChangeHandler}
        />
      )}
      {sectionOpened && (
        <div className={classNames({ 'pb-4 w-full': itemCount !== 0 })}>
          {children}
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
