import { gql } from '@apollo/client';

export const fetchTimeZones = gql`
  query getTimeZones {
    getTimeZones {
      id
      value
      timeZoneName
      offset
    }
  }
`;
