import { TFunction } from 'i18next';

export const getClubTypeOptionsForSelect = (t: TFunction) => [
  {
    label: t(
      'district-dashboard.select-option.rotary-and-rotaract-clubs',
      'All'
    ),
    value: 'All',
  },
  {
    label: t('district-dashboard.select-option.rotary-clubs', 'Rotary'),
    value: 'Rotary',
  },
  {
    label: t('district-dashboard.select-option.rotaract-clubs', 'Rotaract'),
    value: 'Rotaract',
  },
];
