// Libs
import React from 'react';

// Utils
import tailwind from '@styles/tailwind.js';

const CUSTOM_BAR_WIDTH = 48;

const CustomizedCursor: React.FC = (props: any) => {
  const { x, y, width } = props;

  return (
    <svg>
      <path
        pointerEvents="none"
        width={CUSTOM_BAR_WIDTH}
        height="419"
        fill={tailwind.theme.colors['gray']['200']}
        x={x}
        y={y}
        radius="0"
        d={`M ${x +
          (width - CUSTOM_BAR_WIDTH) /
            2},${y} h ${CUSTOM_BAR_WIDTH} v 335 h -${CUSTOM_BAR_WIDTH} Z`}
      />
    </svg>
  );
};

export default CustomizedCursor;
