import React from 'react';

import { useParams } from '@reach/router';

import DesktopItem from './DesktopItem';
import MobileItem from './MobileItem';

import { ConferenceItemProps } from '@domain/districts';

import { isPastConference } from '@use-cases/districts/conferences';

import { getRotaryYear } from '@utils/datetime';

const DistrictConferenceItem: React.FC<ConferenceItemProps> = props => {
  const { districtId } = useParams();
  const RY = Number(getRotaryYear());

  const {
    conference: {
      relatedRotaryYear,
      endDate,
      districtId: conferenceDistrictId,
    },
  } = props;

  const isMyDistrict = conferenceDistrictId === districtId;

  const isPastConf = isPastConference(endDate);
  const isThisYear = RY === relatedRotaryYear;
  const canEditConference = isThisYear && isMyDistrict && !isPastConf;

  const itemProps = {
    ...props,
    canEditConference,
    isMyDistrict,
  };

  return (
    <>
      <DesktopItem {...itemProps} />
      <MobileItem {...itemProps} />
    </>
  );
};

export default DistrictConferenceItem;
