import React from 'react';

import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import { ConfirmCandidateValidationSchema } from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/AddCandidate/ValidationSchema';
import ConfirmCandidateDetails from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/CandidateDetails/ConfirmCandidateDetails';
import { confirmCandidateInitialValues } from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/FormInfo';

import { useTranslation } from '@external/react-i18next';

interface Props {
  submitHandler: () => void;
  handleCancelClick: () => void;
}

const ConfirmCandidateDetailsForm: React.FC<Props> = ({
  submitHandler,
  handleCancelClick,
}) => {
  const { t } = useTranslation();
  const gridContainerClassName = 'tablet:w-1/2 desktop:w-1/2';
  const initialValues = confirmCandidateInitialValues;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validateOnBlur
      validationSchema={ConfirmCandidateValidationSchema(t)}
    >
      {({ values }) => {
        return (
          <Form>
            <p className="mb-8">
              {t(
                'membership_confirm_candidate_form.message',
                `Make sure the person’s information is correct and current. You can edit the information if needed.`
              )}
            </p>
            <div className={gridContainerClassName}>
              <ConfirmCandidateDetails values={values} />
              <p className="mb-12">
                {t(
                  'membership_confirm_candidate_form.legal_description',
                  `Your privacy and that of club members is important to Rotary and the personal data you share with Rotary will only be used for core business purposes. These purposes include financial processing, supporting The Rotary Foundation, facilitating event planning, communicating key organizational messages and responding to your inquiries. Rotary’s privacy policy can be found at <a target="_blank" href="https://my.rotary.org/en/privacy-policy">my.rotary.org/privacy-policy</a>. Questions about this policy can be directed to <a href="mailto:privacy@rotary.org">privacy@rotary.org</a>`
                )}
              </p>
              <Button full className="mb-8">
                {t('continue', 'Continue')}
              </Button>
              <Button
                secondary
                full
                className="mb-8"
                clickHandler={handleCancelClick}
              >
                {t('cancel', 'Cancel')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ConfirmCandidateDetailsForm;
