// Libs
import React from 'react';

// Utils

// Components
import Link from '@components/Link';

// Types
import { GetDetailsChartLinkName } from '@domain/districts';

import { isIE } from '@utils/browser-detection';

const textProps = (x = 0, y = 0) => ({
  textAnchor: 'end',
  transform: `translate(${x},${y})`,
});

type Props = {
  x?: number;
  y?: number;
  payload?: {
    index: number;
    value: string;
  };
  getLinkHref: (value: string) => string;
  getLinkName: GetDetailsChartLinkName;
  getLinkClass: (value: string) => string;
};

const DetailsChartYAxisTick: React.FC<Props> = ({
  x = 0,
  y = 0,
  payload = { index: 0, value: '' },
  getLinkHref,
  getLinkName,
  getLinkClass,
}) => {
  const xPosition = !isIE ? 0 : x;
  const yPosition = !isIE ? y - 17 : y;

  // disabled foreignObject element for IE
  const WrapperElement = !isIE ? 'foreignObject' : 'text';

  return (
    <svg>
      <g {...textProps(xPosition, yPosition)}>
        <WrapperElement width={135} height={45}>
          <Link
            noLinkStyles
            className={`text-xs text-gray-600 underline font-bold cursor-pointer block ${getLinkClass(
              payload.value
            )}`}
            data-testid="clubs-statistic-item"
            to={getLinkHref(payload.value)}
          >
            {getLinkName(payload.index, payload.value)}
          </Link>
        </WrapperElement>
      </g>
    </svg>
  );
};

export default DetailsChartYAxisTick;
