import React from 'react';

import { sumBy } from 'lodash';

import DetailsChart from '@presenters/web/pages/Districts/Details/DetailsChart/DetailsChart';

import BlockWrapper from './BlockWrapper';

import { CLUB_FILTERS, DetailsChartData } from '@domain/districts';

import { getClubTypesLabels, getLabelValue } from '@use-cases/districts';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useMobileLayout } from '@hooks/useMobileLayout';

import { DistrictClubsStatistics } from '@typings/operations';

type Props = {
  clubsStatistics: DistrictClubsStatistics[];
  districtId: string;
};

const Clubs: React.FC<Props> = ({ clubsStatistics, districtId }) => {
  const { t } = useTranslation();

  const totalCount = sumBy(clubsStatistics, 'totalCount');

  const chartData = clubsStatistics.map(
    ({ clubType, totalCount }): DetailsChartData => ({
      name: clubType,
      totalCount,
    })
  );

  const { isMobileLayout } = useMobileLayout();

  return (
    <BlockWrapper
      title={t(
        'district-details.clubs-heading',
        'District Clubs ({{totalCount}})',
        {
          totalCount,
        }
      )}
      handleClick={() => localizedNavigate(`/district/${districtId}/clubs`)}
    >
      <DetailsChart
        chartData={chartData}
        getLinkHref={(value: string) =>
          `/district/${districtId}/clubs?${CLUB_FILTERS.CLUB_TYPES}=${value}`
        }
        getLinkName={(index = 0, value = '') =>
          `${getLabelValue(getClubTypesLabels(t), value)?.label} (${
            chartData[index].totalCount
          })`
        }
        getLinkClass={(value: string) =>
          `leading-relaxed ${value.includes('Satellite') ? 'w-24' : 'pt-2'}`
        }
        graphWidth={isMobileLayout ? '100%' : '75%'}
        graphHeight={200}
        graphBarWidth={125}
      />
    </BlockWrapper>
  );
};

export default Clubs;
