import React, { useEffect, useState } from 'react';

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import CustomizedActiveDot from '../components/CustomizedActiveDot';
import CustomizedYAxisTick from '../components/CustomizedYAxisTick';
import CurrentMonthInfo from './CurrentMonthInfo';
import CustomizedAxisTick from './CustomizedAxisTick';
import CustomizedTooltip from './CustomizedTooltip';
import Details from './Details';
import LegendContent from './LegendContent';

import { MembersTrendsChartData } from '@domain/districts';

import {
  getMembershipTrendsDetails,
  getMinMaxChartValues,
  getYearsRangeLabels,
  YEAR_COLORS,
} from '@use-cases/districts/chartsDataMappers';

import tailwind from '@styles/tailwind.js';

import { useTranslation } from '@external/react-i18next';
import { useWindowSize } from '@hooks/useWindowSize';

type SetLineIndex = (value: number | null) => void;

const getLineProps = (
  color: string,
  index: number,
  setLineIndex: SetLineIndex
) => ({
  stroke: color,
  strokeWidth: 2,
  dot: {
    fill: YEAR_COLORS[index],
    r: 5,
    onMouseOver: () => setLineIndex(index),
  },
  text: {
    scaleToFit: true,
  },
});

const MembreshipTrendsChart: React.FC<{
  chartData: MembersTrendsChartData[];
  availableYears: Array<number | undefined>;
  asOfDate: string;
}> = ({ chartData, availableYears, asOfDate }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [lineIndex, setLineIndex] = useState(null);
  const [isMobileLayout, setIsMobileLayout] = useState<boolean>(false);

  const { minValue, maxValue, yAxisTicks } = getMinMaxChartValues(chartData);

  const yearsRange = getYearsRangeLabels(undefined, availableYears);

  const resetLineIndex = () => setLineIndex(null);

  useEffect(() => {
    setIsMobileLayout(width < parseInt(tailwind.theme.screens.desktop, 10));
  }, [width]);

  const detailsData = getMembershipTrendsDetails(yearsRange, chartData, t);

  return (
    <>
      <div className="desktop:hidden">
        <CurrentMonthInfo chartData={chartData} asOfDate={asOfDate} />
      </div>
      <ResponsiveContainer width="100%" height={460}>
        <LineChart
          data={chartData}
          margin={
            isMobileLayout
              ? { top: 5, right: 2, left: -21, bottom: 30 }
              : { top: 5, right: 0, left: -10, bottom: 60 }
          }
          onMouseLeave={resetLineIndex}
        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis
            dataKey="month"
            tick={<CustomizedAxisTick />}
            tickSize={0}
            padding={{ right: 15 }}
            interval="preserveStartEnd"
            minTickGap={10}
          />
          <YAxis
            domain={[minValue, maxValue]}
            tick={
              <CustomizedYAxisTick
                isMobileLayout={isMobileLayout}
                shouldRotateTicksOnMobile
              />
            }
            ticks={yAxisTicks}
            tickSize={0}
            tickCount={5}
          />
          <Legend
            {...(isMobileLayout
              ? {
                  wrapperStyle: { left: 0 },
                }
              : {
                  layout: 'vertical',
                  align: 'right',
                  verticalAlign: 'top',
                  wrapperStyle: { top: 0, right: -35 },
                })}
            content={<LegendContent />}
          />
          {yearsRange.map((label, index) => (
            <Line
              dataKey={label}
              {...getLineProps(
                YEAR_COLORS[index],
                index,
                setLineIndex as SetLineIndex
              )}
              activeDot={props => (
                <CustomizedActiveDot
                  {...props}
                  elementIndex={index}
                  lineIndex={lineIndex}
                  onMouseOut={resetLineIndex}
                />
              )}
              key={label}
            />
          ))}
          {!isMobileLayout && (
            <Tooltip
              content={({ active, payload }) => (
                <CustomizedTooltip
                  active={active}
                  payload={payload}
                  lineIndex={lineIndex}
                  asOfDate={asOfDate}
                  yearsRange={yearsRange}
                />
              )}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
      <Details className="desktop:hidden -mx-8" detailsData={detailsData} />
    </>
  );
};

export default MembreshipTrendsChart;
