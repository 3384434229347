import { gql } from '@apollo/client';

export const individualHATEOSLinksQuery = gql`
  query fetchIndividualHATEOSLinks($individualId: String!) {
    getIndividual(id: $individualId) {
      hateoasLinks {
        href
        method
      }
    }
  }
`;
