import React, { useState } from 'react';

import classNames from 'classnames';

import { Select } from '@components/Forms/Select';

import { RoleOption, ROLES_REQUIRED_BY_POLICY } from '@domain/districts';

import { useTranslation } from '@external/react-i18next';
import { OptionTypeBase, ValueType } from '@external/react-select';

import { TranslatedOption } from '@typings/translation';

interface Props {
  handleChange?: (item: RoleOption) => void;
  rolesToAssign: RoleOption[];
  initialValue?: RoleOption | null;
  isRequiredRoleText?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isResetValue?: boolean;
}

const SelectRole: React.FC<Props> = ({
  handleChange,
  rolesToAssign,
  initialValue = null,
  isRequiredRoleText = true,
  isLoading,
  isDisabled,
  isResetValue,
}) => {
  const selectorName = 'select-rotary-year';

  const formattedRoleName = (roleName: string): string =>
    ROLES_REQUIRED_BY_POLICY.includes(roleName) ? `${roleName}*` : roleName;

  const [selectedValue, setSelectedValue] = useState<ValueType<OptionTypeBase>>(
    {
      value: initialValue,
      label: formattedRoleName(initialValue?.name || ''),
    }
  );
  const { t } = useTranslation();

  const onChange = (item: ValueType<OptionTypeBase>) => {
    setSelectedValue(item);
    handleChange?.((item as OptionTypeBase).value);
  };

  const requiredRoleTextContainer = (
    { key, name }: TranslatedOption = {
      key: 'represents-role-required-by-policy',
      name: 'Represents Role required by Policy',
    }
  ) => <div className="text-xs text-gray-600 mb-1">{`* ${t(key, name)}`}</div>;

  const placeholderText = `--${t('select-placeholder', 'Select')}--`;

  const getValue = () => {
    if (isResetValue) {
      return null;
    }

    const { value } = selectedValue as OptionTypeBase;
    return value ? selectedValue : placeholderText;
  };

  return (
    <Select
      options={rolesToAssign.map(item => ({
        value: item,
        label: formattedRoleName(item.name),
      }))}
      defaultValue={initialValue ? selectedValue : null}
      value={getValue() as OptionTypeBase}
      onChange={onChange}
      id={selectorName}
      name={selectorName}
      isSelectedOptionBold
      placeholder={placeholderText}
      labelClassName={classNames({ '-my-4': isRequiredRoleText })}
      prefix={isRequiredRoleText && requiredRoleTextContainer()}
      label={t(
        'available-district-committee-roles',
        'Available district committee roles'
      )}
      isLoading={isLoading}
      disabled={isDisabled}
    />
  );
};

export default SelectRole;
