import React, { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '@components/Loading';
import { titleTemplateParams } from '@components/Title/util';

import Clubs from './Clubs';
import ContactsView from './Contacts/ContactsView';
import Members from './Members';
import Officers from './Officers';

import {
  CLUBS_TYPES,
  MEMBERSHIP_STATUSES,
  OFFICERS_ROLES,
} from '@domain/districts';

import { useDISCountries } from '@repositories/disCountry';
import {
  useFetchAllDistrictOfficers,
  useFetchClubsStatistics,
  useFetchMembersStatictics,
} from '@repositories/districts';

import { getRotaryYear } from '@utils/datetime';
import { getDistrictDetailsPath } from '@utils/districts';
import { FEATURE_DOMINO_ORGANIZATION, isEnabled } from '@utils/features';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

import { DistrictDis3, DistrictOfficer } from '@typings/graphql';
import { FetchDistrictDetailsQuery } from '@typings/operations';

interface Props extends RouteComponentProps {
  districtId: string;
  riDistrictId?: number | null;
  hasPermissionsToEditContactInfo?: boolean;
  districtData: FetchDistrictDetailsQuery;
  districtDataLoading: boolean;
}

const DistrictDetails: React.FC<Props> = ({
  districtId,
  riDistrictId,
  hasPermissionsToEditContactInfo = false,
  districtData,
  districtDataLoading,
}) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const { user } = useAppConfig();

  const [
    fetchClubsStatistics,
    { data, loading: clubsLoading },
  ] = useFetchClubsStatistics();

  const [
    fetchDistrictOfficers,
    { data: officersData, loading: officersLoading },
  ] = useFetchAllDistrictOfficers();

  const [
    fetchMembersStatistics,
    { data: membersStatistics, loading: membersStatLoading },
  ] = useFetchMembersStatictics();

  const { data: countriesData, loading: countriesLoading } = useDISCountries();

  useEffect(() => {
    if (user?.isLoggedIn && districtId) {
      fetchClubsStatistics({
        variables: {
          districtId,
          status: 'Active',
          types: CLUBS_TYPES,
        },
      });
    }
  }, [districtId, user?.isLoggedIn]);

  useEffect(() => {
    if (user?.isLoggedIn && districtId && riDistrictId) {
      let optionalVariables;
      if (isEnabled(FEATURE_DOMINO_ORGANIZATION)) {
        optionalVariables = {
          options: {
            shouldFetchDominoOfficers: true,
          },
          page: 0,
          pageSize: 10,
        };
      } else {
        optionalVariables = {
          options: {
            shouldFetchNonMemberDES: true,
          },
        };
      }
      fetchDistrictOfficers({
        variables: {
          riDistrictId,
          districtRoles: OFFICERS_ROLES,
          districtId,
          rotaryYear: getRotaryYear(),
          ...optionalVariables,
        },
      });
    }
  }, [districtId, riDistrictId, user?.isLoggedIn]);

  useEffect(() => {
    if (user?.isLoggedIn && riDistrictId) {
      fetchMembersStatistics({
        variables: {
          riDistrictId: `${riDistrictId}`,
          types: MEMBERSHIP_STATUSES,
        },
      });
    }
  }, [riDistrictId, user?.isLoggedIn]);

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-details',
          '{{prefix}} District details {{suffix}}',
          { prefix, suffix }
        )}
      />
      {clubsLoading ||
      officersLoading ||
      membersStatLoading ||
      districtDataLoading ||
      countriesLoading ? (
        <Loading />
      ) : (
        <div className="pt-4 tablet:flex">
          <div className="desktop:flex-2">
            <ContactsView
              districtDetails={districtData?.districtDetails as DistrictDis3}
              countriesData={countriesData?.countries}
              hasPermissionsToEditContactInfo={hasPermissionsToEditContactInfo}
            />
            <Clubs
              clubsStatistics={data?.statistics || []}
              districtId={districtId}
            />
          </div>
          <div className="desktop:flex-1">
            <Members
              membersStatistics={membersStatistics?.statistics || []}
              districtId={districtId}
            />
            <Officers
              officers={
                (officersData?.districtOfficers
                  .leadership as DistrictOfficer[]) || []
              }
              districtId={districtId}
              profilePreviousLinkPath={getDistrictDetailsPath(districtId)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DistrictDetails;
