import React from 'react';

import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import AddressFields from '@components/Formik/Address';
import Checkbox from '@components/Formik/Checkbox';
import { getCandidateFormInformation } from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/FormInfo';
import {
  AddressProps,
  CandidateFormFieldsProps,
  FormAddressFieldsType,
} from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/types';
import MoreInformation from '@presenters/web/pages/Leads/MMLPage/IdentifyDistrictCandidate/AddressInformation/MoreInformation';
import { addNewCandidateAddressInformationValidationSchema } from '@presenters/web/pages/Leads/validationSchema';
import AddressTypeSelect from '@presenters/web/pages/Profile/Edit/ContactInformation/AddressForm/AddressTypeSelect';

import { getDistrictMembershipCandidatePath } from '@use-cases/leads';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface Props {
  submitHandler: () => void;
  districtId: string;
}

const CandidateAddressFormDetails: React.FC<Props> = ({
  submitHandler,
  districtId,
}) => {
  const { t } = useTranslation();
  const gridContainerClassName = 'tablet:w-1/2 desktop:w-1/2';
  const fieldProps: CandidateFormFieldsProps = getCandidateFormInformation(
    t,
    'candidateAddressDetails'
  );
  const { profession, additionalComments } = fieldProps;
  const formikProps = {
    type: '',
    interestGroupId: '',
    primaryAddress: {
      lineOne: '',
      lineTwo: '',
      lineThree: '',
      city: '',
      postalCode: '',
      state: '',
      stateId: '',
      countryId: '',
    },
  };
  const handleCancel = () => {
    localizedNavigate(getDistrictMembershipCandidatePath(districtId));
  };

  return (
    <>
      <h2 className="mt-5 mb-10 mobile:mb-5">
        {t('mml.add-candidate-form.title', 'Add new candidate')}
      </h2>
      <Formik
        initialValues={formikProps}
        onSubmit={submitHandler}
        validationSchema={addNewCandidateAddressInformationValidationSchema(t)}
      >
        {({ values }) => {
          const { countryId } = values.primaryAddress as AddressProps;
          const { interestGroupId } = values;
          const isAddressRequired = Boolean(values.primaryAddress.lineOne);
          return (
            <Form>
              <div className={gridContainerClassName}>
                <AddressTypeSelect
                  name="type"
                  label={t(
                    'mml.add-new-candidate.form.address-type-label',
                    'Candidate’s address type'
                  )}
                  required={isAddressRequired}
                />
                <AddressFields
                  countryId={countryId}
                  {...(fieldProps.addressFields
                    .formAddress as FormAddressFieldsType)}
                  requiredFields={{ lineOne: false }}
                  postalcode={t(
                    'mml.add-new-candidate.form.postalcode-label',
                    'Postal code'
                  )}
                />
                <Checkbox
                  name="mml.add-new-candidate-interest"
                  label={t(
                    'mml.add-new-candidate.form.meeting-interest',
                    'They are interested in an online meeting option, if possible.'
                  )}
                />
              </div>
              <hr className="border-t-0 border-b border-gray-300 w-full mt-6 desktop:mt-2 mb-6" />
              <MoreInformation
                interestGroupId={interestGroupId as string}
                fieldProps={{
                  profession,
                  additionalComments,
                }}
              />
              <div className="desktop:flex-row max-w-lg mt-6 desktop:mt-10 mb-6 desktop:mb-10">
                <Button type="submit" full>
                  {t('mml.add-new-candidate.form.submit-label', 'Submit')}
                </Button>
                <Button
                  full
                  text
                  type="button"
                  className="mt-6"
                  clickHandler={handleCancel}
                >
                  {t('mml.add-new-candidate.form.cancel-label', 'Cancel')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CandidateAddressFormDetails;
