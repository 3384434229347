import React from 'react';

import LinkEdit from '@components/LinkEdit';
import MailingAddress from '@presenters/web/components/MailingAddress';
import TitleLink from '@presenters/web/components/TitleLink';

import Section from './Section';

import { getFormattedPhoneNumber } from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';

import { AccPhone, Country, DistrictDis3 } from '@typings/graphql';

type Props = {
  districtDetails?: DistrictDis3;
  countriesData?: Country[];
  hasPermissionsToEditContactInfo: boolean;
};

const ContactsView: React.FC<Props> = ({
  districtDetails,
  countriesData,
  hasPermissionsToEditContactInfo,
}) => {
  const { t } = useTranslation();

  const {
    id,
    districtLegalName,
    zone,
    zoneSection,
    primaryEmail,
    primaryPhone,
    primaryFax,
    primaryWebsite,
    businessAddress,
  } = districtDetails || {};

  const editContactPath = `/district/${id}/details/edit-contact`;
  const titleText = t(
    'district-details.contacts-heading',
    'Contact Information'
  );
  const noDetailsInSection = t('district-details.no-details', 'None');
  const isLegalDescriptionSectionShown =
    districtLegalName && (zone || zoneSection);
  const address = businessAddress?.[0];

  return (
    <div className="mb-8">
      <div className="flex justify-between mr-10">
        <TitleLink title={titleText} isArrowShown={false} />
        {hasPermissionsToEditContactInfo && (
          <LinkEdit path={editContactPath} component={titleText} />
        )}
      </div>
      <Section title={t('district-details.description', 'Legal Description')}>
        {isLegalDescriptionSectionShown
          ? t(
              'district-details.legalName',
              '{{districtLegalName}}; Zone {{zone}}',
              { districtLegalName, zone: zone || zoneSection }
            )
          : noDetailsInSection}
      </Section>

      <Section title={t('district-details.email-primary', 'Email Address')}>
        {primaryEmail ? <div>{primaryEmail}</div> : noDetailsInSection}
      </Section>

      <Section title={t('district-details.phone-primary', 'Primary Number')}>
        {primaryPhone?.number ? (
          <div>
            {getFormattedPhoneNumber(primaryPhone as AccPhone, countriesData)}
          </div>
        ) : (
          noDetailsInSection
        )}
      </Section>

      <Section title={t('district-details.fax-primary', 'Fax Number')}>
        {primaryFax?.number ? (
          <div>
            {getFormattedPhoneNumber(primaryFax as AccPhone, countriesData)}
          </div>
        ) : (
          noDetailsInSection
        )}
      </Section>

      <Section title={t('district-details.website', 'Website')}>
        {primaryWebsite ? (
          <a href={primaryWebsite} target="_blank" rel="noreferrer">
            {primaryWebsite}
          </a>
        ) : (
          noDetailsInSection
        )}
      </Section>

      <Section title={t('district-details.address-mailing', 'Mailing Address')}>
        {address ? <MailingAddress {...address} /> : noDetailsInSection}
      </Section>
    </div>
  );
};

export default ContactsView;
