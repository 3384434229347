// Libs
import React from 'react';
import qs from 'query-string';

// Hooks
import { useTranslation } from '@external/react-i18next';
import { useLocation } from '@reach/router';

// Utils
import { getDGListingOptionLabels } from './utils';

type Props = {
  optionSeq: number;
};

const CustomDGListingOptions: React.FC<Props> = ({ optionSeq }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { relatedRotaryYear } = qs.parse(location!.search);

  // Custom question should be displayed for PR form for 2021 year
  if (optionSeq !== 50 && relatedRotaryYear !== '2021') return null;

  const { name, yearsServed } = getDGListingOptionLabels(t);

  return (
    <>
      <div className="text-small font-bold block text-gray-600 mb-3 mt-3">
        35.{' '}
        {t(
          'feedback-form.custom-question.governors-listing',
          'Please list any past district governors who were involved in the conference and should be considered for future service in RI:'
        )}
      </div>
      <div className="mobile:hidden tablet:flex text-small font-bold text-gray-600">
        <div className="w-50pct">{name}</div>
        <div className="w-50pct">{yearsServed}</div>
      </div>
    </>
  );
};

export default CustomDGListingOptions;
