import React from 'react';

import AddressFields from '@components/Formik/Address';
import DateOfBirth from '@components/Formik/DateOfBirth';
import GenderInputs from '@components/Formik/GenderInputs';
import PersonalNameForm from '@components/Formik/PersonalNameForm';
import PhoneInput from '@components/Formik/PhoneInput';
import LanguageSelect from '@components/Formik/Select/LanguageSelect';
import TextField from '@components/Formik/TextField';

import { MemberDetailsFormValues } from '@domain/clubs';

import { useTranslation } from '@external/react-i18next';

interface FormProps {
  values: MemberDetailsFormValues;
  handleBlurEmailHandler: () => void;
  emailMessage: string;
}

const IndividualDetailsForm: React.FC<FormProps> = ({
  values,
  handleBlurEmailHandler,
  emailMessage,
}) => {
  const { t } = useTranslation();

  const primaryPhoneLabels = {
    phoneCode: t('phone_extension', 'Phone Extension'),
    phoneNumber: t('phone_number', 'Phone Number'),
  };
  const isPrimaryPhoneRequired = Boolean(
    values.primaryPhone?.countryId || values.primaryPhone?.number
  );

  return (
    <>
      <PersonalNameForm isFullNameRequired />
      <GenderInputs />
      <DateOfBirth
        isYouthFlag={false}
        isDayRequired={Boolean(values.monthOfBirth)}
        isMonthRequired={Boolean(values.dayOfBirth)}
      />
      <TextField
        name="email"
        label={t('email', 'Email')}
        onBlurHandler={handleBlurEmailHandler}
        required
        extraClasses={emailMessage ? 'bg-red-100 border-red-600' : ''}
      />
      {emailMessage && (
        <p className="form-error mb-2 -mt-4 text-xs text-red-600">
          {emailMessage}
        </p>
      )}
      <PhoneInput
        suffix="primaryPhone"
        labels={primaryPhoneLabels}
        required
        isCountryFlagRequired={isPrimaryPhoneRequired}
      />
      <AddressFields
        countryId={values.primaryAddress?.countryId}
        name={{
          lineTwo: 'primaryAddress.lineTwo',
          lineOne: 'primaryAddress.lineOne',
          lineThree: 'primaryAddress.lineThree',
          countryId: 'primaryAddress.countryId',
          city: 'primaryAddress.city',
          stateId: 'primaryAddress.stateId',
          internationalProvince: 'primaryAddress.state',
          hasStates: 'primaryAddress.hasStates',
          postalCode: 'primaryAddress.postalCode',
        }}
        placeholder={{
          lineTwo: t('second-line-adress', 'Second line optional'),
          lineThree: t('third-line-adress', 'Third line optional'),
        }}
      />
      <LanguageSelect
        name="primaryLanguage"
        label={t('language', 'Language')}
        clearable
        required
      />
    </>
  );
};

export default IndividualDetailsForm;
