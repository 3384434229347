// Libs
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import { intersection } from 'lodash';

import {
  actionLookup,
  APIResponseMethodsDivider,
  CRUDAction,
  CRUDResource,
} from '@domain/abilities';

const resourceHrefLookup = {
  [CRUDResource.Conference]: (id?: string) =>
    id ? `/conferences/${id}` : '/conferences',
  [CRUDResource.ConferenceFeedback]: (id?: string) =>
    `/conferences/${id}/feedback`,
};

type Nullable<T> = T | null;

export const getAbility = <T extends { href: string; method: string }>(
  // eslint-disable-next-line default-param-last
  hateoasLinks: Nullable<T[]> = [],
  action: CRUDAction,
  resource: CRUDResource,
  id?: string
) =>
  pipe(
    O.fromNullable(hateoasLinks),
    O.chain(
      A.findFirst(({ href }) => href === resourceHrefLookup[resource](id))
    ),
    O.map(({ method }) => method.split(APIResponseMethodsDivider)),
    O.map(methods => !!intersection(actionLookup[action], methods).length),
    O.getOrElse(() => false)
  );
