import React, {
  Reducer,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { RouteComponentProps } from '@reach/router';

import { Button } from '@components/Button';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import PageSteps from '@components/PageSteps';
import IndividualDetails from '@presenters/web/components/Forms/IndividualDetails';

import AssignDistrictExecutiveSecretary from '.';
import ConfirmDistrictExecSecretary from './ConfirmDistrictExecSecretary';
import ReplaceDistrictExecSecretary from './ReplaceDistrictExecSecretary';

import {
  IdentificationParameters,
  mapMemberValuesToFormInput,
  MemberDetailsInput,
  MemberDetailsOutput,
} from '@domain/clubs';
import { OperationType } from '@domain/districts';
import { DISTRICT_EXECUTIVE_SECRETARY } from '@domain/districts/constants';

import {
  buildOfficersPageUrl,
  DistrictContext,
  useModal,
  useStopBrowserNavigate,
} from '@use-cases/districts';
import { useErrorHandling } from '@use-cases/notifications';

import {
  useCreateIndividual,
  useFetchMemberDetails,
} from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  districtId: string;
  riDistrictId?: number | null;
  isLoading: boolean;
}

const AddDistrictExecSecretary: React.FC<Props> = ({
  districtId: id,
  riDistrictId,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [context] = useContext(DistrictContext);
  const { operationType } = context;

  enum AddOfficerSteps {
    INFO,
    IDENTIFY,
    ASSIGN_CONFIRMATION,
    REPLACE_CONFIRMATION,
  }

  const [step, setStep] = useState<AddOfficerSteps>(AddOfficerSteps.IDENTIFY);
  const isFirstStep = step === AddOfficerSteps.IDENTIFY;
  const roleName = DISTRICT_EXECUTIVE_SECRETARY;

  const [filters, setFilters] = useReducer<
    Reducer<IdentificationParameters, IdentificationParameters>
  >((state, action) => {
    return { ...state, ...action };
  }, {});

  const [selectedMember, selectMember] = useState<undefined | string | false>(
    undefined
  );

  // When a member has been selected, attempt to load data from the api.
  const { data, loading, error } = useFetchMemberDetails(
    selectedMember || '',
    '',
    'District'
  );

  useErrorHandling(error?.message, !!error, 'member-details.error');

  // If member data has been loaded, message it into InitialData.
  const individual = data?.addMemberGetIndividual;

  const initialData = mapMemberValuesToFormInput(individual);

  // Member data that has been reviewed or created by the user.
  const [memberDataReviewed, setMemberDataReviewed] = useState<
    MemberDetailsInput | undefined | null
  >();

  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [individualId, setIndividualId] = useState<string>('');
  const [individualEmail, setIndividualEmail] = useState<string>('');
  const [individualName, setIndividualName] = useState<string>('');
  const [isNewIndividual, setFlagForNewIndividual] = useState<boolean>(false);

  const handleBackClick = () => {
    if (isFieldTouched) {
      show(true);
    } else if (isFirstStep) {
      localizedNavigate(buildOfficersPageUrl(id));
    } else {
      setStep(AddOfficerSteps.IDENTIFY);
    }
  };

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: handleBackClick,
  });

  const [
    createIndividual,
    {
      error: createIndividualError,
      called: createIndividualCalled,
      loading: createIndividualLoading,
    },
  ] = useCreateIndividual();

  useErrorHandling(
    error?.message,
    !!createIndividualError,
    'create-invidividual.error'
  );

  const modalOnConfirmHandler = () => {
    globalHide();
    if (isFirstStep) {
      localizedNavigate(buildOfficersPageUrl(id));
    } else {
      setStep(AddOfficerSteps.IDENTIFY);
    }
  };

  const handleFormFieldsTouched = (isTouched: boolean) => {
    setIsFieldTouched(isTouched);
  };

  const handleCancelClick = () => {
    if (isFieldTouched) {
      show(true);
    } else if (isFirstStep) {
      localizedNavigate(buildOfficersPageUrl(id));
    } else {
      setStep(AddOfficerSteps.IDENTIFY);
    }
    setGlobal(false);
  };

  const BackButton = () =>
    useMemo(() => {
      return (
        <>
          <Button clickHandler={handleCancelClick} text full>
            {t('add_member.identify_member_form.cancel_button', 'Cancel')}
          </Button>
          <LeaveFormConfirmationModal
            isOpen={isShowing}
            closeModal={globalHide}
            onConfirm={modalOnConfirmHandler}
          />
        </>
      );
    }, [isShowing]);

  if (
    isLoading ||
    loading ||
    (createIndividualLoading && createIndividualCalled)
  ) {
    return <Loading />;
  }

  const noPaddingClass = 'p-0';
  const navClassName = 'text-small font-bold mt-4 mb-0';

  const handleSubmit = async (membershipData: MemberDetailsOutput) => {
    let individualId = null;
    if (!selectedMember) {
      const result = await createIndividual({
        variables: membershipData,
      });

      individualId = String(result.data?.createIndividual.individualId);
      setIndividualId(individualId);

      if (result.data && operationType === OperationType.ASSIGN_TO_MEMBER) {
        setStep(AddOfficerSteps.ASSIGN_CONFIRMATION);
      } else if (
        result.data &&
        operationType === OperationType.REPLACE_OFFICER
      ) {
        setStep(AddOfficerSteps.REPLACE_CONFIRMATION);
      }
    }
  };

  const setExistingMemberData = (
    id: string,
    email: string | undefined,
    name: string | undefined
  ) => {
    setIndividualId(id);
    setIndividualEmail(email || '');
    setIndividualName(name || '');
  };

  switch (step) {
    case AddOfficerSteps.IDENTIFY:
      return (
        <PageSteps
          step={1}
          total={3}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <AssignDistrictExecutiveSecretary
            submitHandler={(
              id: string | false | undefined,
              email: string | undefined,
              name: string | undefined
            ) => {
              setMemberDataReviewed(null);
              selectMember(id);
              if (!id) {
                setFlagForNewIndividual(true);
                setStep(AddOfficerSteps.INFO);
              } else if (
                id &&
                operationType === OperationType.ASSIGN_TO_MEMBER
              ) {
                setExistingMemberData(id, email, name);
                setStep(AddOfficerSteps.ASSIGN_CONFIRMATION);
              } else if (
                id &&
                operationType === OperationType.REPLACE_OFFICER
              ) {
                setExistingMemberData(id, email, name);
                setStep(AddOfficerSteps.REPLACE_CONFIRMATION);
              }
            }}
            filters={filters}
            setFilters={setFilters}
            handleFormFieldsTouched={handleFormFieldsTouched}
            riDistrictId={id}
            roleName={roleName}
          >
            <BackButton />
          </AssignDistrictExecutiveSecretary>
        </PageSteps>
      );
    case AddOfficerSteps.INFO:
      return (
        <PageSteps
          step={2}
          total={3}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <IndividualDetails
            data={memberDataReviewed || initialData}
            initialData={initialData}
            roleName={roleName}
            submitHandler={(formData, dataToUpdate) => {
              setMemberDataReviewed(formData);
              handleSubmit(dataToUpdate);
            }}
            handleFormFieldsTouched={handleFormFieldsTouched}
          >
            <BackButton />
          </IndividualDetails>
        </PageSteps>
      );
    case AddOfficerSteps.ASSIGN_CONFIRMATION:
      return (
        <PageSteps
          step={isNewIndividual ? 3 : 2}
          total={isNewIndividual ? 3 : 2}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <ConfirmDistrictExecSecretary
            districtId={id}
            riDistrictId={riDistrictId}
            officerId={individualId}
            officerEmail={memberDataReviewed?.email || individualEmail}
            officerName={memberDataReviewed?.localizedName || individualName}
          />
        </PageSteps>
      );
    case AddOfficerSteps.REPLACE_CONFIRMATION:
      return (
        <PageSteps
          step={isNewIndividual ? 3 : 2}
          total={isNewIndividual ? 3 : 2}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <ReplaceDistrictExecSecretary
            districtId={id}
            riDistrictId={riDistrictId}
            officerId={individualId}
            officerEmail={memberDataReviewed?.email || individualEmail}
            officerName={memberDataReviewed?.localizedName || individualName}
          />
        </PageSteps>
      );
    default:
      return null;
  }
};

export default AddDistrictExecSecretary;
