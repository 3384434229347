import React, { useEffect } from 'react';

import { RouteComponentProps, useParams } from '@reach/router';

import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';
import { titleTemplateParams } from '@components/Title/util';
import DetailSection from '@presenters/web/components/DetailSection';

import DatesAndTimesSection from './DatesAndTimesSection';
import DetailSectionField from './DetailSectionField';
import LocationSection from './LocationSection';
import PresRepSection from './PresRepSection';

import {
  ADDITIONAL_PARTICIPATING_DISTRICTS,
  COMMENTS,
  FEEDBACK_TYPES,
  LANGUAGE,
} from '@domain/districts';

import { getFormattedPhoneNumber } from '@use-cases/districts';
import {
  getConferenceHeading,
  getConferencePage,
  getMeetingType,
  getParticipatingDistrictsRow,
  getPresRepConferencePage,
} from '@use-cases/districts/conferences';

import { useFetchConferenceDetails } from '@repositories/districts/hooks';

import { getQueryParams } from '@utils/query-params';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';
import {
  CountriesQuery,
  FetchLanguagesQuery,
  GetTimeZonesQuery,
} from '@typings/operations';

interface Props {
  districtId: string;
  countriesData?: CountriesQuery;
  countriesLoading: boolean;
  timeZones?: GetTimeZonesQuery;
  timeZonesLoading: boolean;
  languagesData?: FetchLanguagesQuery;
  languagesLoading: boolean;
}

const DistrictConferenceDetails: React.FC<RouteComponentProps & Props> = ({
  districtId: myDistrictID,
  countriesData,
  countriesLoading,
  timeZones,
  timeZonesLoading,
  languagesData,
  languagesLoading,
}) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const { conferenceId } = useParams();

  const [
    fetchConference,
    { data: conferenceData, loading: conferenceLoading },
  ] = useFetchConferenceDetails();

  useEffect(() => {
    if (myDistrictID && conferenceId) {
      fetchConference({
        variables: {
          conferenceId,
        },
      });
    }
  }, [myDistrictID, conferenceId]);

  if (
    conferenceLoading ||
    !conferenceData ||
    languagesLoading ||
    countriesLoading ||
    timeZonesLoading
  ) {
    return <Loading />;
  }

  const conference = conferenceData?.conference;

  const {
    districtId: conferenceDistrictID,
    language,
    timeZone,
    startDate,
    startTime,
    endDate,
    endTime,
    participatingDistricts,
    venueType,
    venueName,
    conferenceAddress,
    onlineLocation,
    detailForJoiningRemotely,
    comments,
    prAccommodation,
    prAddress,
    isPresRepRequested,
    presRepAccPhone,
  } = conference as DIS.DistrictConference;

  const parsedSearch = getQueryParams();
  const isBackPagePR = parsedSearch.back === FEEDBACK_TYPES.PR;
  const backPath = isBackPagePR
    ? getPresRepConferencePage()
    : getConferencePage(myDistrictID);

  const parsedLanguage =
    languagesData?.languages.find(lang => lang.id === language)?.name || '';

  const presRepAccPhoneNumber =
    presRepAccPhone?.number &&
    getFormattedPhoneNumber(presRepAccPhone, countriesData?.countries);

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-conferences',
          '{{prefix}} District conferences {{suffix}}',
          { prefix, suffix }
        )}
      />

      <div className="mt-4 desktop:mt-12">
        {isBackPagePR && (
          <Title className="m-0 p-0">
            {t(
              'conferences-details.pres-rep-title',
              'President’s Representative for District Conferences'
            )}
          </Title>
        )}
        <LinkPrevious path={backPath} label={t('back-link-text', 'Back')} />

        <h2 className="text-xl font-light mt-4 mb-6 leading-xl">
          {t('title.district-conference-details', 'District Conference')}
        </h2>

        <DetailSection>
          <DetailSectionField
            title={t('conference-details.meeting-type', 'Meeting Type')}
            description={getMeetingType(venueType, t)}
          />
        </DetailSection>

        <DatesAndTimesSection
          {...{ startDate, startTime, endDate, endTime, timeZone, timeZones }}
        />

        <LocationSection
          {...{
            venueName,
            venueType,
            conferenceAddress: { ...conferenceAddress },
            onlineLocation,
            detailForJoiningRemotely,
          }}
        />

        {language && (
          <DetailSection title={getConferenceHeading(t, LANGUAGE)}>
            <DetailSectionField
              title={getConferenceHeading(t, LANGUAGE)}
              description={parsedLanguage}
            />
          </DetailSection>
        )}

        {!!participatingDistricts?.length && (
          <DetailSection
            title={getConferenceHeading(t, ADDITIONAL_PARTICIPATING_DISTRICTS)}
          >
            <DetailSectionField
              title={t(
                'conference-details.additional-districts',
                'Additional District(s)'
              )}
              description={getParticipatingDistrictsRow(
                participatingDistricts || [],
                conferenceDistrictID
              )}
            />
          </DetailSection>
        )}

        {isBackPagePR && (
          <PresRepSection
            {...{
              phoneNumber: presRepAccPhoneNumber,
              prAccommodation,
              prAddress,
              isPresRepRequested,
            }}
          />
        )}
        {comments && (
          <DetailSection title={getConferenceHeading(t, COMMENTS)}>
            <DetailSectionField
              title={getConferenceHeading(t, COMMENTS)}
              description={comments}
            />
          </DetailSection>
        )}
      </div>
    </>
  );
};

export default DistrictConferenceDetails;
