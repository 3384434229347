// Libs
import React, { PropsWithChildren, useState } from 'react';
import classNames from 'classnames';

// Components
import { ResultsListItem } from '@components/ResultsList';
import Icon from '@components/Icon';
import Collapse from '@kunukn/react-collapse';

interface Props {
  title: string;
  hasMargin?: boolean;
  hasAdditionalBorder?: boolean;
}

const CollapsibleHeader: React.FC<PropsWithChildren<Props>> = ({
  title,
  hasMargin = false,
  hasAdditionalBorder = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <ResultsListItem
        className={classNames(
          'flex-wrap items-center min-h-16 bg-gray-100 border-gray-300',
          {
            'mt-10': hasMargin,
            'border-t-3': hasAdditionalBorder,
          }
        )}
        borderBottomClass="border-b-3"
        spacingY={2}
      >
        <div
          className="mr-auto inline-flex items-center cursor-pointer"
          onClick={() => setIsOpen(prevState => !prevState)}
        >
          <Icon
            name={`arrows/${isOpen ? 'up' : 'down'}`}
            size="11"
            color="gray-600"
            className="flex-shrink-0 mx-5 tablet:mx-0625"
          />
          <p className="text-lg leading-lg-heading mr-4 mb-0">{title}</p>
        </div>
      </ResultsListItem>
      <Collapse isOpen={isOpen} className="collapse-css-transition mb-4">
        {children}
      </Collapse>
    </>
  );
};

export default CollapsibleHeader;
