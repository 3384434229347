// Libs
import React from 'react';
import classNames from 'classnames';

//Components
import Icon from '@components/Icon';

interface Props {
  isOpen: boolean;
  toggleCollapse: () => void;
  title: string;
  notice?: string;
  count: number;
}

export const CollapsibleHeader: React.FC<Props> = ({
  isOpen,
  toggleCollapse,
  title,
  notice,
  count,
}) => {
  return (
    <div
      className={classNames(
        'pb-3 pl-8 tablet:pl-0 flex items-center flex-wrap',
        {
          'border-b-2 border-gray-300': isOpen && count,
        }
      )}
    >
      <div
        className={classNames('mr-auto inline-flex items-center', {
          'cursor-pointer': count,
        })}
        onClick={toggleCollapse}
      >
        <Icon
          name={`arrows/${isOpen && count ? 'up' : 'down'}`}
          size="11"
          color="gray-600"
        />
        <div className="mb-0 ml-2 h4 alternate">
          {title} ({count})
        </div>
      </div>
      {notice && isOpen && count > 0 && (
        <div className="text-xs leading-md">{notice}</div>
      )}
    </div>
  );
};
