// Libs
import React from 'react';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';

// Constants
import { SURVEY_OPTIONS } from '@domain/districts';

// Utils
import { parseControlValuesFromDISFormat } from '@utils/parseControlValuesFromDISFormat';
import { getTranslateSurveyAnswer } from '../utils';

// Hooks
import { useTranslation } from '@external/react-i18next';

interface Props {
  subTitle: string;
}

const DesktopHeaderSurvey: React.FC<Props> = ({ subTitle }) => {
  const { t } = useTranslation();
  return (
    <div className="hidden tablet:block mt-4">
      <div className="flex items-stretch pl-4 bg-dark-blue-600 border-gray-300 border-b text-white items-center">
        <div className="flex-2 leading-4 text-sm flex justify-center items-center">
          <div className="pr-4 py-2">{subTitle}</div>
        </div>
        <div className="flex-3 flex text-center font-bold">
          {pipe(
            SURVEY_OPTIONS.FORMATTED,
            parseControlValuesFromDISFormat,
            A.reverse,
            A.map(item => (
              <div
                className="flex-1 border-l border-gray-300 flex justify-center items-center"
                key={item}
              >
                {getTranslateSurveyAnswer(t, item)}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopHeaderSurvey;
