// Libs
import React from 'react';
import classNames from 'classnames';

// Utils
import { YEAR_COLORS } from '@use-cases/districts';

// Types
import {
  Props as LegendProps,
  Payload as LegendPayload,
} from 'recharts/types/component/DefaultLegendContent';

const LegendContent = (props: LegendProps) => {
  const { payload: legendData } = props;

  if (legendData) {
    return (
      <ul
        className={classNames(
          'flex flex-row desktop:flex-col flex-wrap mt-9 ml-0',
          'desktop:mt-0'
        )}
      >
        {legendData.reduceRight(
          (acc: React.ReactElement[], item: LegendPayload, index: number) => {
            acc.push(
              <li
                className={classNames(
                  'flex items-center mr-3 mt-3',
                  'desktop:mr-0 desktop:mt-0 desktop:mb-5'
                )}
                key={`legend-${item.value}`}
              >
                <div
                  className="h-5 w-5 rounded-1px desktop:ml-5"
                  style={{ background: YEAR_COLORS[index] }}
                />
                <span
                  className={classNames(
                    'text-2xs inline-block ml-2 text-gray-600',
                    'desktop:font-bold'
                  )}
                >
                  {item.value}
                </span>
              </li>
            );

            return acc;
          },
          []
        )}
      </ul>
    );
  }

  return null;
};

export default LegendContent;
