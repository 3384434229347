import React, { useContext, useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import SelectRotaryYear from '@components/Forms/Select/SelectRotaryYear';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';
import { titleTemplateParams } from '@components/Title/util';
import ContainerWithLinks from '@presenters/web/pages/Districts/Members/ContainerWithLinks';

import AGList from './AGList';
import { LocationStateType } from '../Officers/DistrictOfficersHeader';

import { DistrictAG } from '@domain/districts';

import {
  buildAGsClubAssignmentsUrl,
  DistrictContext,
  getOfficerTermSelectOptions,
  isCurrentTerm,
} from '@use-cases/districts';

import { useFetchDistrictAGsPaginate } from '@repositories/districts';

import { getCurrentDate } from '@utils/datetime';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  districtId: string;
  riDistrictId?: number | null;
  pageSize?: number;
}

const PAGE_SIZE = 10;

const AssistantGovernors: React.FC<Props> = ({
  districtId,
  riDistrictId,
  pageSize = PAGE_SIZE,
  location,
}) => {
  const [
    {
      selectedInfo: { term },
      committeeManager: { isManager },
      defaultTerm,
    },
    setContext,
  ] = useContext(DistrictContext);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { nowTerm, afterNowTerm } = isManager;
  const showClubAssignments = nowTerm || afterNowTerm;

  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const [
    fetchAGsPaginate,
    { data: agsResultData, loading },
  ] = useFetchDistrictAGsPaginate();

  useEffect(() => {
    if (districtId && term) {
      const date = !isCurrentTerm(term)
        ? `${term.endDate}-06-30`
        : getCurrentDate();

      fetchAGsPaginate({
        variables: {
          districtId,
          date,
          pageSize,
          currentPage,
        },
      });
    }
  }, [pageSize, districtId, currentPage, fetchAGsPaginate, term]);

  const pageHandler = (
    event: React.SyntheticEvent,
    pageNumber: number
  ): void => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const searchAGsByDate = (dateParam: string): void => {
    const RY = parseInt(dateParam, 10);
    setContext(prevState => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        term: {
          startDate: `${RY - 1}`,
          endDate: dateParam,
        },
      },
    }));
    setCurrentPage(1);
  };

  const handleBack = () => {
    if (term?.startDate !== defaultTerm?.startDate) {
      setContext(prevState => ({
        ...prevState,
        selectedInfo: {
          ...prevState.selectedInfo,
          term: defaultTerm,
        },
      }));
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-members',
          '{{prefix}} District members {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="mt-6">
        <LinkPrevious
          onClick={handleBack}
          path={
            (location?.state as LocationStateType)?.path ||
            `/district/${districtId}/members`
          }
          label={t('back-link-text', 'Back')}
        />
        <ContainerWithLinks
          className="desktop:mb-4"
          isDistrictOfficer
          officersUrl={`/district/${districtId}/members/officers`}
        />
        <Title className="text-4lg mt-2 desktop:m-0 leading-9">
          {t('assistant-governors', 'Assistant Governors')}
        </Title>
        <ContainerWithLinks
          className="desktop:mb-8"
          isClubAssignments={showClubAssignments}
          clubAssignmentsUrl={buildAGsClubAssignmentsUrl(districtId)}
        />
        <div className="visible desktop:hidden">
          <SelectRotaryYear
            handleChange={searchAGsByDate}
            options={getOfficerTermSelectOptions()}
            initialValue={term?.endDate}
          />
        </div>
        <AGList
          pageSize={pageSize}
          currentPage={currentPage}
          pageHandler={pageHandler}
          totalCount={agsResultData?.result?.totalCount}
          districtAGs={agsResultData?.result?.ags as DistrictAG[]}
          riDistrictId={riDistrictId}
          filters={
            <SelectRotaryYear
              initialValue={term?.endDate}
              options={getOfficerTermSelectOptions()}
              handleChange={searchAGsByDate}
            />
          }
        />
      </div>
    </>
  );
};

export default AssistantGovernors;
