// Libs
import React from 'react';

// Components
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

// Utils
import { useTranslation } from '@external/react-i18next';

// Types
import { MembersListProps } from './types';

const MembersList: React.FC<MembersListProps> = ({
  tableTitle,
  pageSize,
  currentPage,
  totalCount,
  areTabsMultiple,
  members,
  filters,
  memberItem,
  pageHandler,
  areFiltersEmpty,
  showFiltersInContextMenu = false,
  contextMenuTitle,
  headerClassName = '',
  isHeaderPadded = true,
  setActiveTab,
  scrollToTab,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ResultsList
        darkBackground
        setActiveTab={setActiveTab}
        scrollToTab={scrollToTab}
        areTabsMultiple={areTabsMultiple}
        headerClassName={`px-8 tablet:px-5 tablet:bg-gray-100 ${headerClassName}`}
        className="-mx-8 tablet:mx-0"
        summary={
          tableTitle || (
            <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
              {t('district-management.district-members', 'District members')} (
              {totalCount})
            </h2>
          )
        }
        filters={filters}
        areFiltersEmpty={areFiltersEmpty}
        showFiltersInContextMenu={showFiltersInContextMenu}
        contextMenuTitle={contextMenuTitle}
        isHeaderPadded={isHeaderPadded}
      >
        {members.map(member => (
          <ResultsListItem key={member.id}>
            {memberItem(member)}
          </ResultsListItem>
        ))}
      </ResultsList>
      {totalCount > 0 && (
        <Pagination
          pageSize={pageSize}
          page={currentPage}
          totalCount={totalCount}
          pageHandler={pageHandler}
        />
      )}
    </>
  );
};

export default MembersList;
