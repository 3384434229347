// Libs
import { OptionTypeBase } from '@external/react-select';
import React from 'react';
import { MenuListComponentProps } from 'react-select/src/components/Menu';
import SimpleBarReact from 'simplebar-react';

export const MenuList: React.FC<MenuListComponentProps<
  OptionTypeBase
>> = props => {
  return (
    <SimpleBarReact
      style={{
        maxHeight: (props.getStyles('menu', props) as { maxHeight: string })
          .maxHeight,
      }}
      autoHide={false}
    >
      {props.children}
    </SimpleBarReact>
  );
};
