// Libs
import React, { useState } from 'react';
import { ceil, range } from 'lodash';
import { useTranslation } from '@external/react-i18next';

// Components
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';
import CustomizedYAxisTick from '../components/CustomizedYAxisTick';
import CustomizedXAxisTick from '../components/CustomizedXAxisTick';
import ContributionChartLegendContent from '../components/ContributionChartLegendContent';
import CustomizedActiveDot from '../components/CustomizedActiveDot';
import CustomizedTooltip from './CustomizedTooltip';

// Constants
import { ChartValueFormats, ANNUAL_FUND, POLIO_PLUS } from '@domain/districts';

// Utils
import { useWindowSize } from '@hooks/useWindowSize';
import tailwind from '@styles/tailwind.js';

// Types
import { ContributionChartColor } from '@domain/districts';
import { DIS } from '@typings/dis';

type Props = {
  chartData: Array<DIS.ContributionChartItem | DIS.ClubCreditsChartData | null>;
  maxValue: number;
  colors: ContributionChartColor[];
  showTooltip?: boolean;
  useLineNameAsTooltipHeader?: boolean;
  yAxisTickValueFormat?: string;
};

type SetLineIndex = (value: number | null) => void;
type SetLineKey = (value: string | null) => void;

const roundWithPrecision = (
  maxValue: number,
  accuracy: number,
  defaultValue: number = 16e4
) => ceil(maxValue || defaultValue, -accuracy);

const getAreaProps = (
  { lineKey, stroke, fill }: ContributionChartColor,
  index: number,
  setLineIndex: SetLineIndex,
  setLineKey: SetLineKey
) => ({
  dataKey: lineKey,
  stroke,
  fill,
  strokeWidth: 2,
  dot: {
    stroke,
    fill: stroke,
    fillOpacity: 1,
    r: 5,
    onMouseOver: () => {
      setLineKey(lineKey);
      setLineIndex(index);
    },
  },
});

const ContributionChart: React.FC<Props> = ({
  chartData,
  maxValue,
  colors,
  showTooltip = true,
  useLineNameAsTooltipHeader = false,
  yAxisTickValueFormat,
}) => {
  const { t } = useTranslation();
  const windowWidth = useWindowSize().width;
  const isMobile = windowWidth < parseInt(tailwind.theme.screens.desktop);

  const isPercentFormat = yAxisTickValueFormat === ChartValueFormats.percent;

  const [lineIndex, setLineIndex] = useState(null);
  const [lineName, setLineName] = useState(null);
  const accuracy = isPercentFormat ? 1 : 4;

  const handleDotMouseOut = () => {
    setLineIndex(null);
    setLineName(null);
  };

  // Define ticks values based on rounded max value
  const maxYAxisValue = roundWithPrecision(maxValue, accuracy);
  const increment = maxYAxisValue / 8;
  const yAxisTicks = range(maxYAxisValue, 0, -increment).reverse();

  const margin = isMobile
    ? {
        top: 20,
        left: isPercentFormat ? -20 : -5,
        right: 0,
      }
    : {
        top: 20,
        left: isPercentFormat ? -10 : 10,
        right: 35,
      };

  const translations: Record<string, string> = {
    [ANNUAL_FUND]: t('dashboards.title.annualFund', 'Annual Fund'),
    [POLIO_PLUS]: t('dashboards.title.polioEradication', 'Polio Eradication'),
  };

  return (
    <ResponsiveContainer width="100%" height={500}>
      <AreaChart margin={margin} data={chartData}>
        <CartesianGrid className="px-10" strokeDasharray="2 2" />
        <XAxis
          tick={<CustomizedXAxisTick />}
          tickLine={false}
          dataKey="rotaryYear"
          padding={isMobile ? { right: 15 } : { right: 0 }}
        />
        <YAxis
          tick={
            <CustomizedYAxisTick yAxisTickValueFormat={yAxisTickValueFormat} />
          }
          tickLine={false}
          tickCount={9}
          ticks={[0, ...yAxisTicks]}
          domain={[0, maxYAxisValue]}
        />
        <Legend
          content={<ContributionChartLegendContent />}
          wrapperStyle={{ left: 0 }}
        />
        {colors.map((color, index) => (
          <Area
            {...getAreaProps(
              color,
              index,
              setLineIndex as SetLineIndex,
              setLineName as SetLineKey
            )}
            key={color.lineKey}
            activeDot={props => (
              <CustomizedActiveDot
                {...props}
                elementIndex={index}
                lineIndex={lineIndex}
                onMouseOut={handleDotMouseOut}
              />
            )}
          />
        ))}
        {showTooltip && (
          <Tooltip
            content={({ active, payload }) => (
              <CustomizedTooltip
                active={active}
                payload={payload}
                lineIndex={lineIndex}
                lineKey={lineName}
                valueFormat={yAxisTickValueFormat}
                header={
                  useLineNameAsTooltipHeader && lineName
                    ? translations[lineName!]
                    : undefined
                }
              />
            )}
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ContributionChart;
