// Libs
import React from 'react';
import classNames from 'classnames';

// Components
import {
  components,
  OptionTypeBase,
  OptionProps,
} from '@external/react-select';

type Props = {
  optionProps: OptionProps<OptionTypeBase>;
};

const Option = ({ optionProps }: Props) => {
  const { isSelected, data } = optionProps;

  return (
    <div className="form-select-option">
      <components.Option {...optionProps}>
        <div
          className={classNames('text-xs', {
            'text-gray-600': !isSelected,
            'text-white': isSelected,
          })}
        >
          <div>{data.label}</div>
          <div
            className={classNames({
              'text-gray-400': !isSelected,
              'text-white': isSelected,
            })}
          >
            {data.subtitle}
          </div>
        </div>
      </components.Option>
    </div>
  );
};

export default Option;
