import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import { intersection } from 'lodash';

import {
  actionLookup,
  APIResponseMethodsDivider,
  CRUDAction,
  CRUDResource,
} from '@domain/abilities';
import { uuidRegex } from '@domain/common';

const resourceHrefRegexes: Record<string, RegExp> = {
  [CRUDResource.Conference]: new RegExp(`^/conferences/${uuidRegex}$`, 'i'),
};

type Nullable<T> = T | null;

export const hasAnyGettable = <T extends { href: string; method: string }>(
  // eslint-disable-next-line default-param-last
  hateoasLinks: Nullable<T[]> = [],
  resource: CRUDResource
) =>
  pipe(
    O.fromNullable(hateoasLinks),
    O.chain(
      A.findFirst(({ href }) => resourceHrefRegexes[resource]?.test(href))
    ),
    O.map(({ method }) => method.split(APIResponseMethodsDivider)),
    O.map(
      methods => !!intersection(actionLookup[CRUDAction.Read], methods).length
    ),
    O.getOrElse(() => false)
  );
