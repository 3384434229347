// Libs
import { range } from 'lodash';
import moment from 'moment';

// Types
import { Option } from './types';

export const getTimeOptions = (
  start: number,
  end: number,
  step: number
): Option[] =>
  range(start, end * 60, step).map(mins =>
    createOption(
      moment(0, 'h')
        .add(mins, 'm')
        .format('HH:mm')
    )
  );

export const createOption = (label: string) => ({
  label,
  value: label,
});
