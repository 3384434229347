import React, { useState } from 'react';

import Collapse from '@kunukn/react-collapse';

import CollapsibleHeader from '@components/CollapsibleHeader/';
import { ResultsListItem } from '@components/ResultsList';

import DistrictOfficerRoleItem from './DistrictOfficerRoleItem';

import {
  DistrictOfficerRole,
  UnassignedDistrictOfficerRoleTerm,
} from '@domain/districts';

import { isRoleAssignedInFutureRY } from '@use-cases/districts';

import { DistrictOfficer } from '@typings/operations';

interface Props {
  title: string;
  notice?: string;
  roles: DistrictOfficerRole<DistrictOfficer>[];
  assignedFuture3yrTermRoleIds?: string[];
}

const DistrictOfficerRolesList: React.FC<Props> = ({
  title,
  notice,
  roles,
  assignedFuture3yrTermRoleIds,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const rolesCount = roles.length;

  return (
    <div className="mt-10 first:mt-2">
      <CollapsibleHeader
        isOpen={isOpen}
        title={title}
        notice={notice}
        count={rolesCount}
        toggleCollapse={() => setIsOpen(!isOpen)}
      />
      {/* We should add the `overflowOnExpanded` property to the Collapse component for proper overflow handling */}
      <Collapse
        isOpen={isOpen}
        className="collapse-css-transition"
        overflowOnExpanded
      >
        <ul>
          {roles?.map(role => (
            <ResultsListItem key={role.id} columnLayout>
              {role.terms.map((term, index) => {
                const key = `${role.role}${index}`;
                return (term as DistrictOfficer).individual ? (
                  <DistrictOfficerRoleItem
                    key={key}
                    role={role}
                    assignedTerm={term as DistrictOfficer}
                    isAssignedInFutureRY={isRoleAssignedInFutureRY(
                      assignedFuture3yrTermRoleIds || [],
                      role.id
                    )}
                  />
                ) : (
                  <DistrictOfficerRoleItem
                    key={key}
                    role={role}
                    unassignedTerm={term as UnassignedDistrictOfficerRoleTerm}
                    isAssignedInFutureRY={isRoleAssignedInFutureRY(
                      assignedFuture3yrTermRoleIds || [],
                      role.id
                    )}
                  />
                );
              })}
            </ResultsListItem>
          ))}
        </ul>
      </Collapse>
    </div>
  );
};

export default DistrictOfficerRolesList;
