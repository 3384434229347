import React, { useMemo } from 'react';

import { OneColumn } from '@components/Layouts/OneColumn';
import SelectClubType from '@presenters/web/components/Formik/SelectClubType';
import Portal from '@presenters/web/components/Portal';

import {
  ClubTypeSelectOption,
  SelectDashboardClubTypeOptions,
} from '@domain/districts';

import { useHandleStickySelectScroll } from '@use-cases/districts/hooks';

import { useTranslation } from '@external/react-i18next';
import { OptionTypeBase } from '@external/react-select';

interface Props {
  stickySelectContainerRef: React.MutableRefObject<HTMLElement | null>;
  handleChange: (selectedOption: ClubTypeSelectOption) => void;
  options?: OptionTypeBase[];
  initialValue?: string;
}

const SelectDashboardClubType: React.FC<Props> = ({
  stickySelectContainerRef,
  handleChange,
  options,
  initialValue = SelectDashboardClubTypeOptions.All,
}) => {
  const { t } = useTranslation();
  const { isSticky, onRemove } = useHandleStickySelectScroll(
    stickySelectContainerRef
  );

  const rootSelectorId = stickySelectContainerRef.current?.id || '';

  return useMemo(() => {
    return isSticky ? (
      <Portal rootSelectorId={rootSelectorId} onRemove={onRemove}>
        <OneColumn>
          <SelectClubType
            handleChange={handleChange}
            options={options}
            initialValue={initialValue}
            labelName={t('district-dashboard.select-club-type', 'Club Type')}
          />
        </OneColumn>
      </Portal>
    ) : (
      <SelectClubType
        handleChange={handleChange}
        options={options}
        initialValue={initialValue}
        labelName={t('district-dashboard.select-club-type', 'Club Type')}
      />
    );
  }, [isSticky]);
};

export default SelectDashboardClubType;
