import React from 'react';

import Confirmation from '../Confirmation';

import { CommitteeMemberData, TermToAssign } from '@domain/districts';

import { getMinDateForRemoveReplace } from '@use-cases/districts';
import { getMaxDateForRemoveReplace } from '@use-cases/districts/helpers/getMaxDateForRemoveReplace';

import { useTranslation } from '@external/react-i18next';

type ConfirmationRoleInfo = {
  role: string;
  term: TermToAssign;
};

type Props = {
  member: CommitteeMemberData;
  handleSelectDate?: (date: Date) => void;
  defaultDate: Date;
  roleInfo: ConfirmationRoleInfo;
};

const UnassignRoleConfirm: React.FC<Props> = ({
  member,
  handleSelectDate,
  defaultDate,
  roleInfo: { role, term },
}) => {
  const { t } = useTranslation();
  const { nameWithPrefixSuffix, email } = member;

  return (
    <Confirmation
      roleName={role}
      assigneeEmail={email || null}
      assigneeName={nameWithPrefixSuffix}
      term={term}
      title={t('remove-from-role', 'Remove from the role of {{selectedRole}}', {
        selectedRole: role,
      })}
      labels={{
        termLabel: t('unassign-from.term.label', 'Term'),
        roleLabel: t('unassign-from.role.label', 'Role'),
        emailLabel: t('unassign-from.email.label', 'Email'),
        officerLabel: t('unassign-from.officer.label', 'Officer'),
        datePickerLabel: t('unassign-from.endDate.label', 'End Date'),
      }}
      handleSelectDate={handleSelectDate}
      maxDate={getMaxDateForRemoveReplace(term.endDate)}
      minDate={getMinDateForRemoveReplace(term.startDate)}
      selectedDateValue={defaultDate}
    />
  );
};

export default UnassignRoleConfirm;
