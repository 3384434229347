// Libs
import React from 'react';

// Types
import { Maybe } from '@typings/graphql';

type Props = {
  lineOne?: Maybe<string>;
  lineTwo?: Maybe<string>;
  lineThree?: Maybe<string>;
  city?: Maybe<string>;
  postalCode?: Maybe<string>;
  country?: Maybe<string>;
  state?: Maybe<string>;
};

const MailingAddress: React.FC<Props> = ({
  lineOne,
  lineTwo,
  lineThree,
  city,
  postalCode,
  country,
  state,
}) => {
  return (
    <div>
      {lineOne && <span className="block">{lineOne}</span>}
      {lineTwo && <span className="block">{lineTwo}</span>}
      {lineThree && <span className="block">{lineThree}</span>}
      {city && <span>{city}</span>}
      {postalCode && <span>, {postalCode}</span>}
      {country && <span>, {country}</span>}
      {state && <span className="block"> {state}</span>}
    </div>
  );
};

export default MailingAddress;
