import React from 'react';

import classNames from 'classnames';

import { Button } from '@components/Button';
import MemberRow from '@components/MemberRow';
import ClubAffiliation from '@presenters/web/components/DistrictSelectMemberStep/ClubAffiliation';

import {
  getActiveAssociatedClubs,
  getActiveMembershipsOnAddDistrictOfficer,
  getAssociatedClubsInfoOnAddDistrictOfficer,
  getMemberEmail,
  getMemberPhone,
  isMember,
} from './utils';

import { CommitteeMemberData, DistrictMember } from '@domain/districts';

import { activeRangeToCurrentYear } from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';
import { useMobileLayout } from '@hooks/useMobileLayout';

import { DistrictLeadership, MembershipType } from '@typings/operations';

export type Props = {
  member: DistrictMember | CommitteeMemberData;
  selectHandler: (member: DistrictMember | CommitteeMemberData) => void;
  isFilterApplied?: { [key in MembershipType]?: boolean };
  isDES?: boolean;
};

const MemberItem: React.FC<Props> = ({
  member,
  selectHandler,
  isFilterApplied,
  isDES = false,
}) => {
  const { isMobileLayout } = useMobileLayout();
  const { t } = useTranslation();
  const {
    id,
    photoUri,
    localizedName,
    nameWithPrefixSuffix,
    thisDistrictLeadership,
  } = member;

  const sortedAssociatedClubsInfo = [...member.associatedClubsInfo].sort(
    (a, b) =>
      member.activeMemberships.findIndex(({ clubId }) => clubId === a.clubId) -
      member.activeMemberships.findIndex(({ clubId }) => clubId === b.clubId)
  );

  const isEnabledFilters =
    isFilterApplied && (isFilterApplied.Member || isFilterApplied.Rotaractor);

  const activeMemberships = getActiveMembershipsOnAddDistrictOfficer(
    member,
    isFilterApplied,
    isEnabledFilters
  );
  const activeAssociatedClubs = getActiveAssociatedClubs(
    sortedAssociatedClubsInfo,
    activeMemberships
  );
  const associatedClubsInfo = getAssociatedClubsInfoOnAddDistrictOfficer(
    activeAssociatedClubs,
    sortedAssociatedClubsInfo,
    member
  );

  const clubAffiliation =
    isMember(member) && activeMemberships ? (
      <div
        className={classNames({
          'pt-6': !member.localizedName && !isMobileLayout,
          'pt-11': member.localizedName && !isMobileLayout,
        })}
      >
        {activeMemberships.map((activeMembership, index) => (
          <ClubAffiliation
            key={activeMembership.clubId}
            membership={activeMembership}
            hasSeveralMemberships={!!(activeMemberships.length - 1 - index)}
          />
        ))}
      </div>
    ) : null;

  const terms = !isMember(member) ? (
    <>
      {(thisDistrictLeadership as DistrictLeadership[]).map(
        ({ startDate, endDate, id }) => (
          <p className="text-xs mb-0" key={id}>
            {activeRangeToCurrentYear(startDate, endDate)}
          </p>
        )
      )}
    </>
  ) : (
    undefined
  );

  const actionButton = (member: DistrictMember | CommitteeMemberData) => (
    <Button
      clickHandler={() => selectHandler(member)}
      secondary
      isMinWidth={false}
      small
      type="button"
      className="h-10 text-2xs mt-4"
      dataTestId="select-member-btn"
    >
      {t('button.select', 'Select')}
    </Button>
  );

  return (
    <MemberRow
      id={id}
      email={getMemberEmail(member)}
      fullName={nameWithPrefixSuffix}
      localizedName={localizedName || ''}
      photoUri={photoUri || ''}
      phoneNumber={getMemberPhone(member)}
      associatedClubsInfo={associatedClubsInfo}
      clubAffiliation={clubAffiliation}
      memberInfo={undefined}
      terms={terms}
      actionButton={actionButton(member)}
      // TODO: check styles when story for mobile will be implemented
      actionButtonMobile={actionButton(member)}
      isTermsColumn={isDES}
      activeMemberships={activeMemberships}
      isSelectMemberTab
    />
  );
};

export default MemberItem;
