// Libs
import React from 'react';
import classNames from 'classnames';

type Props = {
  deltaLabel?: string;
  isDeltaPositive: boolean;
  isDeltaNegative: boolean;
};

const DeltaValue: React.FC<Props> = ({
  deltaLabel,
  isDeltaPositive,
  isDeltaNegative,
}) => (
  <div
    className={classNames(
      {
        'bg-red-100': isDeltaNegative,
        'bg-green-100': isDeltaPositive,
      },
      'px-2 max-h-9 bg-gray-200 text-xs mb-2 desktop:my-auto'
    )}
  >
    {deltaLabel}
  </div>
);

export default DeltaValue;
