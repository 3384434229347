import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { partition } from 'lodash';

import { Icon } from '@components/Icon';

import { UNASSIGNED } from '../constants/groupnames';
import ContextMenuItem from './ContextMenuItem';

import tailwind from '@styles/tailwind.js';

import { useTranslation } from '@external/react-i18next';
import { useWindowSize } from '@hooks/useWindowSize';

import { ContextMenuOptions, MenuItem } from './types';

const ContextMenu: React.FC<ContextMenuOptions> = ({
  title,
  menuItems,
  className,
  handleMenuItemClick,
  currentItemId,
  mobileWidthBreakpoint = parseInt(tailwind.theme.screens.tablet, 10),
  isDragDisabled,
  dataTestId = '',
}): JSX.Element => {
  const node = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [scrollableItems, pinnedItems] = partition(
    menuItems,
    menuItem => !menuItem.menuItemId.includes(UNASSIGNED)
  );

  useEffect(() => {
    isDragDisabled?.(menuOpened);
  }, [isDragDisabled, menuOpened]);

  useEffect(() => {
    if (width < mobileWidthBreakpoint && menuOpened) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [width, menuOpened, mobileWidthBreakpoint]);

  const handleClickOutside = (event: PointerEvent): void => {
    if (!node?.current?.contains(event.target as Node)) {
      setMenuOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, []);

  const toggleContextMenu = (e: SyntheticEvent): void => {
    e.stopPropagation();
    setMenuOpened(!menuOpened);
  };

  const menuItemClick = (e: SyntheticEvent, item: MenuItem): void => {
    toggleContextMenu(e);
    handleMenuItemClick(item);
  };

  const renderMenuItem = ({ menuItemId, menuItemTitle }: MenuItem) => (
    <ContextMenuItem
      dataTestId={`${dataTestId}-item-${menuItemId}`}
      key={menuItemId}
      menuItemId={menuItemId}
      menuItemTitle={menuItemTitle}
      currentItemId={currentItemId}
      handleMenuItemClick={menuItemClick}
    />
  );

  return (
    <div
      ref={node}
      data-menu-component
      className={`relative ml-auto ${className}`}
      data-testid={dataTestId}
    >
      <button
        type="button"
        className="block context-menu-toggle focus:shadow-none"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={toggleContextMenu}
        data-testid={`${dataTestId}-toggle`}
      >
        <span aria-hidden="true">
          <Icon name="ui/overflow-gray" />
        </span>
        <span className="hidden">
          {t('context-menu.open', 'Open context menu for {{title}}', {
            title,
          })}
        </span>
      </button>
      {menuOpened && (
        <div>
          <div
            role="menu"
            data-menu-origin="left"
            className="bg-white
          border-grey-300
          w-screen
          h-screen
          z-10
          fixed
          inset-0
          overflow-auto
          tablet:border
          tablet:inset-init
          tablet:h-auto
          tablet:absolute
          tablet:shadow-md
          custom-context-menu
          tablet
          tablet:-mt-2
          tablet:right-0"
          >
            <div
              className="tablet:hidden
          p-5
          border-grey-300
          border-b"
            >
              <button
                type="button"
                className="block ml-auto"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={toggleContextMenu}
              >
                <span aria-hidden="true">
                  <Icon name="ui/close" size="16" color="gray-300" />
                </span>
                <span className="hidden">
                  {t('context-menu.close', 'Close context menu')}
                </span>
              </button>
              <h3>{title}</h3>
            </div>
            <p className="hidden tablet:block text-gray-400 mb-0 text-2xs pl-5 pt-2 font-bold">
              {title}
            </p>
            <ul
              data-label={t(
                'context-menu.label',
                'Context menu for {{title}}',
                {
                  title,
                }
              )}
            >
              <div className="tablet:max-h-72 overflow-y-auto">
                {scrollableItems.map(renderMenuItem)}
              </div>
            </ul>
            {pinnedItems.length > 0 && (
              <div className="border-t">{pinnedItems.map(renderMenuItem)}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ContextMenu);
