import { CSSProperties } from 'react';

import { isMobile } from 'react-device-detect';

import tailwind from '@styles/tailwind.js';

import { StylesConfig } from '@external/react-select';

import { SelectState } from './types';

const getOptionBg = ({ isSelected, isFocused }: SelectState) => {
  if (isSelected) {
    return tailwind.theme.colors['bright-blue']['200'];
  }
  if (isFocused && !isMobile) {
    return tailwind.theme.colors.gray['200'];
  }
  return tailwind.theme.colors.white;
};

export const customStyles = (error: boolean): StylesConfig => ({
  container: (provided: CSSProperties) => ({
    ...provided,
    width: '126px',
    fontSize: '14px',
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    minHeight: '44px',
    height: '44px',
    borderColor: `${
      error
        ? tailwind.theme.colors.red['600']
        : tailwind.theme.colors.gray['300']
    }`,
    background: `${
      error ? tailwind.theme.colors.red['100'] : tailwind.theme.colors.white
    }`,
    borderRadius: '2px',
    boxShadow: 'unset',
    '&:hover': {
      boxShadow: 'unset',
      borderColor: tailwind.theme.colors['dark-blue'][400],
    },
  }),
  valueContainer: (provided: CSSProperties) => ({
    ...provided,
    paddingLeft: '12px',
  }),
  indicatorSeparator: (provided: CSSProperties) => ({
    ...provided,
    margin: '7px 0',
    backgroundColor: `${tailwind.theme.colors.gray['300']}`,
  }),
  dropdownIndicator: (provided: CSSProperties) => ({
    ...provided,
    color: tailwind.theme.colors['bright-blue']['600'],
    padding: '0 4px',
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    maxHeight: '218px',
    marginTop: 0,
    border: `1px solid ${tailwind.theme.colors.gray['300']}`,
    boxShadow: `0px 10px 30px rgba(0, 0, 0, 0.2)`,
    borderRadius: `0px 0px 4px 4px`,
    overflow: 'hidden',
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    border: 0,
    paddingTop: '2px',
  }),
  placeholder: (provided: CSSProperties, state: SelectState) => ({
    ...provided,
    display: state.isFocused ? 'none' : 'block',
  }),
  input: (provided: CSSProperties) => ({
    ...provided,
    '& input': {
      boxSizing: 'border-box',
      outlineWidth: '0px',
      boxShadow: 'none',
    },
    '& input:focus': {
      boxShadow: 'none',
      outlineWidth: '0px',
    },
  }),
  option: (provided: CSSProperties, state: SelectState) => ({
    ...provided,
    backgroundColor: getOptionBg(state),
    color: 'inherit',
    padding: '1.7px 15px',
    overflow: 'visible',
    fontSize: 14,
    cursor: 'pointer',
    '&:active': {
      backgroundColor: tailwind.theme.colors['bright-blue']['200'],
    },
  }),
});
