import React from 'react';

import ActionButtons from '@components/ActionButtons';
import SelectRole from '@components/Forms/Select/SelectRole';
import IndividualListColumn from '@components/IndividualListColumn';
import PageSteps from '@components/PageSteps';
import SelectTerm from '@presenters/web/components/SelectTerm';

import {
  CommitteeMemberData,
  RoleOption,
  TermToAssign,
} from '@domain/districts';

import { useTranslation } from '@external/react-i18next';

import { Maybe } from '@typings/graphql';

interface Props {
  title: string;
  rolesToAssign?: RoleOption[];
  backUrl?: string;
  termsToAssign: TermToAssign[];
  selectedTerm: Maybe<TermToAssign>;
  role?: Maybe<RoleOption>;
  assignee: Maybe<CommitteeMemberData>;
  isContinueBtnDisabled?: boolean;
  isRequiredRoleText?: boolean;
  onChangeRole?: (value: RoleOption) => void;
  handleGoToNextStep: () => void;
  handleGoToPrevStep?: () => void;
  onChangeTerm?: (term: TermToAssign) => void;
  step: {
    value: number;
    count: number;
  };
  isRoleSelectLoading?: boolean;
  isRoleSelectDisabled?: boolean;
  isResetValue?: boolean;
}

const RoleForMember: React.FC<Props> = ({
  step,
  title,
  assignee,
  rolesToAssign,
  backUrl,
  role,
  isContinueBtnDisabled = false,
  isRequiredRoleText = true,
  termsToAssign = [],
  selectedTerm,
  onChangeTerm,
  onChangeRole,
  handleGoToNextStep,
  handleGoToPrevStep,
  isRoleSelectLoading,
  isRoleSelectDisabled,
  isResetValue,
}) => {
  const { t } = useTranslation();
  const { value: stepValue, count: stepCount } = step;

  return (
    <PageSteps
      className="p-0"
      backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
      to={backUrl}
      step={stepValue}
      total={stepCount}
      backHandler={handleGoToPrevStep}
    >
      <h2 className="text-xl my-2 leading-6">{title}</h2>
      {assignee && (
        <IndividualListColumn
          fullName={assignee.nameWithPrefixSuffix}
          photoUri={assignee.photoUri || ''}
          id={assignee.id}
          localizedName={assignee.localizedName || ''}
          associatedClubsInfo={assignee.associatedClubsInfo}
          activeMemberships={assignee.activeMemberships}
          hasMemberships={{ notHonorary: true, honorary: false }}
        />
      )}
      <SelectTerm
        termsToAssign={termsToAssign}
        selectedTerm={selectedTerm}
        onChangeTerm={onChangeTerm}
      />
      <div className="w-full mt-8 desktop:w-120">
        <SelectRole
          isRequiredRoleText={isRequiredRoleText}
          initialValue={role}
          rolesToAssign={rolesToAssign || []}
          handleChange={onChangeRole}
          isLoading={isRoleSelectLoading}
          isDisabled={isRoleSelectDisabled}
          isResetValue={isResetValue}
        />
      </div>
      <ActionButtons
        submitBtnLabel={t('assign-role.continue', 'Continue')}
        cancelBtnLabel={t('assign-role.cancel', 'Cancel')}
        isSubmitDisabled={isContinueBtnDisabled}
        onCancel={handleGoToPrevStep}
        onSubmit={handleGoToNextStep}
      />
    </PageSteps>
  );
};

export default RoleForMember;
