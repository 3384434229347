// Libs
import React from 'react';

// Constants
import {
  CUSTOM_QUESTION_19_ID,
  CUSTOM_QUESTION_20_ID,
} from '@domain/districts/conferences/constants';

type Props = {
  sectionId: string;
  sectionName: string;
};

const SectionTitle: React.FC<Props> = ({ sectionId, sectionName }) => {
  const classes =
    sectionId === CUSTOM_QUESTION_19_ID || sectionId === CUSTOM_QUESTION_20_ID
      ? 'text-small font-bold block text-gray-600 mb-3'
      : 'text-lg leading-lg-heading mb-2 mt-3';

  return <h3 className={classes}>{sectionName}</h3>;
};

export default SectionTitle;
