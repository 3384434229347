import React from 'react';

import { Select } from '@components/Forms/Select';

import {
  controlCustomStyles,
  indicatorsCustomStyles,
  menuCustomStyles,
  valueCustomStyles,
} from './customStyles';
import { getClubTypeOptionsForSelect } from './utils';

import { ClubTypeSelectOption } from '@domain/districts';

import { useTranslation } from '@external/react-i18next';
import { OptionTypeBase, ValueType } from '@external/react-select';

interface Props {
  handleChange: (selectedOption: ClubTypeSelectOption) => void;
  options?: OptionTypeBase[];
  initialValue?: string;
  labelName?: string;
}

const SelectClubType: React.FC<Props> = ({
  handleChange,
  options: propsOptions,
  initialValue = 'All',
  labelName,
}) => {
  const selectorName = 'select-club-type';
  const { t } = useTranslation();

  const onChange = (item: ValueType<OptionTypeBase>) => {
    const selectedOption = item as OptionTypeBase;
    handleChange(selectedOption.value);
  };

  const options = propsOptions || getClubTypeOptionsForSelect(t);

  return (
    <div className="flex">
      <span className="items-center mt-5 text-xs text-gray-500">{`${labelName}: `}</span>

      <Select
        isSeparator={false}
        isSearchable={false}
        labelHidden
        isSelectedOptionBold
        label={selectorName}
        id={selectorName}
        noMargins
        name={selectorName}
        options={options}
        onChange={onChange}
        defaultValue={options?.find(item => item.value === initialValue)}
        controlCustomStyles={controlCustomStyles}
        indicatorsCustomStyles={indicatorsCustomStyles}
        valueCustomStyles={valueCustomStyles}
        menuCustomStyles={menuCustomStyles}
      />
    </div>
  );
};

export default SelectClubType;
