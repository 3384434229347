import React, { useEffect } from 'react';

import { Radios } from '@components/Forms/Radios';
import IndividualListColumn from '@components/IndividualListColumn';
import Loading from '@components/Loading';
import InfoMessage from '@presenters/web/components/InfoMessage';

import { CommitteeMemberData, isDRFC, ManagedRY } from '@domain/districts';

import {
  areSomeAssigmentAfter,
  filterDistrictLeadershipByRoles,
} from '@use-cases/districts';

import { isToday } from '@utils/datetime';

import { ROTARY_ASSISTANCE_EMAIL } from '@constants/index';

import { useTranslation } from '@external/react-i18next';
import { useFetchRoles } from '@hooks/useFetchRoles';

type Props = {
  member: CommitteeMemberData;
  title: string;
  legend: string;
  roleId?: string;
  handleChangeRole: (roleId: string) => void;
  radioFieldSelector?: string;
  currentYear: string;
  disableTodayRoles?: boolean;
  currentYearAssignment?: ManagedRY;
};

const MultipleRoles = ({
  title,
  member: {
    nameWithPrefixSuffix,
    photoUri,
    id,
    thisDistrictLeadership,
    localizedName,
    associatedClubsInfo,
  },
  legend,
  roleId,
  handleChangeRole,
  radioFieldSelector = 'available-roles',
  currentYear,
  disableTodayRoles,
  currentYearAssignment,
}: Props) => {
  const { t } = useTranslation();

  const [
    getLeadershipRoles,
    { data: rolesData, loading: rolesLoading },
  ] = useFetchRoles();

  useEffect(() => {
    getLeadershipRoles({
      variables: {
        organizationType: ['District'],
        validOn: `${Number(currentYear) - 1}-07-01`,
        officerManagement: 'District',
      },
    });
  }, []);

  if (rolesLoading) {
    return <Loading />;
  }

  const threeTermRoles =
    rolesData?.roles?.filter(
      ({ isOfficer, termLength }) => isOfficer && termLength === 3
    ) || [];

  const constructRoleName = (roleId: string, roleName: string) => {
    if (threeTermRoles.some(role => role.id === roleId)) {
      return `${roleName} (${t(
        'district-officers.year-term',
        '{{termYears}}-year-term',
        {
          termYears: 3,
        }
      )})`;
    }
    return roleName;
  };

  return (
    <>
      <h2 className="text-xl my-2 leading-6">{title}</h2>
      <IndividualListColumn
        fullName={nameWithPrefixSuffix}
        photoUri={photoUri || ''}
        id={id}
        localizedName={localizedName || ''}
        associatedClubsInfo={associatedClubsInfo}
      />
      <div className="mt-4">
        <Radios
          id={radioFieldSelector}
          name={radioFieldSelector}
          legend={legend}
          value={roleId}
          options={filterDistrictLeadershipByRoles(thisDistrictLeadership).map(
            ({ role, roleId, startDate, endDate }) => {
              const isRoleDRFC = isDRFC(role);
              return {
                value: roleId,
                label: constructRoleName(roleId, role),
                disabled:
                  isRoleDRFC ||
                  (disableTodayRoles && isToday(startDate)) ||
                  areSomeAssigmentAfter(currentYearAssignment?.officers || [], {
                    roleId,
                    endDate,
                  }),
                description: isRoleDRFC ? (
                  <div className="ml-6">
                    <InfoMessage
                      message={t(
                        'district-officers.sorry-cannot-manage-officer',
                        `Sorry, you cannot manage this role. Please contact RI staff by emailing <a href='mailto:{{email}}'>{{email}}</a> for assistance.`,
                        { email: ROTARY_ASSISTANCE_EMAIL }
                      )}
                    />
                  </div>
                ) : (
                  undefined
                ),
              };
            }
          )}
          changeHandler={handleChangeRole}
          classes={{
            radioBtn: 'z-0',
            optionWrapper: 'my-4',
          }}
        />
      </div>
    </>
  );
};

export default MultipleRoles;
