import { Item } from '../types';

const reorder = (
  list: Item[] = [],
  startIndex: number = 0,
  endIndex: number = 0
): Item[] => {
  if (list.length === 0) {
    return [];
  }

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export default reorder;
