// Libs
import React from 'react';
import { useTranslation } from '@external/react-i18next';
import moment, { Moment } from 'moment';
import { isNumber } from 'lodash';

// Components
import AsOfDate from '../components/AsOfDate';

// Utils
import { getRotaryYear } from '@utils/datetime';
import { formatNumberWithSign, localizeDayMonth } from '@use-cases/districts';

// Constants
import { YEAR_START_KEY, DeltasObject } from '@domain/districts';

// Types
import { MembersTrendsChartData } from '@domain/districts';
import { TFunction } from 'i18next';

const getRYRangeLabel = (date: Moment) => {
  const [year, month] = date.toArray();
  return month > 5 ? `${year}-${year + 1}` : `${year - 1}-${year}`;
};

const formatDate = (date: Moment) =>
  `${localizeDayMonth(date.format('D MMM'))} ${getRYRangeLabel(date)}`;

const getChartDataNode = (
  chartData: MembersTrendsChartData[],
  date: Moment
) => {
  const [, month, day] = date.toArray();
  const monthKey =
    day === 1 && month === 6 ? YEAR_START_KEY : moment.monthsShort()[month];

  return chartData.find(({ month }) => month === monthKey);
};

const renderDelta = (
  [delta, date]: [number, Moment],
  index: number,
  t: TFunction
) =>
  isNumber(delta) ? (
    <div key={index} className="mb-1 text-xs leading-sm last:mb-3">
      {formatNumberWithSign(delta!)} {t('dashboards.from', 'from')}{' '}
      {formatDate(date)}
    </div>
  ) : null;

const CurrentMonthInfo: React.FC<{
  chartData: MembersTrendsChartData[];
  asOfDate: string;
}> = ({ chartData, asOfDate }) => {
  const { t } = useTranslation();
  const today = moment();
  const chartNodeYearsKey = getRYRangeLabel(today);
  const nodeData = getChartDataNode(chartData, today) || {};
  const membersCount = nodeData[chartNodeYearsKey];
  const { deltaStartYear, deltaPrevMonth, deltaPrevYear } =
    ((nodeData.deltas as DeltasObject) || {})[chartNodeYearsKey] || {};
  const yearStart = moment([Number(getRotaryYear()) - 1, 6, 1]);
  const getMonthEnd = (key: 'month' | 'year') =>
    moment()
      .subtract(1, key)
      .endOf('month');
  const prevMonthEnd = getMonthEnd('month');
  const sameMonthLastYearEnd = getMonthEnd('year');

  return isNumber(membersCount) ? (
    <>
      <h3 className="mb-2 text-lg capitalize">
        {t('dashboards.title.membership-trends', 'Membership Trends')}
      </h3>
      <div className="mb-1 text-xs leading-sm">
        {formatDate(moment(asOfDate))}
      </div>
      <div className="mb-1 text-xs leading-sm">
        {t('countOf.members-capitalized', '<b>{{membersCount}}</b> Members', {
          membersCount,
        })}
      </div>
      {([
        [deltaStartYear, yearStart],
        [deltaPrevMonth, prevMonthEnd],
        [deltaPrevYear, sameMonthLastYearEnd],
      ] as Array<[number, Moment]>).map((deltaInfo, i) =>
        renderDelta(deltaInfo, i, t)
      )}
      {asOfDate && <AsOfDate date={asOfDate} />}
    </>
  ) : null;
};

export default CurrentMonthInfo;
