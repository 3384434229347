// Libs
import React from 'react';

// Utils
import tailwind from '@styles/tailwind.js';
import { numberToLocale } from '@use-cases/districts';

const CustomizedYAxisTick = (props: any) => {
  const {
    x,
    y,
    payload,
    yAxisTickValueFormat,
    isMobileLayout,
    shouldRotateTicksOnMobile = false,
  } = props;
  const areTicksRotated = shouldRotateTicksOnMobile && isMobileLayout;

  return (
    <svg>
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={areTicksRotated ? 18 : 5}
          dx={areTicksRotated ? 3 : -7}
          textAnchor="end"
          fill={tailwind.theme.colors['gray']['600']}
          className="text-4xs desktop:text-xs"
          transform={areTicksRotated ? 'rotate(45)' : ''}
        >
          {numberToLocale(payload.value, undefined, {
            style: yAxisTickValueFormat,
          })}
        </text>
      </g>
    </svg>
  );
};

export default CustomizedYAxisTick;
