// Libs
import React, { Fragment } from 'react';

// Components
import CustomDGListingOptions from './CustomDGListingOptions';
import HeaderFormattedSurvey from './HeaderFormattedSurvey';
import QuestionItem from './QuestionItem';

// Constants
import {
  CUSTOM_QUESTION_19_ID,
  CUSTOM_QUESTION_20_ID,
  SURVEY_OPTIONS,
} from '@domain/districts/conferences/constants';

//   Utils
import {
  getFormattedSurveyMaxSeqNumber,
  getFormattedSurveyMinSeqNumber,
} from './utils';

// Types
import { Question } from '@typings/operations';

type Props = {
  sectionId: string;
  questions: Question[];
  allQuestionsLength: number;
  allQuestions: Question[];
  isFeedbackWithCustomQuestions: boolean;
  isDG: boolean;
  isViewOnly: boolean;
};

const MIN_INDEX = 15;
const MAX_INDEX = 35;

const GeneralQuestions: React.FC<Props> = ({
  sectionId,
  questions,
  allQuestionsLength,
  allQuestions,
  isFeedbackWithCustomQuestions,
  isDG,
  isViewOnly,
}) => {
  return (
    <>
      {questions.map((question, index) => {
        const prevQuestion = index === 0 ? null : questions[index - 1];

        const isTableBroken =
          prevQuestion?.optionDetail.optionValue !== SURVEY_OPTIONS.FORMATTED &&
          question.optionDetail.optionValue === SURVEY_OPTIONS.FORMATTED;

        const isMinSeq =
          getFormattedSurveyMinSeqNumber(questions) === question.optionSeq;

        const questionIndex = allQuestions.findIndex(
          item => item.id === question.id
        );

        let formattedQuestionIndex = questionIndex;
        let minQuestionIndex = getFormattedSurveyMinSeqNumber(questions)!;
        let maxQuestionIndex = getFormattedSurveyMaxSeqNumber(questions)!;

        if (isFeedbackWithCustomQuestions) {
          minQuestionIndex -= MIN_INDEX;
          maxQuestionIndex -= MIN_INDEX;

          if (questionIndex >= MAX_INDEX) {
            formattedQuestionIndex -= MIN_INDEX;
          }
        }

        return (
          <Fragment key={question.id}>
            {(isMinSeq || isTableBroken) && (
              <HeaderFormattedSurvey
                isDG={isDG}
                sectionId={sectionId}
                isTableBroken={isTableBroken && !isMinSeq}
                min={minQuestionIndex - allQuestionsLength}
                max={maxQuestionIndex - allQuestionsLength}
              />
            )}
            <CustomDGListingOptions optionSeq={question.optionSeq} />
            <QuestionItem
              index={formattedQuestionIndex - allQuestionsLength}
              question={question}
              isViewOnly={isViewOnly}
              hideQuestionNumber={[
                CUSTOM_QUESTION_19_ID,
                CUSTOM_QUESTION_20_ID,
              ].includes(sectionId)}
            />
          </Fragment>
        );
      })}
    </>
  );
};

export default GeneralQuestions;
