import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { Icon } from '../../Icon';
import { FormElement } from '../FormElement';

export const InputCheckbox: React.FC<{
  id: string;
  name: string;
  value?: string;
  checked?: boolean;
  className?: string;
  label: string;
  labelAfter?: boolean;
  labelHidden?: boolean;
  description?: string;
  noMargins?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  changeHandler?: React.ChangeEventHandler<HTMLInputElement>;
  dataTestId?: string;
}> = ({
  id,
  name,
  value,
  checked,
  className,
  label,
  labelAfter,
  labelHidden,
  description,
  noMargins,
  required,
  disabled,
  error,
  errorMessage,
  changeHandler,
  dataTestId = '',
}) => {
  const [isChecked, setChecked] = useState(checked || false);

  useEffect(() => {
    setChecked(checked || false);
  }, [checked]);

  const onChangeSelectStatus = (event: React.ChangeEvent<HTMLInputElement>) =>
    changeHandler?.(event);

  return (
    <FormElement
      id={id}
      className={className}
      label={label}
      labelAfter={labelAfter}
      labelHidden={labelHidden}
      description={description}
      required={required}
      noMargins={noMargins}
      disabled={disabled}
      error={error}
      errorMessage={errorMessage}
      classes={{
        elementWrapper: 'inline-flex relative align-middle',
        description: classNames('leading-6', {
          'text-gray-300': disabled,
          'text-gray-600': !disabled,
          'ml-8': labelAfter,
          'inline-flex w-4/5 -mt-2': !labelAfter,
        }),
        errorMessage: 'ml-8',
      }}
      prefix={
        <div
          className={classNames(
            'flex items-center justify-center h-5 w-5 border absolute pointer-events-none',
            {
              'border-gray-500 bg-gray-400': isChecked && disabled,
              'border-bright-blue-500 bg-bright-blue-500':
                isChecked && !disabled,
              'border-gray-300': !isChecked,
              'bg-gray-100': !isChecked && disabled,
              'bg-white': !isChecked && !disabled,
            }
          )}
        >
          {isChecked && <Icon name="ui/check" size="16" color="white" />}
        </div>
      }
    >
      <input
        id={id}
        data-testid={dataTestId}
        type="checkbox"
        className={classNames(
          'h-5 w-5 cursor-pointer focus-visible:outline-0 focus:shadow-none',
          {
            'mr-3': !labelHidden || description,
          }
        )}
        name={name}
        value={value}
        disabled={disabled}
        required={required}
        checked={isChecked}
        onChange={onChangeSelectStatus}
      />
    </FormElement>
  );
};
