// Libs
import React, { ReactElement } from 'react';

// Components
import Icon from '@components/Icon';

type HeaderProps = {
  title: ReactElement;
  titleClassName?: string;
  isOpen: boolean;
  handleToggling: () => void;
};

const Header: React.FC<HeaderProps> = ({
  title,
  titleClassName,
  isOpen,
  handleToggling,
}) => (
  <div
    className={`pl-8 pb-3 flex items-center cursor-default ${titleClassName}`}
    onClick={handleToggling}
  >
    <div className="mr-auto inline-flex items-center">
      <Icon
        name={`arrows/${isOpen ? 'up' : 'down'}`}
        size="12"
        color="gray-600"
      />
      {title}
    </div>
  </div>
);

export default Header;
