import {
  MEMBERSHIP_TYPE_HONORARY,
  MEMBERSHIP_TYPE_HONORARY_ROTARACTOR,
  MEMBERSHIP_TYPE_MEMBER,
} from '@domain/districts';

export const getMembersLinkClass = (value: string): string => {
  switch (value) {
    case MEMBERSHIP_TYPE_MEMBER:
      return 'w-29';
    case MEMBERSHIP_TYPE_HONORARY:
    case MEMBERSHIP_TYPE_HONORARY_ROTARACTOR:
      return 'w-32';
    default:
      return 'pt-2';
  }
};
