// Libs
import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';

// Components
import FormButtons from '@presenters/web/components/FormButtons';
import SaveChangesModal from '@presenters/web/components/SaveChangesModal';
import Loading from '@components/Loading';
import DynamicQuestions from './DynamicQuestions';
import PRFormAfter2022 from './PRFormAfter2022';

// Hooks
import { useTranslation } from '@external/react-i18next';
import { useParams } from '@reach/router';
import { useFetchFeedbackQuestions } from '@repositories/districts';

// Utils
import { getInitialValue } from './utils';
import { mapDataFeedbackInput } from '@domain/districts';
import { isCurrentRY } from '@utils/datetime';

// Types
import { InputCreateFeedback, QuestionResponse } from '@typings/graphql';
import { FEEDBACK_TYPES } from '@domain/districts/conferences/types';

interface Props {
  responses?: QuestionResponse[];
  isViewOnly?: boolean;
  isShowing?: boolean;
  isDG?: boolean;
  handleSubmit?: (formData: InputCreateFeedback) => Promise<void>;
  cancel?: (showPopup: boolean) => void;
  globalHide?: () => void;
  handleOnContinue?: () => void;
}

const FeedbackForm: React.FC<Props> = ({
  responses,
  isViewOnly = false,
  isShowing,
  isDG = false,
  handleSubmit = () => {},
  cancel = () => {},
  globalHide,
  handleOnContinue,
}) => {
  const { t } = useTranslation();

  const [
    fetchFeedbackQuestions,
    { data: feedbackQuestionsData, loading: loadingFeedback },
  ] = useFetchFeedbackQuestions();

  const {
    feedbackType,
    conferenceId,
    relatedRotaryYear: relatedRY,
  } = useParams();

  useEffect(() => {
    fetchFeedbackQuestions({
      variables: {
        feedbackType,
        conferenceYear: Number(relatedRY),
      },
    });
  }, [feedbackType, relatedRY]);

  if (loadingFeedback) {
    return <Loading />;
  }

  const isPastRYDG =
    feedbackType === FEEDBACK_TYPES.DG && !isCurrentRY(relatedRY);

  const isPRFormAfter2022 =
    feedbackType === FEEDBACK_TYPES.PR && relatedRY >= 2022;

  const questionsBySections = feedbackQuestionsData?.getFeedbackQuestions || [];
  const allQuestions = questionsBySections.flatMap(el => el.questions);

  const initialValues = getInitialValue(allQuestions, responses || []);

  const handleOnSave = (data: { [key: string]: string }) => {
    handleSubmit(
      mapDataFeedbackInput(feedbackType, conferenceId, data, allQuestions)
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleOnSave}
    >
      {({ isSubmitting, values }) => (
        <Form>
          {isPRFormAfter2022 ? (
            <PRFormAfter2022
              isViewOnly={isViewOnly}
              sections={questionsBySections}
            />
          ) : (
            <DynamicQuestions
              isViewOnly={isViewOnly}
              sections={questionsBySections}
              isDG={isDG}
              isPastRYDG={isPastRYDG}
            />
          )}
          <div className="w-full tablet:w-120">
            {!isViewOnly && (
              <FormButtons
                saveBtnText={t(
                  'create-conference.save-and-finish',
                  'Save & Finish'
                )}
                cancelBtnText={t('create-conference.cancel', 'Cancel')}
                isSaveDisabled={isSubmitting}
                isCancelDisabled={isSubmitting}
                cancel={() => cancel(true)}
              />
            )}
          </div>
          <SaveChangesModal
            isOpen={isShowing || false}
            onClose={globalHide}
            onSave={() => handleOnSave(values)}
            onContinue={() => {
              handleOnContinue?.();
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default FeedbackForm;
