import React from 'react';

import classNames from 'classnames';
import ReactDatePicker from 'react-datepicker';

import { Icon } from '../Icon';

import { useTranslation } from '@external/react-i18next';

export interface DateProps {
  name: string;
  label: string;
  selectedValue: Date;
  placeholder?: string;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  isDisabled?: boolean;
  onChange: (value: Date) => void;
  className?: string;
}

const DatePicker: React.FC<DateProps> = ({
  name,
  label,
  placeholder,
  minDate,
  maxDate,
  isDisabled,
  dateFormat = 'yyyy-MM-dd',
  selectedValue,
  onChange,
  className = '',
}) => {
  const { t } = useTranslation();
  return (
    <div className={`custom-datepicker ${className}`}>
      <div className="text-xs font-bold">{label}</div>
      <div className="relative">
        <ReactDatePicker
          id={name}
          className={classNames(
            'border border-gray-300 focus:border-dark-blue-400 rounded-sm w-full py-3 px-4 text-xs leading-loose',
            'focus-visible:outline-0 focus:shadow-none',
            {
              'bg-gray-100 cursor-not-allowed placeholder-gray-300': isDisabled,
              'placeholder-gray-400': !isDisabled,
            }
          )}
          minDate={minDate}
          maxDate={maxDate}
          placeholderText={placeholder}
          dateFormat={dateFormat}
          selected={selectedValue}
          todayButton={t('forms.datepicker.today-label', 'Select today')}
          disabled={isDisabled}
          onChange={onChange}
        />
        <div className="absolute bottom-0 h-6 w-6 m-auto mr-4 right-0 top-0 pointer-events-none">
          <Icon name="ui/calendar" color="gray-400" />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
