// Libs
import React from 'react';

// Constants
import { OTHER_COUNT, TEXT_FILL_COLOR } from '@domain/districts';

type Props = {
  x?: number;
  y?: number;
  label: string;
  value?: number | string | Array<number | string>;
};

const CustomizedLabel: React.FC<Props> = ({ x, y, value, label }) => (
  <>
    {label !== OTHER_COUNT && value !== 0 && (
      <svg>
        <g>
          <text
            x={Number(x) + 5}
            y={Number(y) + 25}
            fill={TEXT_FILL_COLOR}
            className="text-3xs tablet:text-xs"
            textAnchor="start"
          >
            {`${value}%`}
          </text>
        </g>
      </svg>
    )}
  </>
);

export default CustomizedLabel;
