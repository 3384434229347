import React, { useContext } from 'react';

import { Button } from '@components/Button';
import MemberRow from '@components/MemberRow';
import ClubAffiliation from '@presenters/web/components/DistrictSelectMemberStep/ClubAffiliation';
import {
  getActiveAssociatedClub,
  getActiveMembership,
  getAssociatedClubsInfo,
  getMemberEmail,
  getMemberPhone,
  isDisabledAction,
  isMember,
} from '@presenters/web/pages/Districts/CommitteeAppointments/SelectMemberStep/utils';

import { CommitteeMemberData, DistrictMember } from '@domain/districts';

import { DistrictContext } from '@use-cases/districts';

import { getYear } from '@utils/datetime';

import { useTranslation } from '@external/react-i18next';

import { DistrictLeadership } from '@typings/graphql';

type Props = {
  member: DistrictMember;
  originalDistrictLeadership?: DistrictLeadership[];
  selectHandler: (member: DistrictMember | CommitteeMemberData) => void;
  isPreviousAG?: boolean;
};

const MemberItem: React.FC<Props> = ({
  member,
  originalDistrictLeadership,
  selectHandler,
  isPreviousAG,
}) => {
  const { t } = useTranslation();
  const [{ selectedInfo }] = useContext(DistrictContext);

  const {
    id,
    photoUri,
    localizedName,
    nameWithPrefixSuffix,
    thisDistrictLeadership,
  } = member;

  const activeMembership = getActiveMembership(member);
  const activeAssociatedClub = getActiveAssociatedClub(
    member,
    activeMembership
  );
  const associatedClubsInfo = getAssociatedClubsInfo(
    member,
    activeAssociatedClub
  );

  const isDisabled = isDisabledAction(
    selectedInfo.term,
    originalDistrictLeadership,
    isPreviousAG
  );

  const getDistrictLeadership = (
    districtLeadership: (
      | DistrictLeadership
      | {
          startDate: string;
          endDate: string;
        }
    )[]
  ) => {
    return districtLeadership?.map(({ startDate, endDate }, key) => {
      const uniqueKey = `${startDate}-${key}-${endDate}`;
      return (
        <div key={uniqueKey} className="text-xs w-24 pl-px">
          {getYear(startDate)}-{getYear(endDate)}
        </div>
      );
    });
  };

  const clubAffiliation = () => {
    if (isPreviousAG) {
      const districtLeadership =
        isDisabled && selectedInfo.term
          ? [
              {
                startDate: selectedInfo.term.startDate,
                endDate: selectedInfo.term.endDate,
              },
              ...thisDistrictLeadership,
            ]
          : thisDistrictLeadership;

      return getDistrictLeadership(districtLeadership);
    }

    return <ClubAffiliation membership={activeMembership} />;
  };

  const getMemberInfo = () => {
    if (isMember(member)) {
      return undefined;
    }

    const getMessage = () => {
      if (!associatedClubsInfo.length) {
        return t(
          'club-roster.members.search-membership-status-option-terminated',
          'Terminated'
        );
      }
      return undefined;
    };

    return <div className="text-xs mb-0">{getMessage()}</div>;
  };

  const actionButton = (member: DistrictMember) => (
    <Button
      clickHandler={() => selectHandler(member)}
      secondary
      isMinWidth={false}
      small
      type="button"
      className="h-10 text-2xs"
      dataTestId="select-member-btn"
      disabled={isDisabled}
    >
      {t('button.select', 'Select')}
    </Button>
  );

  return (
    <MemberRow
      id={id}
      email={getMemberEmail(member)}
      fullName={nameWithPrefixSuffix}
      localizedName={localizedName || ''}
      photoUri={photoUri || ''}
      phoneNumber={getMemberPhone(member)}
      associatedClubsInfo={associatedClubsInfo}
      clubAffiliation={clubAffiliation()}
      memberInfo={isPreviousAG ? undefined : getMemberInfo()}
      actionButton={actionButton(member)}
      // TODO: check styles when story for mobile will be implemented
      actionButtonMobile={actionButton(member)}
    />
  );
};

export default MemberItem;
