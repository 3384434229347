// Libs
import React from 'react';

// Components
import { Button } from '@components/Button';

interface Props {
  saveBtnText: string;
  cancelBtnText: string;
  isCancelDisabled: boolean;
  isSaveDisabled: boolean;
  cancel: () => void;
}

const FormButtons: React.FC<Props> = ({
  saveBtnText,
  cancelBtnText,
  isCancelDisabled,
  isSaveDisabled,
  cancel,
}) => {
  return (
    <section className="mt-8 tablet:mt-11">
      <div className="flex tablet:flex-row flex-col">
        <div className="flex-1 tablet:pr-4 pr-0">
          <Button
            dataTestId="submit-conf-form-btn"
            type="submit"
            full
            isMinWidth
            className="mb-5 tablet:mr-5 tablet:mb-0"
            disabled={isSaveDisabled}
          >
            {saveBtnText}
          </Button>
        </div>
        <div className="flex-1">
          <Button
            dataTestId="cancel-btn"
            full
            isMinWidth
            type="button"
            secondary
            clickHandler={cancel}
            disabled={isCancelDisabled}
          >
            {cancelBtnText}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default FormButtons;
