import React from 'react';

import ConferenceAction from './ConferenceAction';

import { FEEDBACK_TYPES, RenderActionsButton } from '@domain/districts';

import {
  getConferenceViewPage,
  getCreateOrUpdateFeedbackPage,
  getFeedbackViewPage,
  isPastConference,
} from '@use-cases/districts';

import { getRotaryYear } from '@utils/datetime';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const ActionButtons: RenderActionsButton = (districtId, conference) => {
  const { user } = useAppConfig();
  const { t } = useTranslation();

  const RY = Number(getRotaryYear());
  const {
    id: conferenceId,
    districtId: conferenceDistrictId,
    relatedRotaryYear,
    endDate,
    presidentialRepresentative,
    feedbacks,
  } = conference;
  const isPastConf = isPastConference(endDate);
  const diffBetweenYears = RY - relatedRotaryYear;
  const isPR = presidentialRepresentative?.id === user?.individualId;
  const isFeedbackProvided = feedbacks.some(
    fb => fb.type === FEEDBACK_TYPES.PR
  );

  if (isPastConf && isPR && diffBetweenYears <= 1) {
    // if isFeedbackProvided - redirect to edit feedback
    const urlToNavigate = getCreateOrUpdateFeedbackPage(
      {
        districtId: conferenceDistrictId,
        id: conferenceId,
        relatedRotaryYear,
      },
      'PR'
    );
    return (
      <ConferenceAction to={urlToNavigate}>
        {t('conference.feedback-link', 'Feedback')}
      </ConferenceAction>
    );
  }
  if (isPR && diffBetweenYears <= 1) {
    return (
      <ConferenceAction
        to={`${getConferenceViewPage(districtId, conferenceId)}?back=PR`}
      >
        {t('conference.view-link', 'View')}
      </ConferenceAction>
    );
  }
  if (isFeedbackProvided) {
    const feedbackId =
      feedbacks.find(item => item.type === FEEDBACK_TYPES.PR)?.id || '';

    return (
      <ConferenceAction
        to={getFeedbackViewPage(
          districtId,
          relatedRotaryYear,
          conferenceId,
          feedbackId
        )}
        passThrough={{ isDistrictPresRep: true }}
      >
        {t('conference.view-feedback-link', 'View Feedback')}
      </ConferenceAction>
    );
  }
  return null;
};

export default ActionButtons;
