import React from 'react';

import MemberPreview from '@components/MemberPreview';

import BlockWrapper from './BlockWrapper';

import { OFFICERS_ROLES } from '@domain/districts';

import {
  buildOfficersPageUrl,
  getActiveDO,
  getLabelValue,
  getOfficersRolesLabels,
  sortItemsByRequiredOrder,
} from '@use-cases/districts';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { DistrictOfficer, OfficerIndividual } from '@typings/operations';

type Props = {
  officers: DistrictOfficer[];
  districtId: string;
  profilePreviousLinkPath?: string;
};

const Officers: React.FC<Props> = ({
  officers,
  districtId,
  profilePreviousLinkPath,
}) => {
  const { t } = useTranslation();

  const getName = ({
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
  }: OfficerIndividual) =>
    [prefix, firstName, middleName, lastName, suffix].filter(Boolean).join(' ');

  return (
    <BlockWrapper
      title={t('district-details.officers-heading', 'District Officers')}
      handleClick={() => {
        localizedNavigate(buildOfficersPageUrl(districtId), {
          state: {
            isFromDetailsPage: true,
          },
        });
      }}
    >
      {sortItemsByRequiredOrder(
        getActiveDO(officers),
        OFFICERS_ROLES,
        'role'
      ).map(({ id, individual, role }) => (
        <div key={id}>
          <div className="text-2xs text-gray-600 font-bold mb-2">
            {getLabelValue(getOfficersRolesLabels(t), role)?.label}
          </div>
          <MemberPreview
            name={getName(individual)}
            path={`/profile/${individual.id}`}
            photoUri={individual.photoUri}
            email={individual.primaryEmail}
            noLinkStyles
            linkClassName="underline text-xs text-gray-600 block"
            key={id}
            dataTestId="officer-item"
            profilePreviousLinkPath={profilePreviousLinkPath}
          />
        </div>
      ))}
    </BlockWrapper>
  );
};

export default Officers;
