import React, { ReactNode } from 'react';

import { useTranslation } from '@external/react-i18next';

type Props = {
  title?: ReactNode;
  subTitle?: ReactNode;
  notification?: ReactNode;
  roleTitle: string;
  isSubTitleHidden?: boolean;
};

const Heading: React.FC<Props> = ({
  title,
  subTitle,
  notification,
  roleTitle,
  isSubTitleHidden = false,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-xl leading-6">{title}</h2>
      {isSubTitleHidden ? null : (
        <div className="mb-6">
          {subTitle || (
            <>
              {t('select-member.subtitle', 'Select a member for the role of')}
              <b> {roleTitle}</b>
            </>
          )}
        </div>
      )}
      {notification && <div className="mb-6">{notification}</div>}
    </div>
  );
};

export default Heading;
