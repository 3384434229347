import React from 'react';

import classNames from 'classnames';
import moment from 'moment';

import DatePicker from '@components/DatePicker';
import LabelWithValue from '@components/LabelWithValue';
import EmailInfoMessage from '@presenters/web/components/EmailInfoMessage/EmailInfoMessage';
import SelectTerm from '@presenters/web/components/SelectTerm';

import { OperationType, TermToAssign } from '@domain/districts';

import { getTermStringFromObject } from '@use-cases/districts';

import { getRotaryYearRange, getYear } from '@utils/datetime';

import { useTranslation } from '@external/react-i18next';

import { Maybe } from '@typings/graphql';

type Labels = {
  termLabel: string;
  roleLabel: string;
  emailLabel: string;
  officerLabel: string;
  officerToBeReplacedLabel?: string;
  datePickerLabel?: string;
  effectiveDateLabel?: string;
};

interface Props {
  roleName: string | null;
  assigneeEmail: string | null;
  operationType?: Maybe<OperationType>;
  assigneeName: Maybe<string>;
  officerToBeReplacedName?: Maybe<string>;
  term: Maybe<TermToAssign>;
  termYears?: Maybe<number>;
  termForNowYear?: Maybe<TermToAssign>;
  termForElect?: Maybe<TermToAssign>;
  title?: string;
  onChangeTerm?: (term: TermToAssign) => void;
  labels: Labels;
  handleSelectDate?: (date: Date) => void;
  classes?: {
    titleClass?: string;
  };
  maxDate?: Date;
  minDate?: Date;
  selectedDateValue?: Date;
  effectiveDate?: string;
}

const Confirmation: React.FC<Props> = ({
  roleName,
  assigneeEmail,
  assigneeName,
  officerToBeReplacedName,
  term,
  termYears,
  termForNowYear,
  termForElect,
  operationType,
  title,
  onChangeTerm,
  labels,
  handleSelectDate,
  classes = {
    titleClass: 'h2 mb-5 mt-7',
  },
  minDate = new Date(),
  maxDate,
  selectedDateValue = new Date(),
  effectiveDate,
}) => {
  const { t } = useTranslation();
  const {
    termLabel,
    roleLabel,
    emailLabel,
    officerLabel,
    officerToBeReplacedLabel,
    datePickerLabel,
    effectiveDateLabel,
  } = labels;

  const isSameStartAndEndTerm = moment(term?.startDate).isSame(
    moment(term?.endDate),
    'year'
  );

  const CALENDAR_DATE_FORMAT = 'dd MMM yyyy';

  const showTermBlock = () => {
    if (term) {
      if (
        [OperationType.REASSIGN_TO_ROLE, OperationType.REASSIGN_TO_AG].includes(
          operationType as OperationType
        )
      ) {
        const reassignTerms = [termForNowYear, termForElect].filter(
          Boolean
        ) as TermToAssign[];

        if (reassignTerms.length > 1) {
          return (
            <SelectTerm
              termsToAssign={reassignTerms}
              selectedTerm={term}
              onChangeTerm={onChangeTerm}
              label="Term"
              termsSelector="district-officers.term"
            />
          );
        }
        return (
          <LabelWithValue
            label={termLabel}
            value={getTermStringFromObject(reassignTerms[0])}
          />
        );
      }

      if (
        [OperationType.ASSIGN_TO_ROLE, OperationType.ASSIGN_TO_MEMBER].includes(
          operationType as OperationType
        )
      ) {
        const assignTerms = [termForNowYear, termForElect].filter(
          Boolean
        ) as TermToAssign[];

        if (assignTerms.length === 0) {
          assignTerms.push({
            startDate: term.startDate,
            endDate: String(
              Number(term.startDate) + (termYears ? Number(termYears) : 1)
            ),
          });
        }

        return (
          <LabelWithValue
            label={termLabel}
            value={getTermStringFromObject(assignTerms[0])}
          />
        );
      }

      const { startYear, endYear } = getRotaryYearRange(getYear(term.endDate));
      const rotaryYearRange = isSameStartAndEndTerm
        ? {
            startDate: startYear,
            endDate: endYear,
          }
        : term;

      return (
        <LabelWithValue
          label={termLabel}
          value={getTermStringFromObject(rotaryYearRange)}
        />
      );
    }
    return null;
  };

  return (
    <>
      {roleName && (
        <h2 data-cy="role-title" className={classNames(classes.titleClass)}>
          {title}
        </h2>
      )}

      {officerToBeReplacedName && officerToBeReplacedLabel && (
        <LabelWithValue
          label={officerToBeReplacedLabel}
          value={officerToBeReplacedName}
        />
      )}
      {assigneeName && (
        <LabelWithValue label={officerLabel} value={assigneeName} />
      )}
      {assigneeEmail && (
        <>
          <LabelWithValue label={emailLabel} value={assigneeEmail} />
          {title?.includes(t('action-button.remove', 'Remove')) ? (
            ''
          ) : (
            <EmailInfoMessage
              notificationMessage={t(
                'district-officers.privacy-setting-message',
                `District officer email address(es) will be shared with other 
            district members regardless of an individual’s privacy settings. By adding a new district officer you acknowledge 
             that they are aware that their personal information will be shared.`
              )}
              divClassName="max-w-md pt-1"
            />
          )}
        </>
      )}
      {roleName && <LabelWithValue label={roleLabel} value={roleName} />}
      {showTermBlock()}
      {effectiveDate && effectiveDateLabel && (
        <LabelWithValue label={effectiveDateLabel} value={effectiveDate} />
      )}
      {handleSelectDate && datePickerLabel && (
        <DatePicker
          name="endDate"
          label={datePickerLabel}
          selectedValue={selectedDateValue}
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleSelectDate}
          dateFormat={CALENDAR_DATE_FORMAT}
          className="w-40 relative mb-4"
        />
      )}
    </>
  );
};

export default Confirmation;
