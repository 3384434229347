import React, { useState } from 'react';

import PageSteps from '@components/PageSteps';
import CandidateAddressFormDetails from '@presenters/web/pages/Leads/MMLPage/IdentifyDistrictCandidate/AddressInformation/CandidateAddressFormDetails';
import CandidateFormDetails from '@presenters/web/pages/Leads/MMLPage/IdentifyDistrictCandidate/ContactInformation/CandidateFormDetails';

const AddNewCandidateDistrict: React.FC<{
  districtName: string;
  districtId: string;
  handleBackClick: () => void;
}> = ({ districtId, handleBackClick, districtName }) => {
  enum AddNewDistrictCandidateSteps {
    CANDIDATEINFO,
    ADDRESSINFO,
    THANKYOU,
  }
  const noPaddingClass = 'p-0';
  const navClassName = 'text-small font-bold mt-10 mt-8 mb-0';
  const backBtnClassName = 'font-bold text-bright-blue-600';
  const [step, setStep] = useState<AddNewDistrictCandidateSteps>(
    AddNewDistrictCandidateSteps.CANDIDATEINFO
  );

  const handleBackArrowClick = (pageStep: AddNewDistrictCandidateSteps) =>
    setStep(pageStep);

  switch (step) {
    case AddNewDistrictCandidateSteps.CANDIDATEINFO:
      return (
        <PageSteps
          step={1}
          total={2}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
          backBtnClassName={backBtnClassName}
        >
          <CandidateFormDetails
            districtName={districtName}
            districtId={districtId}
            submitHandler={() =>
              setStep(AddNewDistrictCandidateSteps.ADDRESSINFO)
            }
          />
        </PageSteps>
      );
    case AddNewDistrictCandidateSteps.ADDRESSINFO:
      return (
        <PageSteps
          step={2}
          total={2}
          backHandler={() =>
            handleBackArrowClick(AddNewDistrictCandidateSteps.CANDIDATEINFO)
          }
          className={noPaddingClass}
          navClassName={navClassName}
          backBtnClassName={backBtnClassName}
        >
          <CandidateAddressFormDetails
            districtId={districtId}
            submitHandler={() => setStep(AddNewDistrictCandidateSteps.THANKYOU)}
          />
        </PageSteps>
      );
    case AddNewDistrictCandidateSteps.THANKYOU:
    default:
      return null;
  }
};
export default AddNewCandidateDistrict;
