// Libs
import React from 'react';

// Utils
import tailwind from '@styles/tailwind.js';
import { formatNumberWithSign } from '@use-cases/districts';

// Types
import { Props as LabelProps } from 'recharts/types/component/Label';

const CustomizedLabel: React.FC<LabelProps> = props => {
  const { x, y, value, width, height, offset } = props;

  if (value && x && y && width && height) {
    const xToNumber = Number(x);
    const yToNumber = Number(y);

    const xOffset = xToNumber + Number(width) / 2;
    const yOffset = value > 0 ? yToNumber + Number(offset) : yToNumber;
    const dyOffset = value > 0 ? '0.71em' : '-0.21em';

    return (
      <svg>
        <text
          x={xOffset}
          y={yOffset}
          textAnchor="middle"
          fill={tailwind.theme.colors['gray']['600']}
          className="text-xs font-bold"
        >
          <tspan x={xOffset} dy={dyOffset}>
            {formatNumberWithSign(Number(value))}
          </tspan>
        </text>
      </svg>
    );
  }

  return null;
};

export default CustomizedLabel;
