import React, { useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import PageSteps from '@components/PageSteps';
import ConfirmCandidateDetailsForm from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/CandidateDetails/ConfirmCandidateDetailsForm';

import MembershipInformation from './MembershipInformationDistrict';

import {
  getCandidateDetailsPath,
  getDistrictMemberPath,
} from '@use-cases/leads';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface ClubCandidateProps extends RouteComponentProps {
  clubId: string;
  isRotaractClub: boolean;
  districtId: string;
  isDistrict: boolean;
}

const Candidate: React.FC<ClubCandidateProps> = ({
  clubId,
  districtId,
  isDistrict,
  isRotaractClub,
}) => {
  enum AddCandidateMembershipSteps {
    CONFIRMCANDIDATE,
    ADDMEMBERSHIPINFO,
  }
  const { t } = useTranslation();
  const noPaddingClass = 'p-0 relative bottom-2';
  const navClassName = 'text-small font-bold mt-6 mb-5';
  const backBtnClassName = 'font-bold text-bright-blue-600';
  const [step, setStep] = useState<AddCandidateMembershipSteps>(
    AddCandidateMembershipSteps.CONFIRMCANDIDATE
  );

  const handleBackClick = () => {
    if (!isDistrict) {
      localizedNavigate(getCandidateDetailsPath(clubId));
    } else {
      localizedNavigate(getDistrictMemberPath(districtId));
    }
  };

  const redirectMemberDetailsPath = () => {
    if (!isDistrict) {
      localizedNavigate(getCandidateDetailsPath(clubId));
    } else {
      localizedNavigate(getDistrictMemberPath(districtId));
    }
  };

  switch (step) {
    case AddCandidateMembershipSteps.CONFIRMCANDIDATE:
      return (
        <PageSteps
          step={1}
          total={2}
          backHandler={() => handleBackClick()}
          className={noPaddingClass}
          navClassName={navClassName}
          backBtnClassName={backBtnClassName}
        >
          <div>
            <h2>
              {t(
                'mml.confirm-candidate-as-member.title',
                'Confirm candidate as club member'
              )}
            </h2>
            <ConfirmCandidateDetailsForm
              submitHandler={() =>
                setStep(AddCandidateMembershipSteps.ADDMEMBERSHIPINFO)
              }
              handleCancelClick={() => redirectMemberDetailsPath()}
            />
          </div>
        </PageSteps>
      );
    case AddCandidateMembershipSteps.ADDMEMBERSHIPINFO:
      return (
        <PageSteps
          step={2}
          total={2}
          backHandler={() =>
            setStep(AddCandidateMembershipSteps.CONFIRMCANDIDATE)
          }
          className={noPaddingClass}
          navClassName={navClassName}
          backBtnClassName={backBtnClassName}
        >
          <div>
            <h2>
              {t(
                'mml.confirm-candidate-membership-information.title',
                'Add membership information'
              )}
            </h2>
            <MembershipInformation
              isRotaractClub={isRotaractClub}
              hasActiveMembership={false}
              clubId={clubId || ''}
              recentMemberships={[]}
              submitHandler={() => {}}
            />
          </div>
        </PageSteps>
      );
    default:
      return null;
  }
};
export default Candidate;
