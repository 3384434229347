import React, { useContext } from 'react';

import ConfirmDetails from '@presenters/web/pages/Districts/ManageAssistantGovernors/ConfirmDetails';

import ReplaceOfficerStepConfirm from './ReplaceRole';

import { ASSISTANT_GOVERNOR, OperationType } from '@domain/districts';

import { DistrictContext } from '@use-cases/districts';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
};

const Step3: React.FC<Props> = ({ districtId, riDistrictId }) => {
  const [context] = useContext(DistrictContext);
  const { operationType, selectedInfo } = context;

  const { role } = selectedInfo;

  switch (operationType) {
    case OperationType.REPLACE_ROLE: {
      if (role?.name === ASSISTANT_GOVERNOR) {
        return <ConfirmDetails riDistrictId={riDistrictId} />;
      }
      return (
        <ReplaceOfficerStepConfirm
          districtId={districtId}
          riDistrictId={riDistrictId}
        />
      );
    }
    default:
      return null;
  }
};

export default Step3;
