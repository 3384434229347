// Libs
import React from 'react';
import { useTranslation } from '@external/react-i18next';

// Utils
import DesktopHeaderSurvey from '@presenters/web/components/FeedbackForm/HeaderFormattedSurvey/DesktopHeaderSurvey';
import MobileHeaderSurvey from '@presenters/web/components/FeedbackForm/HeaderFormattedSurvey/MobileHeaderSurvey';

// Constants
import { GOVERNOR_EVALUATION_ID } from '@domain/districts';

interface Props {
  min: number | null;
  max: number | null;
  sectionId?: string;
  isTableBroken?: boolean;
  isDG?: boolean;
}

const HeaderFormattedSurvey: React.FC<Props> = ({
  min = 0,
  max = 0,
  sectionId,
  isTableBroken = false,
  isDG = false,
}) => {
  const { t } = useTranslation();

  const subTitleDG = t(
    'district.feedback.formatted-survey-header-text.dg',
    'For questions {{min}}-{{max}}, please use the following scale to evaluate the performance of the President’s Representative effectiveness in the following areas',
    { min, max }
  );

  let subTitle = t(
    'district.feedback.formatted-survey-header-text.conference-evaluation',
    'For questions {{min}}-{{max}}, please use the following scale to evaluate the conference: ',
    { min, max }
  );
  if (sectionId === GOVERNOR_EVALUATION_ID) {
    subTitle = t(
      'district.feedback.formatted-survey-header-text.evaluate-governor',
      'For questions {{min}}-{{max}}, please use the following scale to evaluate Governor: ',
      { min, max }
    );
  }
  if (isTableBroken) {
    subTitle = t(
      'district.feedback.formatted-survey-header-text.short-conference-evaluation',
      'Please use the following scale to evaluate the conference: '
    );
  }

  const headerProps = {
    subTitle: isDG ? subTitleDG : subTitle,
  };

  return (
    <>
      <DesktopHeaderSurvey {...headerProps} />
      <MobileHeaderSurvey {...headerProps} />
    </>
  );
};

export default HeaderFormattedSurvey;
