import React from 'react';

import { isNumber, last, words } from 'lodash';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';

import TooltipWrapper from '../components/TooltipWrapper';

import {
  DeltasObject,
  FIRST_JUL,
  JUL_KEY,
  YEAR_START_KEY,
} from '@domain/districts';

import {
  formatNumberWithSign,
  getDateForMonthValue,
  getYearLabel,
  localizeDayMonth,
  numberToLocale,
} from '@use-cases/districts';

import { getDaysInMonthByYear } from '@utils/datetime';

import { useTranslation } from '@external/react-i18next';

type PayloadProps = {
  month: string;
  prevMonth: string;
  deltas: DeltasObject;
};

type Props = {
  active?: boolean;
  payload?: {
    dataKey?: string | number;
    value?: ValueType;
    payload?: PayloadProps;
  }[];
  lineIndex: number | null;
  asOfDate: string;
  yearsRange: string[];
};

export const CustomizedTooltip: React.FC<Props> = ({
  active,
  payload: chartData,
  lineIndex,
  asOfDate,
  yearsRange,
}) => {
  const { t } = useTranslation();

  let tooltipData;

  if (isNumber(lineIndex)) {
    const requestedYear = yearsRange[lineIndex];
    // in case there is a dot for June in current RY line
    // `chartData` array will have only one element
    // and lineIndex remains 4, so for getting toolTipData we take `chartData?.[0]`
    tooltipData =
      chartData?.find(datum => datum.dataKey === requestedYear) ||
      chartData?.[0];
  }

  if (active && tooltipData) {
    const { dataKey, value, payload } = tooltipData;

    if (payload) {
      const { deltas, month, prevMonth } = payload;

      const { deltaPrevMonth, deltaPrevYear, deltaStartYear, prevYear } =
        deltas[dataKey as string] || {};

      const isStartRY = month === YEAR_START_KEY;
      const isJuly = (month: string) => month === JUL_KEY;

      // Current and previous year range, e.g. 2020-2021
      const prevRYRange = prevYear ? getYearLabel(prevYear) : '';
      const rotaryYearRange = isStartRY ? prevRYRange : (dataKey as string);

      // Get RY from range, last value is active
      const getYearFromRange = (yearRange: string) =>
        (yearRange && last(words(yearRange))) || '';

      const constructLabel = (
        value: string | number,
        month: string,
        yearRange: string | null,
        isStartRY: boolean = false
      ) => {
        let dayAndMonth = FIRST_JUL;

        if (!isStartRY) {
          const year = getYearFromRange(yearRange as string);
          dayAndMonth = `${getDaysInMonthByYear(year, month)} ${month}`;
        }

        return t('dashboards.delta-from-date', '{{delta}} from {{date}}', {
          delta: value,
          date: `${localizeDayMonth(
            `${dayAndMonth} ${yearRange}`,
            'D MMM YY'
          )} ${yearRange}`,
        });
      };

      const constructDateLabel = () =>
        getDateForMonthValue(
          month,
          getYearFromRange(dataKey as string),
          asOfDate
        );

      return (
        <TooltipWrapper
          title={constructDateLabel()}
          subtitle={t('countOf.members', '{{membersCount}} members', {
            membersCount: numberToLocale(Number(value || 0)),
          })}
        >
          {!isStartRY && (
            <div>
              {constructLabel(
                formatNumberWithSign(deltaStartYear || 0),
                prevMonth,
                dataKey as string,
                true
              )}
            </div>
          )}
          {!isJuly(month) && (
            <div>
              {constructLabel(
                formatNumberWithSign(deltaPrevMonth || 0),
                prevMonth,
                rotaryYearRange
              )}
            </div>
          )}
          <div>
            {constructLabel(
              formatNumberWithSign(deltaPrevYear || 0),
              month,
              prevRYRange,
              isStartRY
            )}
          </div>
        </TooltipWrapper>
      );
    }
  }

  return null;
};

export default CustomizedTooltip;
