import React from 'react';

type Props = {
  header?: string;
  title: string;
  subtitle?: string;
};

const TooltipWrapper: React.FC<Props> = ({
  children,
  header,
  title,
  subtitle,
}) => (
  <div
    className="border border-gray-300 rounded-lg bg-white shadow-xl p-3 min-w-14 text-sm text-gray-400 leading-5"
    data-testid="tooltip"
  >
    {header && <div className="mb-1">{header}</div>}
    <div className={header ? '' : 'mb-1'}>{title}</div>
    {subtitle && (
      <div className="text-gray-600 font-semibold leading-3">{subtitle}</div>
    )}
    {children}
  </div>
);

export default TooltipWrapper;
