import React, { useEffect } from 'react';

import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import NotFound from '@components/Routing/NotFound';
import Title from '@components/Title';
import ConferenceSummarySection from '@presenters/web/components/ConferenceSummarySection';
import DetailSection from '@presenters/web/components/DetailSection';
import FeedbackForm from '@presenters/web/components/FeedbackForm';

import {
  ACCESS_LEVEL_TARGETS,
  FEEDBACK_TYPES,
  mapConferencesFeedbackAccessLevels,
  mapConferenceToConferenceSummary,
} from '@domain/districts';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import {
  getConferencePage,
  getPresRepPageUrl,
} from '@use-cases/districts/conferences';
import { useNotifications } from '@use-cases/notifications';

import {
  useCreateFeedback,
  useFetchConferenceDetails,
  useFetchFormattedDGByRY,
  useFetchOneFeedback,
  useUpdateFeedback,
} from '@repositories/districts/hooks';

import { isEditLevel } from '@utils/access-helpers';
import { getFormattedRYRange } from '@utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useFetchAccessLevels } from '@hooks/useFetchAccessLevels';

import { InputCreateFeedback } from '@typings/operations';

interface Props {
  districtId: string;
  feedbackType: string;
  relatedRotaryYear: string;
  conferenceId: string;
}

const CreateOrUpdateFeedback: React.FC<Props> = ({
  districtId: conferenceDistrictId,
  feedbackType,
  relatedRotaryYear: relatedRY,
  conferenceId,
}) => {
  const { t } = useTranslation();

  const { addSuccess, addError } = useNotifications();

  const [
    fetchConference,
    { data: conferenceData, loading: conferenceLoading },
  ] = useFetchConferenceDetails();

  const [
    createFeedback,
    { loading: loadingCreateFeedback },
  ] = useCreateFeedback();

  const [
    updateFeedback,
    { loading: loadingUpdateFeedback },
  ] = useUpdateFeedback();

  const [
    fetchFeedbackAccessLevels,
    { data: feedbackAccessLevels, loading: feedbackAccessLevelsLoading },
  ] = useFetchAccessLevels();

  const feedbackAccessLevel = mapConferencesFeedbackAccessLevels(
    feedbackAccessLevels
  )?.[conferenceId];
  const canAddFeedback = isEditLevel(feedbackAccessLevel);

  const {
    startYear: minStartDate,
    endYear: maxStartDate,
  } = getFormattedRYRange(Number(relatedRY));

  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isStepMax: true,
  });

  const isDGFeedback = feedbackType === FEEDBACK_TYPES.DG;

  const backPath = isDGFeedback
    ? getConferencePage(conferenceDistrictId)
    : getPresRepPageUrl();

  const pageTitle = t(
    'feedback.district-conference-feedback-create',
    'District Conference Feedback'
  );

  const conferenceSummaryTitle = t(
    'feedback.conference-summary.title',
    'Conference Summary'
  );

  const backBtnTitle = t('feedback.back-link', 'Back');

  const [
    fetchOneFeedback,
    { loading: loadingFeedbackResponses, data: feedbackData },
  ] = useFetchOneFeedback();

  useEffect(() => {
    fetchConference({
      variables: {
        conferenceId,
      },
    });
  }, [conferenceId]);

  useEffect(() => {
    fetchFeedbackAccessLevels({
      variables: {
        options: [
          {
            type: ACCESS_LEVEL_TARGETS.DISTRICT_CONFERENCE_FEEDBACK,
            id: conferenceId,
            targetDistrictId: conferenceDistrictId,
          },
        ],
      },
    });
  }, [conferenceDistrictId, conferenceId]);

  const {
    loadingDGByRY,
    isDG,
    isSpRep,
    fullName,
    districtNumber,
  } = useFetchFormattedDGByRY(
    conferenceDistrictId,
    minStartDate,
    maxStartDate,
    relatedRY
  );

  useEffect(() => {
    if (canAddFeedback) {
      fetchOneFeedback({
        variables: {
          feedbackType,
          conferenceId,
        },
      });
    }
  }, [feedbackType, conferenceId, canAddFeedback]);

  if (feedbackAccessLevels && !feedbackAccessLevelsLoading && !canAddFeedback) {
    return <NotFound default />;
  }

  if (
    loadingDGByRY ||
    loadingCreateFeedback ||
    loadingUpdateFeedback ||
    conferenceLoading ||
    loadingFeedbackResponses ||
    feedbackAccessLevelsLoading
  ) {
    return <Loading />;
  }

  const goBack = (showPopup: boolean) => {
    showPopup ? show(true) : localizedNavigate(backPath);
  };

  const handleOnContinue = () => {
    globalHide();
    goBack(false);
  };

  const { startDate, endDate, location, presidentialRepresentative } =
    mapConferenceToConferenceSummary(t, conferenceData) || {};

  const feedbackInfo = feedbackData?.getOneFeedback;

  const handleFeedbackSubmit = async (input: InputCreateFeedback) => {
    try {
      if (feedbackInfo?.id) {
        await updateFeedback({
          variables: { input, feedbackId: feedbackInfo.id },
        });
      } else {
        await createFeedback({
          variables: { input },
        });
      }

      addSuccess(t('create-feedback.success', 'Success.'), {
        id: 'form.success',
      });

      goBack(false);
      setGlobal(false);
    } catch (error) {
      addError(
        t(
          'create-feedback.error-request',
          'An error occurred with create conference form'
        )
      );
    }
  };

  return (
    <>
      <Title className="m-0 pb-2 tablet:pb-3 pt-0">{pageTitle}</Title>
      <LinkPrevious
        path={backPath}
        showModal={() => show(true)}
        label={backBtnTitle}
      />
      <DetailSection
        classes="mt-5 tablet:mt-10 mb-4 tablet:mb-11"
        noBottomMargin
      >
        {t(
          'create-feedback.note.text',
          'Please respond to the following questions about the district conference you attended.'
        )}
      </DetailSection>
      {((fullName && districtNumber) ||
        presidentialRepresentative ||
        location ||
        startDate ||
        endDate) && (
        <DetailSection classes="mb-5 tablet:mb-10" noBottomMargin>
          <ConferenceSummarySection
            title={conferenceSummaryTitle}
            isDGTitle={isDG && !isDGFeedback}
            isSPTitle={isSpRep}
            managerNameText={
              isDGFeedback
                ? presidentialRepresentative
                : `${fullName} (${districtNumber})`
            }
            locationText={location}
            startDate={startDate}
            endDate={endDate}
          />
        </DetailSection>
      )}
      <FeedbackForm
        responses={feedbackInfo?.responses || []}
        isDG={isDGFeedback}
        handleSubmit={handleFeedbackSubmit}
        cancel={goBack}
        isShowing={isShowing}
        globalHide={globalHide}
        handleOnContinue={handleOnContinue}
      />
    </>
  );
};

export default CreateOrUpdateFeedback;
