// Libs
import React from 'react';

// Components
import ChartTitle from '../components/ChartTitle';
import ContributionChart from './ContributionChart';

// Constants
import { districtContributionChartColors } from '@domain/districts';

// Utils
import { useTranslation } from '@external/react-i18next';
import {
  getContributionLabels,
  getCurrentContributionDelta,
  getMaxContributionValue,
} from '@use-cases/districts';

// Types
import { DIS } from '@typings/dis';

type Props = {
  chartData: DIS.ContributionChartItem[];
  asOfDate: string;
};

const AnnualFundChart: React.FC<Props> = ({ chartData, asOfDate }) => {
  const { t } = useTranslation();
  const { annualFundColors } = districtContributionChartColors;

  const {
    currentValue,
    prevYearDelta,
    currentValueWithSuffix,
    prevYearDeltaWithSuffix,
  } = getCurrentContributionDelta(chartData);

  return (
    <div className="w-full mb-9 desktop:w-1/2 desktop:pr-5">
      <ChartTitle
        title={t('dashboards.title.annualFund', 'Annual Fund')}
        deltaValue={prevYearDelta || undefined}
        totalValue={currentValue}
        deltaLabel={getContributionLabels(
          {
            totalValue: currentValueWithSuffix,
            deltaValue: prevYearDeltaWithSuffix,
            hasDelta: prevYearDelta !== 0,
            isNotCompared: currentValue === prevYearDelta,
          },
          t
        )}
        isCategoryTitle={false}
        asOfDate={asOfDate}
      />
      <ContributionChart
        chartData={chartData}
        colors={annualFundColors}
        maxValue={getMaxContributionValue(chartData)}
      />
    </div>
  );
};

export default AnnualFundChart;
