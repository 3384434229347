import React, { useEffect } from 'react';

import { useLocation } from '@reach/router';

import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';
import ConferenceSummarySection from '@presenters/web/components/ConferenceSummarySection';
import DetailSection from '@presenters/web/components/DetailSection';
import FeedbackForm from '@presenters/web/components/FeedbackForm';

import { FEEDBACK_TYPES } from '@domain/districts';
import { mapConferenceToConferenceSummary } from '@domain/districts/mappers';

import { getPresRepConferencePage } from '@use-cases/districts/conferences';

import {
  useFetchConferenceDetails,
  useFetchFormattedDGByRY,
  useFetchOneFeedback,
} from '@repositories/districts/hooks';

import { getFormattedRYRange } from '@utils/datetime';

import { useTranslation } from '@external/react-i18next';

interface Props {
  districtId: string;
  conferenceId: string;
  relatedRotaryYear: string;
}

const Feedback: React.FC<Props> = ({
  districtId: conferenceDistrictId,
  conferenceId,
  relatedRotaryYear: relatedRY,
}) => {
  const feedbackType = FEEDBACK_TYPES.PR;

  const { t } = useTranslation();

  const pageTitle = t(
    'feedback.district-conference-feedback',
    'District Conference Feedback'
  );

  const conferenceSummaryTitle = t(
    'feedback.conference-summary.title',
    'Conference Summary'
  );

  const backBtnTitle = t('feedback.back-link', 'Back');

  const routeLocation = useLocation();

  const isDistrictPresRep = (routeLocation?.state as Record<string, boolean>)
    ?.isDistrictPresRep;

  const {
    startYear: minStartDate,
    endYear: maxStartDate,
  } = getFormattedRYRange(Number(relatedRY));

  const [
    fetchOneFeedback,
    { loading: loadingFeedback, data: feedbackData },
  ] = useFetchOneFeedback();

  const [
    fetchConference,
    { data: conferenceData, loading: conferenceLoading },
  ] = useFetchConferenceDetails();

  useEffect(() => {
    fetchOneFeedback({
      variables: {
        feedbackType,
        conferenceId,
        isDistrictPresRep,
      },
    });
  }, [feedbackType, conferenceId]);

  useEffect(() => {
    fetchConference({
      variables: {
        conferenceId,
        isDistrictPresRep,
      },
    });
  }, [conferenceDistrictId, conferenceId]);

  const { startDate, endDate, location, presidentialRepresentative } =
    mapConferenceToConferenceSummary(t, conferenceData) || {};

  const {
    loadingDGByRY,
    isDG,
    isSpRep,
    fullName,
    districtNumber,
  } = useFetchFormattedDGByRY(
    conferenceDistrictId,
    minStartDate,
    maxStartDate,
    relatedRY
  );

  if (loadingFeedback || loadingDGByRY || conferenceLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Title className="text-xl m-0 p-0">{pageTitle}</Title>
      <LinkPrevious path={getPresRepConferencePage()} label={backBtnTitle} />
      <DetailSection classes="mt-12">
        {t(
          'feedback.left-feedback.text',
          '{{authorName}} left feedback on district conference.',
          { authorName: presidentialRepresentative }
        )}
      </DetailSection>
      {(startDate || endDate || location) && (
        <DetailSection>
          <ConferenceSummarySection
            isDGTitle={isDG}
            isSPTitle={isSpRep}
            title={conferenceSummaryTitle}
            managerNameText={`${fullName} (${districtNumber})`}
            locationText={location}
            startDate={startDate}
            endDate={endDate}
          />
        </DetailSection>
      )}
      <FeedbackForm
        isViewOnly
        responses={feedbackData?.getOneFeedback?.responses || []}
      />
    </div>
  );
};

export default Feedback;
