import { DraggableLocation } from 'react-beautiful-dnd';
import { Group, MoveResult } from '../types';
import move from './move';

const getDataAfterMove = (
  sourceGroupId: string,
  destinationGroupId: string,
  source: DraggableLocation,
  destination: DraggableLocation,
  state: Group[]
): Group[] => {
  const sourceGroup = state.find(group => group.groupId === sourceGroupId);
  const destinationGroup = state.find(
    group => group.groupId === destinationGroupId
  );
  const sourceGroupItems = sourceGroup?.items || [];
  const destinationGroupItems = destinationGroup?.items || [];
  const newResult: MoveResult = move(
    sourceGroupItems,
    destinationGroupItems,
    source,
    destination
  );

  return state.map(group => {
    const { groupId } = group;
    if (groupId === sourceGroupId) {
      return { ...group, items: newResult[sourceGroupId] };
    }
    if (groupId === destinationGroupId) {
      return { ...group, items: newResult[destinationGroupId] };
    }
    return group;
  });
};

export default getDataAfterMove;
