// Libs
import React from 'react';
import { useTranslation } from '@external/react-i18next';

// Constants
import { ROLES_REQUIRED_BY_POLICY } from '@domain/districts';

// Types
import { DistrictOfficerRole } from '@domain/districts';
import { DistrictOfficer } from '@typings/operations';

const DistrictOfficerRoleTitleDesktop = ({
  role,
  termYears,
}: DistrictOfficerRole<DistrictOfficer>) => {
  const { t } = useTranslation();

  return (
    <div className="h5 alternate">
      {role}
      {ROLES_REQUIRED_BY_POLICY.includes(role) ? '*' : ''}
      {termYears > 1 &&
        ` (${t('district-officers.year-term', '{{termYears}}-year-term', {
          termYears,
        })})`}
    </div>
  );
};

export default DistrictOfficerRoleTitleDesktop;
