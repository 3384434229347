import React from 'react';

import ChartTitle from '../components/ChartTitle';
import MembreshipTrendsChart from './MembreshipTrendsChart';

import { MembersTrendsData } from '@domain/districts';

import { formatNumberWithSign, numberToLocale } from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';

const MembershipTrends: React.FC<{
  data: MembersTrendsData;
  availableYears: Array<number | undefined>;
  asOfDate: string;
}> = ({ data: { chartData, totalCount, delta }, availableYears, asOfDate }) => {
  const { t } = useTranslation();
  const hasNoDelta = delta === 0;
  const deltaValue = formatNumberWithSign(delta);
  const totalValue = numberToLocale(totalCount);

  let deltaLabel = t(
    'dashboards.members.zero_delta',
    '<strong>{{totalValue}}</strong> Members currently. Equals to the Rotary Year Start',
    { totalValue }
  );

  if (totalCount === delta) {
    deltaLabel = t(
      'dashboards.members.not_compared',
      '<strong>{{totalValue}}</strong> Members currently.',
      { totalValue }
    );
  } else if (!hasNoDelta) {
    deltaLabel = t(
      'dashboards.members.not_zero_delta',
      '<strong>{{totalValue}}</strong> Members currently. {{deltaValue}} compared with Rotary Year Start',
      {
        totalValue,
        deltaValue,
      }
    );
  }

  return (
    <div className="membership-trends mb-15">
      <div className="hidden desktop:block">
        <ChartTitle
          title={t('dashboards.title.membership-trends', 'Membership Trends')}
          deltaValue={delta}
          totalValue={totalCount}
          deltaLabel={deltaLabel}
          asOfDate={asOfDate}
        />
      </div>
      <MembreshipTrendsChart
        chartData={chartData}
        availableYears={availableYears}
        asOfDate={asOfDate}
      />
    </div>
  );
};

export default MembershipTrends;
