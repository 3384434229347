import React, { SetStateAction, useContext, useEffect, useState } from 'react';

import { mapValues } from 'lodash';

import SearchMembersForm from '@components/Formik/SearchMembersForm';
import SelectRotaryYear from '@components/Forms/Select/SelectRotaryYear';
import IndividualListColumn from '@components/IndividualListColumn';
import Loading from '@components/Loading';
import MembersList from '@components/MembersList';
import PageSteps from '@components/PageSteps';
import TableWithTabs from '@components/TableWithTabs';
import { TabInfo } from '@components/TableWithTabs/types';
import Filters from '@presenters/web/components/DistrictMembersFilters';
import { MEMBER_FILTERS } from '@presenters/web/components/DistrictMembersFilters/types';
import Heading from '@presenters/web/components/DistrictSelectMemberStep/Heading';

import MemberItem from './MemberItem';

import {
  ASSISTANT_GOVERNOR,
  CommitteeAppointment,
  CommitteeMemberData,
  DistrictMember,
  isAG,
  MEMBERSHIP_TYPE_MEMBER,
  Step,
  TERM_ALL,
} from '@domain/districts';

import {
  DistrictContext,
  getBackUrl,
  getClubIdFilterOptions,
  getPreviousAGTermSelectOptions,
  getPriorToLatestManagedYear,
  getValuesFromOptions,
  isDateBetween,
  useMembersFilters,
  useStopBrowserNavigate,
} from '@use-cases/districts';

import {
  useFetchAllDistrictClubs,
  useFetchDistrictMembers,
} from '@repositories/districts';
import { useLocalStorage } from '@repositories/storage/hooks';

import { getYearsRange } from '@utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/operations';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
  stepCount?: number;
  isReplace?: boolean;
};

const PAGE_SIZE = 10;

const TAB_DISTRICT_MEMBERS = 'District Members';
const TAB_PREVIOUS_AG = 'Previous Assistant Governor';

const SelectMemberStep: React.FC<Props> = ({
  districtId,
  riDistrictId,
  stepCount = 3,
  isReplace = false,
}) => {
  const { t } = useTranslation();
  const [
    {
      operationType,
      selectedInfo: { role, term, unassignFrom },
      step,
    },
    setContext,
  ] = useContext(DistrictContext);
  const [activeTab, setActiveTab] = useState<TabInfo>({
    name: TAB_DISTRICT_MEMBERS,
    scrollTo: 0,
  });

  const { clearStorage } = useLocalStorage<CommitteeAppointment>();

  const {
    searchValues,
    filterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
    handlePagination,
  } = useMembersFilters();

  const [prevTerm, setPrevTerm] = useState<string>(TERM_ALL);
  const [
    fetchPreviousAG,
    { data: previousAGData, loading: previousAGLoading },
  ] = useFetchDistrictMembers();
  const [fetchDistrictMembers, { data, loading }] = useFetchDistrictMembers();
  const [
    fetchAllDistrictClubs,
    { data: districtClubsData, loading: districtClubsLoading },
  ] = useFetchAllDistrictClubs();

  const priorToLatestManagedYear = getPriorToLatestManagedYear(term?.startDate);

  const selectedTermValues =
    prevTerm === TERM_ALL
      ? getYearsRange(priorToLatestManagedYear, 5)
      : [Number(prevTerm)];

  const isEndDateInTerm = (endDate: string) =>
    selectedTermValues.some(year =>
      isDateBetween(
        endDate,
        { start: `${year - 1}-07-01`, end: `${year}-06-30` },
        null
      )
    );

  const handleBack = () => {
    if (step !== 1) {
      setContext(prevState => ({
        ...prevState,
        step: (step - 1) as Step,
      }));
      window.scrollTo(0, 0);
      return;
    }
    clearStorage();
    localizedNavigate(getBackUrl(operationType, districtId));
  };

  useStopBrowserNavigate({
    isNextStepVisited: true,
    onNavigate: handleBack,
  });

  // Fetch active clubs affiliated with the district for filter by club name
  useEffect(() => {
    if (districtId) {
      fetchAllDistrictClubs({
        variables: {
          districtId,
          status: 'Active',
        },
      });
    }
  }, [fetchAllDistrictClubs, districtId]);

  // Make a call to fetch members
  useEffect(() => {
    if (districtId && riDistrictId && term) {
      const filters = mapValues(filterValues, item =>
        getValuesFromOptions(item)
      );

      fetchDistrictMembers({
        variables: {
          district: `${riDistrictId}`,
          isIncludedDistrictLeadership: false,
          isIncludedDistrictMembership: true,
          pageSize: PAGE_SIZE,
          page: currentPage,
          ...searchValues,
          ...filters,
          memberTypes: [MembershipType.Member],
          rolesToExclude: [ASSISTANT_GOVERNOR],
          rotaryYear: term?.endDate,
        },
      });
    }
  }, [
    fetchDistrictMembers,
    districtId,
    riDistrictId,
    currentPage,
    searchValues,
    filterValues,
    term,
  ]);

  /** Fetch previous Assistant Governors */
  useEffect(() => {
    if (districtId && riDistrictId && prevTerm) {
      fetchPreviousAG({
        variables: {
          district: String(riDistrictId),
          page: currentPage,
          pageSize: PAGE_SIZE,
          districtRoles: [ASSISTANT_GOVERNOR],
          memberTypes: [MEMBERSHIP_TYPE_MEMBER],
          yearRange: selectedTermValues,
          includeDLHistory: true,
          isDeceased: false,
          ...searchValues,
        },
      });
    }
  }, [districtId, riDistrictId, currentPage, prevTerm, searchValues]);

  const handleSelectAssignee = (
    assignee: DistrictMember | CommitteeMemberData
  ) => {
    setContext(prevState => ({
      ...prevState,
      step: (prevState.step + 1) as Step,
      selectedInfo: {
        ...prevState.selectedInfo,
        assignee,
      },
    }));
    window.scrollTo(0, 0);
  };

  const searchAGsByDate = (dateParam: string): void => {
    setPrevTerm(dateParam);
    setCurrentPage(1);
  };

  const handleChangeTab = (tab: SetStateAction<TabInfo>) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };

  if (loading || districtClubsLoading || previousAGLoading) {
    return <Loading />;
  }

  // Allowing filtering by clubids instead of clubname for correct club search result.
  const clubs = districtClubsData?.districtClubs?.clubs || [];
  const clubOptionsWithId = getClubIdFilterOptions(clubs, t);

  const multiSelectOptions = {
    memberTypes: [],
    clubTypes: [],
    clubNames: [],
    districtRoles: [],
    clubIds: clubOptionsWithId,
  };

  const membersTab = {
    name: TAB_DISTRICT_MEMBERS,
    title: `${t(
      'district-management.district-members',
      'District members'
    )} (${data?.members.totalCount || 0})`,
    component: (
      <MembersList
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        pageHandler={handlePagination}
        totalCount={data?.members.totalCount || 0}
        members={(data?.members.results as DistrictMember[]) || []}
        memberItem={member => (
          <MemberItem member={member} selectHandler={handleSelectAssignee} />
        )}
        areTabsMultiple
        filters={
          <Filters
            initialFilterValues={filterValues}
            multiSelectOptions={multiSelectOptions}
            applyFilter={handleApplyFilters}
            resetFilter={handleResetFilters}
            filtersListToShow={[MEMBER_FILTERS.CLUB_IDS]}
          />
        }
        showFiltersInContextMenu
        headerClassName="-m-2 px-14"
      />
    ),
  };

  const previousAGList = (previousAGData?.members.results ||
    []) as DistrictMember[];

  const previousAGsCount = previousAGData?.members.totalCount || 0;

  const previousAGsTab = {
    name: TAB_PREVIOUS_AG,
    title: `${t(
      'district-management.district-previous-ag',
      'Previous Assistant Governors'
    )} (${previousAGsCount})`,
    component: (
      <MembersList
        filters={
          <SelectRotaryYear
            initialValue={prevTerm}
            options={getPreviousAGTermSelectOptions(
              priorToLatestManagedYear,
              t
            )}
            handleChange={searchAGsByDate}
          />
        }
        showFiltersInContextMenu
        totalCount={previousAGsCount}
        headerClassName="-m-2 px-14"
        areTabsMultiple
        members={previousAGList}
        memberItem={member => (
          <MemberItem
            member={{
              ...member,
              thisDistrictLeadership: member.thisDistrictLeadership.filter(
                ({ roleId, endDate }) =>
                  isAG(roleId) && isEndDateInTerm(endDate)
              ),
            }}
            originalDistrictLeadership={member.thisDistrictLeadership}
            key={member.id}
            selectHandler={handleSelectAssignee}
            isPreviousAG
          />
        )}
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        pageHandler={handlePagination}
      />
    ),
  };

  const tabs = [membersTab, previousAGsTab];
  const { id, photoUri, nameWithPrefixSuffix } = unassignFrom || {};

  return (
    <PageSteps
      className="p-0"
      backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
      backHandler={handleBack}
      step={step}
      total={stepCount}
      navClassName="mt-6 text-small font-bold"
    >
      <Heading
        roleTitle={role?.name || ''}
        title={
          isReplace
            ? t('select-member-to-replace.title', 'Select a Member to Replace')
            : t('select-member-to-add.title', 'Select a Member to Add')
        }
        isSubTitleHidden={!isReplace}
        subTitle={
          isReplace ? (
            <IndividualListColumn
              fullName={nameWithPrefixSuffix || ''}
              photoUri={photoUri || ''}
              id={id || ''}
              associatedClubsInfo={[]}
            />
          ) : null
        }
      />
      <SearchMembersForm
        initialValues={searchValues}
        loading={loading}
        submitHandler={handleSearch}
        title={{
          classes: 'mb-5 tablet:mb-0 mt-2 tablet:normal-case capitalize',
          value: t(
            'district-management.search-members-title',
            'Search district members'
          ),
        }}
        isLabelsHidden
      />
      <TableWithTabs
        activeTabInfo={activeTab}
        tabs={tabs}
        onChangeTab={handleChangeTab}
      />
    </PageSteps>
  );
};

export default SelectMemberStep;
