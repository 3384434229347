import React from 'react';

// Components
import LinksBox from '@components/LinksBox';

// Utils
import { useTranslation } from '@external/react-i18next';
import { Helmet } from '@external/react-helmet-async';
import { titleTemplateParams } from '@components/Title/util';
import { Drupal } from '@typings/drupal';

interface Props {
  financeData: Drupal.MenuItem[];
}

const DistrictFinance: React.FC<Props> = ({ financeData }) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-finance',
          '{{prefix}} District finance {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="mt-12">
        <LinksBox
          header={t('header.main-menu.districts-menu-label', 'Districts')}
          items={financeData}
        />
      </div>
    </>
  );
};

export default DistrictFinance;
