// Libs
import * as R from 'ramda';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe, identity } from 'fp-ts/lib/function';
import { curry } from 'lodash/fp';

// Constants
import { UNASSIGNED } from '../constants/groupnames';

// Types
import { Group, Item } from '../types';

const isCurrentSourceGroupId = curry(
  (currentGroupId: string, group: Group) =>
    group.relatedGroupName === UNASSIGNED && group.groupId === currentGroupId
);

const isCurrentDestinationId = (menuItemId: string, group: Group) =>
  group.relatedGroupName !== UNASSIGNED && group.groupId === menuItemId;

export const getSourceGroupItems = (
  currentGroupId: string,
  data: Group[]
): Item[] =>
  pipe(
    data,
    A.filter(isCurrentSourceGroupId(currentGroupId)),
    A.head,
    O.mapNullable(R.prop('items')),
    O.fold(() => [], identity)
  );

export const moveGroup = (
  currentGroupId: string,
  menuItemId: string,
  data: Group[],
  movedGroupItems: Item[]
) => {
  return pipe(
    data,
    A.map(group => {
      if (group.groupId === currentGroupId) {
        return { ...group, items: [] };
      }

      if (isCurrentDestinationId(menuItemId, group)) {
        return { ...group, items: [...movedGroupItems, ...group.items] };
      }
      return group;
    })
  );
};
