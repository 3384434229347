import * as React from 'react';

import { Button } from '../Button';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

const AddNewOfficerButton: React.FC<{
  roleName: string;
  isAddNewOfficer: boolean;
}> = ({ roleName, isAddNewOfficer = false }) => {
  const { t } = useTranslation();

  if (roleName && isAddNewOfficer) {
    const navigateToAddNewOfficer = () => {
      const getUrl = new URL(window.location.href);
      localizedNavigate(`${getUrl.pathname}/add-officer`);
    };
    return (
      <Button
        dataTestId="button-test-id-new-role"
        secondary
        small
        clickHandler={navigateToAddNewOfficer}
      >
        {t('button.add-new-role', 'ADD NEW {{role}}', {
          role: roleName,
        })}
      </Button>
    );
  }
  return null;
};

export default AddNewOfficerButton;
