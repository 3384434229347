// Libs
import React from 'react';
import { useTranslation } from '@external/react-i18next';

// Types
import {
  Props as LegendProps,
  Payload as LegendPayload,
} from 'recharts/types/component/DefaultLegendContent';

// Constants
import {
  TOTAL_FOR_30_JUNE,
  PRIOR_MONTH_END,
  ANNUAL_FUND,
  POLIO_PLUS,
} from '@domain/districts';

const ContributionChartLegendContent: React.FC<LegendProps> = ({ payload }) => {
  const { t } = useTranslation();
  const translations: Record<string, string> = {
    [TOTAL_FOR_30_JUNE]: t('districts.total-for-30-june', 'Total for 30 June'),
    [PRIOR_MONTH_END]: t('districts.prior-month-end', 'Prior Month End'),
    [ANNUAL_FUND]: t(
      'districts.annual-fund',
      'Annual Fund for Prior Month End'
    ),
    [POLIO_PLUS]: t(
      'districts.polio-plus',
      'Polio Eradication for Prior Month End'
    ),
  };

  if (payload) {
    return (
      <div className="flex flex-wrap mt-9">
        {payload.map((item: LegendPayload) => {
          return (
            <div
              className="flex items-center mr-3 mb-3"
              key={`legend-${item.value}`}
            >
              <div
                className="h-5 w-5 rounded-1px"
                style={{ background: item.color }}
              />
              <span className="text-2xs inline-block ml-3 text-gray-600">
                {translations[item.value]}
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default ContributionChartLegendContent;
