// Libs
import React, { useContext } from 'react';

import { useLocation } from '@reach/router';
import { isEmpty } from 'lodash';

// Components
import UnnassignRole from '@presenters/web/pages/Districts/CommitteeAppointments/UnassignRole';
import SelectTermStep from '@presenters/web/pages/Districts/ManageAssistantGovernors/SelectTermStep';

import SelectMemberStep from './SelectMemberStep';

// Utils
import { MemberURLParams, OperationType } from '@domain/districts';

import { DistrictContext, getQueryParamsFromURL } from '@use-cases/districts';

import { unflattenObject } from '@utils/objectUtils';

// Hooks

// Context

// Types

type Props = {
  districtId: string;
  riDistrictId?: number | null;
};

const Step1: React.FC<Props> = ({ districtId, riDistrictId }) => {
  const [context] = useContext(DistrictContext);
  const location = useLocation();

  const {
    operationType,
    committeeManager: { isManager },
  } = context;

  const backUrlFromState = (location?.state as Record<string, string>)?.backUrl;

  const URLParamsObj = unflattenObject(
    getQueryParamsFromURL() as MemberURLParams | {}
  );

  switch (operationType) {
    case OperationType.ASSIGN_TO_AG:
    case OperationType.REPLACE_AG: {
      const isManagerTwoSelectedTerms =
        isManager.currentTerm && isManager.nextTerm;
      if (
        !backUrlFromState?.includes('assistant-governor') &&
        isManagerTwoSelectedTerms &&
        isEmpty(URLParamsObj)
      ) {
        return <SelectTermStep riDistrictId={riDistrictId} />;
      }
      return (
        <SelectMemberStep
          districtId={districtId}
          riDistrictId={riDistrictId}
          isReplace={operationType === OperationType.REPLACE_AG}
        />
      );
    }
    case OperationType.UNASSIGN_OFFICER_FROM_ROLE:
      return (
        <UnnassignRole districtId={districtId} riDistrictId={riDistrictId} />
      );
    default:
      return null;
  }
};

export default Step1;
