import React from 'react';

import { Form, Formik } from 'formik';

import RadioField, { RadioOption } from '@components/Formik/RadioField';
import NewCandidateForm from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/AddCandidate/ContactInformation/NewCandidateForm';
import { personalDetailsValidationSchema } from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/AddCandidate/ValidationSchema';
import {
  getCandidateFormInformation,
  getCandidateFormInitialValue,
} from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/FormInfo';
import {
  CandidateFormFieldsProps,
  CandidateFormValues,
} from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/types';

import { useTranslation } from '@external/react-i18next';

interface Props {
  submitHandler: () => void;
  districtId: string;
  districtName: string;
}

const CandidateFormDetails: React.FC<Props> = ({
  submitHandler,
  districtId,
  districtName,
}) => {
  const { t } = useTranslation();
  const fieldInitialValue: CandidateFormValues = getCandidateFormInitialValue(
    'contactInformation'
  );
  const fieldProps: CandidateFormFieldsProps = getCandidateFormInformation(
    t,
    'contactInformation'
  );
  const gridContainerClassName = 'tablet:w-1/2 desktop:w-1/2';
  return (
    <Formik
      initialValues={fieldInitialValue}
      onSubmit={submitHandler}
      validationSchema={personalDetailsValidationSchema(t)}
    >
      {() => {
        const {
          name: clubType,
          label: clubTypeLabel,
          required: firstNameRequired,
        } = fieldProps.clubType;
        return (
          <Form>
            <h2 className="mt-5 mb-11 mobile:mb-5">
              {t('mml.add-candidate-district-form.title', 'Add new candidate')}
            </h2>
            <div className={gridContainerClassName}>
              <RadioField
                name={clubType}
                label={clubTypeLabel}
                options={fieldProps.clubType.options as RadioOption[]}
                required={firstNameRequired}
              />
              <NewCandidateForm
                id={districtId}
                fieldProps={fieldProps}
                districtName={districtName}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CandidateFormDetails;
