import React, { useState } from 'react';

import { useField } from 'formik';
import { isMobile } from 'react-device-detect';

import Element from '@components/Formik/Element';

import { customStyles } from './customStyles';
import Input from './Input';
import Menu from './Menu';
import { MenuList } from './MenuList';
import { createOption, getTimeOptions } from './utils';

import { useTranslation } from '@external/react-i18next';
import Select, { OptionTypeBase, ValueType } from '@external/react-select';

import { Option, SelectTimeProps } from './types';

export const defaultOptions = getTimeOptions(0, 24, 30);

const SelectTime: React.FC<SelectTimeProps> = props => {
  const { options = defaultOptions, name, handleChange } = props;
  const [field, meta, helpers] = useField<string>(name);

  const [value, setValue] = useState<Option>(createOption(field.value || ''));
  const [inputValue, setInputValue] = useState<string>(field.value || '');
  const [isValueApplied, setIsValueApplied] = useState<boolean>(!!field.value);

  const { t } = useTranslation();

  const formOnChangeHandler = (value: string) => {
    helpers.setValue(value);

    setTimeout(() => {
      helpers.setTouched(true);
    }, 0);
  };

  const onInputChange = (
    inputValue: string,
    { action }: { action: string }
  ) => {
    if (action === 'input-blur' && !isMobile) {
      const newInputValue = value?.label || '';
      setInputValue(newInputValue);
      setIsValueApplied(true);
    } else if (action === 'input-change') {
      const isValidValue = /^([0-1]\d|2[0-3]):[0-5]\d$/.test(inputValue.trim());
      const isChanged = isValidValue || !inputValue;

      if (isChanged) {
        setValue({ label: inputValue, value: inputValue });

        formOnChangeHandler(inputValue);
        handleChange?.(inputValue);
      }

      setInputValue(inputValue);
      setIsValueApplied(false);
    }
  };

  const onChange = (option: ValueType<OptionTypeBase>) => {
    setIsValueApplied(true);
    setValue(option as Option);

    formOnChangeHandler((option as OptionTypeBase).label);

    setInputValue((option as OptionTypeBase).label);
    handleChange?.((option as OptionTypeBase).value);
  };

  const filterOption = (option: OptionTypeBase, input: string) =>
    isValueApplied || option.label.includes(input);

  return (
    <Element {...props} showError={false}>
      <Select
        name={name}
        inputId={name}
        options={options}
        styles={customStyles(!!meta.error && meta.touched)}
        filterOption={filterOption}
        placeholder={`--${t('select-time-placeholder', 'Select')}--`}
        isClearable={false}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onChange={onChange}
        controlShouldRenderValue={false}
        components={{
          Input,
          Menu,
          MenuList,
        }}
        captureMenuScroll={false}
      />
    </Element>
  );
};

export default SelectTime;
