import React from 'react';

import classNames from 'classnames';

import Link from '@components/Link';

import { DistrictAG } from '@domain/districts';

import { getClubDisplayName } from '@utils/club-name';
import { constructFullName } from '@utils/constructFullName';

import { useTranslation } from '@external/react-i18next';

import {
  ClubType,
  DistrictClub,
  MembershipStatusFilter,
} from '@typings/operations';

type Props = {
  club: DistrictClub;
  governor?: DistrictAG;
};

const ClubItem: React.FC<Props> = ({ club, governor }) => {
  const { t } = useTranslation();

  const { name, id, type, location, activeMembersCount, riClubId } = club;

  const primaryLocation = location
    ? [location.city, location.state, location.country]
        .filter(loc => loc)
        .join(', ')
    : '';

  const isRotaractors = type === ClubType.RotaractClub;
  const isActiveUsersCountDisplayed =
    type === ClubType.RotaryClub || isRotaractors;

  const getAGName = (ag: DistrictAG) => {
    const { prefix, firstName, middleName, lastName, suffix } = ag;

    return constructFullName({
      prefix,
      firstName,
      middleName,
      lastName,
      suffix,
    });
  };

  return (
    <div
      className="result-row w-full px-8 mb-8 last:mb-0 flex flex-col tablet:items-center tablet:flex-row tablet:justify-between tablet:px-5"
      data-testid="result-row"
    >
      <div className="result-info tablet:w-2/6">
        <div className="result-info__name font-bold">
          <Link to={`/club/${id}/details`} className="underline" noLinkStyles>
            {getClubDisplayName(name, type, t)}
          </Link>
        </div>
        <div className="result-info__location">{primaryLocation}</div>
        <div className="result-info__location">{`ID: ${riClubId}`}</div>
      </div>
      <div className="result-governor tablet:block tablet:w-1/4 ">
        {governor && (
          <>
            <span className="desktop:hidden">
              {`${t('assistant-governor', 'Assistant Governor')}: `}
            </span>
            <Link noLinkStyles to={`/profile/${governor.agId}`}>
              {getAGName(governor)}
            </Link>
          </>
        )}
      </div>
      <div
        className={classNames(
          'tablet:w-1/5',
          isActiveUsersCountDisplayed &&
            activeMembersCount &&
            activeMembersCount > 0
            ? 'result-memberships'
            : ''
        )}
      >
        {isActiveUsersCountDisplayed &&
          (activeMembersCount && activeMembersCount > 0 ? (
            <Link
              to={`/club/${id}/roster?membershipStatus=${
                isRotaractors
                  ? MembershipStatusFilter.Rotaractor
                  : MembershipStatusFilter.Active
              }`}
              className="tablet:font-bold underline"
              noLinkStyles
            >
              {t(
                'district-details.clubs.active-memberships',
                '{{activeMembersCount}} Active Members',
                {
                  activeMembersCount,
                }
              )}
            </Link>
          ) : (
            <p className="tablet:font-bold ">
              {t(
                'district-details.clubs.active-memberships',
                '{{activeMembersCount}} Active Members',
                {
                  activeMembersCount,
                }
              )}
            </p>
          ))}
        {(type === ClubType.SatelliteClub ||
          type === ClubType.RotaractSatelliteClub) && (
          <p>
            {t(
              'district-details.clubs.memberships.satellite',
              'Members are shown in Sponsoring club list'
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default ClubItem;
