// Libs
import React, { SVGAttributes } from 'react';

// Constants
import {
  FIRST_JUL,
  TEXT_FILL_COLOR,
  YEAR_CURRENT_KEY,
} from '@domain/districts';

// Utils
import { useTranslation } from '@external/react-i18next';
import { localizeDayMonth } from '@use-cases/districts';
import { isIE } from '@utils/browser-detection';

const textProps = {
  x: 0,
  y: 0,
  textAnchor: 'start',
  fill: TEXT_FILL_COLOR,
  className: 'text-xs',
};

const renderGroup = (
  children: JSX.Element[],
  { x, y }: SVGAttributes<SVGElement>
) => (
  <svg>
    <g transform={`translate(${x},${y})`}>
      {children.map((child: JSX.Element, index: number) => (
        <React.Fragment key={index}>{child}</React.Fragment>
      ))}
    </g>
  </svg>
);

const renderText = (text: string, { y }: SVGAttributes<SVGElement>) => (
  <text {...textProps} y={y}>
    {text}
  </text>
);

const renderForeignObject = (...children: JSX.Element[]) => (
  <foreignObject width="85" height="60">
    <div className="h-15 flex items-center text-xs leading-xs-heading">
      {children.map((child: JSX.Element, index: number) => (
        <React.Fragment key={index}>{child}</React.Fragment>
      ))}
    </div>
  </foreignObject>
);

const CustomizedYTick: React.FC<any> = ({ x, y, payload }) => {
  const { t } = useTranslation();

  if (isIE) {
    if (payload.value !== YEAR_CURRENT_KEY) {
      return renderGroup(
        [
          renderText(localizeDayMonth(FIRST_JUL), { y: -5 }),
          renderText(payload.value, { y: 15 }),
        ],
        { x, y }
      );
    }

    return renderGroup(
      [renderText(t('demographics.current', 'Current'), { y: 5 })],
      { x, y }
    );
  } else {
    if (payload.value !== YEAR_CURRENT_KEY) {
      return renderGroup(
        [
          renderForeignObject(
            <>{localizeDayMonth(FIRST_JUL)}</>,
            <br />,
            payload.value
          ),
        ],
        { x, y: y - 30 }
      );
    }
    return renderGroup(
      [renderForeignObject(<>{t('demographics.current', 'Current')}</>)],
      { x, y: y - 30 }
    );
  }
};

export default CustomizedYTick;
