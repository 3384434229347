// Libs
import React from 'react';
import { slice } from 'lodash';

// Components
import CheckBoxOptions from './CheckBoxOptions';
import GeneralQuestions from './GeneralQuestions';
import SectionTitle from './SectionTitle';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Utils
import { getTranslatedSection } from './utils';

type Props = {
  sections: any[];
  isViewOnly: boolean;
};

const PRFormAfter2022: React.FC<Props> = ({ sections, isViewOnly }) => {
  const { t } = useTranslation();

  if (sections.length) {
    const allQuestions = sections.flatMap(({ questions }) => questions);
    const [general, question5, question8, conferenceEvaluation] = sections;

    const {
      id: generalId,
      sectionName: generalSectionName,
      questions: generalQuestions,
    } = general;

    // We need to insert two section objects into the questions array
    // That's why we have to split general section into 3 parts
    const questionsFromStartTo4 = slice([...generalQuestions], 0, 4);
    const questionsFrom4To7 = slice([...generalQuestions], 4, 6);
    const questionsFrom7To11 = slice([...generalQuestions], 6, 9);

    return (
      <div>
        <SectionTitle
          sectionName={getTranslatedSection(t, generalId, generalSectionName)}
          sectionId={generalId}
        />
        <GeneralQuestions
          sectionId={generalId}
          questions={questionsFromStartTo4}
          allQuestionsLength={0}
          allQuestions={generalQuestions}
          isFeedbackWithCustomQuestions={false}
          isDG={false}
          isViewOnly={isViewOnly}
        />
        {// Insert this section as 5th question
        question5 && (
          <CheckBoxOptions
            questions={question5.questions}
            sectionId={question5.id}
            sectionName={question5.sectionName}
            orderNumber={5}
            isViewOnly={isViewOnly}
          />
        )}
        {/* Sinse 1 question/section was added we have to hardcode the allQuestionsLength as -1 
            to keep correct number ordering. Otherwise set it as 0.
        */}
        <GeneralQuestions
          sectionId={generalId}
          questions={questionsFrom4To7}
          allQuestionsLength={question5 ? -1 : 0}
          allQuestions={generalQuestions}
          isFeedbackWithCustomQuestions={false}
          isDG={false}
          isViewOnly={isViewOnly}
        />
        {//Insert this section as 8th question
        question8 && (
          <CheckBoxOptions
            questions={question8.questions}
            sectionId={question8.id}
            sectionName={question8.sectionName}
            orderNumber={8}
            isViewOnly={isViewOnly}
          />
        )}
        {/* Sinse 2 questions/sections were added we have to hardcode the allQuestionsLength as -2 
            to keep correct number ordering. Otherwise set it as 0.
        */}
        <GeneralQuestions
          sectionId={generalId}
          questions={questionsFrom7To11}
          allQuestionsLength={question8 ? -2 : 0}
          allQuestions={allQuestions}
          isFeedbackWithCustomQuestions={false}
          isDG={false}
          isViewOnly={isViewOnly}
        />
        {conferenceEvaluation && (
          <>
            <SectionTitle
              sectionName={getTranslatedSection(
                t,
                conferenceEvaluation.id,
                conferenceEvaluation.sectionName
              )}
              sectionId={conferenceEvaluation.id}
            />
            {/* Sinse 9 questions were added before the table we have to hardcode the allQuestionsLength as 9 
            to keep correct number ordering. 
        */}
            <GeneralQuestions
              sectionId={conferenceEvaluation.id}
              questions={conferenceEvaluation.questions}
              allQuestionsLength={9}
              allQuestions={allQuestions}
              isFeedbackWithCustomQuestions={false}
              isDG={false}
              isViewOnly={isViewOnly}
            />
          </>
        )}
      </div>
    );
  }

  return null;
};

export default PRFormAfter2022;
