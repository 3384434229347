import React from 'react';

import { useFormikContext } from 'formik';

import PhoneInput from '@components/Formik/PhoneInput';
import { InputCheckbox } from '@components/Forms/InputCheckbox';

import AddressFields from './AddressFields';
import { AddressSectionProps } from './AddressSection';

import {
  CreateConferenceFormResult,
  REQUEST_PRES_REP,
} from '@domain/districts';

import { getConferenceHeading } from '@use-cases/districts/conferences';

import { useTranslation } from '@external/react-i18next';

import { Maybe } from '@typings/graphql';

type PresRepSectionProps = AddressSectionProps & {
  isOnline: boolean;
  isPresRepRequested?: Maybe<boolean>;
  isAccommodationPhone?: boolean;
};

const PresRepSection: React.FC<PresRepSectionProps> = ({
  isPresRepRequested,
  isOnline,
  countryId,
  fieldNames,
  placeholders,
  labels,
  suffix,
  countriesData,
  countriesLoading,
  loadingStates,
  countryStatesData,
  fetchCountryStates,
  isAccommodationPhone,
}) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<CreateConferenceFormResult>();

  const onChangeRequestPresRep = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFieldValue('isPresRepRequested', event.target.checked);

  return (
    <>
      <InputCheckbox
        id="requestPresRep"
        name="requestPresRep"
        className="text-medium font-normal"
        label={`${getConferenceHeading(t, REQUEST_PRES_REP)}?`}
        description={`${getConferenceHeading(t, REQUEST_PRES_REP)}?`}
        checked={isPresRepRequested || false}
        changeHandler={onChangeRequestPresRep}
        labelHidden
      />

      {isPresRepRequested && !isOnline && (
        <>
          <AddressFields
            {...{
              countryId,
              fieldNames,
              placeholders,
              labels,
              suffix,
              countriesData,
              countriesLoading,
              loadingStates,
              countryStatesData,
              fetchCountryStates,
            }}
          />
          <PhoneInput
            suffix="accomodationPhone"
            labels={{
              phoneCode: labels?.countryId || '',
              phoneNumber: labels?.number || '',
            }}
            isCountryFlagRequired={isAccommodationPhone}
          />
        </>
      )}
    </>
  );
};

export default PresRepSection;
