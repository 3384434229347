// Libs
import React from 'react';

// Components
import ChartTitle from '../components/ChartTitle';
import Chart from './Chart';

// Constants
import { AGE_CATEGORIES, GENDER_CATEGORIES } from '@domain/districts';

// Utils
import { useTranslation } from '@external/react-i18next';
import { DemographicsChartData } from '@domain/districts';

type Props = {
  data: DemographicsChartData[];
  asOfDate: string;
};

const Demographics: React.FC<Props> = ({ data, asOfDate }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-7 desktop:mb-0 desktop:w-1/2 desktop:pr-5">
      <ChartTitle
        title={t('dashboards.title.demographics', 'Demographics')}
        asOfDate={asOfDate}
      />
      <ChartTitle
        title={t('dashboards.demographics.age', 'Age')}
        isCategoryTitle={false}
      />
      <Chart
        chartData={data}
        categories={AGE_CATEGORIES}
        key="ageChart"
        hasLegend
      />
      <ChartTitle
        title={t('dashboards.demographics.gender', 'Women in Rotary')}
        isCategoryTitle={false}
      />
      <Chart
        chartData={data}
        categories={GENDER_CATEGORIES}
        key="genderChart"
        chartHeight={170}
      />
    </div>
  );
};

export default Demographics;
