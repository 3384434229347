// Types
import { DIS } from '@typings/dis';
import { FetchAllDistrictClubsQuery } from '@typings/operations';

export const hasActiveAgClubs = (
  clubId: string,
  districtClubsData?: FetchAllDistrictClubsQuery
) => {
  return districtClubsData?.districtClubs.clubs.some(
    ({ id, status }) => clubId === id && status !== DIS.ClubStatus.Terminated
  );
};
