import React from 'react';

import classNames from 'classnames';
import Modal from 'react-modal';

import { Button } from '@components/Button';
import Icon from '@components/Icon';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onSave: () => void;
  onContinue: () => void | string;
  title: string;
  contentLabel: string;
  text: string;
  saveButtonLabel: string;
  continueButtonLabel: string;
  isExtendedHeight?: boolean;
  isSaveBtnDisabled?: boolean;
}

const MainModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  onContinue,
  title,
  contentLabel,
  text,
  saveButtonLabel,
  continueButtonLabel,
  isExtendedHeight,
  isSaveBtnDisabled,
}) => {
  const closeBtnTestId = 'close-btn-modal';
  const saveBtnTestId = 'save-btn-modal';
  const continueBtnTestId = 'continue-btn-modal';

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={contentLabel}
      className={classNames('modal-mobile', {
        'desktop:modal-desktop': !isExtendedHeight,
        'desktop:modal-desktop-noscroll': isExtendedHeight,
      })}
      ariaHideApp={false}
      testId="ReactModalPortal"
    >
      <h2 className="mb-4 ml-3 text-2lg tablet:text-xl">{title}</h2>
      <p className="mb-7 ml-3">{text}</p>
      <Icon
        name="ui/close"
        color="#BEC4C9"
        className="absolute top-27px right-25px cursor-pointer w-6 h-6 desktop:w-8 desktop:h-8"
        clickHandler={onClose}
        dataTestId={closeBtnTestId}
      />
      <div className="text-center">
        <Button
          clickHandler={onSave}
          className="text-sm mb-6 tablet:w-full mobile-m:min-w-14"
          dataTestId={saveBtnTestId}
          disabled={isSaveBtnDisabled}
        >
          {saveButtonLabel}
        </Button>
        <Button
          clickHandler={onContinue}
          textNormal
          className="text-sm tablet:w-full"
          dataTestId={continueBtnTestId}
        >
          {continueButtonLabel}
        </Button>
      </div>
    </Modal>
  );
};

export default MainModal;
