// Libs
import React from 'react';
import { ceil, max, maxBy } from 'lodash';

// Components
import {
  BarChart,
  Bar,
  LabelList,
  XAxis,
  YAxis,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import CustomizedXAxisTick from '../components/CustomizedXAxisTick';
import CustomizedYAxisTick from '../components/CustomizedYAxisTick';
import CustomizedCursor from './CustomizedCursor';
import CustomizedLabel from './CustomizedLabel';
import CustomizedTooltip from './CustomizedTooltip';

// Utils
import tailwind from '@styles/tailwind.js';

// Types
import { ClubsDevelopmentChartData } from '@domain/districts';

type Props = {
  chartData: ClubsDevelopmentChartData[];
};

const TERMINATED_KEY = 'terminatedClubs';

const getBarProps = (dataKey: string) => ({
  dataKey,
  fill:
    dataKey === TERMINATED_KEY
      ? tailwind.theme.colors['red']['300']
      : tailwind.theme.colors['green']['300'],
  stackId: 'stack',
  barSize: 40,
});

const ClubDevelopmentChart: React.FC<Props> = ({ chartData }) => {
  // Display data for the 4 previous years and the current one
  // Drop data for extra 2 years (the oldest ones, first two elements)
  const data = chartData.length <= 2 ? chartData : chartData.slice(2);

  const getAbsoluteMaxValue = (positiveValue: number, negativeValue: number) =>
    max([positiveValue, Math.abs(negativeValue)]) || 0;

  const dataWithMaxValue = maxBy(data, ({ newClubs, terminatedClubs }) =>
    getAbsoluteMaxValue(newClubs, terminatedClubs)
  );

  // Get the biggest value of new or terminated clubs (compare absolute numbers)
  // Ceil it to closest ten (e.g. max is 42 => ceil to 50)
  const { newClubs, terminatedClubs } = dataWithMaxValue || {};

  const maxValue = getAbsoluteMaxValue(newClubs || 0, terminatedClubs || 0);
  const domainValue = ceil(maxValue, -1);

  return (
    <ResponsiveContainer width="100%" height={380}>
      <BarChart
        width={470}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: -30,
          bottom: 5,
        }}
        stackOffset="sign"
        barCategoryGap="5%"
      >
        <XAxis
          dataKey="year"
          axisLine={false}
          tickSize={0}
          tick={<CustomizedXAxisTick />}
        />
        <YAxis
          domain={[-1 * domainValue, domainValue]}
          tickSize={0}
          tick={<CustomizedYAxisTick />}
        />
        <Tooltip
          content={<CustomizedTooltip />}
          cursor={<CustomizedCursor />}
        />
        <ReferenceLine y={0} stroke={tailwind.theme.colors['gray']['600']} />
        <Bar {...getBarProps('newClubs')}>
          <LabelList
            dataKey="newClubs"
            position="insideTop"
            content={<CustomizedLabel />}
          />
        </Bar>
        <Bar {...getBarProps('terminatedClubs')}>
          <LabelList
            dataKey="terminatedClubs"
            position="insideBottom"
            content={<CustomizedLabel />}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ClubDevelopmentChart;
