import React from 'react';

import Confirmation from '../Confirmation';

import { CommitteeMemberData, TermToAssign } from '@domain/districts';

import {
  getMaxDateForRemoveReplace,
  getMinDateForRemoveReplace,
} from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';

type ConfirmationRoleInfo = {
  role: string;
  term: TermToAssign;
};

type Props = {
  assignee: CommitteeMemberData;
  unassignFrom: CommitteeMemberData;
  handleSelectDate?: (date: Date) => void;
  defaultDate: Date;
  roleInfo: ConfirmationRoleInfo;
};

const ReplaceOfficerStepConfirm: React.FC<Props> = ({
  assignee,
  unassignFrom,
  handleSelectDate,
  defaultDate,
  roleInfo: { role, term },
}) => {
  const { t } = useTranslation();

  return (
    <Confirmation
      roleName={role}
      term={term}
      assigneeEmail={assignee?.email || null}
      assigneeName={assignee?.nameWithPrefixSuffix || null}
      officerToBeReplacedName={unassignFrom.nameWithPrefixSuffix}
      title={t('confirm-role', 'Confirm the role of {{selectedRole}}', {
        selectedRole: role || null,
      })}
      classes={{ titleClass: 'mb-5 text-xl leading-6' }}
      labels={{
        officerLabel: t(
          'assign-from.newly-assigned-officer.label',
          'Newly assigned officer'
        ),
        officerToBeReplacedLabel: t(
          'assign-from.officer-to-be-replaced.label',
          'Officer to be replaced'
        ),
        termLabel: t('assign-from.term.label', 'Term'),
        roleLabel: t('assign-from.role.label', 'Role'),
        emailLabel: t('assign-from.email.label', 'Email'),
        datePickerLabel: t('assign-from.datepicker-label', 'Effective date'),
      }}
      handleSelectDate={handleSelectDate}
      maxDate={getMaxDateForRemoveReplace(term.endDate, true)}
      minDate={getMinDateForRemoveReplace(term.startDate)}
      selectedDateValue={defaultDate}
    />
  );
};

export default ReplaceOfficerStepConfirm;
