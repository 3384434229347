// Libs
import React from 'react';

import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import TextField from '@components/Formik/TextField';

import { useTranslation } from '@external/react-i18next';

import { FilterSearchFormValues } from './types';

type Props = {
  loading: boolean;
  initialValues: FilterSearchFormValues;
  onSubmit: (formValues: FilterSearchFormValues) => void;
};

const SearchClubsForm: React.FC<Props> = ({
  loading,
  onSubmit,
  initialValues,
}) => {
  const { t } = useTranslation();

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      <Form>
        <h2 className="text-xs font-bold mb-5 tablet:mb-0 mt-2 tablet:normal-case capitalize">
          {t('district-details.clubs.search-title', 'Search clubs')}
        </h2>
        <div className="tablet:flex tablet:items-center mb-10 tablet:mb-5">
          <div className="tablet:mr-4 mb-4 tablet:mb-0">
            <TextField
              hasMargins={false}
              labelHidden
              name="clubName"
              placeholder={t(
                'district-details.clubs.search-name-placeholder',
                'Search by name'
              )}
              resettable
            />
          </div>
          <div className="tablet:mr-4 mb-4 tablet:mb-0">
            <TextField
              hasMargins={false}
              labelHidden
              name="clubLocation"
              placeholder={t(
                'district-details.clubs.search-location-placeholder',
                'Search by location'
              )}
              resettable
            />
          </div>
          <Button
            small
            disabled={loading}
            icon="ui/search"
            className="w-auto tablet:inline-block club-roster-search-submit"
          >
            {t('district-details.clubs.search-submit', 'Search')}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default SearchClubsForm;
