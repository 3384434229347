import React from 'react';

import { MEMBER_FILTERS } from '@presenters/web/components/DistrictMembersFilters/types';

import BlockWrapper from './BlockWrapper';
import DetailsChart from './DetailsChart/DetailsChart';

import { DetailsChartData } from '@domain/districts';

import { getLabelValue, getMembershipLabels } from '@use-cases/districts';
import { getMembersLinkClass } from '@use-cases/districts/helpers/getMembersLinkClass';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { DistrictMembersStatistics } from '@typings/operations';

type Props = {
  membersStatistics: DistrictMembersStatistics[];
  districtId: string;
};

const Members: React.FC<Props> = ({ membersStatistics, districtId }) => {
  const { t } = useTranslation();
  const chartData = membersStatistics.map(
    ({ memberType, totalCount }): DetailsChartData => ({
      name: memberType,
      totalCount,
    })
  );

  return (
    <BlockWrapper
      title={t('district-details.members-heading', 'People')}
      handleClick={() => localizedNavigate(`/district/${districtId}/members`)}
    >
      {membersStatistics.length > 0 && (
        <DetailsChart
          chartData={chartData}
          getLinkHref={(value: string) =>
            `/district/${districtId}/members?${MEMBER_FILTERS.MEMBER_TYPES}=${value}`
          }
          getLinkName={(index = 0, value = '') =>
            `${getLabelValue(getMembershipLabels(t), value)?.label} (${
              chartData[index].totalCount
            })`
          }
          getLinkClass={(value: string) =>
            `leading-relaxed ${getMembersLinkClass(value)}`
          }
          graphHeight={200}
        />
      )}
    </BlockWrapper>
  );
};

export default Members;
