import React from 'react';

import classNames from 'classnames';

import { Icon } from '@components/Icon';

import { ParticipantOption } from '@domain/districts';

import { useTranslation } from '@external/react-i18next';

type Props = {
  selectedParticipants: ParticipantOption[];
  toggleHandler: () => void;
  onDeselect: (id: string) => void;
  checkActiveConference: (id: string) => boolean;
};

const ParticipantsInput: React.FC<Props> = ({
  selectedParticipants,
  toggleHandler,
  onDeselect,
  checkActiveConference,
}) => {
  const { t } = useTranslation();

  const renderSelectedItem = () => {
    if (!selectedParticipants?.length) {
      return (
        <div className="text-xs text-gray-400">
          {t('global.selector.placeholder', '--Select--')}
        </div>
      );
    }

    return (
      <div>
        {selectedParticipants?.map(({ id, value: selectedParticipant }) => (
          <div
            key={id}
            className={classNames(
              'text-3xs px-1 mb-2 mr-2 last:mr-0 flex items-center',
              {
                'bg-gray-200': !checkActiveConference(id),
                'bg-red-100': checkActiveConference(id),
              }
            )}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <span>{selectedParticipant}</span>
            <span onClick={() => onDeselect(id)}>
              <Icon
                name="ui/close"
                size="11"
                color="bright-blue-600"
                className="ml-1 text-gray-300 close-svg cursor-pointer"
              />
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        'relative w-full border rounded-sm py-0625 px-4 flex items-baseline justify-between tap-highlight-color-transparent border-gray-300 hover:border-dark-blue-400'
      )}
      data-testid="dropdown"
      onClick={toggleHandler}
    >
      <div className="flex flex-wrap">{renderSelectedItem()}</div>
      <div className="border-l border-gray-300 h-60pct pl-3 pr-5 flex absolute right-0">
        <Icon
          name="arrows/down"
          size="12"
          color="bright-blue-600"
          className="self-center mt-px hover:text-gray-300"
        />
      </div>
    </div>
  );
};

export default ParticipantsInput;
