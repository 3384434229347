import React from 'react';

import classNames from 'classnames';
import moment from 'moment';

import { useTranslation } from '@external/react-i18next';

import { ActiveMembership } from '@typings/graphql';

type Props = {
  membership?: ActiveMembership;
  hasSeveralMemberships?: boolean;
};

const ClubAffiliation: React.FC<Props> = ({
  membership,
  hasSeveralMemberships = false,
}) => {
  const { t } = useTranslation();
  const wrapperClassName = 'text-xs w-24 pl-px';

  if (membership?.admissionDate) {
    const startYear = moment(membership.admissionDate, 'YYYY-MM-DD').year();

    return (
      <div
        className={classNames(wrapperClassName, {
          'mb-3': hasSeveralMemberships,
        })}
      >
        {t('member-row.club-affiliation', 'Member {{startYear}}-present', {
          startYear,
        })}
      </div>
    );
  }

  return null;
};

export default ClubAffiliation;
