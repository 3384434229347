import React from 'react';

import LabelWithValue from '@components/LabelWithValue';

import ConferenceDurationSection from '../ConferenceDurationSection';

import { useTranslation } from '@external/react-i18next';

interface Props {
  title: string;
  managerNameText?: string | null;
  locationText?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  isDGTitle?: boolean;
  isSPTitle?: boolean;
}

const ConferenceSummarySection: React.FC<Props> = ({
  title,
  managerNameText,
  locationText,
  startDate,
  endDate,
  isDGTitle = true,
  isSPTitle = true,
}) => {
  const { t } = useTranslation();

  const dgTitle = t(
    'conference-summary-section.manager.label.DG',
    'District Governor'
  );

  const spTitle = t(
    'conference-summary-section.manager.label.SP',
    'Special Representative'
  );

  const prTitle = t(
    'conference-summary-section.manager.label.PR',
    'President Representative'
  );

  const renderLabel = () => {
    if (isDGTitle) {
      return dgTitle;
    }
    if (isSPTitle) {
      return spTitle;
    }
    return prTitle;
  };

  const locationTitle = t(
    'conference-summary-section.location.label',
    'Location'
  );

  const datesTitle = t('conference-summary-section.dates.label', 'Dates');

  return (
    <section className="conference-summary">
      <h2 className="mb-6 tablet:mb-5 text-xl leading-10">{title}</h2>
      {managerNameText && (
        <LabelWithValue label={renderLabel()} value={managerNameText} />
      )}
      {locationText && (
        <LabelWithValue label={locationTitle} value={locationText} />
      )}
      {(startDate || endDate) && (
        <LabelWithValue
          label={datesTitle}
          value={
            <ConferenceDurationSection
              startDate={startDate}
              endDate={endDate}
            />
          }
        />
      )}
    </section>
  );
};

export default ConferenceSummarySection;
