import React, { CSSProperties, useRef, useState } from 'react';

import { useField } from 'formik';

import Element, { ElementProps } from '@components/Formik/Element';
import { customStyles } from '@components/Formik/MultiSelectFilter/customStyles';
import Dropdown from '@components/Formik/MultiSelectFilter/Dropdown';

import MenuList from './MenuList';
import Option from './OptionItem';
import TimeZoneInput from './TimeZoneInput';

import { TimeZoneOption } from '@domain/districts';

import tailwind from '@styles/tailwind.js';

import { useTranslation } from '@external/react-i18next';
import ReactSelect, {
  OptionTypeBase,
  StylesConfig,
  ValueType,
} from '@external/react-select';
import { useWindowSize } from '@hooks/useWindowSize';

export interface MultiSelectFilterProps extends ElementProps {
  timeZones: TimeZoneOption[];
  required: boolean;
  onSelect?: () => void;
}

const timeZonesSelectorStyles = (isMobile: boolean): StylesConfig => ({
  ...customStyles,
  container: (provided: CSSProperties) => ({
    ...provided,
    position: 'absolute',
    zIndex: 1000,
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    width: isMobile ? '80vw' : '480px',
    border: `1px solid ${tailwind.theme.colors.gray['300']}`,
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      borderColor: tailwind.theme.colors['dark-blue'][400],
    },
  }),
  menuList: (provided: CSSProperties) => ({
    ...provided,
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)',
  }),
});

const TimeZoneSelector: React.FC<MultiSelectFilterProps> = props => {
  const { timeZones: options, name } = props;

  const { t } = useTranslation();
  const [field, , helpers] = useField<string>(name);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onSelect = (option: ValueType<OptionTypeBase>) => {
    helpers.setValue((option as OptionTypeBase)?.value);

    // Need to use setTimeout to prevent calling Yup schema with previous values
    setTimeout(() => {
      helpers.setTouched(true);
    }, 0);

    toggleOpen();
  };

  const tzMenuRef = useRef(null);
  const windowWidth = useWindowSize().width;
  const isAutoFocusActive =
    windowWidth > parseInt(tailwind.theme.screens.desktop, 10);

  const getOptionByValue = options.find(
    ({ value, displayValue }) =>
      value === field.value || displayValue === field.value
  );

  return (
    <Element {...props}>
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <TimeZoneInput
            selectedValue={getOptionByValue}
            toggleHandler={toggleOpen}
          />
        }
      >
        <ReactSelect
          inputId={name}
          name={name}
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          tabSelectsValue={false}
          menuIsOpen
          isMulti={false}
          isSearchable
          autoFocus={isAutoFocusActive}
          styles={timeZonesSelectorStyles(!isAutoFocusActive)}
          options={options}
          value={getOptionByValue}
          windowWidth={windowWidth}
          menuRef={tzMenuRef}
          onChange={onSelect}
          noOptionsMessage={() =>
            t('forms.select.no-options-default', 'No results')
          }
          placeholder={t(
            'timeZone-select.placeholder-default',
            'Search by Time Zone'
          )}
          components={{
            Option: optionProps => <Option optionProps={optionProps} />,
            MenuList,
            IndicatorSeparator: null,
            DropdownIndicator: null,
          }}
        />
      </Dropdown>
    </Element>
  );
};

export default TimeZoneSelector;
