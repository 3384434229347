import React from 'react';

import moment from 'moment';

import { ConferenceItemProps, RenderActionsButton } from '@domain/districts';

import { getActionsConfig, isDateBetween } from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';

interface ConferenceActionsProps extends ConferenceItemProps {
  districtId: string;
  renderButton: RenderActionsButton;
  inProgressLabelClassName?: string;
}

const ConferenceActions: React.FC<ConferenceActionsProps> = ({
  conference,
  onDelete,
  conferenceAccessLvls,
  conferenceFeedbackAccessLvls,
  inProgressLabelClassName,
  renderButton,
  districtId,
}) => {
  const { t } = useTranslation();
  const { startDate, endDate, createdByRiDistrictId } = conference;
  const { isMyDistrict } = getActionsConfig(
    conference,
    districtId,
    conferenceAccessLvls
  );

  const renderLabels = () => {
    const inProgressLabel = (
      <span className={`italic ${inProgressLabelClassName}`}>
        {t('conferences.in-progress', 'In Progress')}
      </span>
    );

    return isMyDistrict ? (
      inProgressLabel
    ) : (
      <div className="tablet:text-right tablet:flex flex-wrap justify-end">
        <div className="italic">
          {t(
            'conference.created-by',
            'Created by {{ createdByRiDistrictId }}',
            {
              createdByRiDistrictId,
            }
          )}
        </div>
        {inProgressLabel}
      </div>
    );
  };

  return isDateBetween(
    moment().format(),
    { start: startDate, end: endDate },
    'd'
  )
    ? renderLabels()
    : renderButton(
        districtId || conference.districtId,
        conference,
        onDelete,
        conferenceAccessLvls,
        conferenceFeedbackAccessLvls
      );
};

export default ConferenceActions;
