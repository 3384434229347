// Libs
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import { curry } from 'lodash/fp';

// Constants
import { UNASSIGNED } from '../constants/groupnames';

// Types
import { Group } from '../types';

export const isRelatedGroup = curry(
  (
    paramGroupName: string,
    { relatedGroupId, relatedGroupName }: Group
  ): boolean =>
    paramGroupName === relatedGroupName &&
    `groupId-${paramGroupName}` === relatedGroupId
);

export const isUnassigned = ({ groupName }: Group): boolean =>
  groupName === UNASSIGNED;

export const isNotRelated = (group: Group) =>
  !group.relatedGroupName || !group.relatedGroupId;

export const isNotUnassigned = (group: Group) => !isUnassigned(group);

export const areItemsInGroup = ({ items }: Group) => Boolean(items.length);

export const getMenuItems = (
  groupsData: Group[],
  currentGroupId: string,
  includesUnassigned = true
) =>
  pipe(
    groupsData,
    A.filter(isNotRelated),
    A.filter(
      ({ groupId }) =>
        groupId !== currentGroupId &&
        (includesUnassigned || !groupId.includes(UNASSIGNED))
    ),
    A.map(({ groupId: menuItemId, groupName: menuItemTitle }) => ({
      menuItemId,
      menuItemTitle,
    }))
  );
