// Libs
import React from 'react';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import { toString } from 'lodash/fp';
import classNames from 'classnames';

// Components
import TextArea from '@components/Formik/TextArea';
import RadioField from '@components/Formik/RadioField';

// Constants
import {
  COMMENTS_BOX_MAX_CHARS,
  DG_NAME_OPTION,
  DG_YEARS_SERVED_OPTION,
  SURVEY_OPTIONS,
} from '@domain/districts/conferences/constants';

// Hooks
import { useMobileLayout } from '@hooks/useMobileLayout';
import { useTranslation } from '@external/react-i18next';

// Utils
import { isQuestionRadio } from '@use-cases/districts';
import {
  getFormattedOptions,
  getTranslateAnswer,
  getTranslateQuestion,
  getDGListingOptionLabels,
} from './utils';

// Types
import { Question } from '@typings/operations';
import { ControlOperationType } from '@domain/districts/conferences/types';

interface Props {
  index: number;
  question: Question;
  isViewOnly?: boolean;
  hideQuestionNumber?: boolean;
}

const QuestionItem: React.FC<Props> = ({
  isViewOnly,
  index,
  question: {
    id,
    option,
    optionType: { optionType },
    optionDetail: { optionValue },
  },
  hideQuestionNumber,
}) => {
  const { t } = useTranslation();
  const { isMobileLayout } = useMobileLayout();

  const questionText = getTranslateQuestion(t, id, option);

  const questionTitle = hideQuestionNumber
    ? questionText
    : `${index + 1}. ${questionText}`;

  const isDGNameOption = option === DG_NAME_OPTION;
  if (isDGNameOption || option === DG_YEARS_SERVED_OPTION) {
    const { name, yearsServed } = getDGListingOptionLabels(t);
    const MAX_CHARS = isDGNameOption ? 80 : 10;

    return (
      <TextArea
        disabled={isViewOnly}
        key={id}
        name={id}
        areaclasses="py-2 px-2 text-2xs h-11"
        classes={{
          content: isDGNameOption ? 'pt-0' : 'w-50pct pt-0',
          container: 'tablet:w-50pct m-0 mb-0 pr-2 tablet:inline-block',
          item: 'tablet:hidden',
        }}
        label={isDGNameOption ? name : yearsServed}
        hasMargins={false}
        displayCharLimit={false}
        characters={isViewOnly ? undefined : MAX_CHARS}
        displayLabelByDefault={false}
      />
    );
  }

  if (optionType === ControlOperationType.TEXTBOX) {
    return (
      <TextArea
        disabled={isViewOnly}
        key={id}
        name={id}
        label={questionTitle}
        areaclasses="min-h-20 py-4 px-2"
        classes={{
          container: 'mt-6 tablet:mt-4 -mb-3 tablet:-mb-4',
          content: 'pt-2 pl-4 pb-4',
        }}
        characters={isViewOnly ? undefined : COMMENTS_BOX_MAX_CHARS}
        isAutoResizable={true}
      />
    );
  }

  const controlOptions = pipe(
    optionValue,
    toString,
    getFormattedOptions,
    A.mapWithIndex((index, { value, label }) => ({
      value,
      label: getTranslateAnswer(
        t,
        `${id}-${index}`,
        toString(optionValue),
        label
      ),
    }))
  );

  if (isQuestionRadio({ id, type: optionType })) {
    if (optionValue === SURVEY_OPTIONS.FORMATTED) {
      const surveyRadioButtonsOptions = pipe(
        controlOptions,
        A.map(({ value, label }) => ({
          value,
          label: isMobileLayout ? label : '',
        })),
        A.reverse
      );

      return (
        <div className="tablet:bg-gray-100 mt-6 tablet:mt-0">
          <RadioField
            disabled={isViewOnly}
            key={id}
            name={id}
            label={questionTitle}
            classes={{
              radioBtnWrapper: 'flex flex-col tablet:flex-row tablet:h-15',
              radioBtnContainer:
                'flex tablet:flex-3 flex-col tablet:flex-row pl-4 pt-1 pb-2 tablet:p-0',
              radioBtnContent:
                'tablet:flex-1 border-gray-300 tablet:border-l tablet:border-b mt-1 tablet:mt-0',
              radioBtnInput: classNames('z-0', {
                RadioInputFixed: !isMobileLayout,
              }),
              radioBtnLabel: classNames('ml-1 tablet:ml-0', {
                RadioLabelFixed: !isMobileLayout,
              }),
              radioBtnTitle:
                'tablet:flex-2 mb-3 tablet:mb-0 border-gray-300 tablet:border-b tablet:pl-4',
            }}
            hasMargins={false}
            hasLabelMargin={false}
            options={surveyRadioButtonsOptions}
          />
        </div>
      );
    }

    return (
      <RadioField
        classes={{
          radioBtnWrapper: 'mt-6 tablet:mt-4',
          radioBtnContainer: 'relative pt-2 pl-4 pb-2 tablet:pb-0',
          radioBtnContent: 'mt-1 tablet:mt-0',
          radioBtnInput: 'z-0',
          radioBtnLabel: 'ml-1 tablet:ml-0',
        }}
        hasMargins={false}
        disabled={isViewOnly}
        key={id}
        name={id}
        label={questionTitle}
        options={controlOptions}
      />
    );
  }

  return <div />;
};

export default QuestionItem;
