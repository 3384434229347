/* eslint-disable react/no-unknown-property */

// Libs
import React from 'react';

// Utils
import { TFunction } from 'i18next';

import { JUN_KEY, YEAR_START_KEY } from '@domain/districts';

import { localizeDayMonth } from '@use-cases/districts';

import { isIE } from '@utils/browser-detection';

import tailwind from '@styles/tailwind.js';

import { useTranslation } from '@external/react-i18next';

// Types

const getTooltipTextValue = (payloadValue: string, t: TFunction) => {
  switch (payloadValue) {
    case YEAR_START_KEY:
      return t('dashboards.rotary-year-start', 'Rotary Year Start');
    case JUN_KEY:
      return '';
    default:
      return localizeDayMonth(payloadValue, 'MMM', { month: 'short' });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomizedAxisTick = (props: any) => {
  const { t } = useTranslation();
  const { x, y, payload } = props;
  const isFirst = payload.value === YEAR_START_KEY;

  const textValue = getTooltipTextValue(payload.value, t);

  let element = (
    <foreignObject
      x={isFirst ? -40 : -10}
      y={15}
      textAnchor="end"
      width="80"
      height="50"
      fill={tailwind.theme.colors.gray['600']}
      className="text-xs leading-xs-heading"
    >
      {textValue}
    </foreignObject>
  );

  if (isIE) {
    element = (
      <text
        x={10}
        y={25}
        textAnchor="end"
        fill={tailwind.theme.colors.gray['600']}
        className="text-xs leading-xs-heading"
      >
        {textValue}
      </text>
    );
  }

  return (
    <svg>
      <g transform={`translate(${x},${y})`}>{element}</g>
    </svg>
  );
};

export default CustomizedAxisTick;
