// Libs
import React, { useEffect } from 'react';
import { useField } from 'formik';
import { isEqual } from 'lodash/fp';

// Components
import Loading from '@components/Loading';
import Select from '@components/Formik/Select';
import TextField from '@components/Formik/TextField';

// Types
import { State } from '@typings/graphql';
import { QueryLazyOptions } from '@apollo/client';
import { StatesQueryVariables } from '@typings/operations';

interface Props {
  label: string;
  statePlaceholder: string;
  countryCode: string;
  nameStateField: string;
  nameHasStatesField: string;
  nameInternationalProvinceField: string;
  countryStatesData: State[];
  loadingStates: boolean;
  fetchCountryStates: (
    options?: QueryLazyOptions<StatesQueryVariables>
  ) => void;
}

const StateSelect: React.FC<Props> = ({
  label,
  statePlaceholder,
  countryCode,
  nameStateField,
  nameHasStatesField,
  nameInternationalProvinceField,
  loadingStates,
  countryStatesData,
  fetchCountryStates,
}) => {
  const [field, , helpers] = useField(nameHasStatesField);

  useEffect(() => {
    fetchCountryStates({
      variables: {
        countryCode,
      },
    });
  }, [countryCode]);

  useEffect(() => {
    const hasStates = !!countryStatesData.length;

    if (!isEqual(field.value, hasStates)) {
      helpers.setValue(hasStates);
    }
  }, [countryStatesData]);

  if (loadingStates) {
    return <Loading inline small />;
  }

  const generateOptions = (countryId: string, states: State[]) =>
    states
      .filter(({ countryId: id }) => id === countryId)
      .map(({ name, id }) => ({ label: name, value: id }));

  if (!countryStatesData.length) {
    return <TextField name={nameInternationalProvinceField} label={label} />;
  }

  return (
    <Select
      searchable
      options={generateOptions(countryCode, countryStatesData)}
      name={nameStateField}
      label={label}
      required
      placeholder={statePlaceholder}
    />
  );
};

export default StateSelect;
