import React from 'react';

import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';

import CheckBoxOptions from './CheckBoxOptions';
import GeneralQuestions from './GeneralQuestions';
import SectionTitle from './SectionTitle';
import {
  getTranslatedSection,
  isAllQuestionsCheckboxes,
  isFeedbackWithCustomQuestions,
} from './utils';

import { SECTION_IDS } from '@domain/districts/conferences/constants';

import { useTranslation } from '@external/react-i18next';

import { FeedbackQuestionsResponse, Question } from '@typings/graphql';

type Props = {
  sections: FeedbackQuestionsResponse[];
  isViewOnly: boolean;
  isDG: boolean;
  isPastRYDG: boolean;
};

const DynamicQuestions: React.FC<Props> = ({
  sections,
  isViewOnly,
  isDG,
  isPastRYDG,
}) => {
  const { t } = useTranslation();

  const allQuestions = sections.flatMap(item => item.questions);

  const getCheckBoxQuestionOrderCount = (
    prevSectionsQuestions: Question[] | null,
    currentSectionQuestionsCount: number
  ) => {
    const isPreviousSectionCheckboxes =
      prevSectionsQuestions && isAllQuestionsCheckboxes(prevSectionsQuestions);

    const prevSectionCheckboxesLength =
      isPreviousSectionCheckboxes && prevSectionsQuestions?.length
        ? prevSectionsQuestions?.length || 1 - 1
        : 0;

    return (
      (prevSectionsQuestions?.length || 0) +
      prevSectionCheckboxesLength +
      1 -
      (isPreviousSectionCheckboxes ? currentSectionQuestionsCount - 1 : 0)
    );
  };

  const getAllQuestionsLength = (
    sections: FeedbackQuestionsResponse[],
    currentIndex: number
  ) => {
    const isFirstElement = currentIndex === 0;
    const prevSections = sections.filter(
      (section, sectionIndex) =>
        sectionIndex < currentIndex &&
        isAllQuestionsCheckboxes(section.questions)
    );

    return isFirstElement
      ? 0
      : prevSections.flatMap(el => el.questions).length - prevSections.length;
  };

  const renderGeneralQuestion = (
    currentSection: FeedbackQuestionsResponse,
    sections: FeedbackQuestionsResponse[],
    currentIndex: number,
    allQuestions: Question[]
  ) => {
    return (
      <>
        <SectionTitle
          sectionName={getTranslatedSection(
            t,
            isPastRYDG ? SECTION_IDS[2] : currentSection.id,
            currentSection.sectionName
          )}
          sectionId={currentSection.id}
        />
        <GeneralQuestions
          sectionId={currentSection.id}
          questions={currentSection.questions}
          allQuestionsLength={getAllQuestionsLength(sections, currentIndex)}
          allQuestions={allQuestions}
          isFeedbackWithCustomQuestions={isFeedbackWithCustomQuestions(
            sections
          )}
          isDG={isDG}
          isViewOnly={isViewOnly}
        />
      </>
    );
  };

  return (
    <>
      {pipe(
        sections,
        A.mapWithIndex((sectionIndex, currentSection) => {
          const isFirstElement = sectionIndex === 0;
          const prevSectionsQuestions = isFirstElement
            ? null
            : sections[sectionIndex - 1].questions;
          const isAllQuestionsCheckBox = isAllQuestionsCheckboxes(
            currentSection.questions
          );

          return (
            <div key={sectionIndex}>
              {isAllQuestionsCheckBox ? (
                <CheckBoxOptions
                  questions={currentSection.questions}
                  sectionId={currentSection.id}
                  sectionName={currentSection.sectionName}
                  orderNumber={getCheckBoxQuestionOrderCount(
                    prevSectionsQuestions,
                    currentSection.questions.length
                  )}
                  isViewOnly={isViewOnly}
                />
              ) : (
                renderGeneralQuestion(
                  currentSection,
                  sections,
                  sectionIndex,
                  allQuestions
                )
              )}
            </div>
          );
        })
      )}
    </>
  );
};

export default DynamicQuestions;
