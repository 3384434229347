// Libs
import React from 'react';

// Components
import TooltipWrapper from '../components/TooltipWrapper';

// Utils
import { formatNumberWithSign, getYearLabel } from '@use-cases/districts';
import { useTranslation } from '@external/react-i18next';

// Types
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ClubsDevelopmentChartData } from '@domain/districts';

type Props = {
  active?: boolean;
  payload?: {
    dataKey?: string | number;
    value?: ValueType;
    payload?: ClubsDevelopmentChartData;
  }[];
};

export const CustomizedTooltip: React.FC<Props> = ({
  active,
  payload: chartData,
}) => {
  const { t } = useTranslation();

  if (active && chartData?.[0].payload) {
    // chartData has 2 objects which have the same payload
    // get data for particular year from the 1st item
    const {
      newClubs,
      terminatedClubs,
      year,
      // MRD-2367: commented out till BI update
      // terminatedMembers,
      // newMembers,
    } = chartData[0].payload;

    return (
      <TooltipWrapper title={getYearLabel(year)}>
        <div className="mb-2">
          <div className="font-semibold">
            {t('countOf.newClubs', '{{newClubsDelta}} New Clubs', {
              newClubsDelta: formatNumberWithSign(newClubs),
            })}
          </div>
          {/* MRD-2367: commented out till BI update
          <div>
            {t(
              'countOf.newRotarians',
              '{{newMembers}} First Time Rotarians Admitted',
              { newMembers }
            )}
          </div>
          */}
        </div>
        <div>
          <div className="font-semibold">
            {t(
              'countOf.terminatedClubs',
              '{{terminatedClubsDelta}} Terminated Clubs',
              { terminatedClubsDelta: formatNumberWithSign(terminatedClubs) }
            )}
          </div>
          {/* MRD-2367: commented out till BI update
          <div>
            {t(
              'countOf.terminatedRotarians',
              '{{terminatedMembers}} Rotarians Remain Terminated Today',
              { terminatedMembers }
            )}
          </div>
          */}
        </div>
      </TooltipWrapper>
    );
  }

  return null;
};

export default CustomizedTooltip;
