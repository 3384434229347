// Libs
import React from 'react';

// Utils
import { useTranslation } from '@external/react-i18next';

const ClubsListHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="district-details__table-header hidden bg-gray-100 py-5 px-5 tablet:flex tablet:justify-between">
      <div className=" tablet:w-2/6 ">
        {t('district-details.clubs.header.club-name', 'Club Name')}
      </div>
      <div className="tablet:w-1/4">
        {t('district-details.clubs.header.governor', 'Assistant Governor')}
      </div>
      <div className="tablet:w-1/5 ">
        {t('district-details.clubs.header.membership', 'Membership')}
      </div>
    </div>
  );
};

export default ClubsListHeader;
