// Types
import { CRUDAction, HTTPVerb } from './types';

export const actionLookup = {
  [CRUDAction.Create]: [HTTPVerb.POST],
  [CRUDAction.Read]: [HTTPVerb.GET],
  [CRUDAction.Update]: [HTTPVerb.PATCH, HTTPVerb.PUT],
  [CRUDAction.Delete]: [HTTPVerb.DELETE],
};

export const APIResponseMethodsDivider = '|';
