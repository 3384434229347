import React from 'react';

import { RouteComponentProps } from '@reach/router';
import Alert from '@rotaryintl/harris-alert';

import GenerateReportAccordion from '@presenters/web/components/GenerateReportAccordion';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import { GetSitecoreReportQuery } from '@typings/operations';

const DistrictReportsReact: React.FC<RouteComponentProps & {
  riDistrictId: string;
  reportsData: GetSitecoreReportQuery['reportData'];
}> = ({ riDistrictId, reportsData: districtReportData }) => {
  const { t } = useTranslation();

  const columnViseReportsData = [];
  if (districtReportData && districtReportData?.length !== 0) {
    for (let i = 0; i < districtReportData?.length; i += 2) {
      columnViseReportsData.push(districtReportData?.slice(i, i + 2));
    }
  }

  return (
    <div className="w-full">
      <Helmet
        titleTemplate={`${t(
          'metadata.title.district-reports',
          'District Reports'
        )}: %s | ${t('metadata.title.default', 'My Rotary')}`}
      />
      <h1 className="font-semibold text-lg-24 mb-4 mt-8 tablet:mt-10 leading-md-heading mobile-m:text-2lg mobile-m:leading-3md tablet:text-3lg tablet:leading-lgx tablet:mb-6">
        {t('district-reports.title', 'Reports')}
      </h1>
      <div className="flex flex-col gap-2 tablet:gap-4 mb-12 tablet:mb-15 w-full tablet:w-1/2 tablet:pr-1875">
        <p className="m-0 text-md leading-md">
          {t(
            'district-reports.description',
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras suscipit ut nisi sed sodales. Suspendisse ac condimentum enim. Sed suscipit fringilla sapien nec pellentesque.'
          )}
        </p>
        <Alert variant="info" isDismissible={false}>
          {t(
            'district-reports.note',
            'Note: Membership and Contribution data updates may take up to 4-6 days to be reflected on the reports upon receipt by RI. During peak periods, it may take 8-11 days.'
          )}
        </Alert>
      </div>
      <div className="flex flex-col w-full tablet:gap-15 mb-12 gap-12">
        {columnViseReportsData.map((reportsArray, index) => {
          return (
            <div
              key={`section-${index + 1}`}
              className="w-full flex flex-col tablet:flex-row tablet:gap-15 gap-12"
            >
              {reportsArray.map(report => {
                return (
                  <div
                    key={report.label}
                    className="w-full tablet:w-1/2 flex flex-col tablet:gap-2 gap-4"
                  >
                    <div className="text-lg-24 font-semibold">
                      {report.label}
                    </div>
                    <GenerateReportAccordion
                      items={report.children}
                      riDistrictId={riDistrictId}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default DistrictReportsReact;
