import React from 'react';

import MainModal from '@presenters/web/components/SaveChangesModal/MainModal';

import { ModalTextContent } from '@domain/districts';

import { useTranslation } from '@external/react-i18next';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onSave: () => void;
  onContinue: () => void;
  textContent?: ModalTextContent;
  isExtendedHeight?: boolean;
  isSaveBtnDisabled?: boolean;
}

const SaveChangesModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  onContinue,
  textContent,
  isExtendedHeight,
  isSaveBtnDisabled,
}) => {
  const { t } = useTranslation();

  const defaultTextData = {
    content: t('global.modals.save-changes.label', 'Confirm saving changes'),
    title: t('global.modals.save-changes.title', 'Save Changes'),
    text: t(
      'global.modals.save-changes.subtitle',
      'Are you sure you want to leave this page?'
    ),
    saveBtn: t('global.modals.save-changes.save', 'Save Changes'),
    continueBtn: t(
      'global.modals.save-changes.skip-saving',
      'Continue without saving'
    ),
  };

  const {
    content: defaultContent,
    title: defaultTitle,
    text: defaultText,
    saveBtn: defaultSaveBtn,
    continueBtn: defaultContinueBtn,
  } = defaultTextData;

  const { content, title, text, saveBtn, continueBtn } = textContent || {};

  return (
    <MainModal
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
      isSaveBtnDisabled={isSaveBtnDisabled}
      onContinue={onContinue}
      contentLabel={content || defaultContent}
      title={title || defaultTitle}
      text={text || defaultText}
      saveButtonLabel={saveBtn || defaultSaveBtn}
      continueButtonLabel={continueBtn || defaultContinueBtn}
      isExtendedHeight={isExtendedHeight}
    />
  );
};

export default SaveChangesModal;
