import { Group } from '../types';
import reorder from './reorder';

const getDataAfterReorderColumn = (
  sourceGroupId: string,
  sourceIndex: number,
  destinationIndex: number,
  state: Group[]
): Group[] => {
  return state.map(group => {
    const { groupId, items } = group;
    if (groupId === sourceGroupId) {
      return {
        ...group,
        items: reorder(items, sourceIndex, destinationIndex),
      };
    }
    return group;
  });
};

export default getDataAfterReorderColumn;
