// Libs
import React from 'react';

// Components
import { Icon } from '@components/Icon';

interface Props {
  children: string;
}

const CopyItem: React.FC<Props> = props => {
  const { children } = props;

  return (
    <div
      className="item-copy
    quote-item
    flex
    items-center
    bg-white
    w-full
    cursor-pointer"
    >
      <div className="w-full">{children}</div>
      <div>
        <Icon name="ui/overflow-gray" />
      </div>
    </div>
  );
};

export default CopyItem;
