// Libs
import classNames from 'classnames';
import React from 'react';

interface Props {
  title?: string;
  classes?: string;
  noBottomMargin?: boolean;
  noBottomIndentTitle?: boolean;
  dataTestId?: string;
}

const DetailSection: React.FC<Props> = ({
  title,
  children,
  classes = '',
  noBottomMargin,
  noBottomIndentTitle,
  dataTestId = 'detail-section',
}) => {
  return (
    <section
      data-testid={dataTestId}
      className={classNames(classes, {
        'mb-12': !noBottomMargin,
      })}
    >
      {title && (
        <h3
          className={classNames('text-lg', {
            'mb-2': noBottomIndentTitle,
            'tablet:mb-6 mb-2': !noBottomIndentTitle,
          })}
        >
          {title}
        </h3>
      )}
      {children}
    </section>
  );
};

export default DetailSection;
