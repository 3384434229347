import React from 'react';

// Components
import LinksBox from '@components/LinksBox';
import { Masonry } from 'masonic';

// Utils
import { useTranslation } from '@external/react-i18next';
import { Helmet } from '@external/react-helmet-async';
import { titleTemplateParams } from '@components/Title/util';
import { Drupal } from '@typings/drupal';

interface Props {
  reportsData: Drupal.MenuItem[];
}

const DistrictReports: React.FC<Props> = ({ reportsData }) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-reports',
          '{{prefix}} District reports {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="flex flex-col justify-items-center items-center mt-11">
        <Masonry
          items={reportsData}
          columnGutter={32}
          columnWidth={320}
          render={({ data: { label, children } }) => (
            <LinksBox classes="w-auto" header={label} items={children || []} />
          )}
        />
      </div>
    </>
  );
};

export default DistrictReports;
