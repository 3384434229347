import React from 'react';

import Select, { SelectOption } from '@components/Formik/Select';
import TextArea from '@components/Formik/TextArea';
import PrivacyPolicy from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/AddCandidate/AddressInformation/PrivacyPolicy';

import { FormFieldsProps } from '@domain/leads/types';

import { useTranslation } from '@external/react-i18next';

type Props = {
  interestGroupId: string;
  fieldProps: FormFieldsProps;
};

const MoreInformation: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { fieldProps } = props;

  return (
    <>
      <p>
        {t(
          'mml.add-new-candidate.district.form.more-info',
          'Please tell us a little bit more about the candidate.'
        )}
      </p>
      <div className="max-w-lg mt-8">
        <Select
          {...fieldProps.profession}
          options={fieldProps.profession.options as SelectOption[]}
          clearable
          searchable
        />
        <TextArea
          {...fieldProps.additionalComments}
          characters={250}
          areaclasses="h-40"
        />
        <PrivacyPolicy />
      </div>
    </>
  );
};

export default MoreInformation;
