// Libs
import React from 'react';
import { useTranslation } from '@external/react-i18next';

// Types
import { Group, Item, Location, LocationResult } from '../types';

// Components
import { MenuItem } from '../ContextMenu/types';
import ContextMenu from '../ContextMenu';
interface Props {
  id: string;
  data: string;
  index: number;
  children: string;
  menuItems?: MenuItem[];
  currentGroupId: string;
  isDragDisabled: (isDisabled: boolean) => void;
  assignItem: (
    locationResult: LocationResult,
    assignedItem: Item,
    group: Group
  ) => void;
}

const QuoteItem: React.FC<Props> = props => {
  const { t } = useTranslation();

  const {
    id,
    data,
    index,
    children,
    menuItems = [],
    currentGroupId,
    isDragDisabled,
    assignItem,
  } = props;

  const handleMenuItemClick = ({
    menuItemId,
    menuItemTitle,
  }: MenuItem): void => {
    const group: Group = {
      groupId: menuItemId,
      groupName: menuItemTitle,
      items: [],
    };

    const { groupId } = group;
    const destination: Location = { index: 0, droppableId: groupId };
    const source: Location = { index, droppableId: currentGroupId };
    const assignedItem: Item = { id, content: children, data };
    const locationResult: LocationResult = { destination, source };
    assignItem(locationResult, assignedItem, group);
  };

  return (
    <div
      className="quote-item
      flex
      items-center
      bg-white w-full
      cursor-pointer"
    >
      <div className="w-full">{children}</div>
      {!!menuItems.length && (
        <div>
          <ContextMenu
            dataTestId={`context-menu-${currentGroupId}-${id}`}
            isDragDisabled={isDragDisabled}
            handleMenuItemClick={handleMenuItemClick}
            title={t(
              'club-assignments.unassigned-item',
              'Assign Club to an AG'
            )}
            className="m-0"
            currentItemId={currentGroupId}
            menuItems={menuItems}
          />
        </div>
      )}
    </div>
  );
};

export default QuoteItem;
