import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';

import Loading from '@components/Loading';
import NotFound from '@components/Routing/NotFound';
import { titleTemplateParams } from '@components/Title/util';

import ClubDevelopment from './ClubDevelopment';
import SelectDashboardClubType from './components/SelectDashboardClubType/SelectDashboardClubType';
import Contribution from './Contribution';
import Demographics from './Demographics';
import MembershipTrends from './MembershipTrends';

import {
  ClubTypeSelectOption,
  SelectDashboardClubTypeOptions,
} from '@domain/districts';

import {
  getClubDevelopmentData,
  getMembersTrendsChartData,
  getYearsRange,
} from '@use-cases/districts';

import { useFetchDistrictDashboards } from '@repositories/districts';

import { getRotaryYear } from '@utils/datetime';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import {
  ClubDevelopment as ClubDevelopmentType,
  Contribution as ContributionType,
  DashboardClubType,
  Demographic,
  MembersTrends,
} from '@typings/operations';

type Props = {
  districtId: string;
  hasPermissionToDashboard: boolean;
  stickyContainerRef: React.MutableRefObject<HTMLElement | null>;
};

const DistrictDashboard: React.FC<Props> = ({
  districtId,
  hasPermissionToDashboard,
  stickyContainerRef,
}) => {
  const { t } = useTranslation();

  const [clubTypeInitialValue, setClubTypeInitialValue] = useState<
    ClubTypeSelectOption
  >(SelectDashboardClubTypeOptions.All);

  const { prefix, suffix } = titleTemplateParams(t);

  const [
    fetchDashboards,
    { data: dashboardsData, loading: dashboardsLoading },
  ] = useFetchDistrictDashboards();

  const mapClubTypeValue = (clubTypeValue: ClubTypeSelectOption) => {
    const mappedOptionTypes = {
      [SelectDashboardClubTypeOptions.All]: null,
      [SelectDashboardClubTypeOptions.Rotary]: DashboardClubType.RotaryClub,
      [SelectDashboardClubTypeOptions.Rotaract]: DashboardClubType.RotaractClub,
    };

    return mappedOptionTypes[clubTypeValue];
  };

  useEffect(() => {
    const currentYear = Number(getRotaryYear());

    if (districtId) {
      fetchDashboards({
        variables: {
          districtId,
          years: getYearsRange(currentYear, 7),
          clubType: mapClubTypeValue(clubTypeInitialValue),
        },
      });
    }
  }, [fetchDashboards, districtId, clubTypeInitialValue]);

  if (dashboardsLoading) {
    return <Loading />;
  }

  if (!hasPermissionToDashboard) {
    return <NotFound default />;
  }

  const membersTrends = getMembersTrendsChartData(
    (dashboardsData?.dashboards.membersTrends as MembersTrends[]) || []
  );

  const availableYears =
    dashboardsData?.dashboards.membersTrends.map(item => item?.year) || [];

  const clubDevelopment =
    getClubDevelopmentData(
      dashboardsData?.dashboards.clubDevelopment as ClubDevelopmentType[]
    ) || [];

  const asOfDate = dashboardsData?.dashboards.asOfDate || '';

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-dashboard',
          '{{prefix}} District dashboard {{suffix}}',
          { prefix, suffix }
        )}
      />

      <div>
        <p className="mt-8 mb-2 desktop:mb-0">
          {t(
            'district-dashboard.description',
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do adipiscing elit. Nibh tortor id aliquet lectus proin. Ac auctor augue mauris augue neque gravida in. Sem et tortor consequat id porta nibh.'
          )}
        </p>

        <Formik
          initialValues={{ clubType: clubTypeInitialValue }}
          onSubmit={() => {}}
          className="relative"
        >
          <Form>
            <div
              id="relativeSelectContainer"
              data-testid="relativeSelectContainer"
              className="mb-3"
            >
              <SelectDashboardClubType
                handleChange={(selectedOption: ClubTypeSelectOption) => {
                  setClubTypeInitialValue(selectedOption);
                }}
                stickySelectContainerRef={stickyContainerRef}
                initialValue={clubTypeInitialValue}
              />
            </div>
          </Form>
        </Formik>

        <div>
          <MembershipTrends
            data={membersTrends}
            availableYears={availableYears}
            asOfDate={asOfDate}
          />
          <div className="flex flex-col desktop:flex-row">
            <Demographics
              data={
                (dashboardsData?.dashboards.demographics as Demographic[]) || []
              }
              asOfDate={asOfDate}
            />
            <ClubDevelopment data={clubDevelopment} asOfDate={asOfDate} />
          </div>
          <Contribution
            data={dashboardsData?.dashboards.contribution as ContributionType}
            asOfDate={asOfDate}
          />
        </div>
      </div>
    </>
  );
};

export default DistrictDashboard;
