// Libs
import React, { useState } from 'react';
import { useTranslation } from '@external/react-i18next';
import classNames from 'classnames';
import moment from 'moment';

// Components
import Collapse from '@kunukn/react-collapse';
import Header from './Header';

// Utils
import {
  formatNumberWithSign,
  numberToLocale,
  localizeDayMonth,
} from '@use-cases/districts';

// Types
import { ChartPointData } from '@domain/districts';

type DetailsProps = {
  detailsData: Record<string, ChartPointData[][]>;
  className: string;
};
type DetailsVisibility = Record<string, boolean>;

const Details: React.FC<DetailsProps> = ({ detailsData, className }) => {
  const { t } = useTranslation();
  const [detailsVisibility, setDetailsVisibility] = useState<DetailsVisibility>(
    {
      all: false,
      ...Object.keys(detailsData).reduce(
        (acc: DetailsVisibility, year) => ((acc[year] = false), acc),
        {}
      ),
    }
  );

  const toggleVisibility = (key: string) => {
    setDetailsVisibility({
      ...detailsVisibility,
      [key]: !detailsVisibility[key],
    });
  };

  const tableHeaderColumns = [
    t('dashboards.month-short', 'mo'),
    t('dashboards.amount-short', 'amt'),
    t('dashboards.compare-previous-month-short', 'Compare Prev Mo'),
    t('dashboards.compare-previous-year-short', 'Compare Prev Yr'),
    t('dashboards.compare-start', 'Compare Start'),
  ];

  const renderDetailsCell = (content: ChartPointData, cellIndex: number) => {
    if (typeof content === 'string')
      return moment(content, 'MMM').isValid()
        ? localizeDayMonth(content, 'MMM', { month: 'short' })
        : content;
    if (typeof content === 'number')
      return (cellIndex == 1 ? numberToLocale : formatNumberWithSign)(content);
    return '—';
  };

  return (
    <div className={className}>
      <Header
        title={
          <div className="mb-0 ml-2 text-lg leading-lg-heading">
            {t('dashboards.membership-trends-data', 'Membership Trends Data')}
          </div>
        }
        titleClassName="pt-4"
        isOpen={detailsVisibility.all}
        handleToggling={() => toggleVisibility('all')}
      />
      <Collapse
        isOpen={detailsVisibility.all}
        className="collapse-css-transition"
        overflowOnExpanded
      >
        <div
          className={classNames(
            'h-14 flex items-center bg-gray-100',
            'border-b-3 border-gray-300-60'
          )}
        >
          {tableHeaderColumns.map((header, i) => (
            <div
              key={header}
              className={classNames(
                'w-1/5 flex-none px-3 text-xs text-gray-600',
                'font-bold leading-xs-heading capitalize first:text-center',
                { 'pl-0': i > 1 }
              )}
            >
              {header}
            </div>
          ))}
        </div>
        {Object.keys(detailsData)
          .map(yearLabel => (
            <div key={yearLabel} className="border-b">
              <Header
                title={
                  <div className="mb-0 ml-2 font-bold text-2xs leading-xs-heading">
                    {yearLabel}
                  </div>
                }
                titleClassName="pt-4"
                isOpen={detailsVisibility[yearLabel]}
                handleToggling={() => toggleVisibility(yearLabel)}
              />
              <Collapse
                isOpen={detailsVisibility[yearLabel]}
                className="flex flex-col collapse-css-transition"
                overflowOnExpanded
              >
                {detailsData[yearLabel].map(
                  (monthData: ChartPointData[], i: number) => (
                    <div key={i} className="flex items-center border-t">
                      {monthData.map((datum, i) => (
                        <span
                          key={i}
                          className={classNames(
                            'w-1/5 h-10 flex-none px-1 text-xs leading-10',
                            'first:text-center border-r last:border-r-0'
                          )}
                          style={{ lineHeight: '40px' }}
                        >
                          {renderDetailsCell(datum, i)}
                        </span>
                      ))}
                    </div>
                  )
                )}
              </Collapse>
            </div>
          ))
          .reverse()}
      </Collapse>
    </div>
  );
};

export default Details;
