// Libs
import * as R from 'ramda';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/lib/function';

// Types
import { CountriesQuery, Country } from '@typings/operations';

export const normalizeCountries = (
  countriesData?: CountriesQuery
): Country[] => {
  return pipe(
    countriesData,
    O.fromNullable,
    O.mapNullable(R.prop('countries')),
    O.toNullable,
    item => item || [],
    A.map(({ id, name, shortCode }: Country) => ({
      id,
      name,
      shortCode,
    }))
  );
};
