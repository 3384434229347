import React, { useContext, useEffect, useState } from 'react';

import { useLocation } from '@reach/router';

import ActionButtons from '@components/ActionButtons';
import IndividualListColumn from '@components/IndividualListColumn';
import Loading from '@components/Loading';
import PageSteps from '@components/PageSteps';
import SelectTerm from '@presenters/web/components/SelectTerm';

import { CommitteeAppointment } from '@domain/districts';

import {
  defaultContextState,
  DistrictContext,
  getBackUrl,
  getTermByYear,
} from '@use-cases/districts';

import { useFetchIsAgInfoById } from '@repositories/districts';
import { useLocalStorage } from '@repositories/storage/hooks';

import { getRotaryYear } from '@utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { Maybe } from '@typings/graphql';

const SelectTermStep = ({ riDistrictId }: { riDistrictId?: Maybe<number> }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [
    {
      step,
      districtId,
      operationType,
      selectedInfo: { assignee, term },
      committeeManager: { isManager },
    },
    setContext,
  ] = useContext(DistrictContext);
  const { clearStorage, getStorageData } = useLocalStorage<
    CommitteeAppointment
  >();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [selectedTerm, setSelectedTerm] = useState(term!);

  const { data: storageData } = getStorageData('backUrl') || {};
  const storageDataBackUrl = storageData?.backUrl;

  const backUrlFromState = (location?.state as Record<string, string>)?.backUrl;

  const handleBack = () => {
    clearStorage();
    localizedNavigate(
      backUrlFromState ||
        storageDataBackUrl ||
        getBackUrl(operationType, districtId)
    );
  };

  const {
    loading,
    currentYearAssigned,
    nextYearAssigned,
  } = useFetchIsAgInfoById(assignee?.id, String(riDistrictId), isManager);

  const yearNow = Number(getRotaryYear());

  const termForNowYear = getTermByYear(yearNow);
  const termForElect = getTermByYear(yearNow + 1);

  const availableTerms = [
    {
      ...termForNowYear,
      isDisabled: currentYearAssigned,
    },
    {
      ...termForElect,
      isDisabled: nextYearAssigned,
    },
  ];

  useEffect(() => {
    if (currentYearAssigned) {
      setSelectedTerm(termForElect);
    }
  }, [currentYearAssigned]);

  const handleCancel = () => {
    setContext(prevState => ({
      ...prevState,
      step: 1,
      selectedInfo: {
        ...prevState.selectedInfo,
        role: defaultContextState.selectedInfo.role,
        assignee: defaultContextState.selectedInfo.assignee,
        unassignFrom: defaultContextState.selectedInfo.unassignFrom,
        term: prevState.termsToAssign[0],
      },
    }));
    handleBack();
  };

  const handleSubmit = () => {
    setContext(prevState => ({
      ...prevState,
      step: 2,
      selectedInfo: {
        ...prevState.selectedInfo,
        term: selectedTerm,
      },
    }));
    window.scrollTo(0, 0);
  };

  const isSomeTermDisabled = currentYearAssigned || nextYearAssigned;
  const areBothTermsDisabled = currentYearAssigned && nextYearAssigned;

  return (
    <PageSteps
      className="p-0"
      backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
      backHandler={handleBack}
      step={step}
      total={3}
    >
      <h2 className="text-xl my-2 leading-6">
        {t('manage-ag.select-term-for-member', 'Select a Term for a Member')}
      </h2>
      {assignee && (
        <IndividualListColumn
          fullName={assignee.nameWithPrefixSuffix}
          photoUri={assignee.photoUri || ''}
          id={assignee.id}
          localizedName={assignee.localizedName || ''}
          associatedClubsInfo={assignee.associatedClubsInfo}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <SelectTerm
          termsToAssign={availableTerms}
          selectedTerm={selectedTerm}
          onChangeTerm={setSelectedTerm}
          termsSelector="district-officers.term"
          classes={{
            optionsWrapper: 'desktop:flex-row flex-col',
            optionWrapper: 'desktop:mt-0 mt-4',
          }}
        />
      )}
      {isSomeTermDisabled ? (
        <p className="text-xs mb-0">
          {t(
            'manage-ag.one-of-terms-selected-warning',
            'This person is already added as Assistant Governor to one of the terms.'
          )}
        </p>
      ) : null}
      <ActionButtons
        submitBtnLabel={t('assign-role.continue', 'Continue')}
        cancelBtnLabel={t('assign-role.cancel', 'Cancel')}
        isSubmitDisabled={loading || areBothTermsDisabled}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </PageSteps>
  );
};

export default SelectTermStep;
