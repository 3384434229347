// Libs
import React from 'react';
import { find } from 'lodash';

// Components
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import ClubItem from './ClubItem';
import ClubsListHeader from './ClubsListHeader';
import Filters from './Filters';
import Loading from '@components/Loading';

// Utils
import { useTranslation } from '@external/react-i18next';

// Types
import { DistrictClub } from '@typings/operations';
import { ClubFilterProps, DistrictAG } from '@domain/districts';

export type Props = {
  clubsList: DistrictClub[];
  districtAGs?: DistrictAG[];
  totalCount: number;
  isLoading: boolean;
  filterProps: ClubFilterProps;
  showFiltersInContextMenu: boolean;
  contextMenuTitle: string;
};

const ClubsList: React.FC<Props> = ({
  clubsList,
  totalCount,
  districtAGs,
  isLoading,
  filterProps,
  showFiltersInContextMenu = false,
  contextMenuTitle,
}) => {
  const { t } = useTranslation();

  const getAG = (clubId: string) =>
    find(districtAGs, ({ clubsAffiliations }) =>
      clubsAffiliations.some(({ clubId: itemClubId }) => itemClubId === clubId)
    );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ResultsList
      headerClassName="px-8 tablet:px-5 tablet:bg-gray-100"
      className="-mx-8 tablet:mx-0"
      summary={
        <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
          {t('district-details.clubs.headline', 'Clubs')} ({totalCount})
        </h2>
      }
      filters={<Filters {...filterProps} />}
      showFiltersInContextMenu={showFiltersInContextMenu}
      contextMenuTitle={contextMenuTitle}
    >
      <ClubsListHeader />
      {clubsList.map(club => (
        <ResultsListItem key={club.id}>
          <ClubItem club={club} governor={getAG(club.id)} />
        </ResultsListItem>
      ))}
    </ResultsList>
  );
};

export default ClubsList;
