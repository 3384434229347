import React, { useContext } from 'react';

import classnames from 'classnames';
import { v4 as uuid } from 'uuid';

import { ContextMenu } from '@components/ContextMenu';
import { Icon } from '@components/Icon';
import MemberRow from '@components/MemberRow';
import InfoMessage from '@presenters/web/components/InfoMessage';

import DistrictOfficerRoleTitleDesktop from './DistrictOfficerRoleTitleDesktop';

import {
  CommitteeAppointmentPartialData,
  CommitteeDistrictLeadership,
  DistrictOfficerRole,
  isDRFC,
  mapDOToCommitteeMemberData,
  OperationType,
  TermToAssign,
  UnassignedDistrictOfficerRoleTerm,
} from '@domain/districts';

import {
  buildCommitteeAppointmentsUrl,
  buildOfficersPageUrl,
  defaultContextState,
  DistrictContext,
  getManagedRYRoleTerms,
  getOfficersContextMenuItems,
  getOfficerTermLabel,
} from '@use-cases/districts';

import { constructFullName, MemberFullName } from '@utils/constructFullName';
import { getClubTypeName } from '@utils/getClubTypeName';
import { localizedNavigate } from '@utils/localized-navigate';

import { ROTARY_ASSISTANCE_EMAIL } from '@constants/index';

import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';
import { Enum, From, mapEnum } from '@typings/map-enum';
import { DistrictOfficer } from '@typings/operations';

interface Props {
  role: DistrictOfficerRole<DistrictOfficer>;
  assignedTerm?: DistrictOfficer;
  unassignedTerm?: UnassignedDistrictOfficerRoleTerm;
  isAssignedInFutureRY?: boolean;
}

const DistrictOfficerRoleItem: React.FC<Props> = ({
  role,
  assignedTerm,
  unassignedTerm,
  isAssignedInFutureRY,
}) => {
  const { t } = useTranslation();
  const [
    {
      districtId,
      managedRYs,
      committeeManager: { isManager },
      selectedInfo: { term },
    },
    setContext,
  ] = useContext(DistrictContext);

  const [unassignedOfficerContext, setUnassignedOfficerContext] = useContext(
    DistrictContext
  );

  if (assignedTerm) {
    const {
      individual,
      startDate,
      endDate,
      id,
      role: currentRole,
      roleId,
    } = assignedTerm;

    const {
      membership: { type: membershipType },
    } = individual;

    const fullName = constructFullName(individual as MemberFullName);

    const formattedRoleInfo: CommitteeDistrictLeadership = {
      id,
      startDate,
      endDate,
      role: currentRole,
      roleId,
      termYears: role.termYears,
    };

    const getOfficerMember = mapDOToCommitteeMemberData(
      individual,
      fullName,
      formattedRoleInfo
    );

    const getRoleTerms = getManagedRYRoleTerms(
      managedRYs,
      isManager.nowTerm,
      role.role
    );

    const handleClickMenu = (
      committeeAppointmentsURL: string,
      operationType: OperationType
    ) => {
      const updatedState: CommitteeAppointmentPartialData = {
        operationType,
        selectedInfo: {
          ...defaultContextState.selectedInfo,
        },
      };

      if (
        [
          OperationType.UNASSIGN_ROLE_FROM_OFFICER,
          OperationType.UNASSIGN_OFFICER_FROM_ROLE,
          OperationType.REPLACE_OFFICER,
        ].includes(operationType as OperationType)
      ) {
        updatedState.selectedInfo.unassignFrom = getOfficerMember;
      }
      if (
        operationType === OperationType.REPLACE_OFFICER &&
        updatedState.selectedInfo.unassignFrom
      ) {
        const leadership =
          updatedState.selectedInfo.unassignFrom?.thisDistrictLeadership[0];
        updatedState.selectedInfo.role = {
          id: leadership.roleId,
          name: leadership.role,
          termYears: leadership.termYears,
        };
      }

      const isReassign = operationType === OperationType.REASSIGN_TO_ROLE;

      if (isReassign) {
        updatedState.selectedInfo = {
          role: {
            id: role.id,
            name: role.role,
            terms: getRoleTerms,
          },
          assignee: mapDOToCommitteeMemberData(
            individual,
            fullName,
            formattedRoleInfo
          ),
        };
      }

      setContext(prevState => ({
        ...prevState,
        step: isReassign ? 2 : 1,
        operationType: updatedState.operationType,
        selectedInfo: {
          ...prevState.selectedInfo,
          ...updatedState.selectedInfo,
          term: isReassign
            ? prevState.selectedInfo.term
            : prevState.termsToAssign[0],
        },
      }));

      localizedNavigate(committeeAppointmentsURL);
    };

    const createContextMenuItems = () =>
      getOfficersContextMenuItems(t, handleClickMenu, {
        districtId,
        isManagerSelected: isManager.currentTerm,
        isManagerNow: isManager.nowTerm,
        isManagerAfterNow: isManager.afterNowTerm,
        endTermDate: (term as TermToAssign).endDate,
        roleEndDate: endDate,
        roleStartDate: startDate,
        role,
        managedRYs,
        membershipType,
        membershipDistrictId: individual.membership.districtId,
        getOfficerMember,
        term: term as TermToAssign,
        terms: getRoleTerms,
        isAssignedInFutureRY,
      });

    const contextMenuItems = createContextMenuItems();

    const isContextMenuItemsExist = !!contextMenuItems.length;

    return (
      <MemberRow
        id={individual.id}
        email={individual.primaryEmail}
        fullName={fullName}
        localizedName={individual.localizedName}
        photoUri={individual.photoUri || ''}
        phoneNumber={individual.primaryPhone || ''}
        isLinkToProfile={Boolean(membershipType)}
        terms={
          <div className="text-xs text-gray-600">
            {getOfficerTermLabel(t, startDate, endDate)}
          </div>
        }
        roleTitle={
          <div className="role-title">
            <DistrictOfficerRoleTitleDesktop {...role} />
          </div>
        }
        clubAffiliation={
          individual.clubName || individual.clubType ? (
            <>
              {individual.clubName && (
                <p className="text-xs mb-0 truncate">{individual.clubName}</p>
              )}
              {individual.clubType && (
                <p className="text-xs mb-0 text-gray-400 truncate">
                  {getClubTypeName(
                    t,
                    mapEnum(
                      From.DIS,
                      Enum.ClubType,
                      individual.clubType as DIS.ClubType
                    )
                  )}
                </p>
              )}
            </>
          ) : null
        }
        actionButton={
          isContextMenuItemsExist && (
            <ContextMenu title={fullName || ''} items={contextMenuItems} />
          )
        }
        actionButtonMobile={
          isContextMenuItemsExist && (
            <ContextMenu
              title={fullName || ''}
              items={contextMenuItems}
              isRightIndent={false}
              optionsToggleButton
            />
          )
        }
        infoMessage={
          isDRFC(role.role) && (
            <InfoMessage
              message={t(
                'district-officers.sorry-cannot-manage-officer',
                `Sorry, you cannot manage this role. Please contact RI staff by emailing <a href='mailto:{{email}}'>{{email}}</a> for assistance.`,
                { email: ROTARY_ASSISTANCE_EMAIL }
              )}
            />
          )
        }
        profilePreviousLinkPath={buildOfficersPageUrl(districtId)}
      />
    );
  }

  if (unassignedTerm) {
    const { startDate, endDate, isAssignable } = unassignedTerm;
    const cannotAssignRole =
      (!isAssignable && startDate && endDate) || isAssignedInFutureRY;

    const handleAssignClick = () => {
      setUnassignedOfficerContext(prevState => ({
        ...prevState,
        step: 1,
        operationType: OperationType.ASSIGN_TO_MEMBER,
        selectedInfo: {
          ...prevState.selectedInfo,
          unassignFrom: null,
          role: {
            id: role.id,
            name: role.role,
            terms: role.terms,
            termYears: role.termYears,
          },
        },
      }));
      localizedNavigate(
        `${buildCommitteeAppointmentsUrl(
          unassignedOfficerContext.districtId
        )}/${uuid()}`
      );
    };

    return (
      <MemberRow
        id=""
        unassigned
        infoMessage={
          cannotAssignRole && (
            <InfoMessage
              message={t(
                'district-officers.sorry-cannot-assign-officer',
                `Sorry, you cannot add a past officer here. Please contact RI staff by emailing <a href="mailto:{{email}}">{{email}}</a> for assistance.`,
                { email: ROTARY_ASSISTANCE_EMAIL }
              )}
            />
          )
        }
        terms={
          cannotAssignRole && (
            <div className="text-xs text-gray-600">
              {getOfficerTermLabel(t, startDate, endDate)}
            </div>
          )
        }
        unAssignMessage={
          isAssignable &&
          !isAssignedInFutureRY && (
            <div
              className={classnames(
                'text-lg leading-lg-heading font-normal text-bright-blue-600'
              )}
            >
              {t('district-officers.unassigned', 'Unassigned')}
            </div>
          )
        }
        roleTitle={
          <div className="role-title">
            <DistrictOfficerRoleTitleDesktop {...role} />
          </div>
        }
        actionButton={
          isAssignable &&
          !isAssignedInFutureRY && (
            <button
              onClick={handleAssignClick}
              type="button"
              className="focus:shadow-none desktop:mr-3"
              data-testid="assign-button"
            >
              <span aria-hidden="true" className="text-center">
                <Icon
                  className="w-8 h-8 tablet:w-10 tablet:h-10"
                  name="circles-lg/add"
                />
              </span>
            </button>
          )
        }
        isAssigned3yrRoleInFutureRY={isAssignedInFutureRY}
      />
    );
  }

  return null;
};

export default DistrictOfficerRoleItem;
