// Libs
import React from 'react';

// Components
import { Dot } from 'recharts';

type DotProps = {
  elementIndex: number;
  lineIndex: number | null;
  cx: number;
  cy: number;
  fill: string;
  onMouseOut: () => void;
};

const CustomizedActiveDot = ({
  cx,
  cy,
  fill,
  elementIndex,
  lineIndex,
  onMouseOut,
}: DotProps) => {
  if (elementIndex === lineIndex) {
    return (
      <svg>
        <Dot r={12} cx={cx} cy={cy} fill={fill} onMouseOut={onMouseOut} />
      </svg>
    );
  }

  return null;
};

export default CustomizedActiveDot;
