import React from 'react';

import { isUndefined } from 'lodash';

import Loading from '@components/Loading';
import NotFound from '@components/Routing/NotFound';
import ConferenceForm from '@presenters/web/components/ConferenceForm';

import { CREATE_CONFERENCE_INITIAL_VALUES } from '@domain/districts/conferences/constants';
import { mapFormResultToCreateConferenceInput } from '@domain/districts/mappers';
import { CreateConferenceFormResult } from '@domain/districts/types';

import { findDefaultTimeZone } from '@use-cases/districts';
import {
  getConferencePage,
  getFutureManagementYear,
  getIsManagerByYear,
} from '@use-cases/districts/conferences';
import { normalizeCountries } from '@use-cases/districts/normalizers/normalizeCountries';
import { useNotifications } from '@use-cases/notifications';

import { useDISStatesLazy } from '@repositories/disCountry';
import { useCreateConference } from '@repositories/districts/hooks';

import { isEditLevel } from '@utils/access-helpers';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import {
  CountriesQuery,
  GetAccessLevelsQuery,
  GetTimeZonesQuery,
} from '@typings/operations';

interface Props {
  districtId: string;
  initialValues?: CreateConferenceFormResult;
  accessLevelData?: GetAccessLevelsQuery;
  canCreateConference: boolean;
  countriesData?: CountriesQuery;
  countriesLoading: boolean;
  timeZones?: GetTimeZonesQuery;
  timeZonesLoading: boolean;
}

const DistrictCreateConference: React.FC<Props> = ({
  districtId,
  initialValues,
  accessLevelData,
  canCreateConference,
  countriesData,
  countriesLoading,
  timeZones,
  timeZonesLoading,
}) => {
  const { t } = useTranslation();
  const { addSuccess, addError } = useNotifications();

  const [
    fetchCountryStates,
    { loading: loadingStates, data: countryStatesData },
  ] = useDISStatesLazy();

  const [
    fetchPRCountryStates,
    { loading: loadingPRStates, data: countryPRStatesData },
  ] = useDISStatesLazy();

  const [
    createConference,
    { loading: loadingCreateConference },
  ] = useCreateConference();

  const isManagerInCurrentOrFutureTerm = accessLevelData?.results.some(
    ({ level }) => isEditLevel(level)
  );

  if (loadingCreateConference || timeZonesLoading || countriesLoading) {
    return <Loading />;
  }

  if (
    !isUndefined(canCreateConference) &&
    !(isManagerInCurrentOrFutureTerm && canCreateConference)
  ) {
    return <NotFound default />;
  }

  const is2023YManager = getIsManagerByYear(accessLevelData, 2023);

  const formattedCountries = normalizeCountries(countriesData);

  const goBack = () => localizedNavigate(getConferencePage(districtId));

  const createConferenceSubmit = async (
    formData: CreateConferenceFormResult
  ) => {
    try {
      const input = mapFormResultToCreateConferenceInput(
        formData,
        formattedCountries,
        timeZones?.getTimeZones
      );

      await createConference({ variables: { input } });

      addSuccess(t('create-conference.success', 'Success.'), {
        id: 'form.success',
      });
      goBack();
    } catch (error) {
      addError(
        t(
          'create-conference.error-request',
          'An error occurred with create conference form'
        )
      );
    }
  };

  const formValues = initialValues || {
    ...CREATE_CONFERENCE_INITIAL_VALUES,
    dateTime: {
      ...CREATE_CONFERENCE_INITIAL_VALUES.dateTime,
      timeZone: findDefaultTimeZone(timeZones?.getTimeZones || []),
    },
  };

  return (
    <ConferenceForm
      initialValues={{ ...formValues, districtId }}
      handleSubmit={createConferenceSubmit}
      countriesData={formattedCountries}
      countriesLoading={countriesLoading}
      loadingStates={loadingStates}
      countryStatesData={countryStatesData?.states || []}
      timeZones={timeZones?.getTimeZones || []}
      fetchCountryStates={fetchCountryStates}
      loadingPRStates={loadingPRStates}
      fetchPRCountryStates={fetchPRCountryStates}
      countryPRStatesData={countryPRStatesData?.states || []}
      handleBackRedirect={goBack}
      backButtonPath={getConferencePage(districtId)}
      is2023YManager={is2023YManager}
      futureManagementYear={getFutureManagementYear(accessLevelData)}
      formTitle={t(
        'create-conference.add-district-conference',
        'Add a District Conference'
      )}
    />
  );
};

export default DistrictCreateConference;
