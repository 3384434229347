// Libs
import React from 'react';

// Constants
import { OVER_40, UNDER_40, UNREPORTED } from '@domain/districts';

// Utils
import { useTranslation } from '@external/react-i18next';

// Types
import {
  Props as LegendProps,
  Payload as LegendPayload,
} from 'recharts/types/component/DefaultLegendContent';
import { DIS } from '@typings/dis';
import { TFunction } from 'i18next';

type WomenKey = 'women';
type LabelKeys = DIS.AgeCategoriesKeys & WomenKey;

const getLabel: (t: TFunction) => Record<LabelKeys, string> = (
  t: TFunction
) => ({
  [OVER_40]: t('demographics.over40', '40 and Over'),
  [UNDER_40]: t('demographics.under40', 'Under 40'),
  [UNREPORTED]: t('demographics.unreported', 'Unreported'),
});

const LegendContent = (props: LegendProps) => {
  const { t } = useTranslation();
  const { payload: legendData } = props;

  if (legendData) {
    return (
      <ul className="flex flex-wrap desktop:flex-row mb-3">
        {legendData.map((item: LegendPayload) => {
          return (
            <li
              className="flex items-center mb-1 desktop:mb-0 mr-5"
              key={`legend-${item.value}`}
            >
              <div className="h-5 w-5" style={{ background: item.color }} />
              <span className="text-xs inline-block ml-2 text-gray-600">
                {getLabel(t)[item.value as LabelKeys]}
              </span>
            </li>
          );
        })}
      </ul>
    );
  }

  return null;
};

export default LegendContent;
