import { TFunction } from 'i18next';
import { isBoolean } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';

import { numberSchema } from '@components/Formik/validation/fieldDefinitions';
import {
  bindTFuncToSchemaField,
  hasMaxLength,
  isRequired,
  isURL,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';

import { isRemoteInfoRequired } from '@use-cases/districts/conferences';

const dateTimeMessage = (t: TFunction) =>
  t(
    'create-conference.dateTime.validation-message',
    'End Date and Time should be after the Start Date and Time'
  );

const dateMessage = (t: TFunction) =>
  t(
    'create-conference.dateTime.date-validation-message',
    'End Date should be after the Start Date'
  );

const parseDateAndFormat = (date: string) =>
  moment(Date.parse(date)).format('YYYY-MM-DD');

const testDateFields = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string
) => {
  if (startDate && endDate && !(startTime && endTime)) {
    return !moment(parseDateAndFormat(startDate)).isAfter(
      parseDateAndFormat(endDate)
    );
  }

  return true;
};

const testDateTimeFields = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string
) => {
  const allFieldsFilled = startTime && startDate && endTime && endDate;

  if (allFieldsFilled) {
    const endDateTime = moment(`${parseDateAndFormat(endDate)} ${endTime}`);
    const startDateTime = moment(
      `${parseDateAndFormat(startDate)} ${startTime}`
    );

    return !moment(startDateTime).isSameOrAfter(endDateTime);
  }

  return true;
};

export const dateTimeSectionSchema = (t: TFunction) =>
  Yup.object().shape(
    {
      timeZone: Yup.string().when(
        ['startTime', 'endTime'],
        (startTime: string, endTime: string, schema: Yup.StringSchema) => {
          if (startTime || endTime) {
            return schema.required(t('form.field.error_required', 'Required'));
          }

          return schema.nullable();
        }
      ),
      startDate: schemaField(t, Yup.string(), isRequired)
        .when(
          ['startTime', 'endDate', 'endTime'],
          (
            startTime: string,
            endDate: string,
            endTime: string,
            schema: Yup.StringSchema
          ) => {
            if (!startTime && !endTime) {
              return schema.test(
                'startDate.test',
                dateMessage(t),
                (value: string) =>
                  testDateFields(value, startTime, endDate, endTime)
              );
            }

            return schema.test(
              'startDate.test',
              dateTimeMessage(t),
              (value: string) =>
                testDateTimeFields(value, startTime, endDate, endTime)
            );
          }
        )
        .nullable(),
      startTime: Yup.string()
        .when(
          ['startDate', 'endDate', 'endTime'],
          (
            startDate: string,
            endDate: string,
            endTime: string,
            schema: Yup.StringSchema
          ) =>
            schema.test('startTime.test', dateTimeMessage(t), (value: string) =>
              testDateTimeFields(startDate, value, endDate, endTime)
            )
        )
        .when(['endTime'], {
          is: endTime => endTime,
          then: schemaField(t, Yup.string(), isRequired),
        })
        .nullable(),
      endDate: schemaField(t, Yup.string(), isRequired)
        .when(
          ['startDate', 'startTime', 'endTime'],
          (
            startDate: string,
            startTime: string,
            endTime: string,
            schema: Yup.StringSchema
          ) => {
            if (!startTime && !endTime) {
              return schema.test(
                'startDate.test',
                dateMessage(t),
                (value: string) =>
                  testDateFields(startDate, startTime, value, endTime)
              );
            }

            return schema.test(
              'endDate.test',
              dateTimeMessage(t),
              (value: string) =>
                testDateTimeFields(startDate, startTime, value, endTime)
            );
          }
        )
        .nullable(),
      endTime: Yup.string()
        .when(
          ['startDate', 'startTime', 'endDate'],
          (
            startDate: string,
            startTime: string,
            endDate: string,
            schema: Yup.StringSchema
          ) =>
            schema.test('endTime.test', dateTimeMessage(t), (value: string) =>
              testDateTimeFields(startDate, startTime, endDate, value)
            )
        )
        .when(['startTime'], {
          is: startTime => startTime,
          then: schemaField(t, Yup.string(), isRequired),
        })
        .nullable(),
    },
    [
      ['startDate', 'startTime'],
      ['startDate', 'endDate'],
      ['startDate', 'endTime'],
      ['startTime', 'endDate'],
      ['startTime', 'endTime'],
      ['endDate', 'endTime'],
      ['timeZone', 'startTime'],
      ['timeZone', 'endTime'],
    ]
  );

export const stateValidation = (t: TFunction) =>
  Yup.string()
    .when('hasStates', {
      is: hasStates => hasStates,
      then: schemaField(t, Yup.string(), isRequired),
    })
    .nullable();

const stateFieldValidation = (t: TFunction, isPresRepValidation?: boolean) => {
  if (isBoolean(isPresRepValidation)) {
    // prAddress - when pres rep checkbox checked
    if (isPresRepValidation) {
      return stateValidation(t);
    }
    // prAddress - when pres rep checkbox unchecked
    return schemaField(t, Yup.string());
  }
  // conferenceAddress - when pres rep checkbox unchecked
  return stateValidation(t);
};

export const conferenceAddressSectionSchema = (
  t: TFunction,
  isPresRepValidation?: boolean
) =>
  Yup.object().shape({
    city: schemaField(t, Yup.string()),
    country: schemaField(t, Yup.string()),
    lineOne: schemaField(t, Yup.string()),
    lineTwo: schemaField(t, Yup.string()),
    lineThree: schemaField(t, Yup.string()),
    postalCode: schemaField(t, Yup.string()),
    internationalProvince: schemaField(t, Yup.string()),
    state: stateFieldValidation(t, isPresRepValidation),
    hasState: schemaField(t, Yup.boolean()),
  });

const validationSchema = (t: TFunction) => {
  const schemaWithT = bindTFuncToSchemaField(t);
  return Yup.object().shape({
    districtId: schemaWithT(Yup.string()),
    venueType: schemaWithT(Yup.string()),
    dateTime: dateTimeSectionSchema(t),
    venueName: schemaWithT(Yup.string()),
    conferenceAddress: conferenceAddressSectionSchema(t),
    language: schemaWithT(Yup.string()),
    comments: schemaWithT(Yup.string(), hasMaxLength(250)),
    onlineLocation: schemaWithT(Yup.string()).when('venueType', {
      is: isRemoteInfoRequired,
      then: schemaWithT(Yup.string(), isURL, isRequired),
    }),
    detailForJoiningRemotely: schemaWithT(Yup.string()),
    prAddress: Yup.object()
      .when('isPresRepRequested', {
        is: isPresRepRequested => isPresRepRequested,
        then: conferenceAddressSectionSchema(t, true).nullable(),
        otherwise: conferenceAddressSectionSchema(t, false).nullable(),
      })
      .nullable(),

    accomodationPhone: Yup.object().shape({
      country: Yup.string().when('number', {
        is: (phoneNumber: string) => !!phoneNumber,
        then: schemaField(t, Yup.string(), isRequired),
      }),
      number: numberSchema(t),
    }),
    prAccommodation: schemaWithT(Yup.string()).nullable(),
    isPresRepRequested: Yup.boolean(),
  });
};

export default validationSchema;
