// Libs
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import tailwind from '@styles/tailwind.js';

// Components
import QuoteItem from '../QuoteItem';
import CopyItem from '../CopyItem';

// Utils
import { getMenuItems } from '../utils';

// Hooks
import { useWindowSize } from '@hooks/useWindowSize';

// Constants
import { UNASSIGNED } from '../constants/groupnames';

// Types
import { Group, LocationResult, Item } from '../types';

interface Props {
  id: string;
  data: string;
  index: number;
  children: string;
  groupsData: Group[];
  currentGroupId: string;
  mobileWidthBreakpoint?: number;
  assignItem: (
    locationResult: LocationResult,
    assignedItem: Item,
    group: Group
  ) => void;
}

const Quote: React.FC<Props> = props => {
  const {
    id,
    data,
    index,
    children,
    groupsData,
    currentGroupId,
    assignItem,
    mobileWidthBreakpoint = parseInt(tailwind.theme.screens.tablet, 10),
  } = props;

  const [isDisabledItem, setIsDisabledItem] = useState<boolean>(false);

  const isDragDisabled = (isDisabled: boolean): void => {
    setIsDisabledItem(isDisabled);
  };

  const { width } = useWindowSize();

  return (
    <Draggable
      isDragDisabled={width < mobileWidthBreakpoint || isDisabledItem}
      key={id}
      draggableId={id}
      index={index}
    >
      {(provided, snapshot) => (
        <div>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              userSelect: 'none',
            }}
          >
            <div className="relative">
              <div className="item-wrapper">
                <QuoteItem
                  id={id}
                  data={data}
                  index={index}
                  isDragDisabled={isDragDisabled}
                  assignItem={assignItem}
                  currentGroupId={currentGroupId}
                  menuItems={getMenuItems(
                    groupsData,
                    currentGroupId,
                    !currentGroupId.includes(UNASSIGNED)
                  )}
                >
                  {children}
                </QuoteItem>
              </div>
            </div>
          </div>
          {snapshot.isDragging && (
            <div className="relative">
              <div className="item-wrapper item-copy-wrapper absolute flex w-full">
                <CopyItem>{children}</CopyItem>
              </div>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default Quote;
