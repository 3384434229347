// Libs
import React from 'react';
import { components, MenuProps, OptionTypeBase } from '@external/react-select';

const Menu = ({ ...props }: MenuProps<OptionTypeBase>) => {
  const { selectProps } = props;

  if (
    selectProps.inputValue &&
    selectProps.options?.every(
      option => !option.label.includes(selectProps.inputValue)
    )
  ) {
    return null;
  }

  return <components.Menu {...props} />;
};

export default Menu;
