import React from 'react';

import { unsafeInsertAt } from 'fp-ts/Array';
import { isNumber, last } from 'lodash';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';

import TooltipWrapper from '../components/TooltipWrapper';

import { ChartValueFormats, TOTAL_FOR_30_JUNE } from '@domain/districts';

// Utils
import {
  formatNumberWithSign,
  getYearLabel,
  localizeDayMonth,
  numberToLocale,
} from '@use-cases/districts';

// Types

import { useTranslation } from '@external/react-i18next';

type Keys = 'totalFor30June' | 'priorMonthEnd' | 'annualFund' | 'polioPlus';
type PayloadProps = Record<Keys, number | null>;

type Props = {
  active?: boolean;
  payload?: {
    dataKey?: string | number;
    value?: ValueType;
    payload?: PayloadProps & {
      rotaryYear: number;
      asOfDate: string;
      prevYearDeltas: PayloadProps & {
        prevYearAsOfDate: string;
      };
    };
  }[];
  lineIndex: number | null;
  lineKey: string | null;
  valueFormat?: string;
  header?: string;
};

const CustomizedTooltip: React.FC<Props> = ({
  active,
  payload: chartData,
  lineIndex,
  lineKey,
  valueFormat,
  header,
}) => {
  const { t } = useTranslation();

  // Should display tooltip for the 1st element in the array
  // When there is no data (dot) for 30 Jun line
  const tooltipData =
    isNumber(lineIndex) && (chartData?.[lineIndex] || chartData?.[0]);

  const localizeDate = (date: string, parseAs?: string) =>
    `${localizeDayMonth(date, parseAs)} ${last(date.split(' '))}`;

  if (active && tooltipData) {
    const { payload, value, dataKey } = tooltipData;

    if (payload) {
      const { asOfDate, prevYearDeltas, rotaryYear } = payload;

      const units =
        valueFormat === ChartValueFormats.percent ? ['', '%'] : ['$ ', ''];

      const totalValue = isNumber(value)
        ? unsafeInsertAt(1, numberToLocale(Math.round(value)), units).join('')
        : undefined;

      const juneAsOfDate = (year: number) =>
        `${localizeDayMonth('30 Jun')} ${getYearLabel(year)}`;

      const date =
        lineKey === TOTAL_FOR_30_JUNE
          ? juneAsOfDate(rotaryYear)
          : localizeDate(asOfDate, 'D MMM YY');

      const deltaValue = `${formatNumberWithSign(
        prevYearDeltas[dataKey as Keys] || 0
      )}%`;

      const deltaDate =
        lineKey === TOTAL_FOR_30_JUNE
          ? juneAsOfDate(rotaryYear - 1)
          : localizeDate(prevYearDeltas.prevYearAsOfDate, 'D MMM YY');

      return (
        <TooltipWrapper header={header} title={date} subtitle={totalValue}>
          <div>
            {t('dashboards.delta-from-date', '{{delta}} from {{date}}', {
              delta: deltaValue,
              date: deltaDate,
            })}
          </div>
        </TooltipWrapper>
      );
    }
  }

  return null;
};

export default CustomizedTooltip;
