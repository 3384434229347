import React, { useCallback, useRef, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useField } from 'formik';
import { isEqual } from 'lodash';

import Element, { ElementProps } from '@components/Formik/Element';
import Dropdown from '@components/Formik/MultiSelectFilter/Dropdown';
import OptionItem from '@components/Formik/MultiSelectFilter/OptionItem';

import MenuList from './MenuList';
import ParticipantsInput from './ParticipantsInput';

import { ParticipantOption } from '@domain/districts';

import {
  getParticipantSelectorStyles,
  validateParticipants,
} from '@use-cases/districts/conferences';

import tailwind from '@styles/tailwind.js';

import { useTranslation } from '@external/react-i18next';
import ReactSelect, { OptionTypeBase, ValueType } from '@external/react-select';
import { useWindowSize } from '@hooks/useWindowSize';

export interface MultiSelectFilterProps extends ElementProps {
  conferenceRY: string;
  selectedParticipants: ParticipantOption[];
  initialParticipantsIds?: string[];
  options?: ParticipantOption[];
  onSelect?: () => void;
  isValidating?: (isValidating: boolean) => void;
}

const ParticipantsSelector: React.FC<MultiSelectFilterProps> = ({
  selectedParticipants,
  initialParticipantsIds,
  conferenceRY,
  isValidating,
  ...props
}) => {
  const { options, name } = props;

  const [invalidParticipants, setInvalidParticipants] = useState<string[]>([]);

  const checkActiveConference = (id: string) =>
    invalidParticipants.includes(id);

  const { t } = useTranslation();
  const [field, , helpers] = useField<ValueType<OptionTypeBase>>(name);
  const [isOpen, setIsOpen] = useState(false);

  const [prevValue, setPrevValue] = useState(field.value);
  const [hasError, setHasError] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onSelect = (option: ValueType<OptionTypeBase>) => {
    helpers.setValue(option);

    // Need to use setTimeout to prevent calling Yup schema with previous values
    setTimeout(() => {
      helpers.setTouched(true);
    }, 0);
  };

  const onDeselect = (optionId: string) => {
    const updatedList = field.value?.filter(
      ({ id }: ParticipantOption) => id !== optionId
    );
    const updatedIds = updatedList.map(({ id }: ParticipantOption) => id);
    const hasInvalidIds = updatedIds.filter(checkActiveConference);

    helpers.setValue(updatedList);

    if (!hasInvalidIds.length) {
      setTimeout(() => {
        helpers.setError(undefined);
      });
      setHasError(false);
    }
  };

  const menuRef = useRef(null);
  const windowWidth = useWindowSize().width;
  const isAutoFocusActive =
    windowWidth > parseInt(tailwind.theme.screens.desktop, 10);

  const apolloClient = useApolloClient();

  const setError = () => {
    helpers.setError(
      t(
        'create-conference.participants.validation-message',
        'District(s) already have a conference scheduled for this Rotary Year and can not be added'
      )
    );
    setHasError(true);
  };

  const validate = (value?: ParticipantOption[]) => {
    if (
      hasError ||
      (value?.length === 1 && checkActiveConference(value[0].id))
    ) {
      setTimeout(() => {
        setError();
      }, 0);
    }

    if (value?.length && !isEqual(prevValue, value)) {
      setPrevValue(value);
      isValidating && isValidating(true);

      validateParticipants(
        value,
        apolloClient,
        setInvalidParticipants,
        setError,
        initialParticipantsIds,
        conferenceRY,
        isValidating
      );
    }
  };

  const Option = useCallback(
    optionProps => (
      <OptionItem optionProps={optionProps}>
        <div className="text-gray-600">{optionProps.data.value}</div>
      </OptionItem>
    ),
    []
  );

  return (
    <Element {...props} validate={validate}>
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={
          <ParticipantsInput
            selectedParticipants={selectedParticipants}
            onDeselect={onDeselect}
            toggleHandler={toggleOpen}
            checkActiveConference={checkActiveConference}
          />
        }
      >
        <ReactSelect
          backspaceRemovesValue={false}
          controlShouldRenderValue={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          tabSelectsValue={false}
          menuIsOpen
          isMulti
          isSearchable
          autoFocus={isAutoFocusActive}
          styles={getParticipantSelectorStyles()}
          options={options}
          value={selectedParticipants}
          windowWidth={windowWidth}
          menuRef={menuRef}
          onChange={onSelect}
          placeholder={t(
            'create-conference.participants.search-placeholder',
            'Search by District'
          )}
          components={{
            Option,
            MenuList,
            IndicatorSeparator: null,
            DropdownIndicator: null,
          }}
        />
      </Dropdown>
    </Element>
  );
};

export default ParticipantsSelector;
