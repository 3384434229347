// Libs
import React from 'react';

// Components
import AnnualFundChart from './AnnualFundChart';
import ClubCreditsChart from './ClubCreditsChart';
import PolioEradicationChart from './PolioEradicationChart';
import ChartTitle from '../components/ChartTitle';

// Utils
import { useTranslation } from '@external/react-i18next';
import { getContributionData } from '@use-cases/districts';

// Types
import { DIS } from '@typings/dis';
import { Contribution as ContributionType } from '@typings/operations';

// Constants
import { ChartValueFormats } from '@domain/districts';

type Props = {
  data: ContributionType;
  asOfDate: string;
};

const Contribution: React.FC<Props> = ({ data, asOfDate }) => {
  const { t } = useTranslation();

  const { annualFund, clubCredits, polioPlus } = getContributionData(data);

  return (
    <div>
      <ChartTitle
        title={t('dashboards.title.contribution', 'Contribution')}
        wrapperClassName="mb-0 desktop:mb-3"
      />
      <div className="flex flex-wrap items-center">
        <AnnualFundChart
          chartData={annualFund as DIS.ContributionChartItem[]}
          asOfDate={asOfDate}
        />
        <PolioEradicationChart
          chartData={polioPlus as DIS.ContributionChartItem[]}
          asOfDate={asOfDate}
        />
        <ClubCreditsChart
          chartData={clubCredits as DIS.ClubCreditsChartData[]}
          yAxisTickValueFormat={ChartValueFormats.percent}
          asOfDate={asOfDate}
        />
      </div>
    </div>
  );
};

export default Contribution;
