import React from 'react';

import ChartTitle from '../components/ChartTitle';
import ContributionChart from './ContributionChart';

import { districtContributionChartColors } from '@domain/districts';

import { getMaxClubCreditsValue } from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';

type Props = {
  chartData: DIS.ClubCreditsChartData[];
  yAxisTickValueFormat: string;
  asOfDate: string;
};

const ClubCreditsChart: React.FC<Props> = ({
  chartData,
  yAxisTickValueFormat,
  asOfDate,
}) => {
  const { t } = useTranslation();
  const { clubContributionColors } = districtContributionChartColors;
  const DEFAULT_MAX_YAXIS_VALUE = 100;

  return (
    <div className="w-full mb-9 desktop:w-1/2 desktop:pr-5">
      <ChartTitle
        title={t('dashboards.title.clubParticipation', 'Club Participation')}
        isCategoryTitle={false}
        alternativeDescripton={t(
          'dashboards.sub-title.clubParticipation',
          'New and Terminated clubs are not included'
        )}
        wrapperClassName="mb-2 flex-col desktop:flex-row desktop:justify-between"
        asOfDate={asOfDate}
      />
      <ContributionChart
        chartData={chartData}
        colors={clubContributionColors}
        maxValue={getMaxClubCreditsValue(chartData) || DEFAULT_MAX_YAXIS_VALUE}
        showTooltip
        useLineNameAsTooltipHeader
        yAxisTickValueFormat={yAxisTickValueFormat}
      />
    </div>
  );
};

export default ClubCreditsChart;
