import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import { TFunction } from 'i18next';
import { curry, fromPairs, get, max, min } from 'lodash/fp';
import * as R from 'ramda';

import {
  CUSTOM_QUESTION_19_ID,
  CUSTOM_QUESTION_20_ID,
  QUESTION_IDS,
  SECTION_IDS,
  SURVEY_OPTIONS,
} from '@domain/districts/conferences/constants';
import {
  Answers,
  ControlOperationType,
} from '@domain/districts/conferences/types';

import { isQuestionRadio } from '@use-cases/districts';

import { parseControlValuesFromDISFormat } from '@utils/parseControlValuesFromDISFormat';

import {
  Feedback,
  FeedbackQuestionsResponse,
  Question,
  QuestionResponse,
} from '@typings/operations';

export const getQuestionsProp = (feedbackData: Feedback): Question[] =>
  pipe(feedbackData, R.prop('questions'));

const parseRadioButtonsInitValue = (value: string) =>
  pipe(
    value,
    parseControlValuesFromDISFormat,
    A.head,
    O.getOrElse(() => '')
  );

const getFoundQuestion = curry(
  (questionId: string, questions: Question[]): Question | null =>
    pipe(
      questions,
      A.filter(({ id }) => id === questionId),
      A.head,
      O.toNullable
    )
);

const getFoundControlType = (
  questionId: string,
  questions: Question[]
): string =>
  pipe(
    O.fromNullable(getFoundQuestion(questionId, questions)),
    O.map(get('optionType.optionType')),
    O.getOrElse(() => '')
  );

const getFoundQuestionDetail = (
  questionId: string,
  questions: Question[]
): string =>
  pipe(
    O.fromNullable(getFoundQuestion(questionId, questions)),
    O.map(get('optionDetail.optionValue')),
    O.getOrElse(() => '')
  );

const parseValue = curry(
  (questions: Question[], { questionId, value }: QuestionResponse) => {
    const foundControlType = getFoundControlType(questionId, questions);

    // If question is hardcoded "radio" button,
    // then we do not need to convert value from the response
    if (isQuestionRadio({ id: questionId })) {
      return { questionId, value };
    }

    let newValue: string | boolean[] | null | boolean = null;

    if (foundControlType === ControlOperationType.RADIO_BUTTONS) {
      newValue = parseRadioButtonsInitValue(value);
    }

    if (foundControlType === ControlOperationType.TEXTBOX) {
      newValue = value;
    }

    if (foundControlType === ControlOperationType.CHECK_BOX) {
      const checkedValues = parseControlValuesFromDISFormat(value);

      newValue = pipe(
        getFoundQuestionDetail(questionId, questions),
        parseControlValuesFromDISFormat,
        A.map(item => checkedValues.includes(item))
      );
    }

    if (['true', 'false'].includes(value)) {
      newValue = value !== 'false';
    }

    return { questionId, value: newValue };
  }
);

const getInitialValueField = curry(
  (questions: Question[], { questionId, value }: QuestionResponse) =>
    pipe(
      { questionId, value },
      parseValue(questions),
      ({ questionId, value }) => [questionId, value]
    )
);

export const getInitialValue = (
  questions: Question[],
  responses: QuestionResponse[]
) => pipe(responses, A.map(getInitialValueField(questions)), fromPairs);

export const getFormattedOptions = (value: string) =>
  pipe(
    value,
    parseControlValuesFromDISFormat,
    A.map((value: string) => ({
      value,
      label: value,
    }))
  );

const getFormattedSurveySeqNumbers = (questions: Question[]): number[] =>
  pipe(
    questions,
    A.filter(
      ({ optionDetail: { optionValue } }) =>
        optionValue === SURVEY_OPTIONS.FORMATTED
    ),
    A.map(R.prop('optionSeq'))
  );

export const getFormattedSurveyMinSeqNumber = (
  questions: Question[]
): number | null =>
  pipe(
    questions,
    getFormattedSurveySeqNumbers,
    min,
    O.fromNullable,
    O.toNullable
  );

export const getFormattedSurveyMaxSeqNumber = (
  questions: Question[]
): number | null =>
  pipe(
    questions,
    getFormattedSurveySeqNumbers,
    max,
    O.fromNullable,
    O.toNullable
  );

export const getTranslateQuestion = (
  t: TFunction,
  questionId: string,
  option: string
): string => {
  switch (questionId) {
    case QUESTION_IDS[0]:
      return t(
        '95b02946-0e94-4b1d-aab7-002c1e339ca3',
        'did you take advantage of the opportunity and attend a club meeting'
      );
    case QUESTION_IDS[1]:
      return t('51d64e69-7270-46fd-a927-019bf1b10361', 'name');
    case QUESTION_IDS[2]:
      return t('b1fb13d0-79ff-4235-9264-03db1d67d75e', 'enthusiasm');
    case QUESTION_IDS[3]:
      return t(
        'c9285db5-8a6a-4ea5-8a20-0494c7a2dd0d',
        'Please list the topic of any speaking opportunities'
      );
    case QUESTION_IDS[4]:
      return t(
        '0c5ca1fb-7b4c-4410-af65-05b8e8f7d56c',
        'Events/programming for young leaders (Rotaractors, Interactors, Youth Exchange students)'
      );
    case QUESTION_IDS[5]:
      return t('187766e4-c7ab-4697-bc1b-09476a8df461', 'Service Activities');
    case QUESTION_IDS[6]:
      return t(
        '441e1913-5c07-4715-88d2-0f99ce2c4d9a',
        'Was a well planned public relations effort evident'
      );
    case QUESTION_IDS[7]:
      return t('533fb6d0-9d1a-4b7b-a5cd-116f41897565', 'public speaking');
    case QUESTION_IDS[8]:
      return t(
        'ebd244ae-8fa7-4c57-8627-15c239aeb12e',
        'please rate the representative overall'
      );
    case QUESTION_IDS[9]:
      return t(
        '75c1d971-83b3-4bd7-8843-1de0009c365b',
        'Did the conference include a district leadership seminar for interested Rotarians who have served either as club president or for three or more years in a club leadership role'
      );
    case QUESTION_IDS[10]:
      return t('d3ed45f7-dad6-4640-8824-27eada7d38d5', 'Plenary Sessions');
    case QUESTION_IDS[11]:
      return t('7ca8de2d-8f2f-41c1-bfd7-2b65502af0b8', 'physical energy');
    case QUESTION_IDS[12]:
      return t(
        'd7f8b495-e635-4687-ab79-315835880e0f',
        'Please list the topic of the address:'
      );
    case QUESTION_IDS[13]:
      return t('0156ea77-c317-4678-9189-32db976f9b75', 'name');
    case QUESTION_IDS[14]:
      return t(
        'c6665155-c406-4830-b978-373b6b78a2d3',
        'Events/programming for young leaders (Rotaractors, Interactors, Youth Exchange students)'
      );
    case QUESTION_IDS[15]:
      return t('c099af8b-ce99-40d7-b97b-3a190d682ef6', 'name');
    case QUESTION_IDS[16]:
      return t(
        'ba93f446-1161-42d9-87aa-3ddf0a690d60',
        'Emphasizing member engagement and retention'
      );
    case QUESTION_IDS[17]:
      return t(
        '35a8f22d-c501-4e97-8a77-46a151f8ef0c',
        'If you rated any of the above weak, please share your thoughts on how this element could have been improved'
      );
    case QUESTION_IDS[18]:
      return t(
        'a5d14b08-4274-415b-89cf-4886e12f8132',
        'Please rate the President’s Representative overall'
      );
    case QUESTION_IDS[19]:
      return t(
        '21423d80-19ee-4101-bea3-4a1bf4f7368f',
        'Representation of the President and their theme and initiatives'
      );
    case QUESTION_IDS[20]:
      return t(
        'dacf9b8d-a396-4ee5-98e8-4b0f97f7b3d5',
        'Presentation skills and quality of content presented during speaking opportunities'
      );
    case QUESTION_IDS[21]:
      return t('c102a5ee-58cc-434b-b645-4b83bc36773a', 'Club Presidents');
    case QUESTION_IDS[22]:
      return t(
        'cf8ed6c0-3a43-48b7-bfd5-4c0833790bc1',
        'did the conference program offer general plenary sessions'
      );
    case QUESTION_IDS[23]:
      return t(
        'aec9ef52-50ce-4c66-8f60-4c8e372547c8',
        'Attendance at all plenary session and associated conference events'
      );
    case QUESTION_IDS[24]:
      return t('e2536418-8b92-4671-8bed-4e4d46b85217', 'effect on attendees');
    case QUESTION_IDS[25]:
      return t(
        'a4d349f8-e153-4b76-a563-4f61c2e57245',
        'How many Rotarians and guests were in attendance'
      );
    case QUESTION_IDS[26]:
      return t(
        'fdb4ea5e-f03b-4707-b124-4fc9454d05bb',
        'did the conference showcase successful club activities'
      );
    case QUESTION_IDS[27]:
      return t(
        '229e4a1f-f344-49a8-acef-50dfab378b35',
        'did you take advantage of the opportunity and visit a club project'
      );
    case QUESTION_IDS[28]:
      return t('1206e94a-e2da-49d9-9596-523514c8eeff', 'name');
    case QUESTION_IDS[29]:
      return t('eecbeac4-897e-4af3-b960-52ab373ec24d', 'Rotaract club members');
    case QUESTION_IDS[30]:
      return t(
        '6f3469f8-f63f-478c-bae8-539dc8b20d00',
        'How much program time was devoted to Rotary (RI and TRF) topics? (in hours)'
      );
    case QUESTION_IDS[31]:
      return t(
        '63b1c614-7337-4979-9fda-53f549382c2d',
        'did the conference encourage interaction among clubs'
      );
    case QUESTION_IDS[32]:
      return t(
        '58c943f0-7f74-4cfd-9b86-54ed43c05ed5',
        'was emphasis placed on the need for Rotary membership growth and retention and chartering new clubs'
      );
    case QUESTION_IDS[33]:
      return t(
        '675c028a-8594-410c-bb03-552a03afa14f',
        'How much program time was devoted to Rotary (RI and TRF) topics'
      );
    case QUESTION_IDS[34]:
      return t(
        '97b02f12-4503-4de4-a88c-563d49f8ed77',
        'if yes: how long was this seminar'
      );
    case QUESTION_IDS[35]:
      return t('8a57a474-efd7-438c-b6e1-5cc667cbf171', 'Interact club members');
    case QUESTION_IDS[36]:
      return t(
        'df600d37-5521-425e-86e5-6132a209e4f9',
        'socializing and talking with attendees'
      );
    case QUESTION_IDS[37]:
      return t(
        'e00bc7dc-85e1-4002-97ac-619875cc0a45',
        'Promoting Rotary Service opportunities'
      );
    case QUESTION_IDS[38]:
      return t('53a535b4-c5f3-454e-b5aa-619a20159eb6', 'Plenary Sessions');
    case QUESTION_IDS[39]:
      return t(
        '797e61b0-2b4e-4c06-9378-6202c509c90e',
        'If you chose did not meet expectations, please share your thoughts on how this representative could have performed better'
      );
    case QUESTION_IDS[40]:
      return t(
        'fea33375-25fa-439f-beca-63187975bf9d',
        'How many Rotarians and guests were in attendance'
      );
    case QUESTION_IDS[41]:
      return t(
        'c94f786b-ee07-42f0-a645-67b388d21208',
        'knowledge and understanding of Rotary'
      );
    case QUESTION_IDS[42]:
      return t(
        '64d860d5-ec4d-422f-8f65-685381590f5b',
        'Keynote/banquet remarks'
      );
    case QUESTION_IDS[43]:
      return t(
        'b26e536d-b894-4d64-a804-68d03f53e1c6',
        'Events/programming for TRF alumni/scholars'
      );
    case QUESTION_IDS[44]:
      return t(
        '7579a5a2-269f-428e-9df3-68e513e76901',
        'On average, how long was the conference program, per day, including plenary and breakout sessions, meal events, and any other organized activities? (in hours)'
      );
    case QUESTION_IDS[45]:
      return t(
        '05b6e2c6-a2a2-45b0-b272-69119e3436ba',
        'was the upcoming Rotary International Convention promoted'
      );
    case QUESTION_IDS[46]:
      return t(
        '0ea0742a-80fa-4de7-9312-6c219add40f2',
        'Knowledge and understanding of Rotary'
      );
    case QUESTION_IDS[47]:
      return t(
        'aee1a1b6-0ed1-4088-bc17-6dcced6c9b62',
        'Events/programming specifically for partners of Rotarians'
      );
    case QUESTION_IDS[48]:
      return t(
        '9c53e5b2-4680-4fc4-bb69-7285e07b6102',
        'Was there a special orientation event for new Rotarians'
      );
    case QUESTION_IDS[49]:
      return t(
        'fdd495eb-78e7-487d-9ac5-7462922be1d9',
        'Events/programming specifically for partners of Rotarians'
      );
    case QUESTION_IDS[50]:
      return t(
        'd66047bf-3e14-427f-ba0a-79fa9134404a',
        'Were you given an opportunity to visit a local Rotary club service project in conjunction with your district conference assignment'
      );
    case QUESTION_IDS[51]:
      return t(
        '22c0fa96-4c2b-4b41-89fc-7b67e762d357',
        'ability to speak the host language'
      );
    case QUESTION_IDS[52]:
      return t(
        '2fac4a66-1355-4581-ba57-7cbc6bba71b1',
        'Were you given the opportunity to make two primary addresses to the conference? (If no, skip to question 14)'
      );
    case QUESTION_IDS[53]:
      return t('18da5723-b412-4d65-b8ba-82e1cfc79ffc', 'Breakout Sessions');
    case QUESTION_IDS[54]:
      return t(
        '511c6d67-6fda-4601-a04c-883493144d79',
        'Youth Exchange participants'
      );
    case QUESTION_IDS[55]:
      return t(
        'a7dd84d3-9be3-4a52-9b2e-894722ab77d5',
        'Please check the box if you would recommend this representative for future district conference assignments'
      );
    case QUESTION_IDS[56]:
      return t(
        'e24cb5af-84a1-4938-866c-89e06e86535a',
        'How many Rotarians and guests were in attendance'
      );
    case QUESTION_IDS[57]:
      return t(
        '5bfde7cb-5779-4478-b8a0-8ba4ec65063a',
        'If yes, was your partner invited to participate in conference activities and/or offered a speaking opportunity'
      );
    case QUESTION_IDS[58]:
      return t('5073684e-e48d-4b43-b0cd-8c8a27dd40e6', 'DG year');
    case QUESTION_IDS[59]:
      return t(
        'a9014173-d9de-41ae-aac1-8e511ba86264',
        'support for the ri theme'
      );
    case QUESTION_IDS[60]:
      return t('32a82904-732a-4554-a604-8e70798149c6', 'Public speaking');
    case QUESTION_IDS[61]:
      return t(
        'a249f3f8-e856-45fc-83c1-908cffa77e93',
        '1st time conference attendees'
      );
    case QUESTION_IDS[62]:
      return t(
        '88857d36-4d5b-4e91-b969-9375deae8136',
        'promoting rotary service'
      );
    case QUESTION_IDS[63]:
      return t('eb593284-ba44-421b-abed-96b072038db8', 'Breakout Sessions');
    case QUESTION_IDS[64]:
      return t(
        '2e7b8322-5af8-4fc8-9103-96e9aced6029',
        'If you rated the conference overall as weak, please share your thought on how this conference could have been improved'
      );
    case QUESTION_IDS[65]:
      return t(
        '1842131b-be75-4ed5-af32-97cba7a990ad',
        'attendance at all plenary sessions and events'
      );
    case QUESTION_IDS[66]:
      return t(
        '1eb648ab-c9a8-45eb-a43d-9847d905bd3c',
        ' If you rated any of the above areas as Weak (4), please share your thoughts on how this element could have been improved'
      );
    case QUESTION_IDS[67]:
      return t('f351c562-7b0b-438a-853a-99ac13fc6f12', 'new rotarians');
    case QUESTION_IDS[68]:
      return t(
        'df164b51-5809-4682-a3ad-9f47ae150537',
        'Overall conference effectiveness'
      );
    case QUESTION_IDS[69]:
      return t(
        '245ef428-6cbb-49e7-ae1e-9f499cb887eb',
        'Breakout session presentation'
      );
    case QUESTION_IDS[70]:
      return t(
        'ea4e36d8-4ed2-4d5f-8208-9f9b9d1412e0',
        'sincere interest in advancing Rotary, its programs and growth'
      );
    case QUESTION_IDS[71]:
      return t(
        'a576d145-c8c1-436a-a652-a2d74f15afb7',
        'did the conference showcase successful district activities'
      );
    case QUESTION_IDS[72]:
      return t(
        '96c5189e-1538-4871-875a-a4c1a755c337',
        'Competence to serve above district level '
      );
    case QUESTION_IDS[73]:
      return t(
        '8b8ae811-5224-4fa2-acc9-a702a3cd8bc2',
        'were you given the opportunity to visit a local Rotary club in conjunction with your district conference assignment'
      );
    case QUESTION_IDS[74]:
      return t(
        '42b7fd11-223f-4951-a859-a95144f5739c',
        'If this representative did not meet expectations, please elaborate'
      );
    case QUESTION_IDS[75]:
      return t(
        '1f92f708-1601-4b03-b9b7-a9923e462670',
        'representation of the president'
      );
    case QUESTION_IDS[76]:
      return t(
        'f8c6695e-fcb6-40f8-9219-a9f8493d7b78',
        'what percent of the programming was offered in discussion groups'
      );
    case QUESTION_IDS[77]:
      return t('ee073909-464b-400b-aa13-ae4de6fba938', 'DG year');
    case QUESTION_IDS[78]:
      return t('c755605b-7046-4850-acb4-aeccef9b3ded', 'name');
    case QUESTION_IDS[79]:
      return t(
        'e6ae84e8-d12a-4fb6-8bb9-b357f4f0e367',
        'How long was the District Conference, in days'
      );
    case QUESTION_IDS[80]:
      return t('2c1bdfcc-fa45-40dd-872f-b506bf397773', 'Physical Energy');
    case QUESTION_IDS[81]:
      return t('c7e28e70-c8c9-4008-85c5-b60e388607a8', 'Fundraising Events');
    case QUESTION_IDS[82]:
      return t(
        '9399a534-83eb-40d6-bb14-b6ca9350187e',
        'Providing fellowship opportunities'
      );
    case QUESTION_IDS[83]:
      return t(
        'b8cf6c15-f1dd-4ded-9043-b6d812d6f86a',
        'TRF program participants or alumni'
      );
    case QUESTION_IDS[84]:
      return t(
        '5fdecce1-16b9-4f53-80ca-b750f9cc7abf',
        'Events/programming for TRF alumni/scholars'
      );
    case QUESTION_IDS[85]:
      return t(
        '130f22d9-fdb2-4da0-bf79-b775b6ce33de',
        'Ability of the representative to effectively engage attendees during their addresses to the conference'
      );
    case QUESTION_IDS[86]:
      return t(
        '1dc684f6-f7d6-4dd0-8f6d-b8bc4df60acb',
        'Facilitating Discussion'
      );
    case QUESTION_IDS[87]:
      return t(
        '56706ae1-d6f2-4565-9eb3-b97641793572',
        'Sincere interest in advancing Rotary, its programs and growth'
      );
    case QUESTION_IDS[88]:
      return t(
        '97c83a82-91be-4c21-a810-bd29d1f3ca92',
        'Did you have a spouse or partner accompany you on your president’s representative assignment'
      );
    case QUESTION_IDS[89]:
      return t('f57b69f6-be05-4ab4-84de-be472f90a053', 'information');
    case QUESTION_IDS[90]:
      return t(
        'ce5452be-cce4-4ed0-8f66-bf87da381abe',
        'If given the opportunity to make two addresses, did this include a major address of 20 – 25 minutes in a fully seated plenary session?'
      );
    case QUESTION_IDS[91]:
      return t(
        '2cb42cff-a4ba-4504-be2a-c46aa993f144',
        'If you rated any of the above weak, please share your thoughts on how this element could have been improved'
      );
    case QUESTION_IDS[92]:
      return t(
        'a9960c36-d615-40b9-a45f-c7cca68bfde3',
        'would you recommend this representative for future District Conference assignments'
      );
    case QUESTION_IDS[93]:
      return t(
        '97b09e3b-9a87-46a3-94fa-c938c7d2b0cd',
        'were you given the opportunity to present concluding remarks of appreciation to the host district at the conclusion of the conference'
      );
    case QUESTION_IDS[94]:
      return t('fbaf6d4b-fc89-477f-8d37-ca2b762748f8', 'DG year');
    case QUESTION_IDS[95]:
      return t(
        '3092fdd4-5a79-426d-9ade-ca640095f579',
        'was there active promotion of the next conference'
      );
    case QUESTION_IDS[96]:
      return t('49f4464c-aa4c-4db2-9510-cada88fc52cf', 'if no');
    case QUESTION_IDS[97]:
      return t('d5aae8d2-3ef1-4206-989f-cbd8da9d0df3', 'Service Activities');
    case QUESTION_IDS[98]:
      return t(
        '29476a74-872c-4efc-b625-cd27d9fd271e',
        'Ability to speak the host language'
      );
    case QUESTION_IDS[99]:
      return t(
        '81988ced-6162-43f5-8892-cefde9ebac36',
        'On average, how long was the conference program, per day, including plenary and breakout sessions, meal events, and any other organized activities? (in hours)'
      );
    case QUESTION_IDS[100]:
      return t(
        '63ed79bc-3548-4b46-9106-d0123965679f',
        'How long was the District Conference, in days'
      );
    case QUESTION_IDS[101]:
      return t('d63b318d-6682-4fbb-93a2-d2b82e043218', 'DG year');
    case QUESTION_IDS[102]:
      return t(
        '94e0f9a0-0f12-4484-9dd6-d7ad6770a906',
        'If the conference offered plenary session, what percent of the programming was offered in general plenary sessions?'
      );
    case QUESTION_IDS[103]:
      return t(
        'c42f6476-204d-49d4-92a7-dab5bc0ba14d',
        'Were you given the opportunity to attend a local Rotary club meeting in conjunction with your district conference assignment'
      );
    case QUESTION_IDS[104]:
      return t('800ad6ce-4b6e-4c74-b225-dc58c4e2bb3c', 'RYLA participants');
    case QUESTION_IDS[105]:
      return t('0e359331-e186-40f9-8950-dd2c1232b7a1', 'If no, why not');
    case QUESTION_IDS[106]:
      return t('37f83fee-376e-456d-b246-dd37d5eddc1c', 'Fundraising Events');
    case QUESTION_IDS[107]:
      return t(
        'fea0074d-eedc-40be-a5c6-dd50118fc031',
        'content in speaking opportunities'
      );
    case QUESTION_IDS[108]:
      return t(
        '361b5630-24b9-4f49-a959-dfd354452eb0',
        'Please rate this conference overall'
      );
    case QUESTION_IDS[109]:
      return t(
        '929cd8f4-f4bc-4f4b-b38d-e01c074f950c',
        'how long was your second address'
      );
    case QUESTION_IDS[110]:
      return t(
        'bb7830da-3a1b-4316-b453-e03129d18825',
        'Serving as presiding officer '
      );
    case QUESTION_IDS[111]:
      return t('14593d64-6c0f-45aa-99eb-e0c24b7b96ab', 'Panel presentation');
    case QUESTION_IDS[112]:
      return t(
        '1bdbbffe-e433-4d74-afc7-e4d19474800e',
        'If you rated any of the aboveas weak, please elaborate'
      );
    case QUESTION_IDS[113]:
      return t('b5f3be51-429a-42b9-92a4-e6cb2e5a7763', 'growth ');
    case QUESTION_IDS[114]:
      return t('576a6b4d-27be-4b1f-bada-e7c996e31bd6', 'Personality');
    case QUESTION_IDS[115]:
      return t(
        '5aa9c8e3-093b-49d8-8e61-eb8e137f11f4',
        'did the conference offer discussion group/break out sessions'
      );
    case QUESTION_IDS[116]:
      return t('cc6b483c-d2d7-4091-9fc8-ec585c67bce3', 'fellowship');
    case QUESTION_IDS[117]:
      return t(
        '5f2812f0-7afd-4374-ba19-ed4c2e12cf06',
        'Was the non-Rotarian community involved in the conference'
      );
    case QUESTION_IDS[118]:
      return t('5be0e61f-e75d-4e36-b395-f0f96a979472', 'motivation');
    case QUESTION_IDS[119]:
      return t(
        '7e3ee8ee-cd26-47ac-a15c-f6e5ed14560b',
        'How long was the conference program, including plenary and break out sessions, meal events and any other organized activities'
      );
    case QUESTION_IDS[120]:
      return t('a00f50b2-f1d3-40c5-bfa0-f72148a6861e', 'Plenary Address');
    case QUESTION_IDS[121]:
      return t(
        'db71c90d-1167-4883-bf07-f9ed5cf1dcc7',
        'were you given the opportunity to visit a local Rotary club service project in conjunction with your district conference assignment'
      );
    case QUESTION_IDS[122]:
      return t('0578183a-a80b-4f0b-a171-fb15a17958cc', 'DG year');
    default:
      return option;
  }
};

export const getTranslatedSection = (
  t: TFunction,
  sectionId: string,
  sectionName: string
): string => {
  switch (sectionId) {
    case SECTION_IDS[0]:
      return t(
        'c114fee7-1f8a-4906-921f-963cac0ec7af',
        'What program elements were included in the conference? Select all that apply'
      );
    case SECTION_IDS[1]:
      return t(
        'a01bb60b-41c1-41d5-9aa4-3d8ae6405ede',
        'Were you given the opportunity to address the conference? (If no, skip to question 14)'
      );
    case SECTION_IDS[2]:
      return t(
        '19af6014-58d4-4298-9764-ff70dffc2a5d',
        'Rate the performance of the President’s Representative'
      );
    case SECTION_IDS[3]:
      return t('afaec596-cf10-4515-b1ec-17f6909f062d', 'General');
    case SECTION_IDS[4]:
      return t(
        'custom-question-19',
        '19. Please indicate if the program included a specific welcome to or recognition of the following:'
      );
    case SECTION_IDS[5]:
      return t(
        'custom-question-20',
        '20. Please indicate whether the conference did the following:'
      );
    case SECTION_IDS[6]:
      return t('045e5b31-f867-4d64-8e35-1f4ef9282983', 'Conference Evaluation');
    default:
      return sectionName;
  }
};

export const getTranslateSurveyAnswer = (
  t: TFunction,
  value: string
): string => {
  switch (value) {
    case Answers.EXCELLENT:
      return t('excellent', 'Excellent');
    case Answers.GOOD:
      return t('good', 'Good');
    case Answers.FAIR:
      return t('fair', 'Fair');
    case Answers.WEAK:
      return t('weak', 'Weak');
    default:
      return value;
  }
};

export const getTranslateTrueFalseAnswer = (
  t: TFunction,
  value: string
): string => {
  switch (value) {
    case Answers.TRUE:
      return t('feedback.true', 'True');
    case Answers.FALSE:
      return t('feedback.false', 'False');
    default:
      return value;
  }
};

export const getTranslateYesNoAnswer = (
  t: TFunction,
  value: string
): string => {
  switch (value) {
    case Answers.YES:
      return t('feedback.yes', 'Yes');
    case Answers.NO:
      return t('feedback.no', 'No');
    default:
      return value;
  }
};

export const getTranslateExpectationsAnswer = (
  t: TFunction,
  value: string
): string => {
  switch (value) {
    case Answers.EXCEEDED_EXPECTATIONS:
      return t('feedback.exceeded-expectations', 'Exceeded expectations');
    case Answers.MET_EXPECTATIONS:
      return t('feedback.met-expectations', 'Met expectations');
    case Answers.DID_NOT_MET_EXPECTATIONS:
      return t(
        'feedback.did-not-met-expectations',
        'Did not meet expectations'
      );
    default:
      return value;
  }
};

export const getTranslateUniqueAnswer = (
  t: TFunction,
  answerID: string,
  value: string
): string => {
  switch (answerID) {
    case `${QUESTION_IDS[33]}-0`:
      return t('675c028a-8594-410c-bb03-552a03afa14f-0', '0 to 3 hours');
    case `${QUESTION_IDS[33]}-1`:
      return t('675c028a-8594-410c-bb03-552a03afa14f-1', '3 to 6 hours');
    case `${QUESTION_IDS[33]}-2`:
      return t('675c028a-8594-410c-bb03-552a03afa14f-2', '6 to 9 hours');
    case `${QUESTION_IDS[33]}-3`:
      return t('675c028a-8594-410c-bb03-552a03afa14f-3', 'More Than 9 hours');
    case `${QUESTION_IDS[34]}-0`:
      return t('97b02f12-4503-4de4-a88c-563d49f8ed77-0', 'Half Day');
    case `${QUESTION_IDS[34]}-1`:
      return t('97b02f12-4503-4de4-a88c-563d49f8ed77-1', '1 Day');
    case `${QUESTION_IDS[34]}-2`:
      return t('97b02f12-4503-4de4-a88c-563d49f8ed77-2', 'More Than 1');
    case `${QUESTION_IDS[56]}-6`:
      return t('e24cb5af-84a1-4938-866c-89e06e86535a-6', 'above 500');
    case `${QUESTION_IDS[109]}-0`:
      return t('929cd8f4-f4bc-4f4b-b38d-e01c074f950c-0', '0-5 minutes');
    case `${QUESTION_IDS[109]}-1`:
      return t('929cd8f4-f4bc-4f4b-b38d-e01c074f950c-1', '5-10 minutes');
    case `${QUESTION_IDS[109]}-2`:
      return t('929cd8f4-f4bc-4f4b-b38d-e01c074f950c-2', '10-15 minutes');
    case `${QUESTION_IDS[109]}-3`:
      return t('929cd8f4-f4bc-4f4b-b38d-e01c074f950c-3', '15-20 minutes');
    case `${QUESTION_IDS[109]}-4`:
      return t(
        '929cd8f4-f4bc-4f4b-b38d-e01c074f950c-4',
        'more than 20 minutes'
      );
    case `${QUESTION_IDS[119]}-0`:
      return t('7e3ee8ee-cd26-47ac-a15c-f6e5ed14560b-0', '0 to 8 hours');
    case `${QUESTION_IDS[119]}-1`:
      return t('7e3ee8ee-cd26-47ac-a15c-f6e5ed14560b-1', '8 to 16 hours');
    case `${QUESTION_IDS[119]}-2`:
      return t('7e3ee8ee-cd26-47ac-a15c-f6e5ed14560b-2', '16 to 24 hours');
    case `${QUESTION_IDS[119]}-3`:
      return t('7e3ee8ee-cd26-47ac-a15c-f6e5ed14560b-3', 'More Than 24 hours');
    default:
      return value;
  }
};

export const getTranslateAnswer = (
  t: TFunction,
  answerID: string,
  optionValue: string,
  value: string
): string => {
  switch (optionValue) {
    case SURVEY_OPTIONS.FORMATTED:
      return getTranslateSurveyAnswer(t, value);
    case SURVEY_OPTIONS.YES_NO:
      return getTranslateYesNoAnswer(t, value);
    case SURVEY_OPTIONS.TRUE_FALSE:
      return getTranslateTrueFalseAnswer(t, value);
    case SURVEY_OPTIONS.EXPECTATIONS:
      return getTranslateExpectationsAnswer(t, value);
    default:
      return getTranslateUniqueAnswer(t, answerID, value);
  }
};

export const getDGListingOptionLabels = (t: TFunction) => ({
  name: t('feedback-form.custom-question.governors-name', 'Name'),
  yearsServed: t(
    'feedback-form.custom-question.governors-year-served',
    'Year served as Governor'
  ),
});

export const isFeedbackWithCustomQuestions = (
  sections: FeedbackQuestionsResponse[]
) =>
  Boolean(
    sections.find(({ id }) =>
      [CUSTOM_QUESTION_19_ID, CUSTOM_QUESTION_20_ID].includes(id)
    )
  );

export const isAllQuestionsCheckboxes = (questions: Question[]) =>
  questions.every(
    q => q.optionType.optionType === ControlOperationType.CHECK_BOX
  );
