// Libs
import React, { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from '@external/react-i18next';
import classNames from 'classnames';

// Types
import { MenuItem } from './types';
import { TFunction } from 'i18next';

// Constants
import { UNASSIGNED } from '../constants/groupnames';

interface Props {
  menuItemId: string;
  menuItemTitle: string;
  currentItemId: string;
  handleMenuItemClick: (e: SyntheticEvent, menuItem: MenuItem) => void;
  dataTestId?: string;
}

const ContextMenuItem: React.FC<Props> = ({
  menuItemId,
  menuItemTitle,
  currentItemId,
  handleMenuItemClick,
  dataTestId = '',
}): JSX.Element => {
  const { t } = useTranslation();

  const isActiveItem = (currentItemId: string, itemId: string): boolean =>
    currentItemId === itemId;

  const activeItemClass = (currentItemId: string, itemId: string): string =>
    isActiveItem(currentItemId, itemId) ? 'font-bold' : '';

  const activeItemText = (
    currentItemId: string,
    itemId: string,
    t: TFunction
  ): string =>
    isActiveItem(currentItemId, itemId)
      ? t('club-assignments.current-item', '(Current)')
      : '';

  const menuItemClick = useCallback(
    (e: SyntheticEvent): void => {
      const menuItem: MenuItem = {
        menuItemId,
        menuItemTitle,
      };
      handleMenuItemClick(e, menuItem);
    },
    [handleMenuItemClick, menuItemId, menuItemTitle]
  );

  return (
    <div
      data-testid={dataTestId}
      key={menuItemId}
      className={classNames(
        `p-5
        leading-loose border-0
        block
        hover:bg-dark-blue-400
        hover:text-white
        focus:bg-dark-blue-400
        focus:text-white
        focus:font-bold
        ${activeItemClass(currentItemId, menuItemId)}
      `
      )}
      onClick={menuItemClick}
    >
      {menuItemTitle.includes(UNASSIGNED)
        ? t('manage-club-assignments.move-to-unassigned', 'Move to Unassigned')
        : menuItemTitle}{' '}
      {activeItemText(currentItemId, menuItemId, t)}
    </div>
  );
};

export default ContextMenuItem;
