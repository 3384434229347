import { TFunction } from 'i18next';
import * as Yup from 'yup';

import addressValidationSchema from '@components/Formik/Address/validation';
import dateOfBirthSchema from '@components/Formik/DateOfBirth/validation';
import genderSchema from '@components/Formik/GenderInputs/validation';
import personalNameSchema from '@components/Formik/PersonalNameForm/validation';
import {
  emailRequiredSchema,
  phoneNumberRequiredSchema,
} from '@components/Formik/validation/fieldDefinitions';
import { isRequired } from '@components/Formik/validation/schemaDefinitions';

const IndividualDetailsValidationSchema = (t: TFunction) =>
  Yup.object({
    email: emailRequiredSchema(t),
    primaryAddress: addressValidationSchema(t),
    primaryPhone: Yup.object().shape(
      {
        country: Yup.string().when(
          'number',
          (number: string, schema: Yup.StringSchema<string>) => {
            if (number) {
              return isRequired(t, schema);
            }

            return schema;
          }
        ),
        number: phoneNumberRequiredSchema(t),
      },
      [['country', 'number']]
    ),
    primaryLanguage: Yup.string().required(
      t('form.field.error_required', 'Required')
    ),
  })
    .concat(personalNameSchema(t, true))
    .concat(dateOfBirthSchema(t))
    .concat(genderSchema);

export default IndividualDetailsValidationSchema;
