// Libs
import React from 'react';

// Types
import { Group, Item, LocationResult } from '../types';

// Components
import Quote from '../Quote';

interface Props {
  quotes: Item[];
  groupsData: Group[];
  currentGroupId: string;
  assignItem: (
    locationResult: LocationResult,
    assignedItem: Item,
    group: Group
  ) => void;
}

const QuoteList: React.FC<Props> = props => {
  const { quotes, groupsData, currentGroupId, assignItem } = props;
  return (
    <>
      {quotes.map((quote: Item, index: number) => {
        const { id, content, data } = quote;
        return (
          <Quote
            data={data}
            groupsData={groupsData}
            key={id}
            id={id}
            index={index}
            assignItem={assignItem}
            currentGroupId={currentGroupId}
          >
            {content}
          </Quote>
        );
      })}
    </>
  );
};

export default React.memo(QuoteList);
