import React from 'react';

import DistrictConferenceSection from '../components/DistrictConferenceSection';
import ActionButtons from './ActionButtons';

import { getSplitConferences } from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';

import { GetConferencesResults } from '@typings/graphql';

interface Props {
  conferences: GetConferencesResults[];
}

const AssignmentItem: React.FC<Props> = ({ conferences }) => {
  const { t } = useTranslation();

  const { pastConferences, upcomingConferences } = getSplitConferences(
    conferences
  );

  return (
    <div className="mt-2">
      {upcomingConferences.length > 0 && (
        <DistrictConferenceSection
          conferences={upcomingConferences}
          title={t('conferences.upcoming-title', 'Upcoming Conferences')}
          isUpcomingConference
          isPresRepConferencesHeader
          renderActionsButton={ActionButtons}
          renderActionsButtonMobile={ActionButtons}
          alignActionsRight
        />
      )}
      <DistrictConferenceSection
        conferences={pastConferences}
        title={t('conferences.past-title', 'Past Conferences')}
        isPresRepConferencesHeader
        renderActionsButton={ActionButtons}
        renderActionsButtonMobile={ActionButtons}
        alignActionsRight
      />
    </div>
  );
};

export default AssignmentItem;
