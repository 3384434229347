// Libs
import React from 'react';
import moment from 'moment';

// Utils
import { useTranslation } from '@external/react-i18next';
import { localizeDayMonth } from '@use-cases/districts';

const AsOfDate: React.FC<{ date: string }> = ({ date }) => {
  const { t } = useTranslation();
  const year = moment(date).year();

  return (
    <div className="text-xs text-gray-600 mb-3">
      {t('dashboards.asofDate', 'Data as of {{date}}', {
        date: `${localizeDayMonth(date)} ${year}`,
      })}
    </div>
  );
};

export default AsOfDate;
