// Libs
import React from 'react';

interface Props {
  subTitle: string;
}

const MobileHeaderSurvey: React.FC<Props> = ({ subTitle }) => {
  return (
    <div className="tablet:hidden mt-2">
      <div className="leading-4 text-sm">{subTitle}</div>
    </div>
  );
};

export default MobileHeaderSurvey;
