import React from 'react';

import classNames from 'classnames';

import { localizeFullDate } from '@use-cases/districts';

interface Props {
  startDate?: string | null;
  endDate?: string | null;
  classess?: string;
}

const ConferenceDurationSection: React.FC<Props> = ({
  startDate,
  endDate,
  classess = '',
}) => {
  if (startDate || endDate) {
    return (
      <div className={classNames(classess)}>
        {startDate && <span>{localizeFullDate(startDate)} - </span>}
        {endDate && <span>{localizeFullDate(endDate)}</span>}
      </div>
    );
  }
  return null;
};

export default ConferenceDurationSection;
