import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { isEqual } from 'lodash';

import { Option } from '@components/Formik/MultiSelectFilter/types';
import Loading from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { titleTemplateParams } from '@components/Title/util';

import ClubsList from './ClubsList';
import SearchClubsForm from './SearchClubsForm';

import { CLUB_FILTERS, ClubFilter, DistrictAG } from '@domain/districts';

import {
  getClubTypesOptions,
  getDefaultFilters,
  getValuesFromOptions,
} from '@use-cases/districts';

import {
  useFetchAllDistrictAGs as useFetchAGs,
  useFetchDistrictClubs as useFetchClubs,
  useSearchClubsByDistrictId as useSearch,
} from '@repositories/districts';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

import { DistrictClub } from '@typings/operations';

import { FilterSearchFormValues as SearchValues } from './SearchClubsForm/types';

interface Props extends RouteComponentProps {
  districtId: string;
  riDistrictId?: number | null;
}

const PAGE_SIZE = 10;
const INITIAL_FILTERS = {
  clubTypes: [],
};
const INITIAL_SEARCH = {
  clubName: '',
  clubLocation: '',
};

const DistrictClubs: React.FC<Props> = ({ districtId, riDistrictId }) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const { user } = useAppConfig();

  const [fetchClubs, { data, loading: isLoadingClubs }] = useFetchClubs();
  const [fetchAGs, { data: AGs, loading: isLoadingAGs }] = useFetchAGs();
  const [searchClubs, { data: foundClubs, loading: isSearching }] = useSearch();

  const multiSelectOptions = {
    clubTypes: getClubTypesOptions(t),
  };

  // Set predefined filter value to default object
  const defaultFilters = getDefaultFilters(
    INITIAL_FILTERS,
    multiSelectOptions.clubTypes
  ) as ClubFilter;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<ClubFilter>(defaultFilters);
  const [search, setSearch] = useState<SearchValues>(INITIAL_SEARCH);

  const isSearchActive = Boolean(
    search.clubName || search.clubLocation || filter.clubTypes.length
  );

  // Fetch Clubs, initial request used without filtering
  useEffect(() => {
    if (user?.isLoggedIn && districtId && !isSearchActive) {
      fetchClubs({
        variables: {
          districtId,
          status: ['Active', 'Suspended'],
          pageSize: PAGE_SIZE,
          page: currentPage,
        },
      });
    }
  }, [fetchClubs, districtId, user?.isLoggedIn, currentPage, isSearchActive]);

  // Fetch Assistant Governors
  // Values used in the filter by AG
  useEffect(() => {
    if (user?.isLoggedIn && districtId) {
      fetchAGs({
        variables: {
          districtId,
        },
      });
    }
  }, [fetchAGs, districtId, user?.isLoggedIn, currentPage]);

  // Fetch clubs according to selected filters or search query
  useEffect(() => {
    const formattedFilter = {
      clubTypes: getValuesFromOptions(filter.clubTypes),
    };

    if (riDistrictId && isSearchActive) {
      searchClubs({
        variables: {
          riDistrictId,
          pageSize: PAGE_SIZE,
          status: 'Active,Suspended',
          page: currentPage,
          ...search,
          ...formattedFilter,
        },
      });
    }
  }, [currentPage, riDistrictId, searchClubs, search, filter, isSearchActive]);

  if (isLoadingClubs || isLoadingAGs) {
    return <Loading />;
  }

  const handleSelectPage = (event: React.SyntheticEvent, page: number) => {
    event.preventDefault();

    setCurrentPage(page);
  };

  const filteredList = isSearchActive
    ? (foundClubs?.districtClubs.clubs as DistrictClub[]) || []
    : data?.districtClubs?.clubs || [];

  const filteredCount = isSearchActive
    ? foundClubs?.districtClubs.totalCount || 0
    : data?.districtClubs?.totalCount || 0;

  const isPaginationActive = filteredList.length > 0 && !isSearching;

  const applyFilter = (values: Option[], name: CLUB_FILTERS) => {
    const updatedFilters = {
      ...filter,
      [name]: values,
    };

    if (!isEqual(filter, updatedFilters)) {
      setCurrentPage(1);
      setFilter({
        ...filter,
        [name]: values,
      });
    }
  };

  const resetFilter = (filterName?: string): void => {
    setCurrentPage(1);

    if (
      filterName &&
      Object.values(CLUB_FILTERS).includes(filterName as CLUB_FILTERS)
    ) {
      setFilter({ ...filter, [filterName]: [] });
    } else {
      setFilter(INITIAL_FILTERS);
    }
  };

  const handleClubSearch = (values: SearchValues) => {
    setCurrentPage(1);
    setSearch(values);
    resetFilter();
  };

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-clubs',
          '{{prefix}} District clubs {{suffix}}',
          { prefix, suffix }
        )}
      />
      <SearchClubsForm
        loading={isSearching}
        onSubmit={handleClubSearch}
        initialValues={{ ...search }}
      />
      <ClubsList
        clubsList={filteredList}
        totalCount={filteredCount}
        isLoading={isSearching}
        districtAGs={AGs?.ags as DistrictAG[]}
        filterProps={{
          initialFilterValues: { ...filter },
          multiSelectOptions: { ...multiSelectOptions },
          resetFilter,
          applyFilter,
        }}
        showFiltersInContextMenu
        contextMenuTitle={t('district.filters.filter-clubs', 'Filter Clubs')}
      />
      {isPaginationActive && (
        <Pagination
          pageSize={PAGE_SIZE}
          page={currentPage}
          totalCount={filteredCount}
          pageHandler={handleSelectPage}
        />
      )}
    </>
  );
};

export default DistrictClubs;
