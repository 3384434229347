import React, { useContext, useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '@components/Loading';
import { titleTemplateParams } from '@components/Title/util';

import DistrictOfficerRolesList from './DistrictOfficerRolesList';
import DistrictOfficersHeader, {
  LocationStateType,
} from './DistrictOfficersHeader';

import { CommitteeAppointment, DORoles, Role } from '@domain/districts';

import {
  buildOfficersQueryVars,
  buildRolesQueryVars,
  DistrictContext,
  extractOfficersData,
  get3yrTermRoles,
  getAssignedFuture3yrTermRoleIds,
  getDORoles,
  getFormattedRoles,
} from '@use-cases/districts';
import { useSetContextManagedRYs } from '@use-cases/districts/hooks';

import { useFetchAllDistrictOfficers } from '@repositories/districts';

import { getRotaryYear, isCurrentRY } from '@utils/datetime';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useFetchRoles } from '@hooks/useFetchRoles';

import { DistrictOfficer } from '@typings/graphql';

interface Props extends RouteComponentProps {
  districtId: string;
  riDistrictId?: number | null;
}

const DistrictOfficers: React.FC<Props> = ({
  districtId,
  riDistrictId,
  location,
}) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const [
    {
      committeeManager: { isManager },
      selectedInfo: { term },
    },
    setContext,
  ] = useContext(DistrictContext);
  const [roles, setRoles] = useState<Role[]>([]);
  const [officers, setOfficers] = useState<DistrictOfficer[]>([]);
  const [districtGovernors, setDistrictGovernors] = useState<DORoles>([]);
  const [committeeAppointments, setCommitteeAppointments] = useState<DORoles>(
    []
  );

  const [
    getLeadershipRoles,
    { data: rolesData, loading: rolesLoading },
  ] = useFetchRoles();

  const [
    getAllDistrictOfficers,
    { data: officersData, loading: officersLoading },
  ] = useFetchAllDistrictOfficers();

  const [
    getFuture3yrTermDistrictOfficers,
    { data: future3yrTermOfficersData, loading: future3yrTermOfficersLoading },
  ] = useFetchAllDistrictOfficers();

  useSetContextManagedRYs(districtId, riDistrictId);

  useEffect(() => {
    if (term && riDistrictId) {
      getLeadershipRoles({
        variables: buildRolesQueryVars(Number(term.endDate) - 1),
      });
      getAllDistrictOfficers({
        variables: buildOfficersQueryVars(
          districtId,
          riDistrictId,
          Number(term.endDate),
          true
        ),
      });
    }
  }, [districtId, term, riDistrictId]);

  useEffect(() => {
    setRoles(getFormattedRoles(rolesData));
  }, [rolesData]);

  useEffect(() => {
    setOfficers(extractOfficersData(officersData));
  }, [officersData]);

  useEffect(() => {
    if (term) {
      const isPastTermSelected = term.endDate < getRotaryYear();

      const [governors, nonGovernors] = getDORoles(
        !isPastTermSelected && isManager.currentTerm,
        {
          roles,
          officers,
          year: term.endDate,
        }
      );

      setDistrictGovernors(governors);
      setCommitteeAppointments(nonGovernors);
    }
  }, [isManager, roles, officers, term]);

  useEffect(() => {
    const threeYrTermRoles = get3yrTermRoles(roles);

    if (
      threeYrTermRoles.length &&
      riDistrictId &&
      term &&
      isCurrentRY(term.endDate) &&
      isManager.currentTerm
    ) {
      getFuture3yrTermDistrictOfficers({
        variables: buildOfficersQueryVars(
          districtId,
          riDistrictId,
          Number(term.endDate) + 1,
          true,
          threeYrTermRoles
        ),
      });
    }
  }, [roles]);

  if (
    rolesLoading ||
    officersLoading ||
    future3yrTermOfficersLoading ||
    term === null
  ) {
    return <Loading />;
  }

  const handleSelectYear = (rotaryYear: string) => {
    const year = parseInt(rotaryYear, 10);

    setContext((prevState: CommitteeAppointment) => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        term: { startDate: String(year - 1), endDate: rotaryYear },
      },
    }));
  };

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-officers',
          '{{prefix}}District officers {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="mt-6">
        <DistrictOfficersHeader
          districtId={districtId}
          selectedYear={term.endDate}
          handleYearChanged={handleSelectYear}
          locationState={location?.state as LocationStateType}
        />
        <div className="-mx-8 tablet:mx-0">
          <DistrictOfficerRolesList
            title={t(
              'district-officers.district-governors',
              'District Governors'
            )}
            roles={districtGovernors}
          />
          <DistrictOfficerRolesList
            title={t(
              'district-officers.committee-appointments',
              'Committee Appointments'
            )}
            notice={t(
              'district-officers.committee-appointments.required-roles-notice',
              '* Represents the Role that is required by Policy'
            )}
            roles={committeeAppointments}
            assignedFuture3yrTermRoleIds={getAssignedFuture3yrTermRoleIds(
              future3yrTermOfficersData,
              term
            )}
          />
        </div>
      </div>
    </>
  );
};

export default DistrictOfficers;
