import React, { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '@components/Loading';
import NotFound from '@components/Routing/NotFound';
import ConferenceForm from '@presenters/web/components/ConferenceForm';

import { CRUDAction, CRUDResource } from '@domain/abilities';
import { CREATE_CONFERENCE_INITIAL_VALUES } from '@domain/districts/conferences/constants';
import {
  getInitialConferenceFields,
  mapFormResultToCreateConferenceInput,
} from '@domain/districts/mappers';
import { CreateConferenceFormResult } from '@domain/districts/types';

import { findDefaultTimeZone } from '@use-cases/districts';
import {
  getConferencePage,
  getFutureManagementYear,
  getIsManagerByYear,
} from '@use-cases/districts/conferences';
import { normalizeCountries } from '@use-cases/districts/normalizers/normalizeCountries';
import { useNotifications } from '@use-cases/notifications';

import { useDISStatesLazy } from '@repositories/disCountry';
import {
  useFetchConferenceDetails,
  useUpdateConference,
} from '@repositories/districts/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import {
  CountriesQuery,
  GetAccessLevelsQuery,
  GetTimeZonesQuery,
} from '@typings/operations';

export interface Props {
  districtId: string;
  conferenceId: string;
  initialValues?: CreateConferenceFormResult;
  accessLevelData?: GetAccessLevelsQuery;
  canEditConferenceFn:
    | ((
        action: CRUDAction,
        resource: CRUDResource,
        id?: string | undefined
      ) => boolean)
    | (() => undefined);
  countriesData?: CountriesQuery;
  countriesLoading: boolean;
  timeZones?: GetTimeZonesQuery;
  timeZonesLoading: boolean;
  isIndividualLoading: boolean;
}

const DistrictEditConference: React.FC<RouteComponentProps & Props> = ({
  districtId,
  conferenceId,
  initialValues,
  accessLevelData,
  canEditConferenceFn,
  countriesData,
  countriesLoading,
  timeZones,
  timeZonesLoading,
  isIndividualLoading,
}) => {
  const { addSuccess, addError } = useNotifications();
  const { t } = useTranslation();

  const [
    fetchCountryStates,
    { loading: loadingStates, data: countryStatesData },
  ] = useDISStatesLazy();

  const [
    fetchPRCountryStates,
    { loading: loadingPRStates, data: countryPRStatesData },
  ] = useDISStatesLazy();

  const [
    updateConference,
    { loading: loadingUpdateConference },
  ] = useUpdateConference();

  const [fetchConference, { data, loading }] = useFetchConferenceDetails();

  useEffect(() => {
    fetchConference({
      variables: {
        conferenceId,
      },
    });
  }, []);

  const formattedCountries = normalizeCountries(countriesData);
  const currentConference = data?.conference;

  const canEditConference = canEditConferenceFn(
    CRUDAction.Update,
    CRUDResource.Conference,
    conferenceId
  );

  if (!isIndividualLoading && !canEditConference) {
    return <NotFound default />;
  }

  if (
    loading ||
    loadingUpdateConference ||
    timeZonesLoading ||
    isIndividualLoading
  ) {
    return <Loading />;
  }

  const is2023YManager = getIsManagerByYear(accessLevelData, 2023);

  const handleSubmit = (originalTimeZone: string) => async (
    formData: CreateConferenceFormResult
  ) => {
    const input = mapFormResultToCreateConferenceInput(
      formData,
      formattedCountries,
      timeZones?.getTimeZones,
      originalTimeZone
    );

    try {
      await updateConference({ variables: { conferenceId, input } });
      await fetchConference({
        variables: {
          conferenceId,
        },
      });
      addSuccess(t('update-conference.success', 'Success.'), {
        id: 'form.success',
      });
    } catch (e) {
      addError(
        t(
          'update-conference.error',
          'An error occurred while updating conference'
        )
      );
    } finally {
      localizedNavigate(getConferencePage(districtId));
    }
  };

  const handleBackRedirect = () => {
    localizedNavigate(getConferencePage(districtId));
  };

  const defaultTimeZone = findDefaultTimeZone(timeZones?.getTimeZones || []);

  const defaultValues = initialValues || {
    ...CREATE_CONFERENCE_INITIAL_VALUES,
    dateTime: {
      ...CREATE_CONFERENCE_INITIAL_VALUES.dateTime,
      timeZone: defaultTimeZone,
    },
  };

  const states = countryStatesData?.states || [];
  const prStates = countryPRStatesData?.states || [];

  const formValues = currentConference
    ? getInitialConferenceFields(
        currentConference,
        formattedCountries,
        defaultTimeZone,
        timeZones?.getTimeZones,
        !!states.length,
        !!prStates.length
      )
    : { ...defaultValues, districtId };

  const originalTimeZone = formValues.dateTime.timeZone || '';

  return (
    <ConferenceForm
      initialValues={formValues}
      countriesData={formattedCountries}
      countriesLoading={countriesLoading}
      loadingStates={loadingStates}
      countryStatesData={states}
      timeZones={timeZones?.getTimeZones || []}
      fetchCountryStates={fetchCountryStates}
      handleBackRedirect={handleBackRedirect}
      handleSubmit={handleSubmit(originalTimeZone)}
      backButtonPath={getConferencePage(districtId)}
      is2023YManager={is2023YManager}
      futureManagementYear={getFutureManagementYear(accessLevelData)}
      formTitle={t(
        'edit-district-conference.title',
        'Edit the District Conference'
      )}
      loadingPRStates={loadingPRStates}
      fetchPRCountryStates={fetchPRCountryStates}
      countryPRStatesData={prStates}
    />
  );
};

export default DistrictEditConference;
