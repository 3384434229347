// Libs
import React from 'react';

// Components
import ChartTitle from '../components/ChartTitle';
import ClubDevelopmentChart from './ClubDevelopmentChart';

// Utils
import { useTranslation } from '@external/react-i18next';
import { formatNumberWithSign, numberToLocale } from '@use-cases/districts';

// Types
import { ClubsDevelopmentData } from '@domain/districts';

type Props = {
  data: ClubsDevelopmentData;
  asOfDate: string;
};

const ClubDevelopment: React.FC<Props> = ({
  data: { chartData, totalCount, delta },
  asOfDate,
}) => {
  const { t } = useTranslation();
  const hasNoDelta = delta === 0;
  const deltaValue = formatNumberWithSign(delta);
  const totalValue = numberToLocale(totalCount);

  let deltaLabel = t(
    'dashboards.clubs.zero_delta',
    '<strong>{{totalValue}}</strong> Clubs currently. Equals to the Rotary Year Start',
    { totalValue }
  );

  if (totalCount === delta) {
    deltaLabel = t(
      'dashboards.clubs.not_compared',
      '<strong>{{totalValue}}</strong> Clubs currently.',
      {
        totalValue,
      }
    );
  } else if (!hasNoDelta) {
    deltaLabel = t(
      'dashboards.clubs.not_zero_delta',
      '<strong>{{totalValue}}</strong> Clubs currently. {{deltaValue}} compared with Rotary Year Start',
      {
        totalValue,
        deltaValue,
      }
    );
  }

  return (
    <div className="mb-7 desktop:mb-0 desktop:w-1/2 desktop:pl-5">
      <ChartTitle
        title={t('dashboards.title.club-development', 'Club Development')}
        deltaValue={delta}
        totalValue={totalCount}
        deltaLabel={deltaLabel}
        wrapperClassName="inline-flex flex-col desktop:mb-3"
        titleClassName="mb-2"
        asOfDate={asOfDate}
      />
      <ClubDevelopmentChart chartData={chartData} />
    </div>
  );
};

export default ClubDevelopment;
