import { useEffect } from 'react';

import { partial } from 'lodash';

import { getAbility, hasAnyGettable } from '../utils';

import { useFetchIndividualHATEOASLinks } from '@repositories/abilities';

type Nullable<T> = T | null;

export const useIndividualAbilities = (
  individualId?: Nullable<string>,
  conferenceMode?: string
) => {
  const [
    getIndividualHATEOASLink,
    { data, loading },
  ] = useFetchIndividualHATEOASLinks();

  useEffect(() => {
    if (
      individualId &&
      (conferenceMode === 'edit' ||
        conferenceMode === 'create' ||
        conferenceMode === 'conferences')
    ) {
      getIndividualHATEOASLink({ variables: { individualId } });
    }
  }, [individualId, conferenceMode]);

  return {
    can: data
      ? partial(getAbility, data.getIndividual.hateoasLinks)
      : () => undefined,
    hasAnyGettable: partial(hasAnyGettable, data?.getIndividual.hateoasLinks),
    isLoading: loading,
  };
};
