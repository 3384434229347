// Libs
import React from 'react';

// Components
import { Icon } from '@components/Icon';

// Types
import { TimeZoneOption } from '@domain/districts';

type Props = {
  selectedValue?: TimeZoneOption;
  toggleHandler: () => void;
};

const TimeZoneInput: React.FC<Props> = ({ selectedValue, toggleHandler }) => (
  <>
    <div
      className={
        'relative w-full min-h-12 border rounded-sm py-0625 px-4 flex items-baseline justify-between tap-highlight-color-transparent border-gray-300'
      }
      data-testid="tz-dropdown"
      onClick={toggleHandler}
    >
      <div className="flex flex-wrap">{selectedValue?.displayValue}</div>
      <div className="timezone-arrow">
        <Icon
          name="arrows/down"
          size="12"
          color="bright-blue-600"
          className="self-center mt-px hover:text-gray-300"
        />
      </div>
    </div>
  </>
);

export default TimeZoneInput;
