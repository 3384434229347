import React from 'react';

import { ApolloError } from '@apollo/client';
import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import { has, isEmpty, isEqual, isString, pick, values } from 'lodash';

import AddressFields from '@components/Formik/Address';
import TextField from '@components/Formik/PasswordField';
import PhoneInput from '@components/Formik/PhoneInput';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';
import FormButtons from '@presenters/web/components/FormButtons';

import {
  checkAddressFieldsRequired,
  editDistrictContactValidationSchema,
} from './validation';

import {
  AddressInputType,
  DistrictDetailsFormValues,
  DistrictDetailsFormValuesWithIds,
  DistrictDetailsValues,
  EmailInputType,
  mapDistrictDetailsToForm,
  mapFormToUpdateDistrictDetails,
  PrimaryFaxInputType,
  PrimaryPhoneInputType,
  PrimaryWebsiteInputType,
} from '@domain/districts';

import { useErrorHandling } from '@use-cases/notifications';

import { useUpdateDistrictDetails } from '@repositories/districts';

import { FEATURE_DOMINO_ORGANIZATION, isEnabled } from '@utils/features';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { DistrictDetailsInput } from '@typings/graphql';
import { FetchDistrictDetailsQuery } from '@typings/operations';

interface Props extends RouteComponentProps {
  districtId: string;
  districtData: FetchDistrictDetailsQuery;
  districtDataLoading: boolean;
  error: ApolloError | undefined;
}

const EditDistrictContact: React.FC<Props> = ({
  districtId,
  districtData,
  districtDataLoading,
  error,
}) => {
  const { t } = useTranslation();

  const [
    updateDistrictDetails,
    { loading: updateDistrictLoading },
  ] = useUpdateDistrictDetails();

  useErrorHandling(error?.message, !!error, 'districtDetailsFetch.error');

  if (
    districtDataLoading ||
    error ||
    !districtData?.districtDetails ||
    updateDistrictLoading
  ) {
    return <Loading />;
  }

  const initialValues = mapDistrictDetailsToForm(
    districtData?.districtDetails as DistrictDetailsValues
  );

  const districtDetailsPath = `/district/${districtId}/details`;

  const addIds = (values: DistrictDetailsFormValues) => {
    const {
      businessAddress,
      primaryPhone,
      emails,
      primaryFax,
      websites,
      primaryEmail,
      primaryWebsite,
    } = districtData.districtDetails;
    const payloadData: DistrictDetailsFormValuesWithIds = {
      ...(values as DistrictDetailsFormValuesWithIds),
    };
    if (businessAddress) {
      payloadData.address = {
        ...payloadData.address,
        id: businessAddress?.[0]?.id as string,
      };
      delete payloadData.address.hasStates;
      delete payloadData.address.state;
    }
    if (primaryEmail) {
      payloadData.primaryEmail = {
        primaryEmail: payloadData.primaryEmail as string,
        id: emails?.[0]?.id as string,
      };
    } else {
      payloadData.primaryEmail = {
        primaryEmail: payloadData.primaryEmail as string,
      };
    }
    if (primaryWebsite) {
      payloadData.primaryWebsite = {
        primaryWebsite: payloadData.primaryWebsite as string,
        id: websites?.id as string,
      };
    } else {
      payloadData.primaryWebsite = {
        primaryWebsite: payloadData.primaryWebsite as string,
      };
    }
    if (primaryPhone) {
      payloadData.primaryPhone = {
        ...primaryPhone,
        ...payloadData.primaryPhone,
      };
      delete payloadData.primaryPhone.__typename;
    }
    if (primaryFax) {
      payloadData.primaryFax = {
        ...primaryFax,
        ...payloadData.primaryFax,
      } as PrimaryFaxInputType;
      delete payloadData.primaryFax.__typename;
    }
    return payloadData;
  };

  type DominoPayload =
    | AddressInputType
    | EmailInputType
    | PrimaryWebsiteInputType
    | PrimaryPhoneInputType
    | PrimaryFaxInputType;

  const prepareDominoPayload = <T extends DominoPayload>(
    formValues: T,
    submittedValues: T
  ): T | null => {
    const actionUpdatedInfo = { ...submittedValues };
    // delete Logic Strat
    let tempInfo: Partial<T> = { ...submittedValues };
    delete tempInfo.id;
    // Incase of Phone only check number filed
    if (has(tempInfo, 'number')) {
      tempInfo = pick(tempInfo, ['number']);
    }
    const valuesArray = values(tempInfo);
    // checking if all the values are empty for delete
    const check = valuesArray.filter(val => isString(val) && !isEmpty(val));
    if (check.length === 0) {
      if (actionUpdatedInfo.id) actionUpdatedInfo.action = 'Delete';
      else return null;
      // delete logic end:
    } else if (!submittedValues?.id && !actionUpdatedInfo.action) {
      // If Submitted Info does not have id, its a newly added Info
      actionUpdatedInfo.action = 'Add';
    } else if (!isEqual(submittedValues, formValues)) {
      actionUpdatedInfo.action = 'Update';
    }
    if (!actionUpdatedInfo.action) {
      return null;
    }
    return actionUpdatedInfo;
  };

  const handleSubmit = async (values: DistrictDetailsFormValues) => {
    let mutationValues: Partial<Omit<DistrictDetailsInput, 'districtId'>> = {};
    if (isEnabled(FEATURE_DOMINO_ORGANIZATION)) {
      const initialValuesWithId = addIds(initialValues);
      const valuesWithId = addIds(values);

      const addressPayload = prepareDominoPayload(
        initialValuesWithId.address as AddressInputType,
        valuesWithId.address as AddressInputType
      );
      if (addressPayload) {
        mutationValues.businessAddress = [{ ...addressPayload }];
      }
      const emailPayload = prepareDominoPayload(
        initialValuesWithId.primaryEmail as EmailInputType,
        valuesWithId.primaryEmail as EmailInputType
      );
      if (emailPayload) {
        mutationValues.emails = { ...emailPayload };
      }
      const websitePayload = prepareDominoPayload(
        initialValuesWithId.primaryWebsite as PrimaryWebsiteInputType,
        valuesWithId.primaryWebsite as PrimaryWebsiteInputType
      );
      if (websitePayload) {
        mutationValues.websites = { ...websitePayload };
      }
      const primaryPhonePayload = prepareDominoPayload(
        initialValuesWithId.primaryPhone as PrimaryPhoneInputType,
        valuesWithId.primaryPhone as PrimaryPhoneInputType
      );
      if (primaryPhonePayload) {
        mutationValues.primaryPhone = primaryPhonePayload;
      }
      const primaryFaxPayload = prepareDominoPayload(
        initialValuesWithId.primaryFax as PrimaryFaxInputType,
        valuesWithId.primaryFax as PrimaryFaxInputType
      );
      if (primaryFaxPayload) {
        mutationValues.primaryFax = primaryFaxPayload;
      }
    } else {
      mutationValues = mapFormToUpdateDistrictDetails(values);
    }
    await updateDistrictDetails({
      variables: {
        districtId,
        ...mutationValues,
      },
    });

    localizedNavigate(districtDetailsPath);
  };

  const handleCancel = () => {
    localizedNavigate(`/district/${districtId}/details`);
  };

  return (
    <div className="mb-20 mt-10">
      <LinkPrevious
        path={districtDetailsPath}
        label={t('district-editcontact.back-link', 'Back')}
      />

      <Title>
        {t('district-editcontact.title', 'Edit Contact Information')}
      </Title>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={editDistrictContactValidationSchema(t)}
      >
        {({ values, isSubmitting, isValid }) => {
          const isPrimaryPhoneRequired = !!values.primaryPhone?.number;

          const primaryPhoneLabels = {
            phoneCode: t(
              'district-editcontact.phone-label-extension',
              'Phone Code'
            ),
            phoneNumber: t('district-editcontact.phone-label', 'Primary Phone'),
          };

          const isPrimaryFaxRequired = !!values.primaryFax?.number;

          const primaryFaxLabels = {
            phoneCode: t(
              'district-editcontact.fax-label-extension',
              'Phone Code'
            ),
            phoneNumber: t('district-editcontact.fax-label', 'Fax Number'),
          };

          return (
            <Form className="max-w-lg mt-16">
              <TextField
                name="primaryEmail"
                label={t('district-editcontact.email-label', 'Primary Email')}
              />

              <PhoneInput
                suffix="primaryPhone"
                isCountryFlagRequired={isPrimaryPhoneRequired}
                labels={primaryPhoneLabels}
              />

              <PhoneInput
                suffix="primaryFax"
                isCountryFlagRequired={isPrimaryFaxRequired}
                labels={primaryFaxLabels}
              />

              <TextField
                name="primaryWebsite"
                label={t('district-editcontact.website-label', 'Website')}
              />

              <AddressFields
                countryId={values.address?.countryId}
                mainLabel={t(
                  'district-editcontact.address-label',
                  'Mailing Address'
                )}
                name={{ hasStates: 'address.hasStates' }}
                requiredFields={checkAddressFieldsRequired(values.address)}
              />

              <FormButtons
                saveBtnText={t(
                  'district-editcontact.save-button-label',
                  'Save Changes'
                )}
                cancelBtnText={t(
                  'district-editcontact.cancel-button-label',
                  'Cancel'
                )}
                isCancelDisabled={isSubmitting}
                isSaveDisabled={isSubmitting || !isValid}
                cancel={handleCancel}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditDistrictContact;
