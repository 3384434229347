import React from 'react';

import classNames from 'classnames';
import { findIndex } from 'lodash';

import {
  AssignmentInfo,
  getLeadershipTerms,
  getMembershipYearsLabel,
} from '../../Clubs/Members/utils';

import { isActiveDateRange } from '@utils/datetime';

import { DistrictLeadership } from '@typings/graphql';

interface Props {
  thisDistrictLeadership?: Array<DistrictLeadership> | null;
  isExpanded?: boolean;
  isRoleFilterApplied?: boolean;
  roleSelected?: string[];
}

const DistrictDetailsList: React.FC<Props> = ({
  thisDistrictLeadership,
  isExpanded,
  isRoleFilterApplied,
  roleSelected,
}) => {
  const getDistrictLeadershipInfo = () => {
    let assignments: AssignmentInfo[] = [];

    // If filter is applied => return leadership even if it is not active
    if (isRoleFilterApplied && thisDistrictLeadership?.length) {
      const filteredLeaderships = thisDistrictLeadership.filter(({ role }) =>
        roleSelected?.includes(role)
      );

      assignments = filteredLeaderships.reduce(
        (acc: AssignmentInfo[], leadership) => {
          const { role, startDate, endDate } = leadership;
          const roleTerms = getMembershipYearsLabel(startDate, endDate);
          const roleIndex = findIndex(acc, { roleName: role });

          if (roleIndex > -1) {
            acc[roleIndex].terms.push(roleTerms);
          } else {
            acc.push({ roleName: role, terms: [roleTerms] });
          }

          return acc;
        },
        []
      );
    } else {
      // If filter is not applied => return leadership only if it is active
      thisDistrictLeadership?.forEach(({ role, startDate, endDate }) => {
        if (isActiveDateRange(startDate, endDate)) {
          assignments.push({
            roleName: role,
            terms: [getMembershipYearsLabel(startDate, endDate)],
          });
        }
      });
    }

    return assignments;
  };

  const finalLeaderShip = getDistrictLeadershipInfo();

  const renderRoleRow = (index: number, roleName: string, terms: string[]) => (
    <div className="last:mb-0 mb-3" key={index}>
      <p
        className={classNames('text-xs mb-0', {
          'truncate desktop:whitespace-normal desktop:text-clip desktop:overflow-visible': !isExpanded,
        })}
      >
        {roleName}
      </p>
      <p
        className={classNames('text-xs mb-0', {
          'truncate desktop:whitespace-normal desktop:text-clip desktop:overflow-visible': !isExpanded,
        })}
      >
        {terms && <>{getLeadershipTerms(terms).join(', ')}</>}
      </p>
    </div>
  );

  return (
    <div>
      {finalLeaderShip?.length
        ? finalLeaderShip.map(({ roleName, terms }, index) =>
            renderRoleRow(index, roleName, terms)
          )
        : null}
    </div>
  );
};

export default DistrictDetailsList;
