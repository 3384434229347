import * as React from 'react';

import { Button } from '@components/Button';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface Props {
  isDistrictOfficer?: boolean | null;
  isAssistantGovernor?: boolean | null;
  isClubAssignments?: boolean | null;
  clubAssignmentsUrl?: string;
  assistantGovernorsUrl?: string;
  officersUrl?: string;
  className?: string;
}

const ContainerWithLinks: React.FC<Props> = ({
  isDistrictOfficer,
  isAssistantGovernor,
  isClubAssignments,
  clubAssignmentsUrl = '',
  assistantGovernorsUrl = '',
  officersUrl = '',
  className = 'mb-8',
}) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      {isDistrictOfficer && (
        <Button
          clickHandler={() =>
            localizedNavigate(officersUrl, {
              state: { path: window.location.pathname },
            })
          }
          text
          alignLeft
          isMinWidth={false}
          capitalize
          className="mt-5 flex min-w-0 outline-none shadow-none border-0 w-auto-important"
        >
          {t('district-management.district-officers', 'District Officers')}
        </Button>
      )}
      {isAssistantGovernor && (
        <Button
          clickHandler={() =>
            localizedNavigate(assistantGovernorsUrl, {
              state: { path: window.location.pathname },
            })
          }
          text
          alignLeft
          isMinWidth={false}
          capitalize
          className="mt-5 flex min-w-0 outline-none shadow-none border-0 w-auto-important"
        >
          {t('district-management.assistant-governors', 'Assistant Governors')}
        </Button>
      )}
      {isClubAssignments && (
        <Button
          clickHandler={() => localizedNavigate(clubAssignmentsUrl)}
          text
          alignLeft
          isMinWidth={false}
          capitalize
          className="mt-5 flex min-w-0 outline-none shadow-none border-0 w-auto-important"
        >
          {t('district-management.club-assignments', 'Club Assignments')}
        </Button>
      )}
    </div>
  );
};

export default ContainerWithLinks;
