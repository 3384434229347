// Libs
import React, { useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  DraggableLocation,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { pipe } from 'fp-ts/lib/function';
import * as A from 'fp-ts/Array';
import { useTranslation } from '@external/react-i18next';

// Constants
import { UNASSIGNED } from './constants/groupnames';

// Types
import {
  DroppableOptions,
  Group,
  Item,
  LocationResult,
  NotificationInfo,
} from './types';
import { MenuItem } from './ContextMenu/types';

// Components
import AccordionItem from './AccordionItem';
import QuoteList from './QuoteList/QuoteList';
import ActionButtons from '@components/ActionButtons';

// Utils
import {
  areItemsInGroup,
  getDataAfterMove,
  getDataAfterReorderColumn,
  getMenuItems,
  getSourceGroupItems,
  isNotRelated,
  isNotUnassigned,
  isRelatedGroup,
  isUnassigned,
  moveGroup,
} from './utils';

interface Props {
  data: Group[];
  prevData: Group[];
  getUpdatedData: (
    updatedData: Group[],
    notificationInfo: NotificationInfo | null
  ) => void;
  reset?: () => void;
  save?: () => void;
  cancel?: () => void;
  isSaveDisabled?: boolean;
  setIsPriorYearClicked?: (value: boolean) => void;
  setIsOpenedPriorYearModal?: (value: boolean) => void;
  setIsCheckedPriorYearGroup?: (value: boolean) => void;
  isCheckedPriorYearGroup?: boolean;
  areClubsMoved: boolean;
}

export const DragAndDropContainers: React.FC<Props> = ({
  data = [],
  prevData = [],
  getUpdatedData,
  reset,
  save,
  cancel,
  isSaveDisabled,
  setIsPriorYearClicked,
  setIsOpenedPriorYearModal,
  setIsCheckedPriorYearGroup,
  isCheckedPriorYearGroup = false,
  areClubsMoved = false,
}) => {
  const { t } = useTranslation();

  const [state, setState] = useState<Group[]>([]);

  const [areItemsMoved, setAreItemsMoved] = useState<boolean>(areClubsMoved);

  useEffect(() => {
    setAreItemsMoved(areClubsMoved);
  }, [areClubsMoved]);

  useEffect(() => {
    setState([...data, ...prevData]);
  }, [data, prevData]);

  const groupsData: Group[] = useMemo(
    () =>
      state.map(
        ({ groupId, groupName, items, relatedGroupName, relatedGroupId }) => ({
          groupId,
          groupName,
          items,
          relatedGroupName,
          relatedGroupId,
        })
      ),
    [state]
  );

  const getDataAfterReorderColumnMemo = useMemo(
    () => (
      sourceGroupId: string,
      sourceIndex: number,
      destinationIndex: number
    ): Group[] =>
      getDataAfterReorderColumn(
        sourceGroupId,
        sourceIndex,
        destinationIndex,
        state
      ),
    [state]
  );

  const getDataAfterMoveMemo = useMemo(
    () => (
      sourceGroupId: string,
      destinationGroupId: string,
      source: DraggableLocation,
      destination: DraggableLocation
    ): Group[] =>
      getDataAfterMove(
        sourceGroupId,
        destinationGroupId,
        source,
        destination,
        state
      ),
    [state]
  );

  const onDragEnd = (dropResult: DropResult): void => {
    const { source, destination } = dropResult;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sourceGroupId = source.droppableId;
    const destinationGroupId = destination.droppableId;

    // dropped on the same place
    if (
      sourceGroupId === destinationGroupId &&
      source.index === destination.index
    ) {
      return;
    }

    const newState: Group[] =
      sourceGroupId === destinationGroupId
        ? getDataAfterReorderColumnMemo(
            sourceGroupId,
            source.index,
            destination.index
          )
        : getDataAfterMoveMemo(
            sourceGroupId,
            destinationGroupId,
            source,
            destination
          );
    getUpdatedData(newState, null);
    setState(newState);
  };

  const assignItem = (
    result: LocationResult,
    assignedItem?: Item,
    assignedGroup?: Group
  ) => {
    const { source, destination } = result;
    const sourceGroupId: string = source.droppableId;
    const destinationGroupId: string = destination.droppableId;

    if (sourceGroupId !== destinationGroupId) {
      const newState = getDataAfterMoveMemo(
        sourceGroupId,
        destinationGroupId,
        source,
        destination
      );

      getUpdatedData(
        newState,
        assignedItem && assignedGroup
          ? {
              assignedItemContent: assignedItem.content,
              assignedGroupName: assignedGroup.groupName,
            }
          : null
      );
      setState(newState);
    }
  };

  const assignGroup = ({ menuItemId }: MenuItem, groupId?: string): void => {
    if (groupId) {
      let movedGroupItems = getSourceGroupItems(menuItemId, state);
      if (movedGroupItems.length === 0) {
        movedGroupItems = state.find(el => el.groupId === groupId)!.items;
      }
      const newState = moveGroup(groupId, menuItemId, state, movedGroupItems);
      getUpdatedData(newState, null);
      setState(newState);
    }
  };

  const getColumnClass = (group: Group, isDraggingOver: boolean): string => {
    if (isUnassigned(group)) {
      return isDraggingOver ? 'bg-purple-600' : 'bg-purple-100';
    }
    return isDraggingOver ? 'bg-bright-blue-600' : 'bg-bright-blue-custom';
  };

  const onReset = () => {
    setState(data);
    reset?.();
  };

  const checkBoxChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsPriorYearClicked?.(true);
    if (areItemsMoved) {
      setIsOpenedPriorYearModal?.(true);
    } else {
      setIsCheckedPriorYearGroup?.(event.target.checked);
    }
  };

  const renderDroppable = (group: Group, options?: DroppableOptions) => {
    const { groupId, groupName, items } = group;
    const { textBeforeName, classes, contextMenuOptions } = options || {};
    return (
      <Droppable key={groupId} droppableId={`${groupId}`}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div
              className={`mb-4 rounded ${getColumnClass(
                group,
                snapshot.isDraggingOver
              )}`}
            >
              <AccordionItem
                dataTestId={`accordion-${groupId}`}
                contextMenuOptions={contextMenuOptions}
                textBeforeName={textBeforeName}
                classes={classes}
                groupId={groupId}
                name={
                  groupName.includes(UNASSIGNED)
                    ? t('club-assignments.unassigned-column', 'Unassigned')
                    : groupName
                }
                itemCount={items.length}
                key={groupId}
                isCheckedPriorYearGroup={isCheckedPriorYearGroup}
                checkBoxChangeHandler={checkBoxChangeHandler}
                isIconReversed={snapshot.isDraggingOver}
                itemText={
                  items.length === 1
                    ? t('club-assignments.club', 'club')
                    : t('club-assignments.clubs', 'clubs')
                }
              >
                <>
                  {isUnassigned(group) &&
                    pipe(
                      state,
                      A.filter(isNotUnassigned),
                      A.filter(areItemsInGroup),
                      A.filter(isRelatedGroup(UNASSIGNED)),
                      A.map(groupItem =>
                        renderDroppable(groupItem, {
                          classes: {
                            container: 'bg-purple-200 my-2',
                            header: 'flex items-center w-full',
                            title: 'text-sm',
                          },
                          textBeforeName: t(
                            'club-assignments.previously-assigned',
                            'Previously assigned to'
                          ),
                          contextMenuOptions: {
                            title: groupItem.groupId
                              ? t(
                                  'club-assignments.unassigned-group-items',
                                  'Assign Group of clubs to an AG'
                                )
                              : t(
                                  'club-assignments.unassigned-item',
                                  'Assign Club to an AG'
                                ),
                            menuItems: getMenuItems(
                              groupsData,
                              groupItem.groupId,
                              false
                            ),
                            currentItemId: `groupId-${UNASSIGNED}`,
                            handleMenuItemClick: assignGroup,
                          },
                        })
                      )
                    )}

                  <QuoteList
                    groupsData={groupsData}
                    quotes={items}
                    currentGroupId={groupId}
                    assignItem={assignItem}
                  />
                </>
              </AccordionItem>
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    );
  };

  return (
    <>
      <div className="flex flex-col tablet:flex-row mobile-full-width">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="w-full left-column relative">
            {pipe(state, A.filter(isUnassigned), A.map(renderDroppable))}
          </div>
          <div className="w-full right-column">
            {pipe(
              state,
              A.filter(isNotUnassigned),
              A.filter(isNotRelated),
              A.map(groupedItem =>
                renderDroppable(groupedItem, {
                  classes: {
                    container: '',
                    header: 'flex items-center w-full',
                    title: '',
                  },
                  contextMenuOptions:
                    groupedItem.items.length > 0
                      ? {
                          title: t(
                            'club-assignments.unassigned-group-items',
                            'Assign Group of clubs to an AG'
                          ),
                          menuItems: getMenuItems(
                            groupsData,
                            groupedItem.groupId
                          ),
                          currentItemId: groupedItem.groupId,
                          handleMenuItemClick: assignGroup,
                        }
                      : undefined,
                })
              )
            )}
          </div>
        </DragDropContext>
      </div>
      {save && reset && cancel && (
        <div className="mt-7 tablet:mt-15 flex flex-col tablet:flex-row">
          <ActionButtons
            submitBtnLabel={t('action-button.save', 'Save')}
            resetBtnLabel={t('action-button.reset', 'Reset')}
            cancelBtnLabel={t('action-Button.cancel', 'Cancel')}
            isSubmitDisabled={Boolean(isSaveDisabled)}
            onSubmit={save}
            onReset={onReset}
            onCancel={cancel}
            wrapperClassNames="w-full flex flex-col left-column max-w-none"
            isColumn
          />
          <div className="w-full flex right-column" />
        </div>
      )}
    </>
  );
};

export default DragAndDropContainers;
