import React, { useEffect } from 'react';

import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import AccessDenied from '@components/Pages/AccessDenied';
import Title from '@components/Title';

import AssignmentItem from './AssignmentItem';
import CollapsibleHeader from './CollapsibleHeader';

import { getYearLabel } from '@use-cases/districts';

import { useFetchPresRepConferences } from '@repositories/districts';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const DistrictConferencesPresRep: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAppConfig();

  const [fetchConferences, { loading, data }] = useFetchPresRepConferences();

  const title = t(
    'conferences.district-conferences-pres-rep',
    "President's Representative for District Conferences"
  );

  useEffect(() => {
    if (user?.individualId) {
      fetchConferences({
        variables: {
          individualId: user.individualId,
        },
      });
    }
  }, [user?.individualId]);

  if (loading) {
    return <Loading />;
  }

  if (data?.result.length === 0) {
    return <AccessDenied />;
  }

  return (
    <>
      <Helmet
        titleTemplate={`${title} | ${t(
          'metadata.title.default',
          'My Rotary'
        )} `}
      />
      <div className="conference-page">
        <div className="px-5 tablet:px-0">
          <Title className="text-xl mb-5 p-0 leading-normal">{title}</Title>
          <LinkPrevious
            path="/account/actions"
            label={t('back-link-text', 'Back')}
          />
        </div>
        {data?.result.map(item => (
          <CollapsibleHeader
            hasMargin
            hasAdditionalBorder
            key={item.term}
            title={t(
              'pres-rep.assigment-title-with-district',
              '{{ term }} Assignment. District<span>&nbsp;</span>{{ riDistrictId }}',
              {
                term: getYearLabel(item.term),
                riDistrictId: item.riDistrictId,
              }
            )}
          >
            <AssignmentItem
              key={item.riDistrictId}
              conferences={item.conferences || []}
            />
          </CollapsibleHeader>
        ))}
      </div>
    </>
  );
};

export default DistrictConferencesPresRep;
