import React, { Reducer, useMemo, useReducer, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import { Button } from '@components/Button';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import IdentifyDistrictCandidate from '@presenters/web/pages/Leads/MMLPage/IdentifyDistrictCandidate';
import AddNewCandidateDistrict from '@presenters/web/pages/Leads/MMLPage/IdentifyDistrictCandidate/AddNewCandidateDistrict';

import AddDistrictContinue from './AddDistrictContinue';

import { IdentificationParameters } from '@domain/clubs';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { getDistrictMembershipCandidatePath } from '@use-cases/leads';
import { useErrorHandling } from '@use-cases/notifications';

import { useFetchMemberDetails } from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface DistrictAddMemberPageProps extends RouteComponentProps {
  districtId: string;
  districtName: string;
}

const AddDistrictCandidate: React.FC<DistrictAddMemberPageProps> = ({
  districtId: id,
  districtName,
}) => {
  const { t } = useTranslation();
  enum AddMemberSteps {
    IDENTIFY,
    INFO,
    NEWCANDIDATE,
  }

  const [step, setStep] = useState<AddMemberSteps>(AddMemberSteps.IDENTIFY);
  const isFirstStep = step === AddMemberSteps.IDENTIFY;

  const [filters, setFilters] = useReducer<
    Reducer<IdentificationParameters, IdentificationParameters>
  >((state, action) => {
    return { ...state, ...action };
  }, {});

  const [isFieldTouched, setIsFieldTouched] = useState<boolean>();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const [selectedMember, selectMember] = useState<undefined | string>(
    undefined
  );

  const { data, loading, error } = useFetchMemberDetails(
    selectedMember || '',
    '',
    'District'
  );

  useErrorHandling(error?.message, !!error, 'member-details.error');

  // If member data has been loaded, message it into InitialData.
  const individual = data?.addMemberGetIndividual.individual;

  const handleBackClick = () => {
    if (isFieldTouched) {
      show(true);
    } else {
      localizedNavigate(getDistrictMembershipCandidatePath(id));
    }
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: handleBackClick,
  });

  const modalOnConfirmHandler = () => {
    globalHide();
    if (isFirstStep) {
      localizedNavigate(getDistrictMembershipCandidatePath(id));
    } else {
      setStep(step - 1);
    }
  };

  const handleFormFieldsTouched = (isTouched: boolean) => {
    setIsFieldTouched(isTouched);
  };

  const handleCancelClick = () => {
    if (isFieldTouched) {
      show(true);
    } else {
      localizedNavigate(getDistrictMembershipCandidatePath(id));
    }
  };

  const BackButton = () =>
    useMemo(() => {
      return (
        <>
          <Button clickHandler={handleCancelClick} text full>
            {t('add_member.identify_member_form.cancel_button', 'Cancel')}
          </Button>
          <LeaveFormConfirmationModal
            isOpen={isShowing}
            closeModal={globalHide}
            onConfirm={modalOnConfirmHandler}
          />
        </>
      );
    }, [isShowing]);

  if (loading) {
    return <Loading />;
  }

  const handleStep = (id: string | undefined) => {
    if (id !== undefined) {
      setStep(AddMemberSteps.INFO);
    } else {
      setStep(AddMemberSteps.NEWCANDIDATE);
    }
    window.scrollTo(0, 0);
  };

  switch (step) {
    case AddMemberSteps.IDENTIFY:
      return (
        <IdentifyDistrictCandidate
          submitHandler={(id: string | undefined) => {
            selectMember(id);
            handleStep(id);
          }}
          filters={filters}
          setFilters={setFilters}
          riDistrictId={id}
          handleFormFieldsTouched={handleFormFieldsTouched}
        >
          <BackButton />
        </IdentifyDistrictCandidate>
      );
    case AddMemberSteps.INFO:
      return (
        <AddDistrictContinue
          handleBackClick={handleBackClick}
          data={individual}
        >
          <BackButton />
        </AddDistrictContinue>
      );
    case AddMemberSteps.NEWCANDIDATE:
      return (
        <AddNewCandidateDistrict
          districtName={districtName}
          districtId={id}
          handleBackClick={handleBackClick}
        />
      );
    default:
      return null;
  }
};

export default AddDistrictCandidate;
