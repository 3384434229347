import React from 'react';

import { Form, Formik } from 'formik';

import MultiSelectFilter from '@components/Formik/MultiSelectFilter';

import { CLUB_FILTERS, ClubFilterProps } from '@domain/districts';

import { useTranslation } from '@external/react-i18next';

export interface Props extends ClubFilterProps {}

const Filters: React.FC<Props> = ({
  applyFilter,
  initialFilterValues,
  resetFilter,
  multiSelectOptions,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialFilterValues }}
      onSubmit={() => {}}
    >
      <Form className="desktop:flex">
        <div className="px-5 desktop:px-3">
          <MultiSelectFilter
            title={t('district-clubs.filters-club-type-label', 'Club Type')}
            name={CLUB_FILTERS.CLUB_TYPES}
            inputPlaceholder={t(
              'district-clubs.club-types-placeholder',
              'Filter by Club Type'
            )}
            resetFilter={resetFilter}
            applyFilter={values => applyFilter(values, CLUB_FILTERS.CLUB_TYPES)}
            options={multiSelectOptions.clubTypes}
            isSearchable={false}
          />
        </div>
        {/* TODO: uncomment out the code below when one more filter will be added */}
        {/* <button
          type="button"
          className={`${
            isFilterApplied ? 'text-dark-blue-400' : 'text-gray-400'
          } text-md leading-6	pl-2`}
          onClick={() => {
            resetFilter();
          }}
          disabled={!isFilterApplied}
        >
          {t('clear-all', 'Clear All')}
        </button> */}
      </Form>
    </Formik>
  );
};

export default Filters;
