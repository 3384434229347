// Libs
import React from 'react';

// Components
import {
  BarChart,
  Bar,
  LabelList,
  Legend,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import CustomizedLabel from './CustomizedLabel';
import CustomizedYTick from './CustomizedYTick';
import LegendContent from './LegendContent';

// Utils
import { isIE } from '@utils/browser-detection';

// Types
import { DemographicsChartData } from '@domain/districts';

const getBarProps = (dataKey: string) => ({
  dataKey,
  stackId: 'stackId',
  barSize: 40,
});

const Chart: React.FC<{
  chartData: DemographicsChartData[];
  categories: { [key: string]: string }[];
  hasLegend?: boolean;
  chartHeight?: number;
}> = ({ chartData, categories, hasLegend = false, chartHeight = 210 }) => (
  <>
    {chartData.length > 0 && (
      <ResponsiveContainer width="100%" height={chartHeight}>
        <BarChart
          height={180}
          layout="vertical"
          data={chartData}
          margin={{ top: 15, right: isIE ? 77 : 30, bottom: 5, left: 0 }}
        >
          <XAxis
            type="number"
            axisLine={false}
            tickLine={false}
            tickCount={0}
          />
          <YAxis
            dataKey="period"
            type="category"
            orientation="right"
            axisLine={false}
            tickLine={false}
            tick={<CustomizedYTick />}
          />
          {hasLegend && (
            <Legend verticalAlign="top" content={<LegendContent />} />
          )}
          {categories.map(({ category, color }) => (
            <Bar {...getBarProps(category)} fill={color} key={category}>
              <LabelList
                dataKey={category}
                position="center"
                content={<CustomizedLabel label={category} />}
              />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    )}
  </>
);

export default Chart;
