import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { emailSchema } from '@components/Formik/validation/fieldDefinitions';
import {
  hasMaxLength,
  matchLatinChars,
  schemaField,
  SchemaFunction,
} from '@components/Formik/validation/schemaDefinitions';

export const matchesNumberChars: SchemaFunction<Yup.StringSchema> = (
  t,
  schema
) => {
  const regexp = /^[0-9]\d*$/;
  return schema.matches(
    regexp,
    t('form.field.error_number_char', 'Field should contain positive number')
  );
};

export const stringShemaField = (t: TFunction) =>
  schemaField(t, Yup.string(), hasMaxLength(40), matchLatinChars);

const SearchFormValidationSchema = (t: TFunction) =>
  Yup.object({
    memberId: schemaField(
      t,
      Yup.string(),
      stringShemaField,
      matchesNumberChars
    ),
    firstName: stringShemaField(t),
    lastName: stringShemaField(t),
    email: emailSchema(t),
  });

export default SearchFormValidationSchema;
