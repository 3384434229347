import React from 'react';

import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';

import Checkbox from '@components/Formik/Checkbox';

import { getTranslatedSection, getTranslateQuestion } from './utils';

import { useTranslation } from '@external/react-i18next';

import { Question } from '@typings/operations';

type Props = {
  questions: Question[];
  sectionId: string;
  sectionName: string;
  orderNumber: number;
  isViewOnly: boolean;
};

const CheckBoxOptions: React.FC<Props> = ({
  questions,
  sectionId,
  sectionName,
  orderNumber,
  isViewOnly,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <label
        className="text-small font-bold block text-gray-600 mb-3 mt-4"
        htmlFor="checkbox-list"
      >
        {`${orderNumber}. ${getTranslatedSection(t, sectionId, sectionName)}`}
      </label>
      <div className="pt-2 pl-4 pb-2 tablet:pb-4 text-xs" id="checkbox-list">
        {pipe(
          questions.map(({ id: questionId, option }) => ({
            questionId,
            value: getTranslateQuestion(t, questionId, option),
          })),
          A.map(({ questionId, value }) => (
            <Checkbox
              checkBoxClasses={{ checkBoxLabel: 'm-0' }}
              disabled={isViewOnly}
              key={questionId}
              name={questionId}
              label={value}
            />
          ))
        )}
      </div>
    </>
  );
};

export default CheckBoxOptions;
