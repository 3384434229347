import React, { useContext, useState } from 'react';

import moment from 'moment';

import ActionButtons from '@components/ActionButtons';
import Loading from '@components/Loading';
import ConfirmDistOfficerForm from '@presenters/web/components/Forms/ConfirmDistOfficerForm';
import SaveChangesModal from '@presenters/web/components/SaveChangesModal';

import { OperationType } from '@domain/districts';

import {
  buildOfficersPageUrl,
  DistrictContext,
  extractRoleInfo,
  getBackUrl,
  getMaxDateForRemoveReplace,
  getMinDateForRemoveReplace,
  useCAReplaceRemoveContext,
  useModal,
  useSetContextManagedRYs,
  useStopBrowserNavigate,
} from '@use-cases/districts';
import { useNotifications } from '@use-cases/notifications';

import { useManageDistrictLeadership } from '@repositories/districts';

import { pushLocalisedUrl } from '@utils/browserHistory';
import { getCurrentDate, getRotaryYear, isCurrentRY } from '@utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useLogger } from '@hooks/logger';

import { LogLevel } from '@typings/graphql';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
  officerId: string;
  officerEmail?: string | null;
  officerName?: string | null;
};

const ReplaceDistictExecSecretary: React.FC<Props> = ({
  districtId,
  riDistrictId,
  officerId,
  officerEmail,
  officerName,
}) => {
  const { t } = useTranslation();
  const { addLog } = useLogger();
  const { addError, addSuccess } = useNotifications();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [isBack, setIsBack] = useState(false);
  const [context, setContext] = useContext(DistrictContext);
  const {
    selectedInfo: { unassignFrom, assignee, role, term, dateToRemoveRole },
    operationType,
  } = context;

  const {
    currentOfficersLoading,
    futureOfficersLoading,
  } = useSetContextManagedRYs(districtId, riDistrictId);

  const {
    isLoading,
    assignDL,
    updateDLForFutureRY,
    updateDLForCurrentRY,
  } = useManageDistrictLeadership({ districtId, riDistrictId });

  const {
    isCurrentTerm,
    resetContextValues,
    selectedLeadership,
  } = useCAReplaceRemoveContext();

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    setIsBack,
    isStepMax: true,
  });

  const backUrl = getBackUrl(operationType, districtId);

  const logError = () =>
    addLog({
      level: LogLevel.Error,
      message: `
            An error occurred when replacing the officer. The values provided are
            assigneeId: ${assignee?.id || officerId},
            assigneeEmail: ${assignee?.email || officerEmail},
            officerToBeReplacedId: ${unassignFrom?.id},
            
            selectedRole: ${role?.name},
            selectedTerm: ${term?.startDate}-${term?.endDate}
            dateToRemoveRole: ${dateToRemoveRole}
          `,
    });

  const onCancelHandler = () => {
    resetContextValues();
    setGlobal(false);
    localizedNavigate(buildOfficersPageUrl(districtId));
  };

  const backHandler = () => {
    if (operationType === OperationType.REASSIGN_TO_ROLE) {
      onCancelHandler();
    } else {
      window.localStorage.setItem('isNextStepVisited', 'true');
      setContext(prevState => ({
        ...prevState,
        step: 1,
      }));
      setGlobal(false);
    }
  };

  const modalOnCancelHandler = () => {
    setIsBack(false);
    show(true);
  };

  const handleAPICallFailure = () =>
    addError(
      t(
        'replace-officer.confirmation.error-request',
        'An error occurred when replacing the officer'
      )
    );

  const handleAPICallSuccess = () => {
    addSuccess(
      t(
        'replace-officer-detail-success',
        'Success! {{officerToBeReplacedName}} has been replaced by {{officerAssigneeName}} as {{role}}.',
        {
          officerAssigneeName: officerName,
          officerToBeReplacedName: unassignFrom?.nameWithPrefixSuffix,
          role: role?.name,
        }
      )
    );
    onCancelHandler();
  };

  // Replace officer logic to calculate replace start date, update prev officer removal date and assign new officer start date //
  const roleInfo = extractRoleInfo(t, role, selectedLeadership);
  const selectedLeadershipDateToRemove =
    dateToRemoveRole && moment(dateToRemoveRole).format('YYYY-MM-DD');
  const replaceMinDate = moment(selectedLeadership?.startDate).format(
    'YYYY-MM-DD'
  );
  const replaceMaxDate = moment(selectedLeadership?.endDate).format(
    'YYYY-MM-DD'
  );

  const replaceDOForCurrentRY = async (
    individualId: string,
    roleId: string,
    assignDate: Date | string,
    assigneeEmail: string | null
  ) => {
    const areNewOfficerValuesSet =
      individualId && roleId && assignDate && assigneeEmail;
    const selectedLeadershipStartDate = moment(selectedLeadership?.startDate);
    const endDateForSelectedLeadership = moment(dateToRemoveRole);

    const isEqualStartDateAndDateToRemove = selectedLeadershipStartDate.isSame(
      endDateForSelectedLeadership,
      'D'
    );

    const isEqualDateToRemoveAndCurrentDate =
      isEqualStartDateAndDateToRemove &&
      !endDateForSelectedLeadership.isSame(moment(getCurrentDate()), 'D');

    if (!isEqualDateToRemoveAndCurrentDate) {
      endDateForSelectedLeadership.subtract(1, 'd');
    }

    if (areNewOfficerValuesSet && selectedLeadershipDateToRemove) {
      isEqualDateToRemoveAndCurrentDate
        ? await updateDLForFutureRY(selectedLeadership?.id || '')
        : await updateDLForCurrentRY(
            selectedLeadership?.id || '',
            endDateForSelectedLeadership
          );
      await assignDL(individualId, roleId, assignDate, assigneeEmail);
      handleAPICallSuccess();
    } else {
      logError();
    }
  };

  const replaceDOForFutureRY = async (
    individualId: string,
    roleId: string,
    assignDate: Date | string,
    assigneeEmail: string | null
  ) => {
    const areNewOfficerValuesSet =
      individualId && roleId && assignDate && assigneeEmail;
    if (areNewOfficerValuesSet) {
      const leadershipId = selectedLeadership?.id || '';
      selectedLeadershipDateToRemove &&
      isCurrentRY(selectedLeadershipDateToRemove)
        ? await updateDLForCurrentRY(
            leadershipId,
            new Date(`${getRotaryYear()}-06-30`),
            true
          )
        : await updateDLForFutureRY(leadershipId);
      await assignDL(individualId, roleId, assignDate, assigneeEmail);
      handleAPICallSuccess();
    } else {
      logError();
    }
  };

  const handleSelectDate = (date: Date) => {
    setContext(prevState => ({
      ...prevState,
      selectedInfo: {
        ...prevState.selectedInfo,
        dateToRemoveRole: date,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const assignDate = isCurrentTerm
        ? selectedLeadershipDateToRemove
        : `${term?.startDate}-07-01`;

      await (isCurrentTerm ? replaceDOForCurrentRY : replaceDOForFutureRY)(
        officerId,
        role?.id || '',
        assignDate || '',
        officerEmail || null
      );
    } catch {
      handleAPICallFailure();
    } finally {
      setGlobal(false);
      pushLocalisedUrl(backUrl);
    }
  };

  if (isLoading || currentOfficersLoading || futureOfficersLoading) {
    return <Loading />;
  }

  return (
    <>
      <ConfirmDistOfficerForm
        roleName={role?.name || null}
        term={roleInfo.term}
        termYears={role?.termYears || null}
        operationType={operationType}
        assigneeEmail={assignee?.email || officerEmail || null}
        assigneeName={assignee?.nameWithPrefixSuffix || officerName || null}
        officerToBeReplacedName={unassignFrom?.nameWithPrefixSuffix || null}
        title={t('confirm-selected-role', 'Confirm {{selectedRole}}', {
          selectedRole: role?.name,
        })}
        labels={{
          officerLabel: t('assign-from.officer.label', 'Officer'),
          termLabel: t('assign-from.term.label', 'Term'),
          roleLabel: t('assign-from.role.label', 'Role'),
          emailLabel: t('assign-from.email.label', 'Email'),
          officerToBeReplacedLabel: t(
            'officer-tobe-replace.label',
            'Officer to be replaced'
          ),
          datePickerLabel: t('assign-from.datepicker-label', 'Effective date'),
        }}
        maxDate={getMaxDateForRemoveReplace(replaceMaxDate, true)}
        minDate={getMinDateForRemoveReplace(replaceMinDate)}
        handleSelectDate={isCurrentTerm ? handleSelectDate : undefined}
        selectedDateValue={dateToRemoveRole as Date}
      />
      <ActionButtons
        submitBtnLabel={t('action-button.finish', 'Finish')}
        cancelBtnLabel={t('action-Button.cancel', 'Cancel')}
        onCancel={modalOnCancelHandler}
        onSubmit={handleSubmit}
      />
      <SaveChangesModal
        isOpen={isShowing}
        onClose={globalHide}
        onSave={handleSubmit}
        onContinue={isBack ? backHandler : onCancelHandler}
      />
    </>
  );
};

export default ReplaceDistictExecSecretary;
