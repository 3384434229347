// Types
import { CommitteeMemberData, ManagedRY, RoleOption } from '@domain/districts';
import { MembershipType } from '@typings/graphql';

// Utils
import { isRoleAvailableInManagedRY } from '@use-cases/districts';

export const getMembershipByType = (
  assignee: CommitteeMemberData,
  type: MembershipType
) => assignee.activeMemberships.find(membership => membership.type === type);

export const getActiveMembership = (assignee: CommitteeMemberData) =>
  getMembershipByType(assignee, MembershipType.Member) ||
  getMembershipByType(assignee, MembershipType.Rotaractor) ||
  getMembershipByType(assignee, MembershipType.Honorary);

export const isRoleAvailable = (
  role: RoleOption,
  { roles, officers }: ManagedRY
): boolean | null => isRoleAvailableInManagedRY(role.name, roles, officers);
