import { useLazyQuery } from '@apollo/client';

import { individualHATEOSLinksQuery } from '../queries';

import {
  FetchIndividualHateosLinksQuery,
  FetchIndividualHateosLinksQueryVariables,
} from '@typings/operations';

export const useFetchIndividualHATEOASLinks = () =>
  useLazyQuery<
    FetchIndividualHateosLinksQuery,
    FetchIndividualHateosLinksQueryVariables
  >(individualHATEOSLinksQuery);
