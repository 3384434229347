// Libs
import React from 'react';

type Props = {
  title: string;
};

const Section: React.FC<Props> = ({ title, children }) => (
  <div className="mb-6 text-xs text-gray-600">
    <h3 className="text-xs mb-2 font-bold">{title}</h3>
    {children}
  </div>
);

export default Section;
