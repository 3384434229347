// Libs
import React from 'react';

// Components
import {
  components,
  OptionTypeBase,
  MenuListComponentProps,
} from '@external/react-select';

const MenuList = ({
  children,
  ...menuProps
}: MenuListComponentProps<OptionTypeBase>) => {
  const { menuRef } = menuProps.selectProps;

  return (
    <div ref={menuRef}>
      <components.MenuList {...menuProps}>{children}</components.MenuList>
    </div>
  );
};

export default MenuList;
