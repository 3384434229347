// Libs
import React from 'react';
import { isNumber } from 'lodash';

// Components
import DeltaValue from './DeltaValue';
import AsOfDate from './AsOfDate';

type Props = {
  title: string;
  deltaLabel?: string;
  deltaValue?: number;
  totalValue?: number;
  isCategoryTitle?: boolean;
  alternativeDescripton?: string;
  wrapperClassName?: string;
  titleClassName?: string;
  asOfDate?: string;
};

const ChartTitle: React.FC<Props> = ({
  title,
  deltaLabel,
  deltaValue,
  totalValue,
  alternativeDescripton,
  isCategoryTitle = true,
  wrapperClassName = 'flex-col desktop-m:flex-row desktop:mb-2',
  titleClassName = 'mb-2 desktop:mr-3 desktop:mb-0',
  asOfDate,
}) => {
  let isDeltaPositive = false;
  let isDeltaNegative = false;

  if (isNumber(deltaValue)) {
    isDeltaPositive = deltaValue > 0 && deltaValue !== totalValue;
    isDeltaNegative = deltaValue < 0;
  }

  const titleClasses = 'capitalize text-gray-600';

  return (
    <>
      <div className={`flex desktop:items-start ${wrapperClassName}`}>
        {isCategoryTitle ? (
          <h2
            className={`font-normal desktop:font-light text-lg desktop:text-xl ${titleClasses} ${titleClassName}`}
          >
            {title}
          </h2>
        ) : (
          <h3
            className={`font-normal text-lg ${titleClasses} ${titleClassName}`}
          >
            {title}
          </h3>
        )}

        {isNumber(deltaValue) && (
          <DeltaValue
            deltaLabel={deltaLabel}
            isDeltaPositive={isDeltaPositive}
            isDeltaNegative={isDeltaNegative}
          />
        )}

        {!deltaValue && alternativeDescripton && (
          <div className="text-xs text-gray-400 desktop:self-center">
            {alternativeDescripton}
          </div>
        )}
      </div>
      {asOfDate && <AsOfDate date={asOfDate} />}
    </>
  );
};

export default ChartTitle;
