import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import { mapValues, some } from 'lodash';

import AddNewOfficerButton from '@components/AddNewOfficer/AddNewOfficerButton';
import SearchMembersForm from '@components/Formik/SearchMembersForm';
import IndividualListColumn from '@components/IndividualListColumn';
import Loading from '@components/Loading';
import MembersList from '@components/MembersList';
import PageSteps from '@components/PageSteps';
import { ResultsList, ResultsListItem } from '@components/ResultsList';
import TableWithTabs from '@components/TableWithTabs';
import { TabInfo } from '@components/TableWithTabs/types';
import Filters from '@presenters/web/components/DistrictMembersFilters';
import { MEMBER_FILTERS } from '@presenters/web/components/DistrictMembersFilters/types';
import Heading from '@presenters/web/components/DistrictSelectMemberStep/Heading';

import MemberItem from './MemberItem';

import {
  CommitteeAppointment,
  CommitteeMemberData,
  DistrictMember,
  mapAndFormatDOToCommitteeMemberData as formatDES,
  isDES,
  OperationType,
  Step,
} from '@domain/districts';

import {
  DistrictContext,
  getBackUrl,
  getClubIdFilterOptions,
  getClubTypesOptions,
  getMemberTypesOptions,
  getValuesFromOptions,
  useMembersFilters,
  useStopBrowserNavigate,
} from '@use-cases/districts';

import {
  useFetchAllDistrictClubs,
  useFetchDistrictMembers,
  useFetchPreviousDES,
} from '@repositories/districts';
import { useLocalStorage } from '@repositories/storage/hooks';

import { getRotaryYear, getYear, isFutureRY } from '@utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import { DistrictOfficer, MembershipType } from '@typings/operations';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
  stepCount?: number;
};

const PAGE_SIZE = 10;
const DEFAULT_STEP_COUNT = 2;
const FUTURE_YEAR_COUNT = 1;

const TAB_DISTRICT_MEMBERS = 'District Members';
const TAB_PREVIOUS_DES = 'Previous District Executive Secretary';

export type SelectMemberTabs =
  | typeof TAB_DISTRICT_MEMBERS
  | typeof TAB_PREVIOUS_DES;

const SelectMemberStep: React.FC<Props> = ({
  districtId,
  riDistrictId,
  stepCount = DEFAULT_STEP_COUNT,
}) => {
  const { t } = useTranslation();
  const [context, setContext] = useContext(DistrictContext);
  const [activeTab, setActiveTab] = useState<TabInfo>({
    name: TAB_DISTRICT_MEMBERS,
    scrollTo: 0,
  });

  const { clearStorage } = useLocalStorage<CommitteeAppointment>();

  const {
    searchValues,
    filterValues,
    currentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
    handlePagination,
  } = useMembersFilters();

  const [fetchDistrictMembers, { data, loading }] = useFetchDistrictMembers();

  const [
    fetchAllDistrictClubs,
    { data: districtClubsData, loading: districtClubsLoading },
  ] = useFetchAllDistrictClubs();
  const [fetchPreviousDES, { data: previousDESData }] = useFetchPreviousDES();

  // Fetch active clubs affiliated with the district for filter by club name
  useEffect(() => {
    if (districtId) {
      fetchAllDistrictClubs({
        variables: {
          districtId,
          status: 'Active',
        },
      });
    }
  }, [fetchAllDistrictClubs, districtId]);

  // Make a call to fetch members
  useEffect(() => {
    if (districtId && riDistrictId) {
      const formattedFilters = mapValues(filterValues, item =>
        getValuesFromOptions(item)
      );

      const { memberTypes, ...filters } = formattedFilters;
      const memberTypeToFetch = memberTypes.length
        ? memberTypes
        : [MembershipType.Member, MembershipType.Rotaractor];

      fetchDistrictMembers({
        variables: {
          district: `${riDistrictId}`,
          isIncludedDistrictLeadership: false,
          isIncludedDistrictMembership: true,
          pageSize: PAGE_SIZE,
          page: currentPage,
          ...searchValues,
          ...filters,
          memberTypes: memberTypeToFetch,
          allowRotaractors: true,
        },
      });
    }
  }, [
    fetchDistrictMembers,
    districtId,
    riDistrictId,
    currentPage,
    searchValues,
    filterValues,
  ]);

  const {
    operationType,
    selectedInfo: { role, unassignFrom, term },
    step,
  } = context;

  const rotaryYear = isFutureRY(getYear(term?.endDate || ''))
    ? Number(getRotaryYear()) + FUTURE_YEAR_COUNT
    : Number(getRotaryYear());

  useEffect(() => {
    if (isDES(role?.name) && riDistrictId) {
      fetchPreviousDES({
        variables: {
          districtId,
          riDistrictId,
          year: rotaryYear,
        },
      });
    }
  }, [role]);
  const handleSelectAssignee = (
    assignee: DistrictMember | CommitteeMemberData
  ) => {
    window.localStorage.removeItem('isNextStepVisited');
    setContext(prevState => ({
      ...prevState,
      step: (prevState.step + 1) as Step,
      selectedInfo: {
        ...prevState.selectedInfo,
        assignee,
      },
    }));
    window.scrollTo(0, 0);
  };

  const handleStepBack = () => {
    window.localStorage.setItem('isNextStepVisited', 'true');
    if (step === 1) {
      clearStorage();
      localizedNavigate(getBackUrl(operationType, districtId));
      return;
    }
    setContext(prevState => ({
      ...prevState,
      step: (prevState.step - 1) as Step,
    }));
    window.scrollTo(0, 0);
  };

  useStopBrowserNavigate({
    isNextStepVisited: true,
    onNavigate: handleStepBack,
  });

  if (loading || districtClubsLoading) {
    return <Loading />;
  }

  // Allowing filtering by clubids instead of clubname for correct club search result.
  const clubs = districtClubsData?.districtClubs?.clubs || [];
  const clubOptionsWithId = getClubIdFilterOptions(clubs, t);

  const multiSelectOptions = {
    // Only Rotarian and Rotaractor values should be displayed
    // To exclude Honorary pass 0 and 1 indexes to pullAt method
    memberTypes: getMemberTypesOptions(t),
    clubTypes: getClubTypesOptions(t),
    clubNames: [],
    districtRoles: [],
    clubIds: clubOptionsWithId,
  };

  const { id, photoUri, nameWithPrefixSuffix } = unassignFrom || {};

  const isReplace = operationType === OperationType.REPLACE_OFFICER;
  const isAssign = operationType === OperationType.ASSIGN_TO_ROLE;

  const isAssignToMember = operationType === OperationType.ASSIGN_TO_MEMBER;

  const isAssignOrReplace = isReplace || isAssign;
  const areTabsMultiple = isDES(role?.name);

  const isRotaractorFilterApplied = some(
    filterValues.memberTypes,
    memberType => memberType.value === MembershipType.Rotaractor
  );

  const isMemberFilterApplied = some(
    filterValues.memberTypes,
    memberType => memberType.value === MembershipType.Member
  );

  const isFilterApplied: { [key in MembershipType]?: boolean } = {
    [MembershipType.Rotaractor]: isRotaractorFilterApplied,
    [MembershipType.Member]: isMemberFilterApplied,
  };

  const membersTab = {
    name: TAB_DISTRICT_MEMBERS,
    title: `${t(
      'district-management.district-members',
      'District members'
    )} (${data?.members.totalCount || 0})`,
    component: (
      <MembersList
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        pageHandler={handlePagination}
        totalCount={data?.members.totalCount || 0}
        members={(data?.members.results as DistrictMember[]) || []}
        memberItem={member => (
          <MemberItem
            member={member}
            selectHandler={handleSelectAssignee}
            isFilterApplied={isFilterApplied}
          />
        )}
        areTabsMultiple={areTabsMultiple}
        filters={
          <Filters
            initialFilterValues={filterValues}
            multiSelectOptions={multiSelectOptions}
            applyFilter={handleApplyFilters}
            resetFilter={handleResetFilters}
            filtersListToShow={[
              MEMBER_FILTERS.CLUB_IDS,
              MEMBER_FILTERS.MEMBER_TYPES,
            ]}
          />
        }
        showFiltersInContextMenu
        headerClassName={classNames('-m-2', { 'pr-14': areTabsMultiple })}
      />
    ),
  };

  const DESList = formatDES(previousDESData?.previousDES as DistrictOfficer[]);

  const desTab = {
    name: TAB_PREVIOUS_DES,
    title: `${t(
      'district-management.district-previous-des',
      'Previous District Executive Secretary'
    )} (${DESList.length || 0})`,
    component: (
      <ResultsList
        darkBackground
        areTabsMultiple={areTabsMultiple}
        headerClassName={classNames(
          'px-8 tablet:px-5 tablet:bg-gray-100 -m-2',
          { 'px-14': areTabsMultiple }
        )}
        className="-mx-8 tablet:mx-0"
      >
        {DESList.map((des: CommitteeMemberData) => (
          <ResultsListItem key={des.id}>
            <MemberItem
              member={des}
              selectHandler={handleSelectAssignee}
              isDES
            />
          </ResultsListItem>
        ))}
      </ResultsList>
    ),
  };

  const tabs = isDES(role?.name) ? [membersTab, desTab] : [membersTab];

  const isSearchDisabled =
    isDES(role?.name) && activeTab.name === TAB_PREVIOUS_DES;

  let notification = '';
  if (isDES(role?.name)) {
    notification = t(
      'select-assign.des-subtitle',
      'Select a District Executive Secretary from previously assigned individuals, or add a new District Executive Secretary.'
    );
  }

  return (
    <PageSteps
      className="p-0"
      backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
      to={isAssignOrReplace ? getBackUrl(operationType, districtId) : undefined}
      backHandler={isAssignOrReplace ? undefined : handleStepBack}
      step={step}
      total={stepCount}
      navClassName="mt-6 mb-0 text-small font-bold"
    >
      <Heading
        roleTitle={role?.name || ''}
        notification={notification}
        title={
          (isReplace
            ? t('select-member-to-replace.title', 'Select a Member to Replace')
            : null) ||
          (isAssignToMember && isDES(role?.name)
            ? t('select-des.title', 'Select District Executive Secretary')
            : t('select-member.title', 'Select a Member'))
        }
        isSubTitleHidden={isDES(role?.name) && isAssignToMember}
        subTitle={
          isReplace ? (
            <IndividualListColumn
              fullName={nameWithPrefixSuffix || ''}
              photoUri={photoUri || ''}
              id={id || ''}
              roleTitle={`${t('global.as', 'as')} ${role?.name}`}
              associatedClubsInfo={[]}
            />
          ) : null
        }
      />
      <AddNewOfficerButton
        roleName={role?.name || ''}
        isAddNewOfficer={isDES(role?.name)}
      />
      <SearchMembersForm
        initialValues={searchValues}
        loading={loading}
        submitHandler={handleSearch}
        title={{
          classes: 'mb-5 tablet:mb-0 mt-2 tablet:normal-case capitalize',
          value: t(
            'district-management.search-members-title',
            'Search district members'
          ),
        }}
        isLabelsHidden
        disabled={isSearchDisabled}
      />
      <TableWithTabs
        activeTabInfo={activeTab}
        tabs={tabs}
        onChangeTab={setActiveTab}
      />
    </PageSteps>
  );
};

export default SelectMemberStep;
