export enum CRUDAction {
  Create = 'Create',
  Read = 'Read',
  Update = 'Update',
  Delete = 'Delete',
}

export enum CRUDResource {
  Conference = 'Conference',
  ConferenceFeedback = 'Conference Feedback',
}

export enum HTTPVerb {
  POST = 'POST',
  GET = 'GET',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}
